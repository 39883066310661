import { EffectCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
    getUsersLocation,
    locationIsCity,
    useRadiusQueryParam,
    useShowVirtualEventsQueryParam,
} from ".";
import { buildEventLocationUrl } from "@skiddle/web.events.helpers";
import {
    updateLocation,
    updateRadius,
    updateShowVirtual,
    FiltersState,
} from "@skiddle/web.events.store.filters";
import { LocationUrlParams, SelectState, UseGetSeoData } from "../types";
import {
    defaultEmptyOptionsMap,
    specialLocations,
} from "@skiddle/web.events.data";
import { EventTypeUrlParams } from "@skiddle/web.events.shared-types";

export const useSetLocationDetails = (
    locationResult: SelectState["locationResult"],
    [latitude, longitude]: SelectState["location"],
    seoResult: SelectState["seoResult"],
    useGetSeoData: UseGetSeoData,
    distance?: number,
    setDistance?: React.Dispatch<React.SetStateAction<number>>,
    useUrlParams: boolean = true,
) => {
    const dispatch = useDispatch();
    const { location: urlLocation } = useParams<LocationUrlParams>();
    const [queryRadius, setRadius] = useRadiusQueryParam();
    const getSeoData = useGetSeoData(locationResult);

    return () => {
        // Load seo data on initial location result fetch
        if (
            !seoResult &&
            locationResult &&
            urlLocation &&
            (urlLocation.toLowerCase() === locationResult.name.toLowerCase() ||
                (urlLocation.toLowerCase() === "newcastle-on-tyne" &&
                    locationResult.name.toLowerCase() ===
                        "newcastle-upon-tyne"))
        ) {
            getSeoData();
        }

        if (
            latitude === undefined &&
            longitude === undefined &&
            locationResult
        ) {
            let radius = distance || 5;
            const {
                latitude: lat,
                longitude: long,
                name,
                place,
            } = locationResult;
            if (latitude === lat && longitude === long) {
                return;
            }
            dispatch(updateLocation(lat, long));

            if (!locationIsCity(name, place) && !queryRadius && setDistance) {
                radius = 2;
                setDistance(radius);
            }
            dispatch(updateRadius(queryRadius || radius));
            if (!queryRadius && useUrlParams) {
                setRadius(radius);
            }

            if (queryRadius && queryRadius !== radius && setDistance) {
                setDistance(queryRadius);
            }
        }
    };
};

export const useOnMount = (
    defaultRadius: number,
    showVirtualEvents: boolean,
    setShowVirtualEvents: React.Dispatch<React.SetStateAction<boolean>>,
    [latitude, longitude]: SelectState["location"],
    radius: SelectState["radius"],
    showVirtual: SelectState["showVirtual"],
    useUrlParams: boolean,
) => {
    const dispatch = useDispatch();
    const { location: urlLocation, poiID: urlPoiID } =
        useParams<LocationUrlParams>();
    const [queryRadius, setRadius] = useRadiusQueryParam();
    const [showVirtualEventsQueryParam] = useShowVirtualEventsQueryParam();

    useEffect(() => {
        const lowerCaseUrlLocation = urlLocation?.toLowerCase();
        if (
            (!lowerCaseUrlLocation ||
                specialLocations.includes(lowerCaseUrlLocation)) &&
            !urlPoiID &&
            latitude === undefined &&
            longitude === undefined &&
            radius === undefined &&
            showVirtual === undefined
        ) {
            dispatch(updateLocation(0, 0));
            dispatch(updateRadius(queryRadius || defaultRadius));
            if (!queryRadius && useUrlParams) {
                setRadius(defaultRadius);
            }

            if (urlLocation === "near-me") {
                (async () => {
                    const [lat, long] = await getUsersLocation();
                    dispatch(updateLocation(lat, long));
                })();
            }
        }

        if (!showVirtual || !showVirtualEvents) {
            let includeVirtualEvents: FiltersState["showVirtual"] = "0";
            if (
                lowerCaseUrlLocation === "online" ||
                showVirtualEventsQueryParam === 1
            ) {
                includeVirtualEvents = "1";
            }

            // Update show virtual events check box
            if (Number(showVirtualEvents) !== Number(includeVirtualEvents)) {
                setShowVirtualEvents(
                    includeVirtualEvents === "1" ? true : false,
                );
            }

            // Update show virtual events filter
            if (Number(showVirtual) !== Number(includeVirtualEvents)) {
                dispatch(updateShowVirtual(includeVirtualEvents));
            }
        }
    }, []);
};

export const useOnLocationResult = (
    distance: number,
    setDistance: React.Dispatch<React.SetStateAction<number>>,
    locationResult: SelectState["locationResult"],
    showVirtual: SelectState["showVirtual"],
    locationFilter: SelectState["location"],
    seoResult: SelectState["seoResult"],
    useGetSeoData: UseGetSeoData,
) => {
    const dispatch = useDispatch();
    const { location: urlLocation } = useParams<LocationUrlParams>();
    const [queryShowVirtual] = useShowVirtualEventsQueryParam();

    const setLocationDetails = useSetLocationDetails(
        locationResult,
        locationFilter,
        seoResult,
        useGetSeoData,
        distance,
        setDistance,
    );

    useEffect(() => {
        setLocationDetails();

        if (!showVirtual) {
            dispatch(updateShowVirtual(queryShowVirtual === 1 ? "1" : "0"));
        }
    }, [urlLocation, locationResult]);
};

export const useOnMultipleLocationResults = (
    distance: number,
    poiID: string,
    setDistance: React.Dispatch<React.SetStateAction<number>>,
    locations: SelectState["locations"],
    [latitude, longitude]: SelectState["location"],
    locationGet: SelectState["locationGet"],
    useUrlParams: boolean,
) => {
    const dispatch = useDispatch();
    const { location: urlLocation } = useParams<LocationUrlParams>();
    const [radius, setRadius] = useRadiusQueryParam();

    useEffect(() => {
        if (
            latitude === undefined &&
            longitude === undefined &&
            poiID === "" &&
            locations
        ) {
            const location = locations.find(
                ({ category }) => category === "city",
            );
            if (location && location?.latitude && location?.longitude) {
                locationGet(location.id);
                dispatch(updateLocation(location.latitude, location.longitude));
            }

            dispatch(updateRadius(radius || distance));
            if (!radius && useUrlParams) {
                setRadius(distance);
            }

            if (radius && radius !== distance) {
                setDistance(radius);
            }
        }
    }, [urlLocation, locations]);
};

export const useOnUrlChange = (
    location: string,
    poiID: string,
    setSearchLocation: (location: string) => void,
    setPoiID: (poiID: string) => void,
) => {
    const dispatch = useDispatch();
    const {
        location: urlLocation = "",
        poiName = "",
        poiID: urlPoiID = "",
    } = useParams<LocationUrlParams>();

    // If urlLocation or urlPoiID changes and isn't the same as what's set in state then
    // browser navigation buttons where likely clicked and reload location
    useEffect(() => {
        const lowerCaseLocation = location.toLowerCase();
        const lowerCaseUrlLocation = urlLocation.toLowerCase();
        const parsedPoiName = poiName.toLowerCase().replace("-", " ");
        const parsedUrlLocation = urlPoiID.replace(/([\w]+)-/, "");
        const lowerCaseUrlLocationNoHyphen = urlLocation.replace("-", " ");

        if (
            ((lowerCaseLocation !== lowerCaseUrlLocation &&
                lowerCaseLocation !== parsedPoiName &&
                lowerCaseUrlLocationNoHyphen !== lowerCaseLocation) ||
                (poiID !== parsedUrlLocation && parsedPoiName !== "")) &&
            !(
                lowerCaseLocation === "newcastle upon tyne" &&
                lowerCaseUrlLocation === "newcastle-on-tyne"
            )
        ) {
            if (!specialLocations.includes(lowerCaseUrlLocation)) {
                dispatch(updateLocation(undefined, undefined));
            }

            if (specialLocations.includes(lowerCaseUrlLocation)) {
                if (lowerCaseUrlLocation === "near-me") {
                    // If the current name is 'show events near me' then it is near-me and don't get users location
                    if (defaultEmptyOptionsMap.has(location)) {
                        return;
                    }

                    (async () => {
                        const [lat, long] = await getUsersLocation();
                        dispatch(updateLocation(lat, long));
                    })();
                } else {
                    dispatch(updateLocation(0, 0));
                }
            }
            setPoiID(urlPoiID || "");
            setSearchLocation(urlLocation || "");
        }
    }, [urlLocation, urlPoiID]);
};

export const useOnLocationStoreChange = (
    setLocation: (location: string) => void,
    setButtonText: (val: string) => void,
    [longitude, latitude]: SelectState["location"],
    pathPrefix: string,
    useUrlParams: boolean,
) => {
    const { location: urlLocation = "", eventType } = useParams<
        LocationUrlParams & EventTypeUrlParams
    >();

    const history = useHistory();

    useEffect(() => {
        const lowerCaseUrlLocation = urlLocation?.toLowerCase();

        if (
            latitude === 0 &&
            longitude === 0 &&
            !specialLocations.includes(lowerCaseUrlLocation)
        ) {
            setLocation("");
            setButtonText("Location");
            if (useUrlParams) {
                history.push(
                    buildEventLocationUrl(
                        "all",
                        pathPrefix,
                        "",
                        "",
                        "",
                        !eventType ? true : false,
                        eventType,
                    ),
                );
            }
        }
    }, [latitude, longitude]);
};

export const useOnRadiusStoreChange = (
    distance: number,
    setDistance: React.Dispatch<React.SetStateAction<number>>,
    radius: SelectState["radius"],
    useUrlParams: boolean,
) => {
    const [, setRadiusParam] = useRadiusQueryParam();

    useEffect(() => {
        if (radius !== undefined && radius !== distance) {
            setDistance(radius);
            if (useUrlParams) {
                setRadiusParam(radius);
            }
        }
    }, [radius]);
};

export const useOnLocationUrlChange = (cb: EffectCallback) => {
    const {
        location: urlLocation = "",
        poiName = "",
        poiID: urlPoiID = "",
    } = useParams<LocationUrlParams>();

    useEffect(cb, [urlLocation, poiName, urlPoiID]);
};

export const useOnOpenFilter = (
    open: boolean,
    virtualEventsChecked: boolean,
    setShowVirtualEvents: React.Dispatch<React.SetStateAction<boolean>>,
    showVirtualFilter: SelectState["showVirtual"],
) => {
    useEffect(() => {
        if (
            open &&
            Number(showVirtualFilter) !== Number(virtualEventsChecked)
        ) {
            setShowVirtualEvents(showVirtualFilter === "1" ? true : false);
        }
    }, [open]);
};

export const useOnShowVirtualQueryParamChange = (
    setShowVirtualEvents: React.Dispatch<React.SetStateAction<boolean>>,
    showVirtual: SelectState["showVirtual"],
) => {
    const { location: urlLocation } = useParams<LocationUrlParams>();
    const [showVirtualQueryParam = 0] = useShowVirtualEventsQueryParam();
    const dispatch = useDispatch();

    useEffect(() => {
        if (
            showVirtualQueryParam !== Number(showVirtual) &&
            urlLocation?.toLowerCase() !== "online"
        ) {
            setShowVirtualEvents(showVirtualQueryParam === 1 ? true : false);
            dispatch(
                updateShowVirtual(showVirtualQueryParam === 1 ? "1" : "0"),
            );
        }
    }, [showVirtualQueryParam]);
};
