import React, { useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { Props } from "./types";
import ucwords from "ucwords";
import useStyles from "./style";
import { IconButton } from "@material-ui/core";
import { InIframeContext } from "@skiddle/web.events.filters.context";

const FilterDialog: React.FC<Props> = ({
    open,
    toggleOpen,
    children,
    onConfirm,
    title,
    onClear,
    height,
    fullWidth = true,
    width,
    clearLabel = "clear",
    disabled = false,
}) => {
    const classes = useStyles({ height, width });
    const inIframe = useContext(InIframeContext);

    return (
        <Dialog
            open={open}
            onClose={toggleOpen}
            classes={{
                paper: classes.paper,
                container: inIframe ? classes.containerIframe : undefined,
            }}
            fullWidth={width || !fullWidth ? false : true}
        >
            <DialogTitle classes={{ root: classes.title }}>
                {ucwords(title)}

                <IconButton
                    onClick={toggleOpen}
                    style={{ float: "right" }}
                    size={"small"}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions className={classes.action}>
                {onClear && (
                    <div>
                        <Button
                            variant="contained"
                            color="default"
                            onClick={onClear}
                            className={classes.button}
                        >
                            {clearLabel}
                        </Button>
                    </div>
                )}
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onConfirm}
                        className={classes.button}
                        disabled={disabled}
                    >
                        Confirm
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default FilterDialog;
