import React, { forwardRef, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import AutocompleteMui from "@material-ui/lab/Autocomplete";
import { Props } from "./types";
import { NoSsr, Typography } from "@material-ui/core";

const Autocomplete = forwardRef<HTMLInputElement, Props>(
    (
        {
            options,
            onChange,
            inputChange,
            className,
            name,
            value,
            withAddNew = false,
            loading = false,
            fontSize,
        },
        ref,
    ) => {
        const newOptions = useMemo(() => {
            if (withAddNew) {
                return [
                    {
                        title: `Add New ${name}`,
                        value: "add",
                    },
                    ...options,
                ];
            }

            return options;
        }, [options]);

        return (
            <NoSsr
                fallback={
                    <TextField
                        label={name}
                        variant="standard"
                        onChange={inputChange}
                        inputProps={
                            fontSize
                                ? {
                                      style: {
                                          fontSize,
                                      },
                                  }
                                : {}
                        }
                        value={value}
                    />
                }
            >
                <AutocompleteMui
                    className={className}
                    onChange={(e, val) =>
                        onChange &&
                        onChange(
                            typeof val === "string" ? val : val?.value || "",
                        )
                    }
                    freeSolo
                    options={newOptions}
                    getOptionLabel={({ title }) => title || value}
                    defaultValue={value}
                    filterOptions={(opts) => opts}
                    renderOption={({ title, subtitle, icon: Icon }) => (
                        <div>
                            <Typography
                                variant="h6"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {Icon && (
                                    <>
                                        <Icon style={{ marginRight: 10 }} />
                                    </>
                                )}
                                {title}
                            </Typography>
                            <Typography variant="subtitle1">
                                {subtitle}
                            </Typography>
                        </div>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={name}
                            variant="standard"
                            onChange={inputChange}
                            inputProps={
                                fontSize
                                    ? {
                                          ...params.inputProps,
                                          style: {
                                              fontSize,
                                          },
                                      }
                                    : params.inputProps
                            }
                            inputRef={ref}
                        />
                    )}
                    value={value}
                />
            </NoSsr>
        );
    },
);

export default Autocomplete;
