import React, { useEffect, useRef, useState } from "react";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Slider,
    Snackbar,
    Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import SearchButton from "@skiddle/web.events.filters.button";
import FilterDialog from "@skiddle/web.events.filters.dialog";
import { LocationViewProps as Props } from "../types";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {
    defaultEmptyOptionsMap,
    specialLocations,
} from "@skiddle/web.events.data";
import LocationSearchInput from "./search-input";

const marks = [
    {
        value: 1,
    },
    {
        value: 5,
    },
    {
        value: 10,
    },
    {
        value: 20,
    },
    {
        value: 30,
    },
    {
        value: 40,
    },
    {
        value: 50,
    },
];

const LocationView: React.FC<Props> = ({
    open,
    toggleOpen,
    confirmLocation,
    onClear,
    parsedLocations,
    locationSelect,
    searchInputChange,
    searchLocation,
    distance,
    distanceChange,
    buttonText,
    filterOpen,
    isActive,
    showVirtualEvents,
    handleVirtualEventsChange,
    isMobile,
    canSubmit,
    locationResult,
}) => {
    const locationInputRef = useRef<HTMLInputElement>(null);
    const [filterErrorMessage, setFilterErrorMessage] = useState("");
    const [showFilterError, setShowFilterError] = useState(false);

    useEffect(() => {
        const currentSetInterval = setInterval(() => {
            if (locationInputRef?.current && open) {
                locationInputRef.current.focus();
                clearInterval(currentSetInterval);
            }
        }, 200);
    }, [locationInputRef, open]);

    const closeFilterErrorMessage = () => {
        setShowFilterError(false);
        setFilterErrorMessage("");
    };

    const validateSearchFilters = () => {
        let errorMessage = "";
        if (
            parsedLocations.length > 0 &&
            !locationResult &&
            !defaultEmptyOptionsMap.has(searchLocation)
        ) {
            errorMessage = "Please select a location from the dropdown";
        }
        if (errorMessage === "") {
            confirmLocation();
        } else {
            setFilterErrorMessage(errorMessage);
            setShowFilterError(true);
        }
    };

    return (
        <>
            <FilterDialog
                title="location"
                open={open}
                toggleOpen={toggleOpen}
                onConfirm={validateSearchFilters}
                onClear={onClear}
                clearLabel="show events country wide"
                disabled={!canSubmit}
            >
                <LocationSearchInput
                    locationSelect={locationSelect}
                    parsedLocations={parsedLocations}
                    searchInputChange={searchInputChange}
                    searchLocation={searchLocation}
                    ref={locationInputRef}
                />
                <>
                    {!defaultEmptyOptionsMap.has(searchLocation) &&
                        !specialLocations.includes(
                            searchLocation.toLowerCase(),
                        ) && (
                            <>
                                <FormGroup style={{ marginTop: 10 }}>
                                    Distance: {distance} miles
                                    <Slider
                                        onChange={distanceChange}
                                        min={1}
                                        max={50}
                                        marks={marks}
                                        value={distance}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={showVirtualEvents}
                                                onChange={
                                                    handleVirtualEventsChange
                                                }
                                                name="include-virtual-events"
                                            />
                                        }
                                        label="Also show nationwide virtual/online events"
                                    />
                                </FormGroup>
                            </>
                        )}
                </>
                <Snackbar
                    open={showFilterError}
                    autoHideDuration={3000}
                    onClose={closeFilterErrorMessage}
                    anchorOrigin={{ horizontal: "center", vertical: "top" }}
                    onClick={closeFilterErrorMessage}
                    style={{ cursor: "pointer" }}
                >
                    <Alert severity="warning">
                        <Typography variant="body1">
                            {filterErrorMessage}
                        </Typography>
                    </Alert>
                </Snackbar>
            </FilterDialog>
            <SearchButton
                title={buttonText}
                onClick={filterOpen}
                isActive={isActive}
                icon={<LocationOnIcon />}
                minWidth={isMobile ? 99 : 0}
            />
        </>
    );
};

export default LocationView;
