import React from "react";
import EventTypesView from "./view";
import useCombinedHooks from "./hooks";
import { EventCodes } from "@skiddle/web.events.shared-types";
import { Props } from "./types";

export const defaultValue: EventCodes[] = [];

const EventTypesFilter: React.FC<Props> = ({ useGetSeoData }) => {
    const {
        open,
        toggleOpen,
        confirmEventTypes,
        clearEventTypes,
        eventTypes,
        selectedEventTypes,
        setSelectedEventTypes,
        buttonTitle,
        isActive,
    } = useCombinedHooks(defaultValue, useGetSeoData);

    return (
        <EventTypesView
            open={open}
            toggleOpen={toggleOpen}
            confirmEventTypes={confirmEventTypes}
            clearEventTypes={clearEventTypes}
            eventTypes={eventTypes}
            selectedEventTypes={selectedEventTypes}
            setSelectedEventTypes={setSelectedEventTypes}
            buttonTitle={buttonTitle}
            isActive={isActive}
        />
    );
};

export default EventTypesFilter;
