import {
    GenresFetch,
    Genre,
    GenresFetchSuccess,
    GetGenres,
    GenresActions,
    GenresAggregationFetch,
    GenresAggregationFetchSuccess,
    GetGenreCounts,
} from "./types";
import { State, APIResponse } from "../types";
import { Dispatch } from "redux";
import { genreIsFetching } from "./selectors";
import "isomorphic-fetch";
import { aggregationResults } from "../aggregations/selectors";
import { GenreAggregation } from "@skiddle/web.events.shared-types";
import { apiError } from "@skiddle/web.events.store.types";

const genresFetch = (): GenresFetch => ({
    type: "GENRES_FETCH",
});

const genresFetchSuccess = (results: Genre[]): GenresFetchSuccess => ({
    type: "GENRES_FETCH_SUCCESS",
    results,
});

export const genreAggregationFetch = (): GenresAggregationFetch => ({
    type: "GENRES_AGGREGATION_FETCH",
});

export const genreAggregationFetchSuccess = (
    aggregations: GenreAggregation,
): GenresAggregationFetchSuccess => ({
    type: "GENRES_AGGREGATION_FETCH_SUCCESS",
    aggregations,
});

export const getGenres: GetGenres =
    (state: State, dispatch: Dispatch<GenresActions>) => async () => {
        if (genreIsFetching(state)) {
            return;
        }

        dispatch(genresFetch());

        try {
            const results = await fetchGenres();
            dispatch(genresFetchSuccess(results));
        } catch (err) {
            console.log(err);
            dispatch(apiError(err as string));
        }
    };

export const fetchGenres = async () => {
    try {
        const apiURL = process.env.REACT_APP_API_URL;
        const publicKey = process.env.REACT_APP_PUB_KEY;
        const data = new URLSearchParams(
            Object.entries({
                order: 3,
                pub_key: publicKey,
                platform: "web",
            }) as string[][],
        );
        const resp = await fetch(`${apiURL}/v2/genres/?${data}`, {
            method: "GET",
            mode: "cors",
        });

        const { error, errormessage, results }: APIResponse<Genre[]> =
            await resp.json();

        if (error && errormessage) {
            throw new Error(errormessage);
        }

        return results;
    } catch (err) {
        throw err;
    }
};

export const getGenreCounts: GetGenreCounts =
    (state: State, dispatch: Dispatch<GenresActions>) => async () => {
        dispatch(genreAggregationFetch());

        try {
            const results = aggregationResults(state);

            if (results && results.genreids) {
                dispatch(genreAggregationFetchSuccess(results.genreids));
            }
        } catch (err) {
            console.log(err);
            dispatch(apiError(err as string));
        }
    };
