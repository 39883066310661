import React from "react";
import KeywordView from "./view";
import useCombinedHooks from "./hooks";
import { useIsMobile } from "@skiddle/web.events.hooks";
import { Props } from "./types";

export const defaultValue = "";

const KeywordFilter: React.FC<Props> = ({
    keywordState,
    useUrlParams = true,
}) => {
    const isMobile = useIsMobile();
    const {
        open,
        toggleOpen,
        confirmKeyword,
        clearKeyword,
        searchChange,
        keyword,
        isActive,
    } = useCombinedHooks(defaultValue, keywordState, useUrlParams);

    return (
        <KeywordView
            open={open}
            toggleOpen={toggleOpen}
            confirmKeyword={confirmKeyword}
            clearKeyword={clearKeyword}
            searchChange={searchChange}
            keyword={keyword}
            filterKeyword={keywordState || ""}
            isMobile={isMobile}
            isActive={isActive}
        />
    );
};

export default KeywordFilter;
