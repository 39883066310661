import { Reducer } from "redux";
import { GenresState, GenresActions } from "./types";

export const initialState: GenresState = {
    fetching: {
        genres: false,
        aggregation: false,
    },
    results: [],
    eventCounts: {},
};
const reducer: Reducer<GenresState, GenresActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "GENRES_FETCH":
            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    genres: true,
                },
            };

        case "GENRES_FETCH_SUCCESS":
            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    genres: false,
                },
                results: action.results,
            };

        case "GENRES_AGGREGATION_FETCH":
            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    aggregation: true,
                },
            };

        case "GENRES_AGGREGATION_FETCH_SUCCESS":
            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    aggregation: false,
                },
                eventCounts: action.aggregations,
            };

        default:
            return state;
    }
};

// const appendEventCounts = (
//     eventCounts: GenreAggregation,
//     aggregations: GenreAggregation,
// ): GenreAggregation =>
//     Object.keys(aggregations).reduce<GenreAggregation>((aggs, genreID) => {
//         const genreIDNum = Number(genreID);
//         aggs[genreIDNum] =
//             eventCounts[genreIDNum] || 0 + aggregations[genreIDNum] || 0;
//
//         return aggs;
//     }, {});

export default reducer;
