import { fade, makeStyles } from "@material-ui/core/styles";
import { lighterBlack, white } from "@skiddle/web.global.theme";

const useStyles = makeStyles((theme) => ({
    drawer: {
        "@media (min-width: 600px)": {
            display: "none",
        },
    },
    listTitle: {
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    listTitleSelected: {
        backgroundColor: "#3cb8b01a !important",
    },
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    nestedDeep: {
        paddingLeft: theme.spacing(6),
    },
    drawerPaper: {
        backgroundColor: lighterBlack,
        color: white,
    },
    icon: {
        fill: white,
    },
    divider: {
        backgroundColor: fade(white, 0.22),
    },
}));

export default useStyles;
