import { Reducer } from "redux";
import { CategoryEventsActions, CategoryEventsState } from "./types";

export const initialState: CategoryEventsState = {
    clubsFetching: false,
    gigsFetching: false,
    festivalFetching: false,
    comedyFetching: false,
    experienceFetching: false,
    foodFetching: false,

    clubsHasFetched: false,
    gigsHasFetched: false,
    festivalHasFetched: false,
    comedyHasFetched: false,
    experienceHasFetched: false,
    foodHasFetched: false,

    clubsEvents: [],
    gigsEvents: [],
    festivalEvents: [],
    comedyEvents: [],
    experienceEvents: [],
    foodEvents: [],
};

const reducer: Reducer<CategoryEventsState, CategoryEventsActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "EVENT_CATEGORIES_FETCH":
            return {
                ...state,
                [action.fetchingCategory]: true,
            };

        case "EVENT_CATEGORIES_FETCH_SUCCESS":
            switch (action.categoryType) {
                case "CLUB":
                    return {
                        ...state,
                        clubsFetching: false,
                        clubsHasFetching: true,
                        clubsEvents: action.result,
                    };

                case "LIVE":
                    return {
                        ...state,
                        gigsFetching: false,
                        gigsHasFetched: true,
                        gigsEvents: action.result,
                    };
                case "FEST":
                    return {
                        ...state,
                        festivalFetching: false,
                        festivalHasFetched: true,
                        festivalEvents: action.result,
                    };
                case "COMEDY,THEATRE,ARTS,FILM,DANCE":
                    return {
                        ...state,
                        comedyFetching: false,
                        comedyEvents: action.result,
                    };
                case "EXHIB,EXPER,DRIVEIN,KIDS":
                    return {
                        ...state,
                        experienceFetching: false,
                        experienceHasFetched: true,
                        experienceEvents: action.result,
                    };
                case "FOOD,BARPUB":
                    return {
                        ...state,
                        foodFetching: false,
                        foodHasFetched: true,
                        foodEvents: action.result,
                    };

                default:
                    return state;
            }

        case "API_ERROR":
            const someEventsLoaded = Object.entries(state)
                .filter(([key]) => key.includes("events"))
                .some(([, value]) => value.length > 0);

            if (someEventsLoaded) {
                return { ...state };
            }

            return initialState;

        default:
            return state;
    }
};

export default reducer;
