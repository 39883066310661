import { makeStyles } from "@material-ui/core/styles";
import { boxShadow, white } from "../../../../theme";

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: boxShadow,
        background: "center black",
        backgroundSize: "cover",
        minHeight: "105px",
        paddingBottom: "5px",
        // desktop
        [theme.breakpoints.up("sm")]: {
            minHeight: "130px",
            maxHeight: "130px",
        },
    },
    title: {
        textTransform: "lowercase",
        color: white,
        textAlign: "center",
        fontSize: "2.8rem",
        marginBlockEnd: "0.4em",
        marginBlockStart: "0.4em",
        textShadow: "1px 1px 5px #131313",
    },
}));

export default useStyles;
