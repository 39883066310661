import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
    canSubmitLocationFilter,
    Location,
} from "@skiddle/web.events.store.location";
import {
    DefaultLocationValues,
    LocationUrlParams,
    SelectState,
    UseGetSeoData,
    UseSearchLocation,
} from "../types";
import ucwords from "ucwords";
import {
    useOnLocationResult,
    useOnLocationStoreChange,
    useOnMount,
    useOnMultipleLocationResults,
    useOnOpenFilter,
    useOnRadiusStoreChange,
    useOnShowVirtualQueryParamChange,
    useOnUrlChange,
} from "./effects";
import {
    useChangeDistance,
    useConfirmLocation,
    useLocationSelect,
    useOnClear,
    useSearchInputChange,
    useShowVirtualEventsChange,
} from "./event-handlers";
import { useQueryParam, NumberParam } from "use-query-params";
import { useToggleOpen } from "@skiddle/web.events.hooks";
import { Option } from "@skiddle/web.events.shared-types";
import {
    defaultEmptyOptions,
    defaultEmptyOptionsMap,
} from "@skiddle/web.events.data";
import { locationClear } from "@skiddle/web.events.store.location";
import { SEOClear } from "@skiddle/web.events.store.types";

import cookie from "js-cookie";
export const locationIsCity = (name: string | null, place: string | null) => {
    if (!place || !name) {
        return false;
    }

    return place.includes(name);
};

const parseLocations = (locations: Location[]): Option[] =>
    locations.map(({ name, displayCategory, id, place }) => {
        let subtitle = displayCategory;
        if (!locationIsCity(name, place)) {
            subtitle = place;
        }

        return {
            title: name,
            subtitle,
            value: `${id}-${name}`,
        };
    });

const parseButtonText = (text: string): string => {
    text = text.toLowerCase() === "all" ? "Location" : text || "Location";
    text = text === "" ? "Location" : text;

    return text;
};

const useCombinedHooks = (
    { location, radius }: DefaultLocationValues,
    getLocation: SelectState["locationGet"],
    getLocations: SelectState["locationSearch"],
    locationFilter: SelectState["location"],
    locationController: SelectState["locationController"],
    locationResult: SelectState["locationResult"],
    locations: SelectState["locations"],
    radiusFilter: SelectState["radius"],
    showVirtual: SelectState["showVirtual"],
    seoResult: SelectState["seoResult"],
    useGetSeoData: UseGetSeoData,
    pathPrefix: string,
    seoClear: (() => SEOClear) | undefined,
    useUrlParams: boolean,
) => {
    const [open, setOpen] = useState(false);
    const [isCleared, setIsCleared] = useState(false);
    const [showVirtualEvents, setShowVirtualEvents] = useState(false);
    const [userChanged, setUserChanged] = useState<boolean>(false);
    const {
        location: urlLocation,
        poiName,
        poiID: urlPoiID,
    } = useParams<LocationUrlParams>();
    const [poiID, setPoiID] = usePoiID(
        urlPoiID,
        locationFilter,
        getLocation,
        locationController,
        locationResult,
    );
    const [searchLocation, setSearchLocation] = useSearchLocation(
        poiName,
        urlLocation,
        location,
        isCleared,
        setIsCleared,
        poiID,
        locationController,
        getLocations,
        locations,
        locationResult,
        pathPrefix,
        seoClear,
    );
    const [distance, setDistance] = useDistance(radius, radiusFilter);
    const [buttonText, setButtonText] = useButtonText(searchLocation);
    const toggleOpen = useToggleOpen(setOpen);
    const filterOpen = useFilterOpen(buttonText, toggleOpen, setSearchLocation);
    const searchInputChange = useSearchInputChange(setSearchLocation, setPoiID);
    const locationSelect = useLocationSelect(
        setSearchLocation,
        setPoiID,
        setDistance,
        setShowVirtualEvents,
        locations,
    );
    const changeDistance = useChangeDistance(setDistance);
    const confirmLocation = useConfirmLocation(
        searchLocation,
        distance,
        poiID,
        setOpen,
        setButtonText,
        showVirtualEvents,
        locationResult,
        locationFilter,
        radiusFilter,
        showVirtual,
        useGetSeoData,
        pathPrefix,
        seoClear,
        useUrlParams,
        setUserChanged,
    );
    const isActive = useIsActive(
        urlLocation,
        pathPrefix,
        locationResult,
        buttonText,
        useUrlParams,
        userChanged,
    );
    const onClear = useOnClear(
        radius,
        setPoiID,
        setButtonText,
        setDistance,
        setSearchLocation,
        setShowVirtualEvents,
        setOpen,
        seoClear,
        pathPrefix,
        useUrlParams,
        setUserChanged,
    );
    const parsedLocations = useParsedLocations(locations);
    const handleVirtualEventsChange =
        useShowVirtualEventsChange(setShowVirtualEvents);

    useSetFilterInitialValues(
        searchLocation,
        radius,
        distance,
        setDistance,
        poiID,
        setSearchLocation,
        setPoiID,
        setButtonText,
        showVirtualEvents,
        setShowVirtualEvents,
        open,
        locationFilter,
        radiusFilter,
        showVirtual,
        locationResult,
        seoResult,
        useGetSeoData,
        locations,
        getLocation,
        pathPrefix,
        useUrlParams,
    );

    return {
        open,
        poiID,
        searchLocation,
        distance,
        buttonText,
        filterOpen,
        toggleOpen,
        searchInputChange,
        locationSelect,
        changeDistance,
        confirmLocation,
        isActive,
        onClear,
        parsedLocations,
        showVirtualEvents,
        handleVirtualEventsChange,
    };
};

export const useRadiusQueryParam = () => useQueryParam("radius", NumberParam);
export const useShowVirtualEventsQueryParam = () =>
    useQueryParam("showvirtual", NumberParam);

const usePoiID = (
    urlPoiID: string | undefined,
    [latitude, longitude]: SelectState["location"],
    locationGet: SelectState["locationGet"],
    locationController: SelectState["locationController"],
    locationResult: SelectState["locationResult"],
): [string, (poiID: string) => void] => {
    const initialPoiIDValue = (() => {
        if (urlPoiID) {
            return urlPoiID.replace(/([\w]+)-/, "");
        }

        return "";
    })();

    const dispatch = useDispatch();
    const [poiID, setPoiID] = useState(initialPoiIDValue);

    const getPoiID = (poiID: string) => {
        if (
            poiID === "" &&
            latitude === undefined &&
            longitude === undefined &&
            locationResult
        ) {
            dispatch(locationClear());
        }

        poiID = poiID.replace(/([\w]+)-/, "");

        if (poiID !== "") {
            locationController?.abort();
            locationGet(poiID);
        }

        setPoiID(poiID);
    };

    useEffect(() => {
        if (locationResult?.id !== initialPoiIDValue) {
            getPoiID(initialPoiIDValue);
        }
    }, []);

    return [poiID, getPoiID];
};

const useSearchLocation = (
    poiName: string | undefined,
    urlLocation: string | undefined,
    defaultLocation: string,
    isCleared: boolean,
    setIsCleared: React.Dispatch<React.SetStateAction<boolean>>,
    poiID: string,
    locationController: SelectState["locationController"],
    locationSearch: SelectState["locationSearch"],
    locations: SelectState["locations"],
    locationResult: SelectState["locationResult"],
    pathPrefix: string,
    seoClear: (() => SEOClear) | undefined,
): UseSearchLocation => {
    // Parse initial location value
    const initialLocationValue = ucwords(
        (() => {
            if (poiName) {
                return ucwords(poiName);
            }

            if (urlLocation && locationResult) {
                return ucwords(locationResult.name);
            }

            if (urlLocation) {
                return ucwords(urlLocation?.replace(pathPrefix, ""));
            }

            return defaultLocation;
        })(),
    ).replace("-", " ");

    const dispatch = useDispatch();
    const [searchLocation, setSearchLocation] = useState(initialLocationValue);

    const searchForLocation = (location: string) => {
        dispatch(canSubmitLocationFilter(true));

        // If the search input is typed in then set is cleared state to false so on confirm the filter isn't cleared
        if (isCleared && location !== "") {
            setIsCleared(false);
        }

        if (
            defaultEmptyOptionsMap.has(location) ||
            defaultEmptyOptionsMap.has(location.toLowerCase()) ||
            defaultEmptyOptionsMap.has(location.replace("-", " "))
        ) {
            setSearchLocation(location);
            return;
        }

        if (
            location.length >= 3 &&
            poiID === "" &&
            ((!(
                searchLocation.toLowerCase() === "newcastle upon tyne" &&
                location.toLowerCase() === "newcastle-on-tyne"
            ) &&
                searchLocation !== location) ||
                locations.length === 0)
        ) {
            locationController?.abort();
            locationSearch(location, 10);
        }

        // Clear location if there are locations in the store as not to show locations for old searches
        if (location.length <= 3 && locations.length > 0) {
            locationController?.abort();
            dispatch(locationClear());
            // when clearing the location, we also need to clear seo values for the rest of the page
            if (seoClear) dispatch(seoClear());
        }

        setSearchLocation(location);
    };

    useEffect(() => {
        const initialLocationValueLower = initialLocationValue.toLowerCase();
        if (
            !defaultEmptyOptionsMap.has(initialLocationValueLower) &&
            initialLocationValueLower !== locationResult?.name.toLowerCase()
        ) {
            searchForLocation(initialLocationValue);
        }
    }, []);

    return [searchLocation, searchForLocation];
};

const useDistance = (defaultRadius: number, radius: SelectState["radius"]) => {
    return useState(radius || defaultRadius);
};

const useButtonText = (defaultVal: string): [string, (val: string) => void] => {
    const [buttonText, setButtonText] = useState(parseButtonText(defaultVal));
    const setParsedButtonText = (val: string) => {
        setButtonText(parseButtonText(val));
    };

    return [buttonText, setParsedButtonText];
};

const useParsedLocations = (locations: SelectState["locations"]) => {
    return useMemo(() => {
        if (locations.length > 0) {
            return parseLocations(locations);
        }

        return defaultEmptyOptions;
    }, [locations]);
};

const useSetFilterInitialValues = (
    location: string,
    radius: number,
    distance: number,
    setDistance: React.Dispatch<React.SetStateAction<number>>,
    poiID: string,
    setLocation: (location: string) => void,
    setPoiID: (poiID: string) => void,
    setButtonText: (val: string) => void,
    showVirtualEvents: boolean,
    setShowVirtualEvents: React.Dispatch<React.SetStateAction<boolean>>,
    open: boolean,
    locationFilter: SelectState["location"],
    radiusFilter: SelectState["radius"],
    showVirtual: SelectState["showVirtual"],
    locationResult: SelectState["locationResult"],
    seoResult: SelectState["seoResult"],
    useGetSeoData: UseGetSeoData,
    locations: SelectState["locations"],
    locationGet: SelectState["locationGet"],
    pathPrefix: string,
    useUrlParams: boolean,
) => {
    useOnMount(
        radius,
        showVirtualEvents,
        setShowVirtualEvents,
        locationFilter,
        radiusFilter,
        showVirtual,
        useUrlParams,
    );
    useOnLocationResult(
        distance,
        setDistance,
        locationResult,
        showVirtual,
        locationFilter,
        seoResult,
        useGetSeoData,
    );
    useOnMultipleLocationResults(
        distance,
        poiID,
        setDistance,
        locations,
        locationFilter,
        locationGet,
        useUrlParams,
    );
    useOnUrlChange(location, poiID, setLocation, setPoiID);
    useOnRadiusStoreChange(distance, setDistance, radiusFilter, useUrlParams);
    useOnLocationStoreChange(
        setLocation,
        setButtonText,
        locationFilter,
        pathPrefix,
        useUrlParams,
    );
    useOnOpenFilter(open, showVirtualEvents, setShowVirtualEvents, showVirtual);
    useOnShowVirtualQueryParamChange(setShowVirtualEvents, showVirtual);
};

const useFilterOpen =
    (
        buttonText: string,
        toggleOpen: () => void,
        setSearchLocation: (location: string) => void,
    ) =>
    () => {
        buttonText = buttonText === "Location" ? "" : buttonText;
        toggleOpen();
        setSearchLocation(buttonText);
    };

const useIsActive = (
    urlLocation: string = "",
    pathPrefix: string,
    locationResult: SelectState["locationResult"],
    buttonText: string,
    useUrlParams: boolean,
    userChanged: boolean,
) => {
    if (!userChanged) {
        return false;
    }

    const parsedUrlLocation = urlLocation.replace(pathPrefix, "");

    if (useUrlParams) {
        if (parsedUrlLocation === "") {
            return false;
        }

        if (parsedUrlLocation === "all") {
            return false;
        }
    }

    if (!useUrlParams) {
        if (
            locationResult?.name !== buttonText &&
            !defaultEmptyOptionsMap.has(buttonText.toLowerCase())
        ) {
            return false;
        }
    }

    return true;
};

export const getUsersLocation = async (): Promise<[number, number]> => {
    try {
        const { lat, long } = await new Promise<{ lat: number; long: number }>(
            (resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    ({ coords: { latitude, longitude } }) =>
                        resolve({ lat: latitude, long: longitude }),
                    reject,
                );
            },
        );

        // Store location in cookie for ssr to access it
        cookie.set("near-me", JSON.stringify({ lat, long }), {
            domain: ".skiddle.com",
        });

        return [lat, long];
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export default useCombinedHooks;
