import React from "react";
import { Props } from "./types";
import useCombinedHooks from "./hooks";
import CategoryView from "./view";
import debug from "../../../../debug";

const reRenderLog = debug("rerender");
export const initialCardsToShow = 36;

const Category: React.FC<Props> = ({
    title,
    filter,
    promote = false,
    initialLoadLimit,
    landing = false,
}) => {
    const {
        appendEvents,
        cardsToShow,
        eventCount,
        events,
        preloading,
        searchCategoryEvents,
        showMore,
        scrollTop,
        setScrollTop,
    } = useCombinedHooks(initialLoadLimit, title, promote, filter);

    reRenderLog("category re-rendered");

    return (
        <CategoryView
            appendEvents={appendEvents}
            cardsToShow={cardsToShow}
            eventCount={eventCount}
            events={events}
            preloading={preloading}
            promote={promote}
            searchCategoryEvents={searchCategoryEvents}
            title={title}
            showMore={showMore}
            scrollTop={scrollTop}
            setScrollTop={setScrollTop}
            landing={landing}
            featured={promote}
        />
    );
};

export default Category;
