import makeStyles from "@material-ui/core/styles/makeStyles";
import { navyBlue } from "../../theme";

const useStyles = makeStyles((theme) => ({
    featuredRootMobile: {
        display: "-webkit-inline-box", // support for iPhone 5 Safari
        "@supports (display: flex)": {
            display: "flex",
        },
        "@media (min-width: 600px)": {
            display: "none",
        },
    },
    featuredRoot: {
        display: "-webkit-inline-box", // support for iPhone 5 Safari
        "@supports (display: flex)": {
            display: "flex",
        },
        "@media (max-width: 600px)": {
            display: "none",
        },
    },

    divider: {
        backgroundColor: navyBlue,
        height: "2px",
    },
    landingCategoryContainer: {
        marginTop: "2rem",
    },
    buttonContainer: {
        display: "flex",
        marginBottom: "2rem",
    },
    mobileScrollContainer: {
        display: "flex",
        flexDirection: "row",
        overflowX: "scroll",
        marginBottom: "1rem",
        width: "100%",
        "@media (min-width: 600px)": {
            display: "none",
        },
    },
}));

export default useStyles;
