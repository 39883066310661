import { LocationState, LocationActions } from "./types";
import { Reducer } from "redux";

export const initialState: LocationState = {
    fetching: false,
    results: [],
    result: null,
    canSubmitLocationFilter: true,
};

const reducer: Reducer<LocationState, LocationActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "LOCATION_FETCH":
            return {
                ...state,
                fetching: true,
            };

        case "LOCATION_FETCH_CONTROLLER":
            return {
                ...state,
                fetching: false,
                controller: action.controller,
            };

        case "LOCATION_FETCH_SUCCESS":
            return {
                ...state,
                fetching: false,
                result: action.result,
            };

        case "LOCATIONS_FETCH_SUCCESS":
            return {
                ...state,
                fetching: false,
                results: action.results,
            };

        case "LOCATION_CLEAR":
            return {
                ...state,
                result: null,
                results: [],
            };

        case "CAN_SUBMIT_LOCATION_FILTER":
            return {
                ...state,
                canSubmitLocationFilter: action.value,
            };

        default:
            return state;
    }
};

export default reducer;
