import React from "react";
import { useSelector } from "react-redux";
import { State } from "../../../store/types";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { Typography } from "@material-ui/core";
import { buildEventSearchLink } from "@skiddle/web.events.helpers";
import {
    locationCanSubmitFilter,
    locationResult,
} from "../../../store/location/selectors";
import { LocationState } from "../../../store/location/types";
import { useStateSelector } from "../../filters/eventTypes/hooks";
import useStyles from "./styles";
import { Props } from "./types";
import { EventCodes } from "@skiddle/web.events.shared-types";

const QuickLinks: React.FC<Props> = () => {
    const classes = useStyles();

    const { result } = useSelector<State, LocationState>((state) => ({
        result: locationResult(state.location),
        fetching: false,
        results: [],
        canSubmitLocationFilter: locationCanSubmitFilter(state.location),
    }));

    const { filterEventTypes } = useStateSelector();
    const postcodeLookup = result?.postcodeLookup || "all";

    const quickLinks = [
        {
            eventCode: "",
            title: "What's On",
            link: buildEventSearchLink(postcodeLookup, ""),
        },
        {
            eventCode: "",
            title: "Things to do",
            link: buildEventSearchLink(postcodeLookup, "things-to-do"),
        },
        {
            eventCode: "CLUB",
            title: "Clubbing events",
            link: buildEventSearchLink(postcodeLookup, "CLUB"),
        },
        {
            eventCode: "LIVE",
            title: "Gigs",
            link: buildEventSearchLink(postcodeLookup, "LIVE"),
        },
        {
            eventCode: "FEST",
            title: "Festivals",
            link: buildEventSearchLink(postcodeLookup, "FEST"),
        },
        {
            eventCode: "BARPUB",
            title: "Bar/pub events",
            link: buildEventSearchLink(postcodeLookup, "BARPUB"),
        },
        {
            eventCode: "THEATRE",
            title: "Theatre",
            link: buildEventSearchLink(postcodeLookup, "THEATRE"),
        },
        {
            eventCode: "COMEDY",
            title: "Comedy events",
            link: buildEventSearchLink(postcodeLookup, "COMEDY"),
        },
        {
            eventCode: "EXHIB",
            title: "Exhibitions",
            link: buildEventSearchLink(postcodeLookup, "EXHIB"),
        },
        {
            eventCode: "",
            title: "Dating",
            link: buildEventSearchLink(postcodeLookup, "DATE"),
        },
        {
            eventCode: "",
            title: "Freshers",
            link: `/cities/${postcodeLookup}/freshersweek.html`,
        },
        {
            eventCode: "",
            title: "Halloween",
            link: `/cities/${postcodeLookup}/halloween.html`,
        },
        {
            eventCode: "XMAS",
            title: "Christmas events",
            link: buildEventSearchLink(postcodeLookup, "XMAS"),
        },
        {
            eventCode: "NYE",
            title: "New Year's Eve events",
            link: buildEventSearchLink(postcodeLookup, "NYE"),
        },
        {
            eventCode: "",
            title: "Whats On Today",
            link: buildEventSearchLink(
                postcodeLookup,
                "today-in-city",
                undefined,
                undefined,
                `whats-on-in-${postcodeLookup}-today`,
            ),
        },
        {
            eventCode: "",
            title: "Whats On This Weekend",
            link: buildEventSearchLink(
                postcodeLookup,
                "today-in-city",
                undefined,
                undefined,
                `whats-on-in-${postcodeLookup}-this-weekend`,
            ),
        },
        {
            eventCode: "",
            title: "Oktoberfest",
            link: buildEventSearchLink(
                postcodeLookup,
                "today-in-city",
                undefined,
                undefined,
                `oktoberfest`,
            ),
        },
        {
            eventCode: "",
            title: "World Cup",
            link: buildEventSearchLink(
                postcodeLookup,
                "today-in-city",
                undefined,
                undefined,
                `world-cup-screenings`,
            ),
        },
        {
            eventCode: "",
            title: "Bottomless Brunch",
            link: buildEventSearchLink(
                postcodeLookup,
                "today-in-city",
                undefined,
                undefined,
                `bottomless-brunch`,
            ),
        },
    ];

    return (
        <Grid
            container
            spacing={2}
            justifyContent={"center"}
            style={{
                padding: "25px 10px",
            }}
        >
            <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant={"h2"} className={classes.quickLinkH2}>
                    Other Links
                </Typography>
            </Grid>

            {quickLinks.map(({ eventCode, title, link }) => (
                <Grid
                    item
                    xs={6}
                    md={3}
                    lg={3}
                    style={{ textAlign: "center" }}
                    key={title}
                >
                    <Link
                        href={link}
                        className={`${
                            filterEventTypes?.includes(eventCode as EventCodes)
                                ? classes.linkHighlight
                                : classes.link
                        }`}
                        style={{
                            color: filterEventTypes?.includes(
                                eventCode as EventCodes,
                            )
                                ? "#c51162"
                                : "rgba(0, 0, 0, 0.87)",
                        }}
                    >
                        {title}
                    </Link>
                </Grid>
            ))}
        </Grid>
    );
};

export default QuickLinks;
