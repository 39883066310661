import makeStyles from "@material-ui/core/styles/makeStyles";
import { navyBlue } from "@skiddle/web.global.theme";

const useStyles = makeStyles((theme) => ({
    presetContainer: {
        display: "flex",
        padding: 5,
        // paddingLeft: 5,
        // paddingRight: 10,
        fontSize: "1.1rem",
        borderRadius: "0 !important",
        flexWrap: "nowrap",
        overflowX: "auto",
        overflowY: "hidden",
        whiteSpace: "nowrap",

        /* browser decide if x is ok to scroll and show bars on, y hidden */
        overflow: "auto hidden",
        /* prevent scroll chaining on x scroll */
        overscrollBehaviorX: "contain",
        /* scrolling should snap children on x */
        scrollSnapType: "x mandatory",
        "@media (hover: none)": {
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                width: 0,
                height: 0,
            },
        },
    },
    buttonRoot: {
        whiteSpace: "nowrap",
        textTransform: "none",
        color: navyBlue,
        border: `1px solid ${navyBlue}`,
        padding: "2px 15px",
        margin: theme.spacing(0, 0.5),
        "&:hover": {
            boxShadow: `inset 0px 0px 0px 1px ${navyBlue}`,
            border: `1px solid ${navyBlue}`,
        },
    },
}));

export default useStyles;
