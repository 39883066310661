import React, { forwardRef } from "react";
import { FormGroup } from "@material-ui/core";
import Autocomplete from "@skiddle/web.events.autocomplete";
import { SearchInputProps } from "../types";

const LocationSearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
    (
        {
            locationSelect,
            parsedLocations,
            searchInputChange,
            searchLocation,
            fontSize,
        },
        ref,
    ) => (
        <FormGroup>
            <Autocomplete
                options={parsedLocations}
                name="location"
                onChange={locationSelect}
                inputChange={searchInputChange}
                value={searchLocation}
                fontSize={fontSize}
                ref={ref}
            />
        </FormGroup>
    ),
);

export default LocationSearchInput;
