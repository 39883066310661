import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import rootReducer, {
    initialFirstClientRenderState,
    initialCompareQueryParamsClientSideState,
} from "./reducer";
import { State, Actions } from "./types";
import { initialState as initialEventSearchState } from "./events/search/reducer";
import { initialAPIState } from "./reducer";
import { Reducer } from "react";
import { initialState as initialFiltersState } from "./filters/reducer";
import { initialState as initialCategoryEventsState } from "./Categories/reducer";
import { initialState as initialGenresState } from "./genres/reducer";
import { initialState as initialEventTypeState } from "./events/types/reducer";
import { initialState as initialLocationState } from "./location/reducer";
import { initialState as initialSEOState } from "./seo/reducer";
import { initialState as initialAggregationsState } from "./aggregations/reducer";
import { initialState as initialHeaderState } from "./header/reducer";
import { initialState as initialUserState } from "./user/reducer";

export const initialState: State = {
    compareQueryParamsClientSide: initialCompareQueryParamsClientSideState,
    api: initialAPIState,
    events: {
        search: initialEventSearchState,
        types: initialEventTypeState,
    },
    categoryEvents: initialCategoryEventsState,
    filters: initialFiltersState,
    genres: initialGenresState,
    location: initialLocationState,
    seo: initialSEOState,
    aggregations: initialAggregationsState,
    header: {
        nav: [
            {
                id: "gigs",
                title: "gigs",
                titleLink: "/live/",
                links: [
                    {
                        id: "all_eventtype",
                        title: "all gigs",
                        link: "/live/",
                    },
                    {
                        id: "all_cities",
                        title: "view all cities",
                        link: "/whats-on/cities.html",
                    },
                ],
                categories: [
                    {
                        id: "cities",
                        category: "browse gigs in",
                        links: [
                            {
                                title: "Birmingham",
                                link: "/gigs/Birmingham/",
                                hrefTitle: "Events in Birmingham",
                            },
                            {
                                title: "Bristol",
                                link: "/gigs/Bristol/",
                                hrefTitle: "Events in Bristol",
                            },
                            {
                                title: "Brighton",
                                link: "/gigs/Brighton/",
                                hrefTitle: "Events in Brighton",
                            },
                            {
                                title: "Cardiff",
                                link: "/gigs/Cardiff/",
                                hrefTitle: "Events in Cardiff",
                            },
                            {
                                title: "Edinburgh",
                                link: "/gigs/Edinburgh/",
                                hrefTitle: "Events in Edinburgh",
                            },
                            {
                                title: "Glasgow",
                                link: "/gigs/Glasgow/",
                                hrefTitle: "Events in Glasgow",
                            },
                            {
                                title: "Leeds",
                                link: "/gigs/Leeds/",
                                hrefTitle: "Events in Leeds",
                            },
                            {
                                title: "Liverpool",
                                link: "/gigs/Liverpool/",
                                hrefTitle: "Events in Liverpool",
                            },
                            {
                                title: "London",
                                link: "/gigs/London/",
                                hrefTitle: "Events in London",
                            },
                            {
                                title: "Manchester",
                                link: "/gigs/Manchester/",
                                hrefTitle: "Events in Manchester",
                            },
                            {
                                title: "Newcastle",
                                link: "/gigs/Newcastle-on-Tyne/",
                                hrefTitle: "Events in Newcastle",
                            },
                            {
                                title: "Sheffield",
                                link: "/gigs/Sheffield/",
                                hrefTitle: "Events in Sheffield",
                            },
                        ],
                    },
                    {
                        id: "all_eventtype",
                        category: "find events",
                        links: [
                            {
                                title: "Artist tour dates",
                                link: "/artists/",
                            },
                            {
                                title: "Events by brand",
                                link: "/groups/",
                            },
                            {
                                title: "Socially Distanced Events",
                                link: "/inspire-me/socially-distanced-events-2020",
                            },
                            {
                                title: "Virtual Events",
                                link: "/whats-on/online/",
                            },
                        ],
                    },
                    {
                        id: "seasonal",
                        category: "seasonal",
                        links: [
                            {
                                title: "Festivals 2021",
                                link: "/festivals/",
                            },
                            {
                                title: "Valentines Day",
                                link: "/valentinesday/",
                            },
                        ],
                    },
                    {
                        id: "top_venues",
                        category: "top venues",
                        links: [
                            {
                                title: "Hare & Hounds ",
                                link: "/whats-on/birmingham/hare-and-hounds/",
                            },
                            {
                                title: "YES - Manchester",
                                link: "/whats-on/manchester/yes/",
                            },
                            {
                                title: "Phase One",
                                link: "/whats-on/liverpool/phase-one/",
                            },
                            {
                                title: "Invisible Wind Factory",
                                link: "/whats-on/liverpool/invisible-wind-factory/",
                            },
                            {
                                title: "The Louisiana",
                                link: "/whats-on/bristol/The-louisiana/",
                            },
                            {
                                title: "The Concorde 2",
                                link: "/whats-on/brighton/the-concorde-2/",
                            },
                            {
                                title: "The Old Dr Bells Baths",
                                link: "/whats-on/edinburgh/the-old-dr-bells-baths/",
                            },
                            {
                                title: "St Lukes",
                                link: "/whats-on/glasgow/St-Luke%27s-/",
                            },
                            {
                                title: "Barrowland Ballroom",
                                link: "/whats-on/glasgow/Barrowland-ballroom/",
                            },
                            {
                                title: "Brudenell Social Club",
                                link: "/whats-on/leeds/brudenell-social-club/",
                            },
                            {
                                title: "Millenium Square",
                                link: "/whats-on/leeds/Millenium-square/",
                            },
                            {
                                title: "The Jazz Cafe",
                                link: "/whats-on/london/The-jazz-cafe/",
                            },
                            {
                                title: "The Steelyard",
                                link: "/whats-on/london/The-steel-yard/",
                            },
                            {
                                title: "The Cluny",
                                link: "/whats-on/Newcastle-on-tyne/the-cluny/",
                            },
                            {
                                title: "Record Junkee",
                                link: "/whats-on/sheffield/record-junkee/",
                            },
                        ],
                    },
                ],
                genres: {
                    id: "genre_images",
                    title: "gigs by genres",
                    links: [
                        {
                            title: "Rock & Metal gigs",
                            image: "https://d1plawd8huk6hh.cloudfront.net/images/landing/backgrounds/rhcp-small2.jpg",
                            link: "/live/rock-metal.html",
                        },
                        {
                            title: "Indie & Alternative gigs",
                            image: "https://d1plawd8huk6hh.cloudfront.net/images/landing/backgrounds/arctic-monkeys-small2.jpg",
                            link: "/live/indie-alternative.html",
                        },
                        {
                            title: "Hip Hop & R&B gigs",
                            image: "https://d1plawd8huk6hh.cloudfront.net/images/landing/backgrounds/loyle-carner-small2.jpg",
                            link: "/live/hip-hop-rnb.html",
                        },
                        {
                            title: "Reggae & Dancehall gigs",
                            image: "https://d1plawd8huk6hh.cloudfront.net/images/landing/backgrounds/lee-scratch-perry-small2.jpg",
                            link: "/live/reggae-dancehall.html",
                        },
                        {
                            title: "Electronic gigs",
                            image: "https://d1plawd8huk6hh.cloudfront.net/images/landing/backgrounds/daft-punk-small2.jpg",
                            link: "/live/electronic.html",
                        },
                        {
                            title: "Pop gigs",
                            image: "https://d1plawd8huk6hh.cloudfront.net/images/landing/backgrounds/jess-glynne-small2.jpg",
                            link: "/live/pop.html",
                        },
                        {
                            title: "Funk & Soul gigs",
                            image: "https://d1plawd8huk6hh.cloudfront.net/images/landing/backgrounds/mark-ronson-small2.jpg",
                            link: "/live/funk-soul.html",
                        },
                        {
                            title: "Folk & Blues gigs",
                            image: "https://d1plawd8huk6hh.cloudfront.net/images/landing/backgrounds/lucy-rose-small2.jpg",
                            link: "/live/folk-blues.html",
                        },
                    ],
                },
            },
            {
                id: "clubs",
                title: "clubs",
                titleLink: "/clubs/",
                links: [
                    {
                        id: "all_eventtype",
                        title: "all clubbing events",
                        link: "/clubs/",
                    },
                    {
                        id: "all_cities",
                        title: "view all cities",
                        link: "/whats-on/cities.html",
                    },
                ],
                categories: [
                    {
                        id: "cities",
                        category: "browse club events in",
                        links: [
                            {
                                title: "Birmingham",
                                link: "/clubs/Birmingham/",
                                hrefTitle: "Events in Birmingham",
                            },
                            {
                                title: "Bristol",
                                link: "/clubs/Bristol/",
                                hrefTitle: "Events in Bristol",
                            },
                            {
                                title: "Brighton",
                                link: "/clubs/Brighton/",
                                hrefTitle: "Events in Brighton",
                            },
                            {
                                title: "Cardiff",
                                link: "/clubs/Cardiff/",
                                hrefTitle: "Events in Cardiff",
                            },
                            {
                                title: "Edinburgh",
                                link: "/clubs/Edinburgh/",
                                hrefTitle: "Events in Edinburgh",
                            },
                            {
                                title: "Glasgow",
                                link: "/clubs/Glasgow/",
                                hrefTitle: "Events in Glasgow",
                            },
                            {
                                title: "Leeds",
                                link: "/clubs/Leeds/",
                                hrefTitle: "Events in Leeds",
                            },
                            {
                                title: "Liverpool",
                                link: "/clubs/Liverpool/",
                                hrefTitle: "Events in Liverpool",
                            },
                            {
                                title: "London",
                                link: "/clubs/London/",
                                hrefTitle: "Events in London",
                            },
                            {
                                title: "Manchester",
                                link: "/clubs/Manchester/",
                                hrefTitle: "Events in Manchester",
                            },
                            {
                                title: "Newcastle",
                                link: "/clubs/Newcastle-on-Tyne/",
                                hrefTitle: "Events in Newcastle",
                            },
                            {
                                title: "Sheffield",
                                link: "/clubs/Sheffield/",
                                hrefTitle: "Events in Sheffield",
                            },
                        ],
                    },
                    {
                        id: "find_events",
                        category: "find events",
                        links: [
                            {
                                title: "Artist/DJ tour dates",
                                link: "/artists/",
                            },
                            {
                                title: "Events by brand",
                                link: "/groups/",
                            },
                            {
                                title: "Top 100 Clubs",
                                link: "/whats-on/top100clubs.html",
                            },
                            {
                                title: "Socially Distanced Events",
                                link: "/inspire-me/socially-distanced-events-2021",
                            },
                            {
                                title: "Virtual Events",
                                link: "/whats-on/online/",
                            },
                        ],
                    },
                    {
                        id: "seasonal",
                        category: "seasonal",
                        links: [
                            {
                                title: "Festivals 2021",
                                link: "/festivals/",
                            },
                            {
                                title: "Ibiza 20201",
                                link: "/ibiza/",
                            },
                            {
                                title: "Croatia 20201",
                                link: "/croatia/",
                            },
                            {
                                title: "Fathers Day",
                                link: "/fathersday/",
                            },
                        ],
                    },
                    {
                        id: "top_clubs",
                        category: "top clubs",
                        links: [
                            {
                                title: "WHP",
                                link: "/groups/thewarehouseproject/",
                            },
                            {
                                title: "Printworks",
                                link: "/whats-on/London/Printworks-London/",
                            },
                            {
                                title: "Motion",
                                link: "/groups/motionbristol/",
                            },
                            {
                                title: "Victoria Warehouse",
                                link: "/whats-on/Manchester/O2-Victoria-Warehouse/",
                            },
                            {
                                title: "Albert Hall",
                                link: "/groups/alberthall/",
                            },
                            {
                                title: "24 Kitchen Street",
                                link: "/whats-on/Liverpool/24-Kitchen-Street/",
                            },
                            {
                                title: "Ministry of Sound",
                                link: "/whats-on/London/Ministry-Of-Sound/",
                            },
                            {
                                title: "Digital",
                                link: "/whats-on/Newcastle-on-Tyne/Digital/",
                            },
                            {
                                title: "Lab11",
                                link: "/whats-on/Birmingham/LAB11/",
                            },
                            {
                                title: "Bramley Moore Dock",
                                link: "/whats-on/Liverpool/Bramley-Moore-Dock/",
                            },
                            {
                                title: "Digbeth Arena",
                                link: "/whats-on/Birmingham/Digbeth-Arena/",
                            },
                            {
                                title: "The Mill",
                                link: "/whats-on/Birmingham/The-Mill-Digbeth/",
                            },
                            {
                                title: "Social Avenue",
                                link: "/whats-on/Manchester/Social-Avenue/",
                            },
                            {
                                title: "Dreamland",
                                link: "/whats-on/Margate/Dreamland/",
                            },
                            {
                                title: "Fabric",
                                link: "/groups/fabric/",
                            },
                        ],
                    },
                    {
                        id: "top_events",
                        category: "top events",
                        links: [
                            {
                                title: "WAH",
                                link: "/groups/elrow/",
                            },
                            {
                                title: "Elrow",
                                link: "/groups/elrow/",
                            },
                            {
                                title: "Glitterbox",
                                link: "/groups/glitterbox/",
                            },
                            {
                                title: "Circus",
                                link: "/groups/circusliverpool/",
                            },
                            {
                                title: "Homoelectric",
                                link: "/groups/homoelectric/",
                            },
                            {
                                title: "Defected",
                                link: "/groups/defectedinthehouse/",
                            },
                            {
                                title: "PierJam",
                                link: "/groups/pierjam/",
                            },
                            {
                                title: "Garage Nation",
                                link: "/groups/garagenation/",
                            },
                            {
                                title: "Goodgreef",
                                link: "/groups/goodgreef/",
                            },
                            {
                                title: "Jika Jika",
                                link: "/groups/Jikajika/",
                            },
                            {
                                title: "Weird Science",
                                link: "/groups/weirdscience/",
                            },
                            {
                                title: "Cirque Du Soul",
                                link: "/groups/cirquedusoul/",
                            },
                            {
                                title: "Bedlam",
                                link: "/groups/bedlamcardiff/",
                            },
                            {
                                title: "Circus x Cream",
                                link: "/groups/circusxcream/",
                            },
                            {
                                title: "Cream",
                                link: "/groups/cream/",
                            },
                        ],
                    },
                ],
                genres: {
                    id: "genre_images",
                    title: "clubs by genres",
                    links: [
                        {
                            title: "House & Techno club events",
                            image: "https://d1plawd8huk6hh.cloudfront.net/landing-pages/backgrounds/nina-kraviz-small2.jpg",
                            link: "/clubs/house-techno.html",
                        },
                        {
                            title: "UKG & Bass Music club events",
                            image: "https://d1plawd8huk6hh.cloudfront.net/landing-pages/backgrounds/dj-ez-small2.png",
                            link: "/clubs/ukg-bass-music.html",
                        },
                        {
                            title: "EDM club events",
                            image: "https://d1plawd8huk6hh.cloudfront.net/landing-pages/backgrounds/don-diablo-small2.png",
                            link: "/clubs/main-room-edm.html",
                        },
                        {
                            title: "Funk, Soul & Disco club events",
                            image: "https://d1plawd8huk6hh.cloudfront.net/landing-pages/backgrounds/dimitri-from-paris-small2.jpg",
                            link: "/clubs/funk-soul-disco.html",
                        },
                        {
                            title: "Trance & Hard Dance club events",
                            image: "https://d1plawd8huk6hh.cloudfront.net/landing-pages/backgrounds/paul-oakenfold-small2.jpg",
                            link: "/clubs/trance-hard-dance.html",
                        },
                        {
                            title: "Hip Hop & R&B club events",
                            image: "https://d1plawd8huk6hh.cloudfront.net/landing-pages/backgrounds/kendrick-small2.jpg",
                            link: "/clubs/hip-hop-rnb.html",
                        },
                        {
                            title: "Reggae, DUB & Dancehall club events",
                            image: "https://d1plawd8huk6hh.cloudfront.net/images/landing/backgrounds/lee-scratch-perry-small2.jpg",
                            link: "/clubs/reggae-dub.html",
                        },
                        {
                            title: "Pop, Rock & Indie club events",
                            image: "https://d1plawd8huk6hh.cloudfront.net/landing-pages/backgrounds/arctic-monkeys-small2.jpg",
                            link: "/clubs/rock-indie.html",
                        },
                    ],
                },
            },
            {
                id: "festivals",
                title: "festivals",
                titleLink: "/festivals/",
                links: [
                    {
                        id: "all_eventtype",
                        title: "festivals",
                        link: "/festivals/",
                    },
                ],
                categories: [
                    {
                        id: "find_festival",
                        category: "find your perfect festival",
                        links: [
                            {
                                title: "Festivals 2021",
                                link: "/festivals/",
                            },
                            {
                                title: "Festival Finder",
                                link: "/festivals/search/",
                            },
                        ],
                    },
                    {
                        id: "types_festivals",
                        category: "types of festivals",
                        links: [
                            {
                                title: "Dance Music Festivals",
                                link: "/festivals/dance.html",
                            },
                            {
                                title: "Rock and Indie Festivals",
                                link: "/festivals/rock-indie.html",
                            },
                            {
                                title: "Boutique Festivals",
                                link: "/festivals/boutique.html",
                            },
                            {
                                title: "Budget Festivals",
                                link: "/festivals/under-100.html",
                            },
                            {
                                title: "European/Overseas Festivals",
                                link: "/festivals/overseas.html",
                            },
                            {
                                title: "Browse all festivals",
                                link: "/festivals/search/",
                            },
                            {
                                title: "Budget Festivals",
                                link: "/festivals/under-100.html",
                            },
                            {
                                title: "Family Friendly Festivals",
                                link: "/festivals/family-friendly.html",
                            },
                            {
                                title: "Payment Plan Festivals",
                                link: "/inspire-me/payment-plan-festivals-2021",
                            },
                        ],
                    },
                    {
                        id: "festival_tickets",
                        category: "festival tickets",
                        links: [
                            {
                                title: "51st state",
                                link: "/festivals/51st-state-festival/",
                            },
                            {
                                title: "Bassfest",
                                link: "/festivals/bassfest/",
                            },
                            {
                                title: "Belladrum",
                                link: "/festivals/belladrum/",
                            },
                            {
                                title: "Bluedot",
                                link: "/festivals/bluedot-festival/",
                                hideMobile: true,
                            },
                            {
                                title: "Boundary Brighton",
                                link: "/festivals/boundary-brighton/",
                            },
                            {
                                title: "Creamfields North",
                                link: "/festivals/creamfields/",
                                hideMobile: true,
                            },
                            {
                                title: "Creamfields South",
                                link: "/festivals/creamfields-south/",
                                hideMobile: true,
                            },
                            {
                                title: "Dream Valley",
                                link: "/festivals/Dream-Valley/",
                                hideMobile: true,
                            },
                            {
                                title: "El Dorado",
                                link: "/festivals/El-Dorado/",
                                hideMobile: true,
                            },
                            {
                                title: "Electric Woodlands",
                                link: "/festivals/Electric-Woodlands/",
                                hideMobile: true,
                            },
                            {
                                title: "Field day",
                                link: "/festivals/Field-day/",
                                hideMobile: true,
                            },
                            {
                                title: "Fields of Gold",
                                link: "/festivals/Fields-of-Gold/",
                                hideMobile: true,
                            },
                            {
                                title: "Hide & Seek",
                                link: "/festivals/hide-seek-festival/",
                                hideMobile: true,
                            },
                            {
                                title: "Highest Point Festival",
                                link: "/festivals/Highest-Point-Festival/",
                                hideMobile: true,
                            },
                            {
                                title: "In It Together",
                                link: "/festivals/In-It-Together/",
                                hideMobile: true,
                            },
                            {
                                title: "Inside Out",
                                link: "/festivals/Inside-Out/",
                                hideMobile: true,
                            },
                            {
                                title: "Junction 2",
                                link: "/festivals/Junction-2/",
                                hideMobile: true,
                            },
                            {
                                title: "Kendal calling",
                                link: "/festivals/Kendal-calling/",
                                hideMobile: true,
                            },
                            {
                                title: "Kubix Festival ",
                                link: "/festivals/Kubix-Festival/",
                                hideMobile: true,
                            },
                            {
                                title: "Lovebox",
                                link: "/festivals/Lovebox/",
                                hideMobile: true,
                            },
                            {
                                title: "Lytham Festival",
                                link: "/festivals/Lytham-prom/",
                                hideMobile: true,
                            },
                            {
                                title: "MADE",
                                link: "/festivals/MADE-birmingham/",
                                hideMobile: true,
                            },
                            {
                                title: "Mint Festival",
                                link: "/festivals/Mint-Festival/",
                                hideMobile: true,
                            },
                            {
                                title: "Moovin Festival",
                                link: "/festivals/Moovin-Festival/",
                                hideMobile: true,
                            },
                            {
                                title: "Neighbourhood Weekender",
                                link: "/festivals/neighbourhood-weekender/",
                            },
                            {
                                title: "Parklife",
                                link: "/festivals/Parklife2/",
                                hideMobile: true,
                            },
                            {
                                title: "Playground Festival",
                                link: "/festivals/playground-festival/",
                                hideMobile: true,
                            },
                            {
                                title: "Reminisce Festival",
                                link: "/festivals/Reminisce-Festival/",
                                hideMobile: true,
                            },
                            {
                                title: "Snowboxx",
                                link: "/festivals/Snowboxx-festival/",
                                hideMobile: true,
                            },
                            {
                                title: "Solfest",
                                link: "/festivals/Solfest/",
                                hideMobile: true,
                            },
                            {
                                title: "Terminal V",
                                link: "/festivals/Terminal-v-festival/",
                                hideMobile: true,
                            },
                            {
                                title: "The Weekender",
                                link: "/festivals/The-weekender/",
                                hideMobile: true,
                            },
                            {
                                title: "Westfest",
                                link: "/festivals/Westfest/",
                                hideMobile: true,
                            },
                            {
                                title: "More festival tickets",
                                link: "/festivals/",
                                hideMobile: true,
                            },
                        ],
                    },
                ],
                genres: null,
            },
            {
                id: "whats_on",
                title: "what's on",
                titleLink: "/whats-on/",
                links: [
                    {
                        id: "all_eventtype",
                        title: "All Events",
                        link: "/whats-on/",
                    },
                ],
                categories: [
                    {
                        id: "cities",
                        category: "browse events in",
                        links: [
                            {
                                title: "Events Home",
                                link: "/whats-on/",
                            },
                            {
                                title: "Birmingham",
                                link: "/whats-on/Birmingham/",
                                hrefTitle: "Events in Birmingham",
                            },
                            {
                                title: "Bristol",
                                link: "/whats-on/Bristol/",
                                hrefTitle: "Events in Bristol",
                            },
                            {
                                title: "Brighton",
                                link: "/whats-on/Brighton/",
                                hrefTitle: "Events in Brighton",
                            },
                            {
                                title: "Cardiff",
                                link: "/whats-on/Cardiff/",
                                hrefTitle: "Events in Cardiff",
                            },
                            {
                                title: "Edinburgh",
                                link: "/whats-on/Edinburgh/",
                                hrefTitle: "Events in Edinburgh",
                            },
                            {
                                title: "Glasgow",
                                link: "/whats-on/Glasgow/",
                                hrefTitle: "Events in Glasgow",
                            },
                            {
                                title: "Leeds",
                                link: "/whats-on/Leeds/",
                                hrefTitle: "Events in Leeds",
                            },
                            {
                                title: "Liverpool",
                                link: "/whats-on/Liverpool/",
                                hrefTitle: "Events in Liverpool",
                            },
                            {
                                title: "London",
                                link: "/whats-on/London/",
                                hrefTitle: "Events in London",
                            },
                            {
                                title: "Manchester",
                                link: "/whats-on/Manchester/",
                                hrefTitle: "Events in Manchester",
                            },
                            {
                                title: "Newcastle",
                                link: "/whats-on/Newcastle-on-Tyne/",
                                hrefTitle: "Events in Newcastle",
                            },
                            {
                                title: "Sheffield",
                                link: "/whats-on/Sheffield/",
                                hrefTitle: "Events in Sheffield",
                            },
                            {
                                title: "View all cities",
                                link: "/whats-on/cities.html",
                                hrefTitle: "View all cities",
                            },
                        ],
                    },
                    {
                        id: "city_guides",
                        category: "city guides",
                        links: [
                            {
                                title: "London Guide",
                                link: "/cities/london/",
                            },
                            {
                                title: "Manchester Guide",
                                link: "/cities/manchester/",
                            },
                            {
                                title: "Liverpool Guide",
                                link: "/cities/liverpool/",
                            },
                            {
                                title: "Leeds Guide",
                                link: "/cities/leeds/",
                            },
                            {
                                title: "Birmingham Guide",
                                link: "/cities/birmingham/",
                            },
                            {
                                title: "Newcastle Guide",
                                link: "/cities/newcastle-on-tyne/",
                            },
                            {
                                title: "Edinburgh Guide",
                                link: "/cities/edinburgh/",
                            },
                            {
                                title: "Glasgow Guide",
                                link: "/cities/glasgow/",
                            },
                            {
                                title: "Brighton Guide",
                                link: "/cities/brighton/",
                            },
                            {
                                title: "Bristol Guide",
                                link: "/cities/bristol/",
                            },
                            {
                                title: "Cardiff Guide",
                                link: "/cities/cardiff/",
                            },
                        ],
                    },
                    {
                        id: "popular_artists",
                        category: "popular artists",
                        links: [
                            {
                                title: "andy c",
                                link: "/artists/andy-c-123457009/",
                            },
                            {
                                title: "bicep",
                                link: "/artists/bicep-123504694/",
                            },
                            {
                                title: "bonobo",
                                link: "/artists/bonobo-123483600/",
                            },
                            {
                                title: "camelphat",
                                link: "/artists/camelphat-123530158/",
                            },
                            {
                                title: "caribou",
                                link: "/artists/caribou-123481754/",
                            },
                            {
                                title: "chase & Status",
                                link: "/artists/chase--status-123456801/",
                            },
                            {
                                title: "denis sulta",
                                link: "/artists/denis-sulta-123542576/",
                            },
                            {
                                title: "eric prydz",
                                link: "/artists/eric-prydz-123456863/",
                            },
                            {
                                title: "four tet",
                                link: "/artists/four-tet-123471730/",
                            },
                            {
                                title: "hybrid minds",
                                link: "/artists/hybrid-minds-123508782/",
                            },
                            {
                                title: "nina kraviz",
                                link: "/artists/nina-kraviz-123499386/",
                            },
                            {
                                title: "patrick topping",
                                link: "/artists/patrick-topping-123520148/",
                            },
                            {
                                title: "peggy gou",
                                link: "/artists/peggy-gou-123551749/",
                            },
                            {
                                title: "solardo",
                                link: "/artists/solardo-123546951/",
                            },
                            {
                                title: "Sonny Fodera",
                                link: "/artists/sonny-fodera-123515298/",
                            },
                            {
                                title: "view all artists",
                                link: "/artists/",
                            },
                        ],
                    },
                    {
                        id: "seasonal_links",
                        category: "Valentines Day",
                        links: [
                            {
                                title: "London Guide",
                                link: "/cities/london/valentines-day.html",
                            },
                            {
                                title: "Manchester Guide",
                                link: "/cities/manchester/valentines-day.html",
                            },
                            {
                                title: "Liverpool Guide",
                                link: "/cities/liverpool/valentines-day.html",
                            },
                            {
                                title: "Cardiff Guide",
                                link: "/cities/cardiff/valentines-day.html",
                            },
                            {
                                title: "Edinburgh Guide",
                                link: "/cities/edinburgh/valentines-day.html",
                            },
                        ],
                    },
                ],
                genres: null,
            },
            {
                id: "things_to_do",
                title: "things to do",
                titleLink: "/cities/",
                links: [
                    {
                        id: "all_eventtype",
                        title: "all things to do",
                        link: "/things-to-do/",
                    },
                ],
                categories: [
                    {
                        id: "cities",
                        category: "things to do in",
                        links: [
                            {
                                title: "Liverpool",
                                link: "/things-to-do/Liverpool/",
                                hrefTitle: "Things to do in Liverpool",
                            },
                            {
                                title: "London",
                                link: "/things-to-do/London/",
                                hrefTitle: "Things to do in London",
                            },
                            {
                                title: "Manchester",
                                link: "/things-to-do/Manchester/",
                                hrefTitle: "Things to do in Manchester",
                            },
                            {
                                title: "Birmingham",
                                link: "/things-to-do/Birmingham/",
                                hrefTitle: "Things to do in Birmingham",
                            },
                            {
                                title: "Leeds",
                                link: "/things-to-do/Leeds/",
                                hrefTitle: "Things to do in Leeds",
                            },
                            {
                                title: "Newcastle",
                                link: "/things-to-do/Newcastle-on-Tyne/",
                                hrefTitle: "Things to do in Newcastle",
                            },
                            {
                                title: "Cardiff",
                                link: "/things-to-do/Cardiff/",
                                hrefTitle: "Things to do in Cardiff",
                            },
                            {
                                title: "Edinburgh",
                                link: "/things-to-do/Edinburgh/",
                                hrefTitle: "Things to do in Edinburgh",
                            },
                            {
                                title: "Bristol",
                                link: "/things-to-do/Bristol/",
                                hrefTitle: "Things to do in Bristol",
                            },
                            {
                                title: "Glasgow",
                                link: "/things-to-do/Glasgow/",
                                hrefTitle: "Things to do in Glasgow",
                            },
                            {
                                title: "Sheffield",
                                link: "/things-to-do/Sheffield/",
                                hrefTitle: "Things to do in Sheffield",
                            },
                        ],
                    },
                    {
                        id: "comedy_in",
                        category: "comedy in",
                        links: [
                            {
                                title: "Liverpool",
                                link: "/comedy/Liverpool/",
                                hrefTitle: "",
                            },
                            {
                                title: "London",
                                link: "/comedy/London/",
                                hrefTitle: "",
                            },
                            {
                                title: "Manchester",
                                link: "/comedy/Manchester/",
                                hrefTitle: "",
                            },
                            {
                                title: "Birmingham",
                                link: "/comedy/Birmingham/",
                                hrefTitle: "",
                            },
                            {
                                title: "Leeds",
                                link: "/comedy/Leeds/",
                                hrefTitle: "",
                            },
                            {
                                title: "Newcastle",
                                link: "/comedy/Newcastle/",
                                hrefTitle: "",
                            },
                            {
                                title: "Cardiff",
                                link: "/comedy/Cardiff/",
                                hrefTitle: "",
                            },
                            {
                                title: "Edinburgh",
                                link: "/comedy/Edinburgh/",
                                hrefTitle: "",
                            },
                            {
                                title: "Bristol",
                                link: "/comedy/Bristol/",
                                hrefTitle: "",
                            },
                            {
                                title: "Glasgow",
                                link: "/comedy/Glasgow/",
                                hrefTitle: "",
                            },
                            {
                                title: "Sheffield",
                                link: "/comedy/Sheffield/",
                                hrefTitle: "",
                            },
                        ],
                    },
                    {
                        id: "theatre_in",
                        category: "theatre in",
                        links: [
                            {
                                title: "Liverpool",
                                link: "/theatre/Liverpool/",
                                hrefTitle: "",
                            },
                            {
                                title: "London",
                                link: "/theatre/London/",
                                hrefTitle: "",
                            },
                            {
                                title: "Manchester",
                                link: "/theatre/Manchester/",
                                hrefTitle: "",
                            },
                            {
                                title: "Birmingham",
                                link: "/theatre/Birmingham/",
                                hrefTitle: "",
                            },
                            {
                                title: "Leeds",
                                link: "/theatre/Leeds/",
                                hrefTitle: "",
                            },
                            {
                                title: "Newcastle",
                                link: "/theatre/Newcastle/",
                                hrefTitle: "",
                            },
                            {
                                title: "Cardiff",
                                link: "/theatre/Cardiff/",
                                hrefTitle: "",
                            },
                            {
                                title: "Edinburgh",
                                link: "/theatre/Edinburgh/",
                                hrefTitle: "",
                            },
                            {
                                title: "Bristol",
                                link: "/theatre/Bristol/",
                                hrefTitle: "",
                            },
                            {
                                title: "Glasgow",
                                link: "/theatre/Glasgow/",
                                hrefTitle: "",
                            },
                            {
                                title: "Sheffield",
                                link: "/theatre/Sheffield/",
                                hrefTitle: "",
                            },
                        ],
                    },
                    {
                        id: "exhibitions_in",
                        category: "exhibitions & attractions in",
                        links: [
                            {
                                title: "Liverpool",
                                link: "/exhibitions/Liverpool/",
                                hrefTitle: "",
                            },
                            {
                                title: "London",
                                link: "/exhibitions/London/",
                                hrefTitle: "",
                            },
                            {
                                title: "Manchester",
                                link: "/exhibitions/Manchester/",
                                hrefTitle: "",
                            },
                            {
                                title: "Birmingham",
                                link: "/exhibitions/Birmingham/",
                                hrefTitle: "",
                            },
                            {
                                title: "Leeds",
                                link: "/exhibitions/Leeds/",
                                hrefTitle: "",
                            },
                            {
                                title: "Newcastle",
                                link: "/exhibitions/Newcastle/",
                                hrefTitle: "",
                            },
                            {
                                title: "Cardiff",
                                link: "/exhibitions/Cardiff/",
                                hrefTitle: "",
                            },
                            {
                                title: "Edinburgh",
                                link: "/exhibitions/Edinburgh/",
                                hrefTitle: "",
                            },
                            {
                                title: "Bristol",
                                link: "/exhibitions/Bristol/",
                                hrefTitle: "",
                            },
                            {
                                title: "Glasgow",
                                link: "/exhibitions/Glasgow/",
                                hrefTitle: "",
                            },
                            {
                                title: "Sheffield",
                                link: "/exhibitions/Sheffield/",
                                hrefTitle: "",
                            },
                        ],
                    },
                ],
                genres: null,
            },
            {
                id: "add_event",
                title: "add event",
                titleLink: "https://promotioncentre.co.uk",
                links: [],
                categories: [],
                genres: null,
            },
        ],
    },
    firstClientRender: initialFirstClientRenderState,
    lastUpdateFilter: "",
    user: initialUserState,
};

const makeStore = (state: State = initialState) => {
    if (process.env.NODE_ENV === "development") {
        return createStore<State | undefined, Actions, unknown, unknown>(
            rootReducer as unknown as Reducer<State | undefined, Actions>,
            state,
            composeWithDevTools(applyMiddleware(logger)),
        );
    }

    return createStore<State | undefined, Actions, unknown, unknown>(
        rootReducer as unknown as Reducer<State | undefined, Actions>,
        state,
    );
};

export default makeStore;
