import { makeStyles } from "@material-ui/core/styles";
import { navBarBackground } from "@skiddle/web.global.theme";

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: navBarBackground,
        zIndex: 999,
    },
    grow: {
        flexGrow: 1,
    },
    toolbar: {
        minHeight: "80px",
        justifyContent: "space-between",
    },
    title: {
        textIndent: "-9999px",
        width: "138px",
        height: "4em",
        display: "block",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundImage:
            "url(https://d1plawd8huk6hh.cloudfront.net/images/responsive/logo_rebrand.2.svg)",
        margin: "auto 0",
        // desktop
        [theme.breakpoints.up("sm")]: {
            width: "145px",
            backgroundSize: "contain",
            backgroundImage:
                "url(https://d1plawd8huk6hh.cloudfront.net/images/responsive/logo_rebrand.png)",
        },
        // mobile
        [theme.breakpoints.down("sm")]: {
            maxHeight: "40px",
        },
    },
    navlist: {
        "@media (max-width: 600px)": {
            display: "none",
        },
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
}));

export default useStyles;
