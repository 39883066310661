import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Typography, Grid } from "@material-ui/core";
import importedComponent from "react-imported-component";
import { Element } from "react-scroll";

import { State } from "../../../store/types";
import { LandingCategoryProps, StateSelector } from "../types";
import { getCategoryEvents } from "../../../store/Categories/actions";
import SeeMoreButton from "../../landing/SeeMoreButton";
import {
    categoryEventsResult,
    categoryEventsHasFetched,
} from "../../../store/Categories/selector";

import useStyles from "../styles";

// LazyLoad Mobile components
const MobileCard = importedComponent(
    () => import("../../results/card/MobileHorizontalCard"),
);

// LazyLoad Desktop components
const DesktopCard = importedComponent(
    () => import("../../results/card/DesktopHorizontalCard"),
);

const parseEventTypesParam = (
    categoryEventCode: string,
    title: string,
): string[] => {
    const eventTypes = categoryEventCode.split(",");
    if (eventTypes.length > 1) {
        return eventTypes;
    }

    return [title];
};

const LandingCategory: React.FC<LandingCategoryProps> = ({
    categoryEventCode,
    title,
    location,
}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const eventTypes = parseEventTypesParam(categoryEventCode, title);

    const { fetchCategoryEvents, categoryEvents, categoryHasFetched } =
        useSelector<State, StateSelector>(
            (state) => ({
                fetchCategoryEvents: getCategoryEvents(state, dispatch),
                categoryEvents: categoryEventsResult(state, categoryEventCode),
                categoryHasFetched: categoryEventsHasFetched(
                    state,
                    categoryEventCode,
                ),
            }),
            shallowEqual,
        );
    useEffect(() => {
        if (
            !categoryEvents ||
            (categoryEvents.length === 0 && !categoryHasFetched)
        ) {
            fetchCategoryEvents(categoryEventCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    if (!categoryEvents || categoryEvents.length === 0) return null;
    return (
        <div className={classes.landingCategoryContainer}>
            <Element name={`category-${title}`}>
                <Typography
                    variant="h2"
                    style={{
                        fontSize: "2.8rem",
                        marginTop: "0.5em",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                    }}
                >
                    {title}
                </Typography>

                {categoryEvents && (
                    <>
                        <Grid
                            className={classes.featuredRootMobile}
                            container
                            spacing={4}
                        >
                            <Grid
                                item
                                xs={12}
                                className={classes.mobileScrollContainer}
                            >
                                {categoryEvents.map((result, index) => {
                                    return (
                                        <Grid
                                            style={{
                                                minWidth: "45%",
                                                width: "45%",
                                                marginRight: 8,
                                            }}
                                            item
                                            key={index}
                                            xs={6}
                                            sm={4}
                                            md={6}
                                        >
                                            <MobileCard
                                                {...result}
                                                featured={false}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>

                        <Grid
                            className={classes.featuredRoot}
                            container
                            spacing={2}
                        >
                            {categoryEvents.map((result, index) => (
                                <Grid
                                    style={{
                                        minWidth: "25%",
                                        width: "25%",
                                    }}
                                    item
                                    key={index}
                                    xs={6}
                                    sm={4}
                                    md={3}
                                >
                                    <DesktopCard {...result} featured={false} />
                                </Grid>
                            ))}
                        </Grid>
                        <div className={classes.buttonContainer}>
                            <SeeMoreButton
                                eventCodes={eventTypes}
                                category={title}
                            />
                        </div>
                    </>
                )}
            </Element>
        </div>
    );
};

export default LandingCategory;
