import { Action } from "redux";
import { EventsSearchResult } from "@skiddle/web.events.shared-types";

export interface EventsCategorySearchFetchSuccess extends Action {
    type: "EVENTS_CATEGORY_SEARCH_FETCH_SUCCESS";
    category: string;
    results: EventsSearchResult[];
    totalCount: number;
}

export interface APIErrorAction extends Action {
    type: "API_ERROR";
    error: string;
}

export const apiError = (error: string): APIErrorAction => ({
    type: "API_ERROR",
    error,
});

export interface APIV3Response<Data = any> {
    status?: number;
    source?: {
        pointer: string;
    };
    title?: string;
    detail?: string;
    request_id?: string;
    data?: Data;
}

export interface SEOClear extends Action {
    type: "SEO_CLEAR";
}

export interface SEO {
    pageTitle: string;
    meta: string;
    description?: string;
    h1?: string;
    h2?: string;
    text?: string;
    landingImage?: string;
}
