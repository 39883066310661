import React from "react";
import SearchButton from "../Button";
import FilterDialog from "../dialog";
import SelectList from "../select";
import { EventTypesViewProps as Props } from "./types";
import LocalActivityIcon from "@material-ui/icons/LocalActivity";

const EventTypesView: React.FC<Props> = ({
    open,
    toggleOpen,
    confirmEventTypes,
    clearEventTypes,
    eventTypes,
    selectedEventTypes,
    setSelectedEventTypes,
    buttonTitle,
    isActive,
}) => (
    <>
        <FilterDialog
            title="Event Type"
            open={open}
            toggleOpen={toggleOpen}
            onConfirm={confirmEventTypes}
            onClear={clearEventTypes}
            clearLabel="show all event types"
        >
            <SelectList
                multiSelect
                options={eventTypes.map(({ eventcodedesc }) => eventcodedesc)}
                selected={selectedEventTypes}
                onSelect={(els) =>
                    Array.isArray(els) && setSelectedEventTypes(els)
                }
                count={eventTypes.map(({ eventcodedesc, eventTypeCount }) => ({
                    name: eventcodedesc,
                    count: eventTypeCount,
                }))}
                hideEmptyCount={true}
            />
        </FilterDialog>
        <SearchButton
            title={buttonTitle}
            onClick={toggleOpen}
            isActive={isActive}
            icon={<LocalActivityIcon />}
        />
    </>
);

export default EventTypesView;
