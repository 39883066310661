import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useStateSelector } from ".";
import { firstClientRender } from "../../../../../store/actions";
import {
    EventCodes,
    EventsSearchParams,
} from "@skiddle/web.events.shared-types";

export const useSearchCategoryEvents = (
    setAppendEvents: React.Dispatch<React.SetStateAction<boolean>>,
    promote: boolean,
    filter: EventCodes[] | undefined,
    limit: number,
    initialLoadLimit: number,
    offset: number,
    title: string,
    cardsToShow: number,
    setCardsToShow: React.Dispatch<React.SetStateAction<number>>,
    setScrollTop: React.Dispatch<React.SetStateAction<number>>,
) => {
    const { eventsSearch, eventsSearchAppend } = useStateSelector(title);
    return (append?: boolean, scrollTop: number = 0) => {
        setAppendEvents(!!append);
        setScrollTop(scrollTop);

        const searchParams: EventsSearchParams = {
            limit: append ? limit : initialLoadLimit,
            offset: append ? offset : 0,
        };

        if (promote) {
            searchParams.specialFeatured = 1;
        }

        if (filter) {
            searchParams.eventcode = filter;
        }

        if (!append) {
            eventsSearch(title, searchParams);
        } else {
            setCardsToShow(cardsToShow + limit);
            eventsSearchAppend(title, searchParams);
        }
    };
};

export const useOnMount = (
    firstRender: boolean,
    setFirstRender: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
            dispatch(firstClientRender());
        }
    }, []);
};

export const useOnFiltersChange = (
    title: string,
    initialLoadLimit: number,
    firstRender: boolean,
    setCardsToShow: React.Dispatch<React.SetStateAction<number>>,
    setSearchWhenFetchStopped: React.Dispatch<React.SetStateAction<boolean>>,
    searchCategoryEvents: (append?: boolean) => void,
) => {
    const {
        allFiltersFilled,
        events,
        fetchController,
        isFetching,
        filtersAll,
    } = useStateSelector(title);

    // Search for events if filters are all filled (default have been set) or filters have changed
    useEffect(() => {
        if (!allFiltersFilled) {
            return;
        }

        // To stop the categories from running on first render if ssr has fetched events already
        if (firstRender && process.env.NODE_ENV === "production") {
            return;
        }

        // If filters have changes and already fetching then abort the fetch
        fetchController?.abort();

        // If filters have changes and not already fetching then search events
        if (!isFetching) {
            setCardsToShow(initialLoadLimit);
            searchCategoryEvents();
            return;
        }
        setSearchWhenFetchStopped(true);
    }, [allFiltersFilled, filtersAll]);
};

export const useOnIsFetchingChange = (
    title: string,
    searchWhenFetchStopped: boolean,
    setSearchWhenFetchStopped: React.Dispatch<React.SetStateAction<boolean>>,
    searchCategoryEvents: (append?: boolean) => void,
    scrollTop: number,
    setScrollTop: React.Dispatch<React.SetStateAction<number>>,
) => {
    const { isFetching } = useStateSelector(title);
    useEffect(() => {
        // If events were being fetched and the fetch has been cancelled then search for events with new filters
        if (!isFetching && searchWhenFetchStopped) {
            searchCategoryEvents();
            setSearchWhenFetchStopped(false);
        }
    }, [isFetching]);
};

export const useOnEventsChange = (
    title: string,
    setOffset: React.Dispatch<React.SetStateAction<number>>,
) => {
    const { events } = useStateSelector(title);

    useEffect(() => {
        // Set offset based on how many events are fetched
        setOffset(events.length);
    }, [events]);
};
