import React from "react";
import CentralContainer from "./components/central-container";
import Grid from "@material-ui/core/Grid";
import Filters from "./components/filters";
import Results from "./components/results";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PageTitle from "./components/seo/PageTitle";
import { useIsMobile } from "@skiddle/web.events.hooks";
import MetaLinks from "./components/seo/MetaLinks";
import Header from "./components/template/Header";
import QuickLinks from "./components/seo/QuickLinks";
import BarDivider from "./components/template/Header/BarDivider";
import CanonicalLink from "./components/seo/CanonicalLink";
import CityMetaBottom from "./components/landing/CityMeta/bottom";

const RouteIndex: React.FC = () => {
    const isMobile = useIsMobile();
    const classes = makeStyles((theme) => ({
        filter: {
            paddingBottom: "0",
            position: "relative",
            maxWidth: "100%",
        },
    }))();

    return (
        <>
            <CanonicalLink />
            <Header />
            <MetaLinks />
            <PageTitle />

            <BarDivider />

            {isMobile && <Filters />}

            <CentralContainer>
                <Grid container spacing={0}>
                    {!isMobile && (
                        <Grid
                            item
                            xl={12}
                            xs={12}
                            className={classes.filter}
                            style={{ padding: 0, paddingTop: "1px" }}
                        >
                            <Filters />
                        </Grid>
                    )}

                    <Grid
                        item
                        xl={12}
                        xs={12}
                        style={{ paddingTop: "1em", overflow: "visible" }}
                    >
                        <Results />
                    </Grid>
                </Grid>
            </CentralContainer>

            {/* SEO */}
            <CentralContainer>
                <Grid
                    container
                    spacing={isMobile ? 2 : 3}
                    justifyContent={"center"}
                    style={{
                        padding: "25px 10px",
                    }}
                >
                    <CityMetaBottom />
                    <QuickLinks landing={false} />
                </Grid>
            </CentralContainer>
        </>
    );
};

export default RouteIndex;
