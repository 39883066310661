import { useSelector } from "react-redux";
import { State } from "../../../../store/types";
import { userProfileResult } from "../../../../store/user/selector";
import { UserProfile } from "../../../../store/user/types";

export const ProfileIcon = () => {
    const userData = useSelector<State, UserProfile | undefined>((state) =>
        userProfileResult(state),
    );

    if (!userData) return null;

    return (
        <img
            src={userData?.profilePic[65]}
            alt="My profile pic"
            style={{
                height: "37px",
                width: "37px",
                borderRadius: "50%",
                fontSize: 11,
                textIndent: "5px",
            }}
        />
    );
};
