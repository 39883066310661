import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "../../../store/types";
import { SeoDataSelectState } from "../types";
import { seoResult } from "../../../store/seo/selectors";
import { Helmet } from "react-helmet";
import { specialLocations } from "@skiddle/web.events.data";
import { SEO } from "@skiddle/web.events.store.types";
import { locationResult } from "@skiddle/web.events.store.location";
import { SelectorState } from "./types";

const defaultMetaData = {
    all: {
        pageTitle: "Events Search",
        description:
            "Search for Events, Clubs, gig guide, Club Nights, Theatre and more. Buy your tickets or get on the guestlist for free",
    },
    online: {
        pageTitle: "Online and Virtual Events",
        description:
            "Search for Online and Virtual Events, Clubs, gig guide, Club Nights, Theatre and more. Buy your tickets here",
    },
};

const parsePageTitle = (locationText: string, seo: SEO) => {
    const lowerCaseLocationText = locationText.toLowerCase();

    if (specialLocations.includes(lowerCaseLocationText)) {
        return lowerCaseLocationText === "online"
            ? defaultMetaData.online.pageTitle
            : seo.pageTitle || defaultMetaData.all.pageTitle;
    }

    return seo?.pageTitle || defaultMetaData.all.pageTitle;
};

const parseDescription = (locationText: string, seo: SEO) => {
    const lowerCaseLocationText = locationText.toLowerCase();

    if (specialLocations.includes(lowerCaseLocationText)) {
        return lowerCaseLocationText === "online"
            ? defaultMetaData.online.description
            : seo.description || defaultMetaData.all.description;
    }

    return seo?.description || defaultMetaData.all.description;
};

const MetaLinks = () => {
    const { seo } = useSelector<State, SeoDataSelectState>((state) => ({
        seo: seoResult(state) || ({} as SEO),
    }));

    const locationResultValue = useSelector<State, SelectorState>((state) => ({
        locationResult: locationResult(state.location),
    }));

    const locationText = locationResultValue.locationResult
        ? locationResultValue.locationResult.name
        : "";

    const [pageTitle, setPageTitle] = useState(
        parsePageTitle(locationText, seo),
    );
    const [description, setDescription] = useState(
        parseDescription(locationText, seo),
    );

    // On location or seo change update meta title or description
    useEffect(() => {
        setPageTitle(parsePageTitle(locationText, seo));
        setDescription(parseDescription(locationText, seo));
    }, [locationText, seo]);

    return (
        <Helmet>
            <meta content={pageTitle} property="og:title" />
            <meta content={description} name="description" />
        </Helmet>
    );
};

export default MetaLinks;
