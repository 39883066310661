import React from "react";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Box, Divider } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import PlayArrow from "@material-ui/icons/PlayArrow";
import TwitterIcon from "@material-ui/icons/Twitter";
import { getMomentWithTimezone } from "@skiddle/web.events.date-format";
import "./footer.css";

const moment = getMomentWithTimezone();

const Footer = () => {
    const year = moment().year();

    return (
        <Box
            id="footer"
            className="footer-root"
            component={"div"}
            style={{ padding: "32px 16px" }}
        >
            <Grid container className="footer-footerGrid">
                {/* Left side */}
                <Grid item md={5}>
                    <Box>
                        <Link
                            underline={"none"}
                            href="/"
                            title="Skiddle"
                            className="footer-logoLink"
                        >
                            <img
                                className="footer-logo"
                                src="https://d1plawd8huk6hh.cloudfront.net/images/responsive/logo_vertical_rebrand.svg"
                                data-src="https://d1plawd8huk6hh.cloudfront.net/images/responsive/logo_vertical_rebrand.svg"
                                alt="Skiddle Logo"
                                data-size="0"
                            />
                        </Link>
                    </Box>
                    <Box style={{ marginBottom: 16, padding: 0 }}>
                        <Typography className="footer-subTitle">
                            <Link underline={"none"} href="/live/">
                                Gigs
                            </Link>
                            <Box
                                component={"span"}
                                style={{ paddingRight: 3, paddingLeft: 3 }}
                            >
                                &nbsp;●&nbsp;
                            </Box>
                            <Link underline={"none"} href="/clubs/">
                                Clubs
                            </Link>
                            <Box
                                component={"span"}
                                style={{ paddingRight: 3, paddingLeft: 3 }}
                            >
                                &nbsp;●&nbsp;
                            </Box>
                            <Link underline={"none"} href="/festivals/">
                                Festivals
                            </Link>
                        </Typography>
                    </Box>
                    <Box style={{ marginBottom: 16, paddingRight: 8 }}>
                        <Typography className="footer-aboutUs">
                            “On your side since 2001, because we believe true
                            fans deserve a fairer and smarter way to discover
                            music events they love.”
                        </Typography>
                    </Box>
                </Grid>
                {/* Right side */}
                <Grid item md={7}>
                    <Box style={{ height: 48, width: "100%" }} />
                    <Box style={{ marginBottom: 8 }}>
                        <Typography className="footer-title">
                            why use skiddle?
                        </Typography>
                    </Box>
                    <Box>
                        <ul className="footer-list footer-aboutUs">
                            <li>
                                <PlayArrow />
                                <div>
                                    Rated 5 stars on
                                    <Link
                                        underline={"none"}
                                        href="https://www.reviews.co.uk/company-reviews/store/skiddle"
                                        target="_blank"
                                        rel="noreferrer nofollow noopener"
                                    >
                                        reviews.co.uk
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <PlayArrow />
                                <div>Official Primary Ticket outlet</div>
                            </li>
                            <li>
                                <PlayArrow />
                                <div>Over 17 million tickets sold</div>
                            </li>
                            <li>
                                <PlayArrow />
                                <div>Established 2001</div>
                            </li>
                            <li>
                                <PlayArrow />
                                <div>Over 4.3 million happy customers</div>
                            </li>
                        </ul>
                        <ul className="footer-list footer-aboutUs">
                            <li>
                                <PlayArrow />
                                <div>
                                    Support the
                                    <Link
                                        underline={"none"}
                                        href="http://fanfairalliance.org/declaration/"
                                        target="_blank"
                                        rel="noreferrer nofollow noopener"
                                    >
                                        Fanfair Alliance
                                    </Link>
                                </div>
                            </li>

                            <li>
                                <PlayArrow />
                                <div>
                                    Part of the
                                    <Link
                                        underline={"none"}
                                        href="http://www.attitudeiseverything.org.uk/ticketingwithoutbarriers"
                                        target="_blank"
                                        rel="noreferrer nofollow noopener"
                                    >
                                        Attitude Is Everything
                                    </Link>
                                    Coalition
                                </div>
                            </li>

                            <li>
                                <PlayArrow />
                                <div>
                                    Raising £250,000 for
                                    <Link
                                        underline={"none"}
                                        href="https://www.skiddle.com/macmillan"
                                        rel="noreferrer nofollow noopener"
                                    >
                                        Macmillan
                                    </Link>
                                </div>
                            </li>
                        </ul>
                    </Box>
                </Grid>
                {/* Middle full width */}
                <Grid item md={12}>
                    <Grid container className={"footer-additionalLinks"}>
                        <Grid item md={4}>
                            <ul className="footer-listSmall">
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="/"
                                        rel="nofollow"
                                        title="Skiddle homepage"
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="/terms/"
                                        target="_blank"
                                        rel="noreferrer nofollow noopener"
                                        title="Terms and conditions"
                                    >
                                        Terms and Conditions
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="/terms/privacy.php"
                                        target="_blank"
                                        rel="noreferrer nofollow noopener"
                                        title="Privacy Policy"
                                    >
                                        Privacy
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="/terms/cookie-policy.php"
                                        target="_blank"
                                        rel="noreferrer nofollow noopener"
                                        title="Cookie Policy"
                                    >
                                        Cookie Policy
                                    </Link>
                                </li>
                            </ul>

                            <ul className="footer-listSmall">
                                <li>
                                    <Link underline={"none"} href="/aboutus/">
                                        About us
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="/orders/"
                                        rel="noreferrer nofollow noopener"
                                    >
                                        Order history/Print tickets
                                    </Link>
                                </li>
                                <li>
                                    <Link underline={"none"} href="/contact/">
                                        Contact us
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="/contact/jobs.php"
                                        rel="nofollow"
                                    >
                                        Jobs
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item md={4}>
                            <ul className="footer-listSmall">
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="https://skiddle.statuspage.io/"
                                        target="_blank"
                                        rel="noreferrer nofollow noopener"
                                    >
                                        Website Status
                                    </Link>
                                </li>
                                <li>
                                    <Link href="/api/" underline={"none"}>
                                        Developer API
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="/affiliates/"
                                        title="Ticket affiliate program"
                                    >
                                        Ticket affiliate program
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="/reps/"
                                        title="Become a Skiddle ticket rep"
                                    >
                                        Become a Skiddle ticket rep
                                    </Link>
                                </li>
                            </ul>

                            <ul className="footer-listSmall">
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="https://www.promotioncentre.co.uk"
                                    >
                                        Sell tickets online
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="https://www.promotioncentre.co.uk"
                                    >
                                        Promotion Centre
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="/sell-tickets-on-facebook.php"
                                    >
                                        Sell tickets on facebook
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        underline={"none"}
                                        href="/sell-festival-tickets.php"
                                    >
                                        Sell festival tickets
                                    </Link>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item md={4}>
                            <div className="footer-iconContainer">
                                <Link
                                    className={"footer-icon"}
                                    underline={"none"}
                                    href="https://twitter.com/skiddle"
                                    title="Twitter"
                                    target="_blank"
                                    rel="noreferrer nofollow noopener"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        aria-hidden="true"
                                        focusable="false"
                                        width="1em"
                                        height="1em"
                                        style={{
                                            msTransform: "rotate(360deg)",
                                            WebkitTransform: "rotate(360deg)",
                                            transform: "rotate(360deg)",
                                        }}
                                        preserveAspectRatio="xMidYMid meet"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23z"
                                            fill="#626262"
                                        />
                                    </svg>
                                </Link>
                                <Link
                                    className="footer-icon"
                                    underline={"none"}
                                    href="https://open.spotify.com/user/skiddleuk"
                                    title="Spotify"
                                    target="_blank"
                                    rel="noreferrer nofollow noopener"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        aria-hidden="true"
                                        focusable="false"
                                        width="1em"
                                        height="1em"
                                        style={{
                                            msTransform: "rotate(360deg)",
                                            WebkitTransform: "rotate(360deg)",
                                            transform: "rotate(360deg)",
                                        }}
                                        preserveAspectRatio="xMidYMid meet"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M17.9 10.9C14.7 9 9.35 8.8 6.3 9.75c-.5.15-1-.15-1.15-.6c-.15-.5.15-1 .6-1.15c3.55-1.05 9.4-.85 13.1 1.35c.45.25.6.85.35 1.3c-.25.35-.85.5-1.3.25m-.1 2.8c-.25.35-.7.5-1.05.25c-2.7-1.65-6.8-2.15-9.95-1.15c-.4.1-.85-.1-.95-.5c-.1-.4.1-.85.5-.95c3.65-1.1 8.15-.55 11.25 1.35c.3.15.45.65.2 1m-1.2 2.75c-.2.3-.55.4-.85.2c-2.35-1.45-5.3-1.75-8.8-.95c-.35.1-.65-.15-.75-.45c-.1-.35.15-.65.45-.75c3.8-.85 7.1-.5 9.7 1.1c.35.15.4.55.25.85M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2z"
                                            fill="#626262"
                                        />
                                    </svg>
                                </Link>
                                <Link
                                    className="footer-icon"
                                    underline={"none"}
                                    href="https://www.mixcloud.com/skiddle"
                                    title="Mixcloud"
                                    target="_blank"
                                    rel="noreferrer nofollow noopener"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        aria-hidden="true"
                                        focusable="false"
                                        width="1em"
                                        height="1em"
                                        style={{
                                            msTransform: "rotate(360deg)",
                                            WebkitTransform: "rotate(360deg)",
                                            transform: "rotate(360deg)",
                                        }}
                                        preserveAspectRatio="xMidYMid meet"
                                        viewBox="0 0 32 32"
                                    >
                                        <path
                                            d="M12.846 6a8.458 8.458 0 0 0-7.99 5.723C2.112 12.123 0 14.474 0 17.326C0 20.448 2.543 23 5.688 23h14.546C22.856 23 25 20.868 25 18.246a4.756 4.756 0 0 0-3.734-4.633C20.845 9.351 17.232 6 12.846 6zm.004 2c3.535 0 6.402 2.898 6.402 6.44c0 .693-.111 1.378-.324 2.03c-.456 1.409 1.642 1.99 2.047.673c.142-.418.253-.856.324-1.295A2.654 2.654 0 0 1 23 18.326A2.677 2.677 0 0 1 20.326 21H5.606A3.609 3.609 0 0 1 2 17.389c0-2 1.62-3.614 3.605-3.614c.952 0 1.855.38 2.534 1.063c1.013 1.02 2.554-.522 1.53-1.553a5.778 5.778 0 0 0-2.644-1.5A6.406 6.406 0 0 1 12.85 8zm16.619 4.008c-.777-.103-1.603.783-1.022 1.629a8.786 8.786 0 0 1 1.473 4.867c0 1.737-.52 3.435-1.473 4.877c-.48.697.049 1.619.854 1.619c.344 0 .669-.167.875-.46A10.804 10.804 0 0 0 32 18.503c0-2.17-.628-4.26-1.826-6.025c-.194-.292-.446-.437-.705-.471zM26.477 13.7c-.782-.102-1.603.776-1.022 1.623c.647.942.97 2.042.97 3.18a5.536 5.536 0 0 1-.97 3.17c-.47.687.02 1.619.863 1.619c.343 0 .667-.147.873-.441a7.734 7.734 0 0 0 1.317-4.348c0-1.56-.444-3.061-1.317-4.336c-.198-.29-.454-.433-.714-.467z"
                                            fill="#626262"
                                        />
                                    </svg>
                                </Link>
                                <Link
                                    className="footer-icon"
                                    underline={"none"}
                                    href="https://www.instagram.com/skiddleuk"
                                    title="Instagram"
                                    target="_blank"
                                    rel="noreferrer nofollow noopener"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        aria-hidden="true"
                                        focusable="false"
                                        width="1em"
                                        height="1em"
                                        style={{
                                            msTransform: "rotate(360deg)",
                                            WebkitTransform: "rotate(360deg)",
                                            transform: "rotate(360deg)",
                                        }}
                                        preserveAspectRatio="xMidYMid meet"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3z"
                                            fill="#626262"
                                        />
                                    </svg>
                                </Link>
                                <Link
                                    className="footer-icon"
                                    underline={"none"}
                                    href="https://www.facebook.com/skiddleuk"
                                    title="Facebook"
                                    target="_blank"
                                    rel="noreferrer nofollow noopener"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        aria-hidden="true"
                                        focusable="false"
                                        width="0.49em"
                                        height="1em"
                                        style={{
                                            msTransform: "rotate(360deg)",
                                            WebkitTransform: "rotate(360deg)",
                                            transform: "rotate(360deg)",
                                        }}
                                        preserveAspectRatio="xMidYMid meet"
                                        viewBox="0 0 486.037 1000"
                                    >
                                        <path
                                            d="M124.074 1000V530.771H0V361.826h124.074V217.525C124.074 104.132 197.365 0 366.243 0C434.619 0 485.18 6.555 485.18 6.555l-3.984 157.766s-51.564-.502-107.833-.502c-60.9 0-70.657 28.065-70.657 74.646v123.361h183.331l-7.977 168.945H302.706V1000H124.074"
                                            fill="#626262"
                                        />
                                    </svg>
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {/* Bottom full width */}
                <Grid item md={12}>
                    <Divider className="footer-divider" />
                    <p className={"footer-copyright"}>
                        © 2001 - {year} Skiddle Ltd | Skiddle is a registered
                        trademark | ALL RIGHTS RESERVED
                    </p>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Footer;
