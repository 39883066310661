import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Props } from './types';

const useStyles = makeStyles(() => ({
    centralContainer: {
        margin: "0 auto",
        maxWidth: "72rem",
        padding: "0 .66667em",
        flexGrow: 1,
    }
}));

const CentralContainer: React.FC<Props> = ({ children }) => {
    const classes = useStyles();

    return <div className={classes.centralContainer}>{children}</div>;
};

export default CentralContainer;
