import React from "react";
import LocationView from "./view";
import useCombinedHooks from "./hooks";
import { useIsMobile } from "@skiddle/web.events.hooks";
import { Props } from "./types";
import ucwords from "ucwords";

export const defaultValues = {
    location: "",
    radius: 10,
};

const LocationFilter: React.FC<Props> = ({
    getLocation,
    getLocations,
    locationFilter,
    locationController,
    locationResult,
    locations,
    radiusFilter,
    showVirtual,
    seoResult,
    useGetSeoData = () => () => {},
    pathPrefix,
    seoClear,
    useUrlParams = true,
    canSubmit,
}) => {
    const isMobile = useIsMobile();

    const {
        open,
        toggleOpen,
        isActive,
        confirmLocation,
        onClear,
        parsedLocations,
        locationSelect,
        searchInputChange,
        searchLocation,
        distance,
        changeDistance,
        filterOpen,
        showVirtualEvents,
        handleVirtualEventsChange,
        buttonText,
    } = useCombinedHooks(
        defaultValues,
        getLocation,
        getLocations,
        locationFilter,
        locationController,
        locationResult,
        locations,
        radiusFilter,
        showVirtual,
        seoResult,
        useGetSeoData,
        pathPrefix,
        seoClear,
        useUrlParams,
    );
    const parseButtonText = () => {
        if (locationResult) {
            return locationResult.name;
        }

        if (["near me", "online"].includes(buttonText.toLowerCase())) {
            return ucwords(buttonText.toLowerCase());
        }

        return "Location";
    };

    return (
        <LocationView
            open={open}
            buttonText={parseButtonText()}
            toggleOpen={toggleOpen}
            confirmLocation={confirmLocation}
            onClear={onClear}
            parsedLocations={parsedLocations}
            locationSelect={locationSelect}
            searchInputChange={searchInputChange}
            searchLocation={searchLocation}
            distance={distance}
            distanceChange={changeDistance}
            filterOpen={filterOpen}
            isActive={isActive}
            showVirtualEvents={showVirtualEvents}
            handleVirtualEventsChange={handleVirtualEventsChange}
            isMobile={isMobile}
            canSubmit={canSubmit}
            locationResult={locationResult}
        />
    );
};

export default LocationFilter;
