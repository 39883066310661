import React from "react";
import FilterDialog from "../dialog";
import SearchButton from "../Button";
import SelectList from "../select";
import Typography from "@material-ui/core/Typography";
import { GenresViewProps as Props } from "./types";
import ucwords from "ucwords";
import MusicNoteIcon from "@material-ui/icons/MusicNote";

// TODO: add loading to genres view
const GenresView: React.FC<Props> = ({
    open,
    toggleOpen,
    confirmGenres,
    clearGenres,
    topGenres,
    selectedGenres,
    setSelectedGenres,
    genres,
    genresDisplay,
    isActive,
}) => (
    <>
        <FilterDialog
            title="Genres"
            open={open}
            toggleOpen={toggleOpen}
            onConfirm={confirmGenres}
            onClear={clearGenres}
            clearLabel="show all genres"
        >
            <>
                <div style={{ marginBottom: 15 }}>
                    <Typography variant="h6">Popular Genres</Typography>
                    <SelectList
                        multiSelect
                        options={topGenres.map(
                            ({ genrename, eventCount }) =>
                                `${ucwords(genrename)}`,
                        )}
                        onSelect={(els) =>
                            Array.isArray(els) && setSelectedGenres(els)
                        }
                        count={genres.map(({ genrename, eventCount }) => ({
                            name: genrename,
                            count: eventCount,
                        }))}
                        selected={selectedGenres}
                    />
                </div>

                <Typography variant="h6">All Genres</Typography>
                <SelectList
                    multiSelect
                    options={genres.map(
                        ({ genrename, eventCount }) => `${ucwords(genrename)}`,
                    )}
                    onSelect={(els) =>
                        Array.isArray(els) && setSelectedGenres(els)
                    }
                    count={genres.map(({ genrename, eventCount }) => ({
                        name: genrename,
                        count: eventCount,
                    }))}
                    selected={selectedGenres}
                />
            </>
        </FilterDialog>
        <SearchButton
            title={ucwords(genresDisplay())}
            onClick={toggleOpen}
            isActive={isActive}
            icon={<MusicNoteIcon />}
        />
    </>
);

export default GenresView;
