import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    inputRoot: {
        borderRadius: 5,
        padding: 1,
    },
    input: {
        fontSize: "1.2rem",
    },
}));

export default useStyles;
