import { EventCodes } from "@skiddle/web.events.shared-types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryParam, NumericArrayParam } from "use-query-params";
import { useToggleOpen } from "@skiddle/web.events.hooks";
import { getEventTypes } from "../../../../store/events/types/actions";
import { eventTypesResults } from "../../../../store/events/types/selector";
import { EventType } from "../../../../store/events/types/types";
import {
    eventTypesFilter,
    orderByFilter,
} from "../../../../store/filters/selector";
import { State } from "../../../../store/types";
import { SelectorState } from "../types";
import {
    eventTypesDisplay,
    useOnEventTypesLoaded,
    useOnFilterEventTypesChange,
    useOnMount,
} from "./effects";
import { useClearEventTypes, useConfirmEventTypes } from "./event-handlers";
import { locationResult } from "@skiddle/web.events.store.location";
import { getCitySEO } from "../../../../store/seo/actions";
import { UseGetSeoData } from "@skiddle/web.events.filters.location";

export const selectedEventTypesFromEventCodes = (
    filterEventTypes: EventCodes[],
    eventTypes: EventType[],
) =>
    eventTypes
        .filter(({ eventcode }) => filterEventTypes?.includes(eventcode))
        .map(({ eventcodedesc }) => eventcodedesc);

const useCombinedHooks = (
    defaultValue: EventCodes[],
    useGetSeoData: UseGetSeoData,
) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = useToggleOpen(setOpen);
    const [selectedEventTypes, setSelectedEventTypes] =
        useSelectedEventTypes(defaultValue);
    const [buttonTitle, setButtonTitle] = useState(
        eventTypesDisplay(selectedEventTypes),
    );
    const [userChanged, setUserChanged] = useState<boolean>(false);
    const { filterEventTypes } = useStateSelector();
    const isActive =
        (userChanged && filterEventTypes && filterEventTypes.length > 0) ||
        false;

    const confirmEventTypes = useConfirmEventTypes(
        selectedEventTypes,
        setOpen,
        setUserChanged,
    );
    const clearEventTypes = useClearEventTypes(
        setSelectedEventTypes,
        defaultValue,
        setOpen,
        setUserChanged,
    );
    const { eventTypes } = useStateSelector();

    useEffects(
        defaultValue,
        selectedEventTypes,
        setButtonTitle,
        setSelectedEventTypes,
        useGetSeoData,
    );

    return {
        open,
        toggleOpen,
        selectedEventTypes,
        setSelectedEventTypes,
        buttonTitle,
        confirmEventTypes,
        clearEventTypes,
        eventTypes,
        isActive,
    };
};

export const useEventCodesQueryParam = () =>
    useQueryParam("eventcodes[]", NumericArrayParam);

export const useStateSelector = () => {
    const dispatch = useDispatch();

    return useSelector<State, SelectorState>((state) => ({
        filterEventTypes: eventTypesFilter(state.filters),
        fetchEventTypes: getEventTypes(state, dispatch),
        eventTypes: eventTypesResults(state),
        locationResult: locationResult(state.location),
        order: orderByFilter(state.filters),
        retrieveCitySeo: getCitySEO(state, dispatch),
    }));
};

const useSelectedEventTypes = (defaultValue: EventCodes[]) => {
    const { eventTypes, filterEventTypes } = useStateSelector();

    return useState<string[]>(() => {
        if (!eventTypes) {
            return defaultValue as string[];
        }

        return selectedEventTypesFromEventCodes(
            filterEventTypes || [],
            eventTypes,
        );
    });
};

const useEffects = (
    defaultValue: EventCodes[],
    selectedEventTypes: string[],
    setButtonTitle: React.Dispatch<React.SetStateAction<string>>,
    setSelectedEventTypes: React.Dispatch<React.SetStateAction<string[]>>,
    useGetSeoData: UseGetSeoData,
) => {
    useOnMount(defaultValue);
    useOnEventTypesLoaded(setSelectedEventTypes, setButtonTitle, useGetSeoData);
    useOnFilterEventTypesChange(
        selectedEventTypes,
        setSelectedEventTypes,
        setButtonTitle,
    );
};

export default useCombinedHooks;
