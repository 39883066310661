import { combineReducers } from "redux";
import events from "./events/reducer";
import categoryEvents from "./Categories/reducer";
import { APIState, FirstClientRenderAction } from "./types";
import { Reducer } from "react";
import filters, { lastUpdatedReducer } from "./filters/reducer";
import genres from "./genres/reducer";
import location from "./location/reducer";
import aggregations from "./aggregations/reducer";
import seo from "./seo/reducer";
import header from "./header/reducer";
import user from "./user/reducer";
import { APIErrorAction } from "@skiddle/web.events.store.types";
import { FilterActions } from "@skiddle/web.events.store.filters";

// All errors from api calls go in here
export const initialAPIState: APIState = {
    error: "",
};
const apiReducer: Reducer<APIState, APIErrorAction> = (
    state = initialAPIState,
    action,
) => {
    switch (action.type) {
        case "API_ERROR":
            if (process.env.NODE_ENV === "development") {
                return {
                    error: action.error,
                };
            }

            return {
                error: "Sorry, something went wrong",
            };

        default:
            return state;
    }
};

export const initialFirstClientRenderState = true;
const firstClientRender: Reducer<boolean, FirstClientRenderAction> = (
    state = initialFirstClientRenderState,
    action,
) => {
    switch (action.type) {
        case "FIRST_CLIENT_RENDER":
            return false;

        default:
            return state;
    }
};
export const initialCompareQueryParamsClientSideState = true;
const compareQueryParamsClientSide: Reducer<Boolean, FilterActions> = (
    state = initialCompareQueryParamsClientSideState,
    action,
) => {
    switch (action.type) {
        case "UPDATE_FILTERS_ORDER_BY":
        case "UPDATE_FILTERS_LOCATION":
        case "UPDATE_FILTERS_RADIUS":
        case "UPDATE_FILTERS_KEYWORD":
        case "UPDATE_FILTERS_GENRES":
        case "UPDATE_FILTERS_EVENT_TYPES":
        case "UPDATE_FILTERS_MIN_DATE":
        case "UPDATE_FILTERS_MAX_DATE":
        case "UPDATE_FILTERS_MIN_MAX_DATES":
        case "UPDATE_FILTERS_TICKETS_AVAILABLE":
        case "UPDATE_FILTERS_HIDE_CANCELLED":
        case "UPDATE_FILTERS_UNDER_18":
        case "UPDATE_FILTERS_OTHER":
        case "UPDATE_SHOW_VIRTUAL":
            return true;

        default:
            return state;
    }
};

export default combineReducers({
    api: apiReducer,
    events,
    categoryEvents,
    filters,
    lastUpdateFilter: lastUpdatedReducer,
    genres,
    location,
    aggregations,
    seo,
    header,
    firstClientRender,
    user,
    compareQueryParamsClientSide,
});
