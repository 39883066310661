import makeStyles from "@material-ui/core/styles/makeStyles";
import { navyBlue } from "../../../../theme";

const useStyles = makeStyles((theme) => ({
    featuredRoot: {
        flexWrap: "nowrap",
        overflowX: "scroll",
        display: "-webkit-inline-box", // support for iPhone 5 Safari
        "@supports (display: flex)": {
            display: "flex",
        },
    },
    divider: {
        backgroundColor: navyBlue,
        height: "2px",
    },
}));

export default useStyles;
