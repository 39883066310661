import { EventTypesState, EventTypesActions } from "./types";
import { Reducer } from "redux";

export const initialState: EventTypesState = {
    fetching: false,
    results: [],
};

const reducer: Reducer<EventTypesState, EventTypesActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "EVENT_TYPES_FETCH":
            return {
                ...state,
                fetching: true,
            };

        case "EVENT_TYPES_FETCH_SUCCESS":
            return {
                fetching: false,
                results: action.eventTypes,
            };

        default:
            return state;
    }
};

export default reducer;
