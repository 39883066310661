import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    selectedToFilterStoreConvert,
    useHideCancelledQueryParam,
    useStateSelector,
    useTicketsOnlyQueryParam,
    useUnder18QueryParam,
} from ".";
import { storeFilterKeysToOptions } from "..";
import { EventsSearchParams } from "@skiddle/web.events.shared-types";
import { updateOther } from "../../../../store/filters/actions";

const parseSelectedFromQueryParams = (
    ticketsOnly: EventsSearchParams["ticketsavailable"],
    hideCancelled: EventsSearchParams["hidecancelled"],
    under18: EventsSearchParams["under18"],
): string[] => {
    const selectedOptions: string[] = [];

    if (ticketsOnly === 1) {
        selectedOptions.push(storeFilterKeysToOptions.ticketsAvailable);
    }

    if (hideCancelled === 1) {
        selectedOptions.push(storeFilterKeysToOptions.hideCancelled);
    }

    if (under18 === "true") {
        selectedOptions.push(storeFilterKeysToOptions.under18);
    }

    return selectedOptions;
};

export const useOnMount = (
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
) => {
    const dispatch = useDispatch();
    const { hideCancelled, ticketsAvailable, under18 } = useStateSelector();
    const [ticketsOnly] = useTicketsOnlyQueryParam();
    const [hideCancelledParam] = useHideCancelledQueryParam();
    const [under18Param] = useUnder18QueryParam();

    useEffect(() => {
        if (
            hideCancelled === undefined &&
            ticketsAvailable === undefined &&
            under18 === undefined
        ) {
            const { ticketsAvailable, hideCancelled, under18 } =
                selectedToFilterStoreConvert(selected);
            dispatch(
                updateOther(
                    ticketsOnly ?? ticketsAvailable,
                    hideCancelledParam ?? hideCancelled,
                    under18Param ?? under18,
                ),
            );

            setSelected(
                parseSelectedFromQueryParams(
                    ticketsOnly ?? ticketsAvailable,
                    hideCancelledParam ?? hideCancelled,
                    under18Param ?? under18 ?? undefined,
                ),
            );
        }
    }, []);
};

export const useOnTicketsAvailableFilterStoreChange = (
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
) => {
    const { ticketsAvailable } = useStateSelector();
    const [, setTicketsOnly] = useTicketsOnlyQueryParam();

    useEffect(() => {
        if (ticketsAvailable !== undefined) {
            const { ticketsAvailable: ticketsAvailableOption } =
                storeFilterKeysToOptions;

            // Set "Show only events with tickets available" option to selected
            if (
                ticketsAvailable === 1 &&
                !selected.includes(ticketsAvailableOption)
            ) {
                setSelected([...selected, ticketsAvailableOption]);
                setTicketsOnly(1);
            }

            // Unselect "Show only events with tickets available" option
            if (
                ticketsAvailable === 0 &&
                selected.includes(ticketsAvailableOption)
            ) {
                const newSelected = selected.filter(
                    (option) => option !== ticketsAvailableOption,
                );
                setSelected([...newSelected]);
                setTicketsOnly(0);
            }
        }
    }, [ticketsAvailable]);
};

export const useOnHideCancelledFilterStoreChange = (
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
) => {
    const { hideCancelled } = useStateSelector();
    const [, setHideCancelledParam] = useHideCancelledQueryParam();

    useEffect(() => {
        if (hideCancelled !== undefined) {
            const { hideCancelled: hideCancelledOption } =
                storeFilterKeysToOptions;

            // Set "Hide cancelled events" option to selected
            if (
                hideCancelled === 1 &&
                !selected.includes(hideCancelledOption)
            ) {
                setSelected([...selected, hideCancelledOption]);
                setHideCancelledParam(1);
            }

            // Unselect "Hide cancelled events" option
            if (hideCancelled === 0 && selected.includes(hideCancelledOption)) {
                const newSelected = selected.filter(
                    (option) => option !== hideCancelledOption,
                );
                setSelected([...newSelected]);
                setHideCancelledParam(0);
            }
        }
    }, [hideCancelled]);
};

export const useUnder18FilterStoreChange = (
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
) => {
    const { under18 } = useStateSelector();
    const [, setUnder18Param] = useUnder18QueryParam();

    useEffect(() => {
        if (under18 !== undefined) {
            const { under18: under18Option } = storeFilterKeysToOptions;

            // Set "show only events with tickets available" option to selected
            if (under18 === "true" && !selected.includes(under18Option)) {
                setSelected([...selected, under18Option]);
                setUnder18Param("true");
            }

            // Unselect "show only events with tickets available" option
            if (under18 === "false" && selected.includes(under18Option)) {
                const newSelected = selected.filter(
                    (option) => option !== under18Option,
                );
                setSelected([...newSelected]);
                setUnder18Param("false");
            }
        }
    }, [under18]);
};
