import YouTubeIcon from "@material-ui/icons/YouTube";
import PublicIcon from "@material-ui/icons/Public";
import { Option } from "@skiddle/web.events.shared-types";
import LocationOnIcon from "@material-ui/icons/LocationOn";
// When the location input is empty show these inputs
export const defaultEmptyOptions: Option[] = [
    {
        title: "Show Events Near Me",
        value: "near me",
        icon: LocationOnIcon,
    },
    {
        title: "Show Online/Virtual Events",
        value: "online",
        icon: YouTubeIcon,
    },
    {
        title: "Show Events Everywhere",
        value: "all",
        icon: PublicIcon,
    },
];

export const defaultEmptyOptionsMap = new Map<string, string>(
    defaultEmptyOptions.reduce<[string, string][]>(
        (concat, { title, value }) => {
            concat.push([value, title]);
            concat.push([title, value]);
            return concat;
        },
        [],
    ),
);

// Options that if they are put in the url location will have special actions (not calling location api)
export const specialLocations = ["all", "online", "near-me"];
