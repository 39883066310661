import { makeStyles } from "@material-ui/core/styles";
import { boxShadow, white } from "@skiddle/web.global.theme";

const useStyles = makeStyles((theme) => ({
    navItem: {
        flexGrow: 1,
        fontWeight: 600,
    },
    navLink: {
        webkitTransition: "color 0.4s ease-in-out",
        transition: "color 0.4s ease-in-out",
        color: white,
        "&:hover": {
            color: theme.palette.primary.main,
        },
        "@media (max-width: 815px)": {
            fontSize: "0.9em",
        },
        "@media (max-width: 740px)": {
            fontSize: "0.8em",
        },
        "@media (max-width: 700px)": {
            fontSize: "0.7em",
        },
        "@media (max-width: 660px)": {
            fontSize: "0.6em",
        },
        "@media (max-width: 609px)": {
            fontSize: "0.55em",
        },
    },
    navMenu: {
        zIndex: 225,
        top: "100%",
        left: "0",
        right: "0",
        marginTop: "12px",
        position: "relative",
        width: "100%",
        background: "#38312e",
        padding: theme.spacing(3),
        boxShadow: boxShadow,
        borderTop: `5px solid ${theme.palette.primary.main}`,
        borderBottom: `5px solid ${theme.palette.primary.main}`,
    },
    // invisible bridge used to keep hover state active between link and nav menu without exiting
    navBridge: {
        zIndex: 225,
        margingBottom: "15px",
        top: "100%",
        left: "0",
        right: "0",
        position: "absolute",
        width: "100%",
        maxWidth: "98vw",
        margin: "auto",
        marginTop: "-15px",
        // display: "none"
    },
    menuTitle: {
        position: "relative",
        zIndex: 10,
        color: white,
        fontWeight: 600,
    },
    categoryTitle: {
        fontWeight: 600,
    },
    categoryLink: {
        display: "flex",
        fontSize: ".91667rem",
        color: white,
    },
    categoryContainer: {
        maxHeight: "360px",
        height: "inherit",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    blur: {
        filter: "blur(3px)",
        zIndex: 10,
        top: "100%",
        left: 0,
        right: 0,
        bottom: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
    },
    genresContainer: {
        marginTop: theme.spacing(2),
    },
    genresLink: {
        webkitTransition: "background 0.4s ease-in-out",
        transition: "background 0.4s ease-in-out",
        fontSize: "13.75px",
        color: white,
        fontWeight: 600,
        position: "absolute",
        left: "0",
        bottom: "0",
        width: "100%",
        padding: ".33333em !important",
        background: "rgba(22,61,141,0.8)",
        "&:hover": {
            background: "rgba(22,61,141,1)",
        },
    },
    genresImage: {
        webkitTransition: "transform 0.8s ease-in-out",
        transition: "transform 0.8s ease-in-out",
        transform: "scale(1)",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    genresCard: {
        height: "5.66667em",
        overflow: "hidden",
        position: "relative",
        textAlign: "center",
        borderRadius: "5px",
        margin: theme.spacing(1),
    },
}));

export default useStyles;
