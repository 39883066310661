import moment from "moment";
import "moment-timezone";

const dateFormat = {
    // when the date is closer, specify custom values
    // lastWeek: '[Last] dddd',
    lastDay: "[Yesterday]",
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    // when the date is further away
};

export const mobileFavDateFormat = {
    ...dateFormat,
    lastWeek: "ddd Do MMM",
    nextWeek: "ddd Do MMM",
    sameElse: "ddd Do MMM",
};

export const getMomentWithTimezone = () => {
    moment.tz.setDefault("UTC");
    return moment;
};

export default {
    ...dateFormat,
    lastWeek: "ddd Do MMM",
    nextWeek: "ddd Do MMM",
    sameElse: "ddd Do MMM",
};
