import React from "react";
import Typography from "@material-ui/core/Typography";
import { CarouselFullType, Props } from "./types";
import Grid from "@material-ui/core/Grid";
import FeaturedCard from "../FeaturedCard";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import skeletonTheme from "../theme";
import Cards from "../Cards";
import importedComponent from "react-imported-component";
import { DefaultComponent } from "react-imported-component/dist/es5/types";

const Carousel = importedComponent<CarouselFullType, CarouselFullType>(
    () =>
        (import("@brainhubeu/react-carousel") as unknown) as Promise<
            DefaultComponent<CarouselFullType>
        >,
);

const Category: React.FC<Props> = ({
    promote,
    hideFeatured = true,
    matchMobile,
}) => {
    const cardLimit = 5;
    const featuredLimit = 5;

    if (promote) {
        if (matchMobile) {
            const mobileFeatured = () => {
                let cards = [];

                for (let i = 1; i <= featuredLimit; i++) {
                    cards[i] = (
                        <Grid
                            style={
                                matchMobile
                                    ? {
                                          minWidth: "40%",
                                          width: "40%",
                                      }
                                    : {
                                          minWidth: "25%",
                                          width: "25%",
                                      }
                            }
                            item
                            key={i}
                            xs={6}
                            sm={4}
                            md={6}
                        >
                            <FeaturedCard mobile={true} />
                        </Grid>
                    );
                }

                return cards;
            };

            return (
                <SkeletonTheme {...skeletonTheme}>
                    <div>
                        {/* featured events */}
                        <Typography variant="h2">
                            <Skeleton width={160} height={25} />
                        </Typography>

                        <Grid
                            container
                            spacing={2}
                            style={{ flexWrap: "nowrap", overflowX: "scroll" }}
                        >
                            {mobileFeatured()}
                        </Grid>
                    </div>
                </SkeletonTheme>
            );
        }

        const desktopFeatured = () => {
            let cards = [];

            for (let i = 1; i <= featuredLimit; i++) {
                cards[i] = (
                    <Grid item key={i} md={11} lg={11} sm={11} xs={12}>
                        {" "}
                        <FeaturedCard mobile={false} />
                    </Grid>
                );
            }

            return cards;
        };

        return (
            <SkeletonTheme {...skeletonTheme}>
                <div>
                    {/* featured events */}
                    <Typography variant="h2">
                        <Skeleton width={160} height={25} />
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item md={12} lg={12} sm={12}>
                            <Carousel
                                centered={false}
                                arrows={true}
                                slidesPerPage={5}
                                slidesPerScroll={1}
                            >
                                {desktopFeatured()}
                            </Carousel>
                        </Grid>
                    </Grid>
                </div>
            </SkeletonTheme>
        );
    }

    return (
        <div>
            <Typography variant="h2">
                <SkeletonTheme {...skeletonTheme}>
                    <Skeleton width={160} height={25} />
                </SkeletonTheme>
            </Typography>

            <Grid container spacing={2}>
                {<Cards cards={cardLimit} matchMobile={matchMobile} />}
            </Grid>
        </div>
    );
};

export default Category;
