import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NumericArrayParam, useQueryParam } from "use-query-params";
import { topGenresCount } from "..";
import { genresFilter } from "../../../../store/filters/selector";
import { getGenres } from "../../../../store/genres/actions";
import {
    genreIsFetching,
    genresResults,
} from "../../../../store/genres/selectors";
import { Genre } from "../../../../store/genres/types";
import { State } from "../../../../store/types";
import { SelectorState } from "../types";
import ucwords from "ucwords";
import { useToggleOpen } from "@skiddle/web.events.hooks";
import { useClearGenres, useConfirmGenres } from "./event-handlers";
import {
    useOnGenresLoaded,
    useOnGenresStoreFilterChange,
    useOnMount,
} from "./effects";

const filterGenresName = (
    filterGenres: number[],
    genres: Genre[],
    withEventCount: boolean = false,
) =>
    filterGenres.map((genreID) => {
        const genre = genres.find(
            ({ genreid }) => Number(genreID) === Number(genreid),
        );

        if (genre) {
            return `${ucwords(genre.genrename)}${
                withEventCount ? ` (${genre.eventCount})` : ""
            }`;
        }

        return "";
    });

const useCombineHooks = (defaultValue: (number | string)[]) => {
    const [open, setOpen] = useState(false);
    const [selectedGenres, setSelectedGenres] = useSelectGenres(
        defaultValue as number[],
    );
    const [userChanged, setUserChanged] = useState<boolean>(false);
    const isActive = userChanged && selectedGenres.length > 0;

    const toggleOpen = useToggleOpen(setOpen);
    const genresDisplay = useGenresDisplay();
    const { isFetching, topGenres, genres } = useStateSelector();
    const confirmGenres = useConfirmGenres(
        selectedGenres,
        setOpen,
        setUserChanged,
    );
    const clearGenres = useClearGenres(
        defaultValue as string[],
        setSelectedGenres,
        setOpen,
        setUserChanged,
    );

    useEffects(defaultValue as number[], selectedGenres, setSelectedGenres);

    return {
        open,
        selectedGenres,
        setSelectedGenres,
        toggleOpen,
        genresDisplay,
        isFetching,
        confirmGenres,
        clearGenres,
        topGenres,
        genres,
        isActive,
    };
};

export const useGenresQueryParam = () =>
    useQueryParam("genre[]", NumericArrayParam);

export const useStateSelector = () => {
    const dispatch = useDispatch();

    return useSelector<State, SelectorState>((state) => ({
        filterGenres: genresFilter(state.filters),
        fetchGenres: getGenres(state, dispatch),
        genres: genresResults(state),
        topGenres: genresResults(state)
            .sort((a, b) => {
                // Sort genres into descending order by event count
                const aEventCount = a.eventCount || 0;
                const bEventCount = b.eventCount || 0;
                if (aEventCount === bEventCount) {
                    return 0;
                }

                return aEventCount < bEventCount ? 1 : -1;
            })
            .slice(0, topGenresCount),
        isFetching: genreIsFetching(state),
    }));
};

const useSelectGenres = (defaultValue: number[]) => {
    const { filterGenres, genres } = useStateSelector();
    return useState<string[]>(
        filterGenresName(filterGenres || defaultValue, genres),
    );
};

const useGenresDisplay = () => {
    const { filterGenres } = useStateSelector();

    return () => {
        const genresLength = filterGenres?.length || 0;
        if (filterGenres && genresLength > 0) {
            return `Genres (${genresLength})`;
        }

        return "Genres";
    };
};

const useEffects = (
    defaultValue: number[],
    selectedGenres: string[],
    setSelectedGenres: React.Dispatch<React.SetStateAction<string[]>>,
) => {
    useOnMount(defaultValue);
    useOnGenresLoaded(setSelectedGenres);
    useOnGenresStoreFilterChange(selectedGenres, setSelectedGenres);
};

export default useCombineHooks;
