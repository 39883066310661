import { makeStyles } from "@material-ui/core/styles";
import { boxShadow } from "../../../theme";

const useStyles = makeStyles({
    root: {
        padding: 12,
    },
    container: {
        "@media (min-width: 600px)": {
            borderRadius: 5,
            boxShadow,
            marginLeft: 5,
            padding: "25px !important",
            zIndex: 222,
            backgroundColor: "#fff",
        },
    },
    gridContainer: {
        zIndex: 222,
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        "@media (min-width: 600px)": {
            justifyContent: undefined,
        },
    },
    imageContainer: {
        "@media (max-width: 600px)": {
            display: "none",
        },
    },
    inputs: {
        padding: "8px 16px",
        boxShadow: "#dddddd 0 0 0 1px inset !important",
        borderRadius: 8,
        marginBottom: 15,
        "& div.DateRangePickerInput": {
            border: "none",
        },
    },
    image: {
        borderRadius: 8,
        maxWidth: "none",
        width: "165%",
        left: "-50%",
        position: "relative",
        zIndex: 0,
    },
    button: {
        textTransform: "none",
        fontWeight: 600,
        fontSize: "1.25rem",
    },
    heading: {
        marginTop: -5,
        marginBottom: 15,
        fontWeight: 700,
        textTransform: "lowercase",
    },
});

export default useStyles;
