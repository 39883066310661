import React from "react";
import CardMui from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import useStyles from "./styles";
import {Box} from "@material-ui/core";
import Artist from "./Artist";
import Favourite from "../Favourite"
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import skeletonTheme from "../theme";

const DesktopCard: React.FC = () => {
    const classes = useStyles();

    return (
        <SkeletonTheme {...skeletonTheme}>
            <CardMui className={classes.root}>
                <CardContent
                    className={classes.content}
                    style={{ position: "relative" }}
                >
                    <Favourite size={'medium'} />
                    <Artist size={'medium'} />

                    <CardMedia style={{height: 0, background : '#eaeaea'}} className={classes.media} image={'none'}/>

                    <div className={classes.contentBox}>
                        <Typography
                            className={classes.contentSections}
                            variant="body2"
                            color="inherit"
                        >
                            <span className={classes.detailsList}>
                                <Skeleton width={40} height={10}/>
                            </span>
                        </Typography>

                        <div>
                            <Box className={classes.contentSections} color="inherit">
                                <span className={classes.detailsListBlue}>
                                    <Skeleton className={classes.smallIcon} width={10} height={10}/>
                                </span>
                                <Box component="div" textOverflow="ellipsis">
                                    <Typography noWrap className={classes.detailsListBlue}>
                                        <Skeleton width={100} height={10}/>
                                    </Typography>
                                </Box>
                            </Box>
                        </div>

                        <Typography
                            className={classes.cardTitle}
                            variant="body1"
                            component="h3"
                        >
                            <Skeleton width={40} height={10}/>
                        </Typography>

                    </div>

                </CardContent>
            </CardMui>
        </SkeletonTheme>
    );
};

export default DesktopCard;
