import {
    EventTypesFetch,
    EventType,
    EventTypesFetchSuccess,
    EventTypesActions,
    GetEventTypes,
    GetEventTypeCounts,
    EventsAggregationFetch,
    EventTypeAggregation,
    EventsAggregationFetchSuccess,
} from "./types";
import { State, APIResponse } from "../../types";
import { Dispatch } from "redux";
import { eventTypesIsFetching } from "./selector";
import "isomorphic-fetch";
import { aggregationResults } from "../../aggregations/selectors";
import { apiError } from "@skiddle/web.events.store.types";

const eventTypesFetch = (): EventTypesFetch => ({
    type: "EVENT_TYPES_FETCH",
});

const eventTypesFetchSuccess = (
    eventTypes: EventType[],
): EventTypesFetchSuccess => ({
    type: "EVENT_TYPES_FETCH_SUCCESS",
    eventTypes,
});

export const eventAggregationFetch = (): EventsAggregationFetch => ({
    type: "EVENTS_AGGREGATION_FETCH",
});

export const getEventTypes: GetEventTypes =
    (state: State, dispatch: Dispatch<EventTypesActions>) => async () => {
        if (eventTypesIsFetching(state)) {
            return;
        }

        dispatch(eventTypesFetch());

        try {
            const eventTypes = await fetchEventTypes();
            dispatch(eventTypesFetchSuccess(eventTypes));
        } catch (err) {
            console.log(err);
            dispatch(apiError(err as string));
        }
    };

export const fetchEventTypes = async () => {
    try {
        const apiURL = process.env.REACT_APP_API_URL;
        const publicKey = process.env.REACT_APP_PUB_KEY;

        const data = new URLSearchParams(
            Object.entries({
                pub_key: publicKey,
            }) as string[][],
        );

        const resp = await fetch(`${apiURL}/v1/eventtypes?${data}`, {
            method: "GET",
            mode: "cors",
        });

        const { error, errormessage, results }: APIResponse<EventType[]> =
            await resp.json();

        if (error && errormessage) {
            throw new Error(errormessage);
        }

        return results;
    } catch (err) {
        throw err;
    }
};

export const eventAggregationFetchSuccess = (
    aggregations: EventTypeAggregation,
): EventsAggregationFetchSuccess => ({
    type: "EVENTS_AGGREGATION_FETCH_SUCCESS",
    aggregations,
});

export const getEventTypeCounts: GetEventTypeCounts =
    (state: State, dispatch: Dispatch<EventTypesActions>) => async () => {
        dispatch(eventAggregationFetch());

        try {
            const results = aggregationResults(state);

            if (results && results.eventcode) {
                dispatch(eventAggregationFetchSuccess(results.eventcode));
            }
        } catch (err) {
            console.log(err);
            dispatch(apiError(err as string));
        }
    };
