import { SEO } from "@skiddle/web.events.store.types";
import { State } from "../types";

export const seoIsFetching = (state: State): boolean => state.seo.fetching;

export const seoResults = (state: State): SEO[] => state.seo.results;

export const seoResult = (state: State): SEO | null => state.seo.result;

export const seoFetchController = (state: State): AbortController | undefined =>
    state.seo.controller;
