import React, { createRef } from "react";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import useStyles from "./styles";
import IconButton from "@material-ui/core/IconButton";
import Fade from "@material-ui/core/Fade";
import { Props } from "@skiddle/web.global.template.nav-bar.search-bar/types";

const SearchBar: React.FC<Props> = ({ edge }) => {
    const formAction = "/whats-on/search.html";
    const classes = useStyles();
    const [checked, setChecked] = React.useState(false);
    const [buttonRef, setButtonRef] = React.useState(
        createRef() as React.RefObject<HTMLDivElement>,
    );

    const handleChange = () => {
        setChecked((prev) => !prev);

        if (buttonRef.current && !checked) {
            buttonRef.current.focus();
        }
    };

    const input = (
        <div
            className={`${classes.search} ${checked ? classes.showSearch : ""}`}
        >
            <form action={formAction} method="POST">
                <Fade in={checked}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                </Fade>
                <input type="hidden" name="home" value="1" />
                <InputBase
                    inputRef={buttonRef}
                    onBlur={handleChange}
                    placeholder="Search..."
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    name="keyword"
                />
            </form>
        </div>
    );

    return (
        <>
            <Fade
                in={!checked}
                timeout={{
                    appear: 0,
                    enter: 1000,
                    exit: 0,
                }}
            >
                <IconButton
                    aria-label="Search bar"
                    onClick={handleChange}
                    color="inherit"
                >
                    <SearchIcon />
                </IconButton>
            </Fade>
            <div
                className={`${classes.overlay} ${
                    checked ? classes.showOverlay : ""
                }`}
            >
                <div className={classes.inputContainer}>{input}</div>
            </div>
            <div className={classes.desktopOverlay}>{input}</div>
        </>
    );
};

export default SearchBar;
