import makeStyles from "@material-ui/core/styles/makeStyles";
import { StyleProps } from "./types";

const useStyles = makeStyles({
    action: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        backgroundColor: "#fff",
        zIndex: 52,
        borderTop: "1px solid #ded6d6",
    },
    rowBreak: { flexBasis: "100%", height: 7 },
    fullRow: {
        flexBasis: "100%",
        margin: 0,
        "& *": {
            width: "100%",
        },
    },
    paper: ({ height, width }: StyleProps) => {
        const paper: StyleProps = {};
        if (height) {
            paper.height = height;
        }

        if (width) {
            paper.width = width;
        }

        return paper;
    },
    title: {
        paddingBottom: 8,
    },
    button: {
        textTransform: "lowercase",
    },
    containerIframe: {
        height: "auto",
    },
});

export default useStyles;
