import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { HorizontalViewProps as Props } from "../types";
import useStyles from "../styles";
import importedComponent from "react-imported-component";
import { CarouselFullType } from "../../../../skeleton-templates/Category/types";
import { DefaultComponent } from "react-imported-component/dist/es5/types";
import { defaultCategories as eventCategories } from "../../../index";
import SeeMoreButton from "../../../../landing/SeeMoreButton";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router-dom";
import { LocationUrlParams } from "../../../../filters/location/types";

const Carousel = importedComponent<CarouselFullType, CarouselFullType>(
    () =>
        import("@brainhubeu/react-carousel") as unknown as Promise<
            DefaultComponent<CarouselFullType>
        >,
);

const HorizontalView: React.FC<Props> = ({
    title,
    events,
    matchMobile,
    eventCount,
    cardsToShow,
    featured,
    landing,
    component: Component,
}) => {
    const classes = useStyles();
    const { location } = useParams<LocationUrlParams>();

    // Get event category and then if only one filter use the category title for event filters
    const eventCats = eventCategories.find(
        (eventFilter) => eventFilter.title === title,
    );
    let eventFilters: string[] | undefined = eventCats?.filter;
    if (
        eventFilters &&
        eventFilters.length === 1 &&
        eventCats &&
        eventCats.title
    ) {
        eventFilters = [eventCats.title];
    }
    const eventGroup = eventCategories.find(
        (eventFilter) => eventFilter.title === title,
    )?.group;

    const filterEvents = events.filter(
        ({ cancellationType }) => cancellationType !== "cancelled",
    );

    const divider = (
        <Box pt={4} pl={8} pr={8}>
            <Divider className={classes.divider} orientation="horizontal" />
        </Box>
    );

    if (matchMobile) {
        // don't show featured if less than 3 on mobile and featured boolean is true
        if (eventCount < 3 && featured) {
            return null;
        }

        return (
            <div>
                {filterEvents.length > 0 && !eventGroup && !featured && divider}

                <Typography
                    variant="h2"
                    style={{
                        fontSize: "2.8rem",
                        marginTop: "0.5em",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                    }}
                >
                    {title}

                    {!featured && landing && (
                        <SeeMoreButton
                            eventCodes={
                                !eventFilters
                                    ? [events[0].EventCode]
                                    : eventFilters
                            }
                            category={title}
                        />
                    )}
                </Typography>

                <Grid className={classes.featuredRoot} container spacing={2}>
                    {filterEvents.map((result, index) => (
                        <Grid
                            style={
                                matchMobile
                                    ? { minWidth: "45%", width: "45%" }
                                    : { minWidth: "25%", width: "25%" }
                            }
                            item
                            key={index}
                            xs={6}
                            sm={4}
                            md={6}
                        >
                            <Component
                                featured={featured}
                                mobile={matchMobile}
                                {...result}
                            />
                        </Grid>
                    ))}
                </Grid>

                {filterEvents.length > 0 && !eventGroup && featured && divider}
            </div>
        );
    }

    // don't show featured if less than 6 on desktop
    if (eventCount < 6 && featured) {
        return null;
    }

    return (
        <div>
            {filterEvents.length > 0 && !eventGroup && !featured && divider}

            <Typography variant="h2">{title}</Typography>

            <Grid container spacing={3}>
                <Grid item md={12} lg={12} sm={12}>
                    <Carousel
                        centered={true}
                        arrows={true}
                        slidesPerPage={5}
                        slidesPerScroll={1}
                        infinite={true}
                        itemWidth={200}
                        offset={-1}
                    >
                        {filterEvents.map((result, index) => {
                            if (index + 1 > cardsToShow) {
                                return null;
                            }

                            return (
                                <Grid
                                    item
                                    key={index}
                                    md={11}
                                    lg={11}
                                    sm={11}
                                    xs={12}
                                >
                                    <Component
                                        mobile={matchMobile}
                                        featured={featured}
                                        location={location}
                                        {...result}
                                    />
                                </Grid>
                            );
                        })}
                    </Carousel>
                </Grid>
            </Grid>

            {filterEvents.length > 0 && !eventGroup && featured && divider}
        </div>
    );
};

export default HorizontalView;
