import { State } from "../../types";
import { EventType } from "./types";

export const eventTypesIsFetching = (state: State): boolean =>
    state.events.types.fetching;

// export const eventTypesResults = (state: State): EventType[] =>
//     state.events.types.results;

export const eventTypesResults = (state: State): EventType[] =>
    state.events.types.results.map<EventType>(({...props }) => {
        return {
            ...props,
            eventTypeCount: eventsTypeCount(state, String(props.eventcode)),
        };
    });

export const eventsTypeCount = (state: State, eventcode: string): number =>
    state.aggregations.eventcode && state.aggregations.eventcode[eventcode]
        ? Number(state.aggregations.eventcode[eventcode]) : 0;
