import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    inputRoot: {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 5,
        padding: 1,
    },
    input: {
        padding: "8px 12px",
    },
}));

export default useStyles;
