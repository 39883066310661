import { useState } from "react";
import { useSelector } from "react-redux";
import {
    hideCancelledFilter,
    otherFilters,
    ticketsAvailableFilter,
    under18Filter,
} from "../../../../store/filters/selector";
import { State } from "../../../../store/types";
import {
    FilterSelected,
    SelectorState,
    StoreFilterKeysToOptions,
    SelectedToFilterStore,
} from "../types";
import { useToggleOpen } from "@skiddle/web.events.hooks";
import {
    useOnHideCancelledFilterStoreChange,
    useOnMount,
    useOnTicketsAvailableFilterStoreChange,
    useUnder18FilterStoreChange,
} from "./effects";
import { useConfirmOtherFilter, useOnClear } from "./event-handlers";
import { storeFilterKeysToOptions } from "..";
import { NumberParam, useQueryParam } from "use-query-params";
import { EventsSearchParams } from "@skiddle/web.events.shared-types";
import { OtherFilters } from "../../../../store/filters/types";

export const selectedToFilterStoreConvert = (
    selected: string[],
): SelectedToFilterStore => {
    const { ticketsAvailable, hideCancelled, under18 } =
        storeFilterKeysToOptions;

    const storeVals: SelectedToFilterStore = {
        ticketsAvailable: 0,
        hideCancelled: 0,
        under18: null,
    };

    if (selected.includes(ticketsAvailable)) {
        storeVals.ticketsAvailable = 1;
    }

    if (selected.includes(hideCancelled)) {
        storeVals.hideCancelled = 1;
    }

    if (selected.includes(under18)) {
        storeVals.under18 = "true";
    }

    return storeVals;
};

const useCombinedHooks = (defaultValue: string) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useSelectedState(defaultValue);
    const { filterSelected } = useStateSelector();
    const [userChanged, setUserChanged] = useState<boolean>(false);
    const isActive = userChanged && selected.length > 0;

    const toggleOpen = useToggleOpen(setOpen);
    const onClear = useOnClear(setSelected, setOpen, setUserChanged);
    const confirmOtherFilter = useConfirmOtherFilter(
        selected,
        setOpen,
        setUserChanged,
    );
    const buttonDisplay = useButtonDisplay(selected);

    useEffects(selected, setSelected);

    return {
        open,
        selected,
        setSelected,
        toggleOpen,
        onClear,
        confirmOtherFilter,
        buttonDisplay,
        filterSelected,
        isActive,
    };
};

export const parseTicketsOnlyQueryParam = (
    ticketsOnly: number | null | undefined,
): OtherFilters["ticketsAvailable"] => {
    if (ticketsOnly !== 0 && ticketsOnly !== 1) {
        return undefined;
    }

    return ticketsOnly;
};

export const parseHideCancelledQueryParam = (
    hideCancelled: number | null | undefined,
): EventsSearchParams["hidecancelled"] => {
    if (hideCancelled !== 0 && hideCancelled !== 1) {
        return undefined;
    }

    return hideCancelled;
};

export const parseUnder18QueryParam = (
    under18: number | null | undefined,
): OtherFilters["under18"] => {
    let parsedUnder18: EventsSearchParams["under18"];

    if (under18 !== 0 && under18 !== 1) {
        parsedUnder18 = undefined;
    }

    if (under18 === 1) {
        parsedUnder18 = "true";
    }

    if (under18 === 0) {
        parsedUnder18 = "false";
    }

    return parsedUnder18;
};

export const useTicketsOnlyQueryParam = (): [
    OtherFilters["ticketsAvailable"],
    (val: OtherFilters["ticketsAvailable"]) => void,
] => {
    const [ticketsOnly, setTicketsOnly] = useQueryParam(
        "ticketsonly",
        NumberParam,
    );
    const ticketsOnlyParsed = parseTicketsOnlyQueryParam(ticketsOnly);

    const setVal = (val: EventsSearchParams["ticketsavailable"]) => {
        setTicketsOnly(val);
    };

    return [ticketsOnlyParsed, setVal];
};

export const useHideCancelledQueryParam = (): [
    EventsSearchParams["hidecancelled"],
    (val: EventsSearchParams["hidecancelled"]) => void,
] => {
    const [hideCancelled, setHideCancelled] = useQueryParam(
        "hidecancelled",
        NumberParam,
    );
    const hideCancelledParsed = parseHideCancelledQueryParam(hideCancelled);

    const setVal = (val: EventsSearchParams["hidecancelled"]) => {
        setHideCancelled(val);
    };

    return [hideCancelledParsed, setVal];
};

export const useUnder18QueryParam = (): [
    OtherFilters["under18"],
    (val: OtherFilters["under18"]) => void,
] => {
    const [under18, setUnder18] = useQueryParam("under18", NumberParam);
    const under18Parsed = parseUnder18QueryParam(under18);

    const setVal = (val: OtherFilters["under18"]) => {
        let parsedVal: number | undefined;

        if (val === "true") {
            parsedVal = 1;
        }

        if (val === "false") {
            parsedVal = 0;
        }
        setUnder18(parsedVal);
    };

    return [under18Parsed, setVal];
};

export const useStateSelector = () =>
    useSelector<State, SelectorState>((state) => ({
        filterSelected: Object.entries(otherFilters(state.filters)).reduce<
            Array<FilterSelected>
        >((selected, [key, val]) => {
            if (val && val !== 0 && val !== "false") {
                const option =
                    storeFilterKeysToOptions[
                        key as keyof StoreFilterKeysToOptions
                    ];
                return [...selected, option as FilterSelected];
            }

            return selected;
        }, [] as FilterSelected[]),
        hideCancelled: hideCancelledFilter(state.filters),
        ticketsAvailable: ticketsAvailableFilter(state.filters),
        under18: under18Filter(state.filters),
    }));

const useSelectedState = (defaultValue: string) => {
    const { filterSelected } = useStateSelector();
    return useState<string[]>(() => {
        if (filterSelected.length === 0 && defaultValue !== "") {
            return [defaultValue];
        }

        return filterSelected;
    });
};

const useButtonDisplay = (selected: string[]) => () => {
    const selectedLength = selected.length;
    if (selectedLength > 0) {
        return `More Filters (${selectedLength})`;
    }

    return "More Filters";
};

const useEffects = (
    selected: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
) => {
    useOnMount(selected, setSelected);
    useOnTicketsAvailableFilterStoreChange(selected, setSelected);
    useOnHideCancelledFilterStoreChange(selected, setSelected);
    useUnder18FilterStoreChange(selected, setSelected);
};

export default useCombinedHooks;
