import React from "react";
import OrderByFilter from "./orderBy";
import LocationFilter from "@skiddle/web.events.filters.location";
import KeywordFilter from "./keyword";
import EventTypesFilter from "./eventTypes";
import DatesFilter from "./dates";
import GenresFilter from "./genres";
import OtherFilters from "./other";
import Spacer from "../spacer";
import {
    keywordFilter,
    locationFilter,
    maxDateFilter,
    minDateFilter,
    orderByFilter,
    radiusFilter,
    showVirtualFilter,
} from "@skiddle/web.events.store.filters";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { State } from "../../store/types";
import { SelectState } from "./types";
import { getLocation, getLocations } from "../../store/location/actions";
import {
    locationCanSubmitFilter,
    locationFetchController,
    locationResult as locationResultSelector,
    locationResults,
} from "@skiddle/web.events.store.location";
import { seoResult as seoResultSelector } from "../../store/seo/selectors";
import { useGetSeoData } from "../seo/hooks";
import { pathPrefix } from "../../App";
import Bar from "./bar";
import { seoClear } from "../../store/seo/actions";

const Filters: React.FC = () => {
    const dispatch = useDispatch();
    const {
        orderBy,
        filterKeyword,
        filterMinDate,
        filterMaxDate,
        locationGet,
        locationSearch,
        locationController,
        location,
        locationResult,
        locations,
        radius,
        seoResult,
        showVirtual,
        canSubmitLocationFilter,
        compareQueryParamsClientSideState,
    } = useSelector<State, SelectState>(
        (state) => ({
            orderBy: orderByFilter(state.filters),
            filterKeyword: keywordFilter(state.filters),
            filterMinDate: minDateFilter(state.filters),
            filterMaxDate: maxDateFilter(state.filters),
            locationGet: getLocation(state.location, dispatch),
            locationSearch: getLocations(state.location, dispatch),
            locationController: locationFetchController(state.location),
            location: locationFilter(state.filters),
            locationResult: locationResultSelector(state.location),
            locations: locationResults(state.location),
            radius: radiusFilter(state.filters),
            seoResult: seoResultSelector(state),
            showVirtual: showVirtualFilter(state.filters),
            canSubmitLocationFilter: locationCanSubmitFilter(state.location),
            compareQueryParamsClientSideState:
                state.compareQueryParamsClientSide,
        }),
        shallowEqual,
    );

    return (
        <Bar>
            <LocationFilter
                getLocation={locationGet}
                getLocations={locationSearch}
                locationController={locationController}
                locationFilter={location}
                locationResult={locationResult}
                locations={locations}
                radiusFilter={radius}
                seoResult={seoResult}
                showVirtual={showVirtual}
                useGetSeoData={useGetSeoData}
                pathPrefix={pathPrefix}
                seoClear={seoClear}
                canSubmit={canSubmitLocationFilter}
                useUrlParams={compareQueryParamsClientSideState}
            />
            <DatesFilter
                filterMinDate={filterMinDate}
                filterMaxDate={filterMaxDate}
                useUrlParams={compareQueryParamsClientSideState}
            />
            <EventTypesFilter useGetSeoData={useGetSeoData} />
            <GenresFilter />
            <KeywordFilter
                keywordState={filterKeyword}
                useUrlParams={compareQueryParamsClientSideState}
            />
            <OtherFilters />
            <Spacer />
            <OrderByFilter
                orderByState={orderBy}
                useUrlParams={compareQueryParamsClientSideState}
            />
        </Bar>
    );
};

export default Filters;
