import React from "react";
import useStyles from "./styles";
import HomeIcon from "@material-ui/icons/Home";
import Link from "@material-ui/core/Link";
import { Box, Container } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { maxContentWidth } from "@skiddle/web.global.theme";
import { useParams } from "react-router-dom";
import { LocationUrlParams } from "@skiddle/web.events.filters.location";
import ucwords from "ucwords";
import { EventTypeUrlParams } from "@skiddle/web.events.shared-types";
import { buildEventLocationUrl } from "@skiddle/web.events.helpers";
import { Props } from "./types";

const currentORLink = (href: string, breadCrumb: string) => {
    const { pathname } =
        typeof window !== "undefined" ? window.location : { pathname: "" };

    // Check they match with or without "/" at the end
    if (href.match(`${pathname}/?`)) {
        return <span>{breadCrumb}</span>;
    }

    return (
        <Link href={href} underline={"none"} color={"textPrimary"}>
            {breadCrumb}
        </Link>
    );
};

const createEventTypeName = (eventTypes: string[]) =>
    eventTypes.reduce((eventTypesStr, eventType, i) => {
        // Create the events selected string
        if (i === eventTypes.length - 1 && eventTypes.length > 1) {
            eventTypesStr += ` and `;
        } else if (i !== 0) {
            eventTypesStr += `, `;
        }

        eventTypesStr += ucwords(eventType.replace(/events?/i, ""));
        eventTypesStr = eventTypesStr.trimRight();
        if (!/s$/.test(eventTypesStr)) {
            eventTypesStr += "s";
        }
        return eventTypesStr;
    }, "");

const Breadcrumbs: React.FC<Props> = ({ pathPrefix }) => {
    const classes = useStyles();
    const { location = "all", eventType } = useParams<
        LocationUrlParams & EventTypeUrlParams
    >();
    const { pathname } =
        typeof window !== "undefined" ? window.location : { pathname: "" };

    return (
        <Paper elevation={0} square>
            <Container style={{ maxWidth: maxContentWidth, padding: 0 }}>
                <Box
                    p={1}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    id="breadcrumb"
                    className={classes.root}
                >
                    <Link href="/" underline={"none"} color={"textPrimary"}>
                        <HomeIcon className={classes.icon} fontSize={"small"} />
                    </Link>
                    <Typography className={classes.typography}>
                        &nbsp;»&nbsp;
                        {currentORLink(
                            buildEventLocationUrl(
                                "all",
                                pathPrefix,
                                "",
                                "",
                                "",
                                false,
                                undefined,
                                false,
                            ),
                            "Events",
                        )}
                        {!["all", ""].includes(location) && (
                            <>
                                &nbsp;»&nbsp;
                                {currentORLink(
                                    buildEventLocationUrl(
                                        location,
                                        pathPrefix,
                                        "",
                                        "",
                                        "",
                                        false,
                                        undefined,
                                        false,
                                    ),
                                    `${ucwords(location)}`,
                                )}
                            </>
                        )}
                        {eventType && ( // City / shortcut event category events
                            <>
                                &nbsp;»&nbsp;
                                {currentORLink(
                                    buildEventLocationUrl(
                                        location,
                                        pathPrefix,
                                        "",
                                        "",
                                        "",
                                        false,
                                        eventType,
                                        false,
                                    ),
                                    ucwords(eventType),
                                )}
                            </>
                        )}
                        {pathname.includes("/events/") && (
                            <>
                                &nbsp;»&nbsp;
                                <span>Search Results</span>
                            </>
                        )}
                    </Typography>
                </Box>
            </Container>
        </Paper>
    );
};

export default Breadcrumbs;
