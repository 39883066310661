import { useDispatch } from "react-redux";
import {
    getUsersLocation,
    locationIsCity,
    useRadiusQueryParam,
    useShowVirtualEventsQueryParam,
} from ".";
import { useHistory, useParams } from "react-router-dom";
import { buildEventLocationUrl } from "@skiddle/web.events.helpers";
import {
    updateLocation,
    updateRadius,
    updateShowVirtual,
} from "@skiddle/web.events.store.filters";
import { locationClear } from "@skiddle/web.events.store.location";
import { defaultEmptyOptionsMap } from "@skiddle/web.events.data";
import { SelectState, UseGetSeoData } from "../types";
import { SEOClear } from "@skiddle/web.events.store.types";
import { EventTypeUrlParams } from "@skiddle/web.events.shared-types";
import { titleCase } from "title-case";

export const useSearchInputChange =
    (
        setSearchLocation: (location: string) => void,
        setPoiID: (poiID: string) => void,
    ) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearchLocation(value);
        setPoiID("");
    };

export const useLocationSelect =
    (
        setSearchLocation: (location: string) => void,
        setPoiID: (poiID: string) => void,
        setDistance: React.Dispatch<React.SetStateAction<number>>,
        setShowVirtualEvents: React.Dispatch<React.SetStateAction<boolean>>,
        locations: SelectState["locations"],
    ) =>
    (val: string) => {
        if (defaultEmptyOptionsMap.has(val)) {
            const defaultOptionValue = defaultEmptyOptionsMap.get(val) || "";

            if (val === "all") {
                setShowVirtualEvents(false);
            }
            if (val === "online") {
                setShowVirtualEvents(true);
            }

            if (val === "near me") {
                getUsersLocation();
            }

            setSearchLocation(defaultOptionValue);
            return;
        }

        const [id = "", name = ""] = val.split("-");

        setSearchLocation(name);
        setPoiID(id);

        const locationSelected = locations.find(
            ({ id: locID }) => locID === id,
        );

        // If the location is not a city then set the radius to 2 miles to show events closer to location
        if (
            locationSelected &&
            !locationIsCity(locationSelected.name, locationSelected.place)
        ) {
            setDistance(2);
        }
    };

export const useChangeDistance =
    (setDistance: React.Dispatch<React.SetStateAction<number>>) =>
    (e: React.ChangeEvent<{}>, value: number | number[]) =>
        setDistance(Number(value));

export const useConfirmLocation = (
    searchLocation: string,
    distance: number,
    poiID: string,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setButtonText: (val: string) => void,
    showVirtualEvents: boolean,
    locationResult: SelectState["locationResult"],
    locationFilter: SelectState["location"],
    radiusFilter: SelectState["radius"],
    showVirtual: SelectState["showVirtual"],
    useGetSeoData: UseGetSeoData,
    pathPrefix: string,
    seoClear: (() => SEOClear) | undefined,
    useUrlParams: boolean,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const confirmLocationResult = useConfirmLocationResult(
        searchLocation,
        distance,
        poiID,
        showVirtualEvents,
        setButtonText,
        locationResult,
        locationFilter,
        radiusFilter,
        showVirtual,
        useGetSeoData,
        pathPrefix,
        seoClear,
        useUrlParams,
        setUserChanged,
    );

    return () => {
        setOpen(false);
        confirmLocationResult();
    };
};

export const useOnClear = (
    defaultRadius: number,
    setPoiID: (poiID: string) => void,
    setButtonText: (val: string) => void,
    setDistance: React.Dispatch<React.SetStateAction<number>>,
    setSearchLocation: (location: string) => void,
    setShowVirtualEvents: React.Dispatch<React.SetStateAction<boolean>>,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    seoClear: (() => SEOClear) | undefined,
    pathPrefix: string,
    useUrlParams: boolean,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { eventType } = useParams<EventTypeUrlParams>();
    const [, setQueryShowVirtualEvents] = useShowVirtualEventsQueryParam();

    return () => {
        const eventTypeParam = !eventType ? true : false;

        dispatch(locationClear());

        dispatch(updateLocation(0, 0));
        setUserChanged(true);

        dispatch(updateShowVirtual("0"));

        setSearchLocation("all");
        setPoiID("");
        setButtonText("Location");
        setDistance(defaultRadius);
        if (useUrlParams) {
            history.push(
                buildEventLocationUrl(
                    "all",
                    pathPrefix,
                    "",
                    "",
                    "",
                    eventTypeParam,
                    eventType,
                ),
            );
            setQueryShowVirtualEvents(0);
        }
        setShowVirtualEvents(false);

        setOpen(false);
    };
};

const useConfirmLocationResult = (
    searchLocation: string,
    distance: number,
    poiID: string,
    showVirtualEvents: boolean,
    setButtonText: (val: string) => void,
    locationResult: SelectState["locationResult"],
    [lat, long]: SelectState["location"],
    radius: SelectState["radius"],
    showVirtual: SelectState["showVirtual"],
    useGetSeoData: UseGetSeoData,
    pathPrefix: string,
    seoClear: (() => SEOClear) | undefined,
    useUrlParams: boolean,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [, setQueryRadius] = useRadiusQueryParam();
    const getSeoData = useGetSeoData(locationResult);
    const { eventType } = useParams<EventTypeUrlParams>();
    const [, setQueryShowVirtualEvents] = useShowVirtualEventsQueryParam();

    return async () => {
        const eventTypeParam = !eventType ? true : false;
        if (radius !== distance) {
            dispatch(updateRadius(distance));
            if (useUrlParams) {
                setQueryRadius(distance);
            }
        }

        if (Number(showVirtual) !== Number(showVirtualEvents)) {
            if (useUrlParams) {
                setQueryShowVirtualEvents(showVirtualEvents ? 1 : 0);
            }
            dispatch(updateShowVirtual(showVirtualEvents ? "1" : "0"));
        }

        if (
            defaultEmptyOptionsMap.has(searchLocation) ||
            searchLocation === ""
        ) {
            // If location input is blank (empty string) use 'all'
            const defaultOptionValue =
                defaultEmptyOptionsMap.get(searchLocation) ||
                (searchLocation === "" ? "all" : "");

            setButtonText(titleCase(defaultOptionValue));
            if (defaultOptionValue === "all") {
                dispatch(updateLocation(0, 0));
            }

            if (defaultOptionValue === "near me") {
                const latLong = await getUsersLocation();
                dispatch(updateLocation(...latLong));
            }

            dispatch(locationClear());
            setUserChanged(true);

            if (useUrlParams) {
                history.push(
                    buildEventLocationUrl(
                        defaultOptionValue,
                        pathPrefix,
                        "",
                        "",
                        "",
                        eventTypeParam,
                        eventType,
                    ),
                );
            }
            return;
        }

        if (locationResult) {
            const { postcodeLookup, name, type, latitude, longitude } =
                locationResult;

            if (
                (latitude === lat && longitude === long) ||
                (!latitude && !longitude)
            ) {
                return;
            }

            dispatch(updateLocation(latitude, longitude));
            setUserChanged(true);

            if (name === searchLocation) {
                setButtonText(searchLocation);
            }

            if (searchLocation)
                if (postcodeLookup && useUrlParams) {
                    history.push(
                        buildEventLocationUrl(
                            postcodeLookup,
                            pathPrefix,
                            poiID,
                            name,
                            type,
                            eventTypeParam,
                            eventType,
                        ),
                    );
                }
            return;
        }
    };
};

export const useShowVirtualEventsChange =
    (setShowVirtualEvents: React.Dispatch<React.SetStateAction<boolean>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
        setShowVirtualEvents(e.target.checked);
    };
