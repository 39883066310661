import React, { useEffect, useMemo, useState } from "react";
import Category from "./card/Category";
import { Results as ResultsProp } from "./types";
import { Category as CategoryType, SelectedState } from "./types";
import { useSelector, useDispatch } from "react-redux";
import { State } from "../../store/types";
import { useMediaQuery } from "@material-ui/core";

import {
    eventTypesFilter,
    filtersAllFilled,
} from "../../store/filters/selector";
import { eventTypesResults } from "../../store/events/types/selector";
import { eventsSearchResults } from "../../store/events/search/selector";
import deepEqual from "deep-equal";
import {
    eventFilters,
    eventsSearchIsFetchingAll,
} from "../../store/events/search/selector";
import NoResults from "./no-results";
import { clearAggregations } from "../../store/aggregations/actions";
import debug from "../../debug";
import * as CSS from "csstype";
import JumpTo from "./card/JumpTo";
import { Links, Links as JumpToLinks } from "./card/JumpTo/types";
import { Element } from "react-scroll";
import { FetchingCategories } from "../../store/events/search/types";
import { EventsSearchResult } from "@skiddle/web.events.shared-types";
import LandingFilters from "../landing/Filters";
import { useIsMobile } from "@skiddle/web.events.hooks";

const filterLog = debug("filtersChange");
const fetchLog = debug("fetch");

export const defaultCategories: CategoryType[] = [
    {
        title: "Clubs",
        filter: ["CLUB"],
        group: "Clubs, Gigs & Festivals",
        isLandingLink: false,
        initialLimit: 8,
        limit: {
            mobile: 8,
            desktop: 8,
        },
    },
    {
        title: "Gigs",
        filter: ["LIVE"],
        group: "Clubs, Gigs & Festivals",
        isLandingLink: false,
        initialLimit: 8,
        limit: {
            mobile: 8,
            desktop: 8,
        },
    },
    {
        title: "Festivals",
        filter: ["FEST"],
        group: "Clubs, Gigs & Festivals",
        isLandingLink: false,
        initialLimit: 8,
        limit: {
            mobile: 8,
            desktop: 8,
        },
    },
    {
        title: "Comedy, Theatre & Arts",
        filter: ["COMEDY", "THEATRE", "ARTS", "FILM", "DANCE"],
        isLandingLink: false,
        initialLimit: 8,
        limit: {
            mobile: 8,
            desktop: 4,
        },
    },
    {
        title: "Experiences & Attractions",
        filter: ["EXHIB", "EXPER", "DRIVEIN", "KIDS"],
        isLandingLink: false,
        initialLimit: 8,
        limit: {
            mobile: 8,
            desktop: 4,
        },
    },
    {
        title: "Food & Drink",
        filter: ["FOOD", "BARPUB"],
        isLandingLink: false,
        initialLimit: 8,
        limit: {
            mobile: 8,
            desktop: 4,
        },
    },
    {
        title: "Sport",
        filter: ["SPORT"],
        isLandingLink: true,
        initialLimit: 8,
        limit: {
            mobile: 8,
            desktop: 4,
        },
    },
    // {
    //     title: "Educational, Business & Fundraising",
    //     filter: ["EDU", "FUND", "CONF", "NET", "BOOKS"],
    //     limit: 4,
    // },
    {
        title: "Other events",
        filter: [
            "TOUR",
            "DATE",
            "RELIG",
            "AWARD",
            "HEALTH",
            "LGB",
            "EDU",
            "FUND",
            "CONF",
            "NET",
            "BOOKS",
        ],
        isLandingLink: true,
        initialLimit: 8,
        limit: {
            mobile: 8,
            desktop: 4,
        },
    },
    {
        title: "Bars",
        filter: ["BARPUB"],
    },
    {
        title: "Dating",
        filter: ["DATE"],
    },
    {
        title: "Exhibitions",
        filter: ["EXHIB"],
    },
    {
        title: "Things-To-Do",
        filter: [
            "12",
            "10",
            "24",
            "42",
            "14",
            "49",
            "16",
            "22",
            "32",
            "34",
            "44",
        ],
    },
];

const emptyCategoriesCount = (
    emptyCats: string[],
    selectedCategories: CategoryType[],
    isFetching: FetchingCategories,
    eventSearchResults: EventsSearchResult[],
): number => {
    let emptyCatCount = 0;
    emptyCats = [];
    selectedCategories.forEach(({ title, filter }) => {
        if (!isFetching[title] && eventSearchResults) {
            let count = eventSearchResults.filter(({ EventCode }) => {
                return filter?.includes(EventCode);
            }).length;

            if (count === 0) {
                emptyCatCount++;
                emptyCats.push(title);
            }
        }
    });

    return emptyCatCount;
};

let eventFiltersChanged = false;
const selectorEquality = (a: SelectedState, b: SelectedState) => {
    if (a.allFiltersFilled !== b.allFiltersFilled) {
        return false;
    }

    if (!deepEqual(a.selectedCategories, b.selectedCategories)) {
        return false;
    }

    if (!deepEqual(a.allEventFilters, b.allEventFilters)) {
        filterLog("filters have changed");
        eventFiltersChanged = true;
        return false;
    }

    if (!deepEqual(a.isFetching, b.isFetching)) {
        fetchLog("fetching has changed");
        return false;
    }

    return true;
};

let emptyCats: string[] = [];

const Results: React.FC<ResultsProp> = ({ landing = false }) => {
    const {
        allFiltersFilled,
        selectedCategories,
        eventSearchResults,
        allEventFilters,
        isFetching,
    } = useSelector<State, SelectedState>(
        (state) => ({
            eventSearchResults: eventsSearchResults(state),
            isFetching: eventsSearchIsFetchingAll(state),
            allFiltersFilled: filtersAllFilled(state.filters),
            allEventFilters: eventFilters(state),
            selectedCategories: (() => {
                // Create categories from selected event types filter or use default categories
                const selectedEventTypes = eventTypesFilter(state.filters);
                const eventTypes = eventTypesResults(state);

                // If not on a landing page merge event type filters into one category
                if (!landing) {
                    return [
                        eventTypes
                            .filter(({ eventcode }) =>
                                selectedEventTypes?.includes(eventcode),
                            )
                            .reduce<CategoryType>(
                                (category, { eventcode }) => ({
                                    ...category,
                                    filter: [
                                        ...(category.filter || []),
                                        eventcode,
                                    ],
                                }),
                                { title: "", filter: [] } as CategoryType,
                            ),
                    ];
                }

                if (
                    selectedEventTypes &&
                    selectedEventTypes.length > 0 &&
                    landing
                ) {
                    return [
                        {
                            title: "Featured",
                            promote: true,
                            isLandingLink: true,
                            filter: selectedEventTypes,
                        },
                        ...eventTypes
                            .filter(({ eventcode }) =>
                                selectedEventTypes.includes(eventcode),
                            )
                            .reduce<CategoryType[]>(
                                (categories, { eventcode, eventcodedesc }) => {
                                    return [
                                        ...categories,
                                        {
                                            title: eventcodedesc,
                                            filter: [eventcode],
                                        },
                                    ];
                                },
                                [],
                            ),
                    ];
                }

                return defaultCategories;
            })(),
        }),
        selectorEquality,
    );

    const catCount = selectedCategories.length;
    const matchesMobile = useIsMobile();

    const dispatch = useDispatch();
    const [showNoResults, setShowNoResults] = useState<boolean>(() => {
        const emptyCatCount = emptyCategoriesCount(
            emptyCats,
            selectedCategories,
            isFetching,
            eventSearchResults,
        );
        return emptyCatCount === catCount && eventSearchResults.length === 0;
    });
    const [jumpToCats, setJumpToCats] = useState<Links[]>([]);

    useEffect(() => {
        if (eventFiltersChanged) {
            dispatch(clearAggregations());
            eventFiltersChanged = false;
        }
    }, [allEventFilters, dispatch]);

    useEffect(() => {
        const emptyCatCount = emptyCategoriesCount(
            emptyCats,
            selectedCategories,
            isFetching,
            eventSearchResults,
        );
        setShowNoResults(
            emptyCatCount === catCount && eventSearchResults.length === 0,
        );
    }, [
        isFetching,
        selectedCategories,
        eventSearchResults,
        catCount,
        allEventFilters,
        emptyCats,
        landing,
    ]);

    let currentCategoryIds: JumpToLinks[] = [];

    const categories = useMemo(() => {
        let lastGroup: string;

        return selectedCategories.map(
            (
                { title, filter, promote = false, limit, group, isLandingLink },
                i,
            ) => {
                // don't return category component if landing link and not promote
                if (!promote && isLandingLink) {
                    return false;
                }

                if (landing && !group && !promote) {
                    currentCategoryIds.push({
                        id: `category-${i}`,
                        originalTitle: title,
                        title: title,
                    });
                } else if (
                    landing &&
                    group &&
                    lastGroup !== group &&
                    !promote
                ) {
                    lastGroup = group;
                    currentCategoryIds.push({
                        id: `category-${i}`,
                        originalTitle: title,
                        title: group,
                    });
                }

                return (
                    <div
                        className="category-wrapper"
                        style={
                            {
                                display: "initial",
                                contentVisibility: "auto",
                                containIntrinsicSize: "1000px 550px",
                            } as React.CSSProperties
                        }
                        key={title}
                    >
                        <Element name={`category-${i}`}>
                            {/* Category layout cost saving container */}
                            <Category
                                key={title}
                                title={title}
                                filter={filter}
                                promote={promote}
                                initialLoadLimit={
                                    matchesMobile
                                        ? limit?.mobile
                                        : limit?.desktop
                                }
                                landing={landing}
                            />
                        </Element>
                    </div>
                );
            },
        );
    }, [selectedCategories, matchesMobile, currentCategoryIds]);

    useEffect(() => {
        setJumpToCats(
            currentCategoryIds.filter(
                ({ originalTitle }) => !emptyCats.includes(originalTitle),
            ),
        );
    }, []);

    return (
        <>
            {showNoResults && allFiltersFilled && <NoResults />}
            <div>
                {landing && (
                    <>
                        <LandingFilters />
                        <JumpTo titles={jumpToCats} />
                    </>
                )}
                {categories}
            </div>
        </>
    );
};

export default Results;
