import { Theme } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { filterColor, boxShadowFilter } from "@skiddle/web.global.theme";
import { StyleProps } from "./types";

export const animationTime = 0.4;

const useStyles = makeStyles((theme: Theme) => ({
    root: ({ isMobile }: StyleProps) => {
        const genericStyles = {
            backgroundColor: theme.palette.primary.main,
            boxShadow: boxShadowFilter,
            ...(!isMobile
                ? {
                      marginTop: -7,
                  }
                : {
                      marginTop: -5,
                  }),
        };
        if (isMobile) {
            return {
                ...genericStyles,
                borderBottom: `1px solid ${filterColor}`,
                margin: "-5px -1px 0px -1px",
                // marginLeft: -1,
                // padding: "10px 10px 5px 5px !important"
            };
        }

        return genericStyles;
    },
    filters: ({ isMobile }: StyleProps) => {
        const sharedStyles = {
            display: "flex",
            background: theme.palette.primary.main,
            padding: 5,
            paddingLeft: isMobile ? 5 : 10,
            paddingRight: 10,
            fontSize: "1.1rem",
            borderRadius: "0 !important",
            backgroundColor: theme.palette.primary.main,
        };

        if (!isMobile) {
            return {
                ...sharedStyles,
                marginTop: -7,
                flexWrap: "nowrap",
                overflowX: "auto",
                overflowY: "hidden",
                whiteSpace: "nowrap",
            };
        }

        return {
            ...sharedStyles,
            flexWrap: "nowrap",
            overflowX: "auto",
            overflowY: "hidden",
            whiteSpace: "nowrap",

            /* browser decide if x is ok to scroll and show bars on, y hidden */
            overflow: "auto hidden",
            /* prevent scroll chaining on x scroll */
            overscrollBehaviorX: "contain",
            /* scrolling should snap children on x */
            scrollSnapType: "x mandatory",
            "@media (hover: none)": {
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                    width: 0,
                    height: 0,
                },
            },
        };
    },
    title: {
        textTransform: "none",
        // marginLeft: "-4px !important",
        paddingLeft: 15,
        paddingTop: 5,
        fontWeight: 600,
        zIndex: 51,
    },
    fadeIn: {
        opacity: 1,
        transition: `opacity ${animationTime}s`,
    },
    fadeOut: {
        opacity: 0,
        transition: `opacity ${animationTime}s`,
    },
}));

export default useStyles;
