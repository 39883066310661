import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
    updateEventTypes,
    updateGenres,
    updateKeyword,
    updateLocation,
    updateMinMaxDates,
    updateRadius,
} from "../../../store/filters/actions";
import { useDispatch, useSelector } from "react-redux";
import Link from "@material-ui/core/Link";
import { FetchingCategories } from "../../../store/events/search/types";
import { defaultValue as eventValue } from "../../filters/eventTypes";
import { defaultValue as keywordValue } from "../../filters/keyword";
import { StateSelector } from "./types";
import { defaultValues as defaultDateValues } from "../../filters/dates";
import { defaultValue as defaultGenreValues } from "../../filters/genres";
import { State } from "../../../store/types";
import {
    anyEventsSearchIsFetching,
    eventsSearchIsFetchingAll,
    eventsSearchResults,
} from "../../../store/events/search/selector";
import {
    eventTypesFilter,
    keywordFilter,
    lastUpdatedFilter,
    radiusFilter,
} from "../../../store/filters/selector";
import { LastUpdatedFilterState } from "../../../store/filters/types";
import { useHistory, useParams } from "react-router-dom";
import { LocationUrlParams } from "../../filters/location/types";
import { buildEventLocationUrl } from "@skiddle/web.events.helpers";
import { pathPrefix } from "../../../App";
import {
    EventCodes,
    EventsSearchResult,
    EventTypeUrlParams,
} from "@skiddle/web.events.shared-types";
import { locationResult as locationResultSelector } from "@skiddle/web.events.store.location";

export const defaultLocationValue = "";

const parseSearchState = (
    radius: number,
    keyword: string,
    eventTypes: EventCodes[] | string,
    lastUpdatedFilter: LastUpdatedFilterState,
    anyEventCategoriesFetching: boolean,
) => {
    if (
        lastUpdatedFilter === "location" &&
        radius &&
        radius < 50 &&
        !anyEventCategoriesFetching
    ) {
        return "AREA_EXPAND";
    }

    if (
        (keyword && keyword.length > 1) ||
        (eventTypes && eventTypes?.length > 1)
    ) {
        return "TYPES_KEYWORD";
    }

    return "";
};

const updateSearchParams = (
    searchState: string | undefined,
    allEvents: EventsSearchResult[],
    anyEventCategoriesFetching: boolean,
    setNextRadius: React.Dispatch<React.SetStateAction<number>>,
    searchWiderArea: (radius: number) => void,
    description: string,
    linkText: string,
    setDescription: React.Dispatch<React.SetStateAction<string>>,
    setLinkText: React.Dispatch<React.SetStateAction<string>>,
    setLinkFunction: React.Dispatch<React.SetStateAction<() => void>>,
    clearKeywordsAndEvents: () => void,
    emptyFilter: () => void,
    allFetch: FetchingCategories,
    nextRadius: number,
    radius: number,
    onMount: boolean = false,
) => {
    switch (searchState) {
        case "AREA_EXPAND":
            // automatically search wider area
            if (allEvents.length === 0 && !anyEventCategoriesFetching) {
                if (nextRadius === radius && nextRadius !== 0) {
                    setNextRadius(0);
                    break;
                }

                searchWiderArea((radius % 20 === 0 ? radius : 0) + 20);
            }
            break;

        case "TYPES_KEYWORD":
            const dataToSet = {
                description: "Would you like to find events by clearing",
                linkText: "keywords and event types?",
            };

            if (
                dataToSet.description !== description &&
                dataToSet.linkText !== linkText
            ) {
                setDescription(dataToSet.description);
                setLinkText(dataToSet.linkText);
                setLinkFunction(() => clearKeywordsAndEvents);
            }
            break;

        default: {
            const dataToSet = {
                description: "Maybe try clearing",
                linkText: "all filters",
            };

            if (
                dataToSet.description !== description &&
                dataToSet.linkText !== linkText
            ) {
                setDescription(dataToSet.description);
                setLinkText(dataToSet.linkText);
                setLinkFunction(() => emptyFilter);
            }
            break;
        }
    }
};

const NoResults: React.FC = () => {
    const dispatch = useDispatch();
    const {
        allEvents,
        anyEventCategoriesFetching,
        allFetch,
        radius = 0,
        keyword = "",
        eventTypes = "",
        filterLastUpdated,
        locationResult,
    } = useSelector<State, StateSelector>((state) => ({
        allEvents: eventsSearchResults(state),
        anyEventCategoriesFetching: anyEventsSearchIsFetching(state),
        allFetch: eventsSearchIsFetchingAll(state),
        radius: radiusFilter(state.filters),
        keyword: keywordFilter(state.filters),
        eventTypes: eventTypesFilter(state.filters),
        filterLastUpdated: lastUpdatedFilter(state, "lastUpdateFilter"),
        locationResult: locationResultSelector(state.location),
    }));
    const [searchState, setSearchState] = useState(
        parseSearchState(
            radius,
            keyword,
            eventTypes,
            filterLastUpdated,
            anyEventCategoriesFetching,
        ),
    );
    const [description, setDescription] = useState("");
    const [linkText, setLinkText] = useState("");
    const [linkFunction, setLinkFunction] = useState<() => void>(
        () => () => {},
    );
    const [nextRadius, setNextRadius] = useState(0);
    const [searching, setSearching] = useState(false);
    const { eventType, location } = useParams<
        EventTypeUrlParams & LocationUrlParams
    >();
    const history = useHistory();

    const searchWiderArea = (radius: number) => {
        if (!searching) {
            //
            dispatch(updateRadius(radius));
            setNextRadius(radius);

            // Sort of debouncing, by setting searching, prevent updating of the radius again too soon.
            // Not how I'd like to solve this but can't track down why it's doubling up the calls
            setSearching(true);
            setTimeout(() => {
                setSearching(false);
            }, 500);
        }
    };
    const clearKeywordsAndEvents = () => {
        if (eventType) {
            const {
                id = "",
                postcodeLookup = "",
                name = "",
                type = "",
            } = locationResult || {};

            history.push(
                buildEventLocationUrl(
                    postcodeLookup || location,
                    pathPrefix,
                    id,
                    name,
                    type,
                    true,
                ),
            );
        }
        dispatch(updateEventTypes(eventValue as EventCodes[]));
        dispatch(updateKeyword(keywordValue));
    };
    const emptyFilter = () => {
        dispatch(updateMinMaxDates(...defaultDateValues));
        dispatch(updateGenres(defaultGenreValues as number[]));
        dispatch(updateLocation(0, 0));
        dispatch(updateEventTypes([]));
        dispatch(updateKeyword(keywordValue));
    };

    useEffect(() => {
        setSearchState(
            parseSearchState(
                radius,
                keyword,
                eventTypes,
                filterLastUpdated,
                anyEventCategoriesFetching,
            ),
        );
    }, [radius, keyword, eventTypes]);

    useEffect(() => {
        updateSearchParams(
            searchState,
            allEvents,
            anyEventCategoriesFetching,
            setNextRadius,
            searchWiderArea,
            description,
            linkText,
            setDescription,
            setLinkText,
            setLinkFunction,
            clearKeywordsAndEvents,
            emptyFilter,
            allFetch,
            nextRadius,
            radius,
            true,
        );
    }, []);

    useEffect(() => {
        updateSearchParams(
            searchState,
            allEvents,
            anyEventCategoriesFetching,
            setNextRadius,
            searchWiderArea,
            description,
            linkText,
            setDescription,
            setLinkText,
            setLinkFunction,
            clearKeywordsAndEvents,
            emptyFilter,
            allFetch,
            nextRadius,
            radius,
        );
    }, [searchState, allEvents, anyEventCategoriesFetching]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ minHeight: 200 }}
        >
            <Grid item xs={12} md={8} lg={8} xl={8}>
                <Typography variant={"h5"} align={"center"}>
                    No events found matching your search selection.
                </Typography>
                <Typography variant={"h4"} align={"center"}>
                    {description}
                    &nbsp;
                    <Link
                        style={{ verticalAlign: "unset" }}
                        component="button"
                        variant={"h4"}
                        onClick={() => linkFunction()}
                    >
                        <strong>{linkText}</strong>
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default NoResults;
