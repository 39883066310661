import { useState } from "react";
import { useQueryParam } from "use-query-params";
import { useToggleOpen } from "@skiddle/web.events.hooks";
import { useOnKeywordFilterStoreChange, useOnMount } from "./effects";
import { SelectorState } from "../types";
import {
    useClearKeyword,
    useConfirmKeyword,
    useSearchChange,
} from "./event-handlers";

const useCombinedHooks = (
    defaultValue: string,
    keywordState: SelectorState["filterKeyword"],
    useUrlParams: boolean,
) => {
    const [open, setOpen] = useState(false);
    const [keyword, setKeyword] = useKeyword(defaultValue, keywordState);
    const [userChanged, setUserChanged] = useState<boolean>(false);
    const isActive = userChanged && keywordState !== "";

    const toggleOpen = useToggleOpen(setOpen);
    const searchChange = useSearchChange(setKeyword);
    const confirmKeyword = useConfirmKeyword(
        keyword,
        setOpen,
        setUserChanged,
        useUrlParams,
    );
    const clearKeyword = useClearKeyword(
        defaultValue,
        setKeyword,
        setUserChanged,
    );

    useEffects(defaultValue, keyword, setKeyword, keywordState, useUrlParams);

    return {
        open,
        keyword,
        toggleOpen,
        searchChange,
        confirmKeyword,
        clearKeyword,
        isActive,
    };
};

export const useKeywordQueryParam = () => useQueryParam<string>("keyword");

const useKeyword = (
    defaultValue: string,
    filterKeyword: SelectorState["filterKeyword"],
) => {
    const [keywordQueryParam] = useKeywordQueryParam();
    return useState(keywordQueryParam || filterKeyword || defaultValue);
};

const useEffects = (
    defaultValue: string,
    keyword: string,
    setKeyword: React.Dispatch<React.SetStateAction<string>>,
    keywordState: SelectorState["filterKeyword"],
    useUrlParams: boolean,
) => {
    useOnMount(defaultValue, keywordState, useUrlParams);
    useOnKeywordFilterStoreChange(
        keyword,
        setKeyword,
        keywordState,
        useUrlParams,
    );
};

export default useCombinedHooks;
