import { fade, makeStyles } from "@material-ui/core/styles";
import { navBarBackground } from "@skiddle/web.global.theme";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
        },
    },
    search: {
        zIndex: 11,
        overflow: "hidden",
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: 0,
        marginLeft: 0,
        width: "0px",
        // desktop
        [theme.breakpoints.up("sm")]: {
            webkitTransition: "width 0.4s ease-in-out",
            transition: "width 0.4s ease-in-out",
            marginLeft: 0,
            width: "0px",
        },
    },
    showSearch: {
        marginTop: "0.5rem",
        overflow: "visible",
        width: "200px",
        // mobile
        "@media (max-width: 980px)": {
            width: "100%",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        width: "100%",
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        overflow: "hidden",
    },
    overlay: {
        webkitTransition: "top 0.4s ease-in-out",
        transition: "top 0.4s ease-in-out",
        width: "100%",
        background: navBarBackground,
        height: "100%",
        position: "absolute",
        top: "-100%",
        left: 0,
        right: 0,
        zIndex: 51,
        "@media (min-width: 980px)": {
            display: "none",
        },
    },
    desktopOverlay: {
        "@media (max-width: 980px)": {
            display: "none",
        },
    },
    inputContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    showOverlay: {
        top: "0%",
    },
}));

export default useStyles;
