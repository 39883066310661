import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const useIsMobile = () => {
    const theme = useTheme();
    const ssr = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: false });
    const noSsr = useMediaQuery(theme.breakpoints.down("xs"), { noSsr: true });
    return ssr || noSsr;
};

export const useToggleOpen =
    (setOpen: React.Dispatch<React.SetStateAction<boolean>>) => () => {
        setOpen((open) => !open);
    };
