import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
    containerStyle: {
        position: "absolute",
        left: "10px",
        top: "10px",
        zIndex: 2,
        display: "flex",
        alignContent: "center",
        alignItems: "center",
    },
    iconStyle: {
        fill: '#00000045',
        stroke: '#fffffff5',
        strokeWidth: '2px',
    },
    iconStyleActive: {
        fill: '#f50057f7',
        stroke: '#fffffff5',
        strokeWidth: '2px',
    },
    iconLarge: {
        width: '30px',
        height: '30px',
        borderRadius: '50% 50% 0 50%',
        border: '2px solid #fffffff5',
        overflow: 'hidden'
    },
    iconMedium: {
        width: '25px',
        height: '25px',
        borderRadius: '50% 50% 0 50%',
        border: '2px solid #fffffff5',
        overflow: 'hidden'
    },
    iconSmall: {
        width: '20px',
        height: '20px',
        borderRadius: '50% 50% 0 50%',
        border: '2px solid #fffffff5',
        overflow: 'hidden'
    },
    artistName: {
        paddingLeft: '3px',
        fontSize: '0.7rem',
        fontStyle: 'italic',
        fontWeight: 'bold',
        textShadow: '1px 1px 1px #000000d9'
    }
}));

export default useStyles;
