import { Reducer } from "redux";
import { HeaderState } from "./type";

export const initialState = {
    nav: [],
};

export const reducer: Reducer<HeaderState> = (state = initialState, action) =>
    state;

export default reducer;
