import React from "react";
import { OrderByViewProps as Props } from "./types";
import SearchButton from "@skiddle/web.events.filters.button";
import FilterDialog from "@skiddle/web.events.filters.dialog";
import SelectList from "@skiddle/web.events.filters.select";
import SortIcon from "@material-ui/icons/Sort";

const OrderByView: React.FC<Props> = ({
    open,
    toggleOpen,
    selectOption,
    orderOptions,
    setSelected,
    selected,
    display,
    isActive,
}) => (
    <>
        <FilterDialog
            title="order by"
            open={open}
            toggleOpen={toggleOpen}
            onConfirm={selectOption}
        >
            <SelectList
                options={orderOptions}
                onSelect={(el) => typeof el === "string" && setSelected(el)}
                selected={selected}
            />
        </FilterDialog>
        <SearchButton
            onClick={toggleOpen}
            title={`Order By ${display}`}
            isActive={isActive}
            icon={<SortIcon />}
        />
    </>
);

export default OrderByView;
