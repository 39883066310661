import { useDispatch } from "react-redux";
import { updateOther } from "../../../../store/filters/actions";
import {
    selectedToFilterStoreConvert,
    useHideCancelledQueryParam,
    useTicketsOnlyQueryParam,
    useUnder18QueryParam,
} from ".";

export const useOnClear = (
    setSelected: (selected: string[]) => void,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const dispatch = useDispatch();
    const [, setTicketsOnly] = useTicketsOnlyQueryParam();
    const [, setHideCancelled] = useHideCancelledQueryParam();
    const [, setUnder18] = useUnder18QueryParam();

    return () => {
        setSelected([]);
        setTicketsOnly(0);
        setHideCancelled(0);
        setUnder18(null);
        setUserChanged(true);

        dispatch(updateOther(0, 0, null));
        setOpen(false);
    };
};

export const useConfirmOtherFilter = (
    selected: string[],
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const dispatch = useDispatch();
    const [, setTicketsOnly] = useTicketsOnlyQueryParam();
    const [, setHideCancelled] = useHideCancelledQueryParam();
    const [, setUnder18] = useUnder18QueryParam();

    return () => {
        const { ticketsAvailable, hideCancelled, under18 } =
            selectedToFilterStoreConvert(selected);

        setTicketsOnly(ticketsAvailable);
        setHideCancelled(hideCancelled);
        setUnder18(under18);
        setUserChanged(true);

        dispatch(updateOther(ticketsAvailable, hideCancelled, under18));
        setOpen(false);
    };
};
