import React from "react";
import { Box } from "@material-ui/core";
import useStyles from "./styles";

const BarDivider: React.FC = () => {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.root} />
        </>
    );
};

export default BarDivider;
