import React, { useEffect } from "react";
import { Links, Props } from "./types";
import { Box, Link as MuiLink } from "@material-ui/core";
import useStyles from "./styles";
import Button from "@material-ui/core/Button";
import { Link } from "react-scroll";
import { pathPrefix } from "../../../../App";
import { useParams } from "react-router-dom";
import { LocationUrlParams } from "../../../filters/location/types";
import { shallowEqual, useSelector } from "react-redux";
import { State } from "../../../../store/types";
import { StateSelector } from "./types";
import { categoryEventsAllResults } from "../../../../store/Categories/selector";
import { getSsrCategory } from "../../../../store/Categories/utils";
import { useIsMobile } from "@skiddle/web.events.hooks";

const JumpButton: React.FC<{
    title: string;
    classes: ReturnType<typeof useStyles>;
    href?: string;
}> = ({ title, classes, href }) => (
    <Button
        variant="outlined"
        // className={classes.button}
        classes={{ root: classes.buttonRoot }}
        disableElevation={false}
    >
        <a href={href || `#${title}`}>{title}</a>
    </Button>
);

const JumpTo: React.FC<Props> = ({ titles }) => {
    const classes = useStyles();
    const { location } = useParams<LocationUrlParams>();
    const matchesMobile = useIsMobile();
    const { categoryEvents } = useSelector<State, StateSelector>(
        (state) => ({
            categoryEvents: categoryEventsAllResults(state),
        }),
        shallowEqual,
    );

    // append all events
    useEffect(() => {
        const allExists = titles.find(({ title }) => title === "All Events");
        if (titles?.length > 0 && !allExists) {
            titles.push({
                id: "",
                title: "All Events",
                originalTitle: "All Events",
                href: `${pathPrefix}/events/${
                    location !== "" && location ? location : "all"
                }/`,
            });
        }
    }, [titles]);

    if (titles?.length < 1 && categoryEvents) {
        return null;
    }

    const removeEmptyCateogries = () => {
        if (categoryEvents !== undefined) {
            const validCategories: Links[] = [];
            titles.forEach((category) => {
                // Skip checks on the all option
                if (category.title === "All Events") {
                    validCategories.push(category);
                    return;
                }
                const keyName = `${getSsrCategory(category.originalTitle)}`;
                // If there are events keep the category.
                if (categoryEvents[keyName].length !== 0) {
                    validCategories.push(category);
                }
            });
            return validCategories;
        }
        return [];
    };

    const categoriesWithEvents = removeEmptyCateogries();
    return (
        <Box pt={4} mr={"-12px"}>
            <div className={classes.title}>Jump to:</div>
            <Box
                className={classes.container}
                sx={{ justifyContent: matchesMobile ? "flex-start" : "center" }}
            >
                <Box
                    style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        padding: 5,
                    }}
                >
                    {categoriesWithEvents.map(({ id, title, href }, key) =>
                        !href ? (
                            <Link
                                key={id}
                                to={id}
                                spy={true}
                                smooth={true}
                                duration={500}
                                href={`#${title}`}
                            >
                                <JumpButton title={title} classes={classes} />
                            </Link>
                        ) : (
                            <MuiLink
                                key={id}
                                href={`${pathPrefix}${href}`}
                                underline="none"
                            >
                                <JumpButton
                                    title={title}
                                    classes={classes}
                                    href={href}
                                />
                            </MuiLink>
                        ),
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default JumpTo;
