import React, { useEffect } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import RouteIndex from "./routeIndex";
import debug from "./debug";
import "./css/skiddle-used.css";
import "./css/App.css";
import "./css/brainhub-carousel.css";
import Footer from "./components/template/Footer";
import "@gouch/to-title-case";
import Landing from "./Landing";
import { NumberParam, useQueryParam } from "use-query-params";
import { isLoggedIn } from "@skiddle/web.events.helpers";
import { getUserProfile } from "./store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { GetUserProfile, UserProfile } from "./store/user/types";
import { State } from "./store/types";
import { userProfileResult } from "./store/user/selector";
import { SEOUrlContext } from "./contexts";

const routeLog = debug("routes");

// eslint-disable-next-line no-restricted-globals
const ROUTE_PREFIX = process.env.REACT_APP_EVENTS_ROUTE_PREFIX || "";
export const pathPrefix = ROUTE_PREFIX;

routeLog({ ROUTE_PREFIX, pathPrefix });

const App = () => {
    const { pathname, search } = useLocation();
    const [fromHome, setFromHome] = useQueryParam("home", NumberParam);
    const dispatch = useDispatch();
    const fetchUserProfile = useSelector<State, ReturnType<GetUserProfile>>(
        (state) => getUserProfile(state, dispatch),
    );
    const userData = useSelector<State, UserProfile | undefined>((state) =>
        userProfileResult(state),
    );

    useEffect(() => {
        if (isLoggedIn() && !userData) {
            fetchUserProfile();
        }
    }, []);

    // Mainly used for webpack dev server
    // landing on the landing page from home (home param 1) then redirect to events page
    if (fromHome && fromHome === 1 && !pathname.includes("events")) {
        const pathWithoutPrefix = pathname.replace(`${pathPrefix}/`, "");

        setFromHome(null);
        return (
            <Redirect
                to={{
                    pathname: `${pathPrefix}/events/${pathWithoutPrefix}`,
                    search,
                }}
            />
        );
    }

    return (
        <>
            {/* Template */}
            <Switch>
                {/* Events page routes */}

                {/* With prefix routes */}
                <Route
                    path={`${pathPrefix}/events/`}
                    component={RouteIndex}
                    exact
                />
                <Route
                    path={`${pathPrefix}/:location/whats-on-in-:location-today`}
                    render={({
                        match: {
                            params: { location },
                        },
                    }) => (
                        <SEOUrlContext.Provider
                            value={`whats-on-in-${location}-today/`}
                        >
                            <RouteIndex />
                        </SEOUrlContext.Provider>
                    )}
                    exact
                />
                <Route
                    path={`${pathPrefix}/:location/bottomless-brunch`}
                    render={() => (
                        <SEOUrlContext.Provider value={`bottomless-brunch/`}>
                            <RouteIndex />
                        </SEOUrlContext.Provider>
                    )}
                    exact
                />
                <Route
                    path={`${pathPrefix}/:location/whats-on-in-:location-this-weekend`}
                    render={({
                        match: {
                            params: { location },
                        },
                    }) => (
                        <SEOUrlContext.Provider
                            value={`whats-on-in-${location}-this-weekend/`}
                        >
                            <RouteIndex />
                        </SEOUrlContext.Provider>
                    )}
                    exact
                />
                <Route
                    path={`${pathPrefix}/:location/oktoberfest`}
                    render={() => (
                        <SEOUrlContext.Provider value="oktoberfest/">
                            <RouteIndex />
                        </SEOUrlContext.Provider>
                    )}
                    exact
                />
                <Route
                    path={`${pathPrefix}/:location/world-cup-screenings`}
                    render={() => (
                        <SEOUrlContext.Provider value="world-cup-screenings/">
                            <RouteIndex />
                        </SEOUrlContext.Provider>
                    )}
                    exact
                />
                <Route
                    path={`${pathPrefix}/:location/drag-shows`}
                    render={() => (
                        <SEOUrlContext.Provider value="drag-shows/">
                            <RouteIndex />
                        </SEOUrlContext.Provider>
                    )}
                    exact
                />
                <Route
                    path={`${pathPrefix}/:location/family-days-out`}
                    render={() => (
                        <SEOUrlContext.Provider value="family-days-out/">
                            <RouteIndex />
                        </SEOUrlContext.Provider>
                    )}
                    exact
                />
                <Route
                    path={`${pathPrefix}/events/:location`}
                    component={RouteIndex}
                    exact
                />
                <Route
                    path={`${pathPrefix}/events/:location/near/:poiName/:poiID`}
                    component={RouteIndex}
                    exact
                />

                {/* Landing page routes */}
                <Route path={`${pathPrefix}/`} component={Landing} exact />
                <Route
                    path={`${pathPrefix}/:location`}
                    component={Landing}
                    exact
                />
                <Route
                    path={`${pathPrefix}/:eventType/:location`}
                    component={RouteIndex}
                    exact
                />
                <Route
                    path={`${pathPrefix}/:eventType/:location/near/:poiName/:poiID`}
                    component={RouteIndex}
                    exact
                />

                {/* Origin routes */}
                <Route path={`${pathPrefix}/`} component={RouteIndex} exact />
                <Route
                    path={`${pathPrefix}/:location`}
                    component={RouteIndex}
                    exact
                />
                <Route
                    path={`${pathPrefix}/:location/near/:poiName/:poiID`}
                    component={RouteIndex}
                    exact
                />

                {/* Without prefix routes */}
                <Route
                    path={`/:eventType/:location`}
                    component={RouteIndex}
                    exact
                />

                {/* Festival city routes */}
                <Route
                    path={`/:eventType/:location/near/:poiName/:poiID`}
                    component={RouteIndex}
                    exact
                />
                <Route
                    path={`/:eventType/cities/:location`}
                    component={RouteIndex}
                    exact
                />
                <Route
                    path={`/:eventType/cities/:location/near/:poiName/:poiID`}
                    component={RouteIndex}
                    exact
                />
            </Switch>

            <Footer />
        </>
    );
};
export default App;
