import {
    LocationFetch,
    LocationFetchSuccess,
    Location,
    GetLocations,
    LocationActions,
    LocationFetchController,
    LocationFetchAbort,
    LocationsFetchSuccess,
    GetLocation,
    LocationClear,
    LocationState,
} from "./types";
import { Dispatch } from "redux";
import { locationIsFetching } from "./selectors";
import "isomorphic-fetch";
import { apiError, APIV3Response } from "@skiddle/web.events.store.types";
import { CanSubmitLocationFilter } from "@skiddle/web.events.store.filters";

const locationFetch = (): LocationFetch => ({
    type: "LOCATION_FETCH",
});

const locationsFetchSuccess = (results: Location[]): LocationsFetchSuccess => ({
    type: "LOCATIONS_FETCH_SUCCESS",
    results,
});

const locationFetchSuccess = (result: Location): LocationFetchSuccess => ({
    type: "LOCATION_FETCH_SUCCESS",
    result,
});

const locationFetchController = (
    controller: AbortController,
): LocationFetchController => ({
    type: "LOCATION_FETCH_CONTROLLER",
    controller,
});

const locationFetchAbort = (): LocationFetchAbort => ({
    type: "LOCATION_FETCH_ABORT",
});

export const locationClear = (): LocationClear => ({
    type: "LOCATION_CLEAR",
});

export const canSubmitLocationFilter = (
    value: boolean,
): CanSubmitLocationFilter => ({
    type: "CAN_SUBMIT_LOCATION_FILTER",
    value,
});

export const getLocations: GetLocations =
    (state: LocationState, dispatch: Dispatch<LocationActions>) =>
    async (term: string, limit: number) => {
        if (locationIsFetching(state)) {
            return;
        }

        dispatch(locationFetch());

        try {
            const controller = new AbortController();
            dispatch(locationFetchController(controller));

            const results = await fetchLocations(controller, term, limit);

            if (results) {
                dispatch(locationsFetchSuccess(results));
            }
        } catch (err) {
            console.log(err);
            if ((err as Error).name === "AbortError") {
                dispatch(locationFetchAbort());
                return;
            }

            dispatch(apiError(err as string));
        }
    };

export const fetchLocations = async (
    controller: AbortController,
    term: string,
    limit: number,
) => {
    try {
        const apiURL = process.env.REACT_APP_V3_API_URL;

        const searchParams = new URLSearchParams(
            Object.entries({
                term,
                limit,
            }) as string[][],
        );
        const resp = await fetch(`${apiURL}/location?${searchParams}`, {
            method: "GET",
            mode: "cors",
            signal: controller.signal,
        });

        const {
            data,
            status,
            title,
            detail,
            request_id,
        }: APIV3Response<Location[]> = await resp.json();
        if (status && title && detail) {
            throw new Error(
                `${status}: ${title}: ${detail}.\n requestID: ${request_id}`,
            );
        }

        return data;
    } catch (err) {
        throw err;
    }
};

export const getLocation: GetLocation =
    (state: LocationState, dispatch: Dispatch<LocationActions>) =>
    async (poiID: string) => {
        if (locationIsFetching(state)) {
            return;
        }

        dispatch(locationFetch());

        try {
            const controller = new AbortController();
            dispatch(locationFetchController(controller));

            const results = await fetchLocation(controller, poiID);

            if (!results || results.length > 1) {
                throw new Error("Expected one result");
            }

            dispatch(locationFetchSuccess(results[0]));
        } catch (err) {
            console.log(err);
            if ((err as Error).name === "AbortError") {
                dispatch(locationFetchAbort());
                return;
            }

            dispatch(apiError(err as string));
        }
    };

export const fetchLocation = async (
    controller: AbortController,
    poiID: string,
) => {
    try {
        const apiUrl = process.env.REACT_APP_V3_API_URL;

        const resp = await fetch(`${apiUrl}/location/${poiID}`, {
            method: "GET",
            mode: "cors",
            signal: controller.signal,
        });

        const {
            data,
            status,
            title,
            detail,
            request_id,
        }: APIV3Response<Location[]> = await resp.json();
        if (status && title && detail) {
            throw new Error(
                `${status}: ${title}: ${detail}.\n requestID: ${request_id}`,
            );
        }

        return data;
    } catch (err) {
        throw err;
    }
};
