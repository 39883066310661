import React from "react";
import { Box, Button, Grid, Divider as MuiDivider } from "@material-ui/core";
import { Props } from "./types";
import useStyles from "./styles";

const ShowMoreButton: React.FC<Props> = ({ title, onClick }) => {
    const classes = useStyles();

    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ paddingTop: 0, marginTop: 10 }}
        >
            <Box component="div" paddingTop={1} paddingBottom={1}>
                <Button
                    variant="contained"
                    className={classes.showMore}
                    fullWidth={true}
                    disableElevation={false}
                    onClick={onClick}
                >
                    {`Show more ${title.toLowerCase()}`}
                </Button>
            </Box>
            <MuiDivider orientation="horizontal" />
        </Grid>
    );
};

export default ShowMoreButton;
