import { Reducer } from "redux";
import { UserActions, UserState } from "./types";

export const initialState: UserState = {
    fetching: false,
    result: undefined,
};

const reducer: Reducer<UserState, UserActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "USER_PROFILE_FETCH":
            return {
                ...state,
                fetching: true,
            };

        case "USER_PROFILE_FETCH_SUCCESS":
            return {
                ...state,
                fetching: false,
                result: action.result,
            };

        case "API_ERROR":
            return {
                ...state,
                fetching: false,
            };

        default:
            return state;
    }
};

export default reducer;
