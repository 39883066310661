import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import SearchTitle from "../../../seo/SearchTitle";
import { onlineTitleText } from "../../../seo/PageTitle";
import { useSelector } from "react-redux";
import { State } from "../../../../store/types";
import { SeoDataSelectState } from "../../../seo/types";
import { seoResult } from "../../../../store/seo/selectors";
import { SEO } from "@skiddle/web.events.store.types";
import { locationResult } from "@skiddle/web.events.store.location";
import { SelectorState } from "./types";

const locationsWithoutBackground = ["", "location", "online", "all"];

const defaultLandingImages = {
    all: "https://d1plawd8huk6hh.cloudfront.net/landing-pages/backgrounds/live.jpg",
    online: "https://d1plawd8huk6hh.cloudfront.net/landing-pages/backgrounds/virtual.jpg",
};

const defaultStyling = (landingImage: string = defaultLandingImages.all) => {
    if (
        landingImage ===
        "https://d1plawd8huk6hh.cloudfront.net/landing-pages/meta/live.jpg"
    ) {
        landingImage = defaultLandingImages.all;
    }

    return {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),  url(${landingImage})`,
    };
};

const Title = () => {
    const {
        seo: { landingImage = defaultLandingImages.all },
    } = useSelector<State, SeoDataSelectState>((state) => ({
        seo: seoResult(state) || ({} as SEO),
    }));

    const locationResultValue = useSelector<State, SelectorState>((state) => ({
        locationResult: locationResult(state.location),
    }));

    const locationText = locationResultValue.locationResult
        ? locationResultValue.locationResult.name
        : "";

    const [style, setStyle] = useState(defaultStyling(landingImage));

    const classes = useStyles();
    const title = SearchTitle();

    useEffect(() => {
        const lowerCaseLocationText = locationText.toLowerCase();

        if (!locationsWithoutBackground.includes(lowerCaseLocationText)) {
            setStyle(defaultStyling(landingImage));
        }

        if (locationsWithoutBackground.includes(lowerCaseLocationText)) {
            setStyle(
                defaultStyling(
                    lowerCaseLocationText === "online"
                        ? defaultLandingImages.online
                        : defaultLandingImages.all,
                ),
            );
        }
    }, [locationText, landingImage]);

    return (
        <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            id="topcont"
            className={classes.root}
            style={{
                ...style,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "50% 66%",
            }}
        >
            <h1 className={classes.title}>
                {locationText.toLowerCase() === "online"
                    ? onlineTitleText
                    : title}
            </h1>
        </Box>
    );
};

export default Title;
