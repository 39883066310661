import { useState } from "react";
import { useQueryParam } from "use-query-params";
import { getKeyByValue } from "@skiddle/web.events.helpers";
import { useToggleOpen } from "@skiddle/web.events.hooks";
import { Order } from "@skiddle/web.events.shared-types";
import { useOnMount, useOnOrderByFilterStoreChange } from "./effects";
import { OrderOptions, SelectState } from "../types";
import { useSelectOption } from "./event-handlers";

// Combine all the hooks and return a set of functions / hooks to use in the main component
const useCombinedHooks = (
    orderOptions: OrderOptions,
    defaultValue: Order,
    orderByState: SelectState["orderBy"],
    useUrlParams: boolean,
) => {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useSelected(orderOptions, orderByState);
    const [userChanged, setUserChanged] = useState<boolean>(false);
    const isActive = userChanged;

    const toggleOpen = useToggleOpen(setOpen);
    const selectOption = useSelectOption(
        orderOptions,
        selected,
        toggleOpen,
        orderByState,
        useUrlParams,
        setUserChanged,
    );
    const orderByDisplay = useOrderByDisplay(
        orderOptions,
        defaultValue,
        orderByState,
    );

    useEffects(
        defaultValue,
        selected,
        setSelected,
        orderOptions,
        orderByState,
        useUrlParams,
    );

    return {
        open: [open, setOpen] as [
            boolean,
            React.Dispatch<React.SetStateAction<boolean>>,
        ],
        selected: [selected, setSelected] as [
            string,
            React.Dispatch<React.SetStateAction<string>>,
        ],
        toggleOpen,
        selectOption,
        orderByDisplay,
        isActive,
    };
};

export const useSortQueryParam = () => useQueryParam<Order>("sort");

const useSelected = (
    options: OrderOptions,
    orderBy: SelectState["orderBy"] = "0",
) => {
    const orderOptionKeys: string[] = Object.keys(options);

    return useState<string>(() => {
        const value = getKeyByValue<OrderOptions>(options, orderBy) as string;
        if (value) {
            return value;
        }

        return orderOptionKeys[2];
    });
};

const useEffects = (
    defaultValue: Order,
    selected: string,
    setSelected: React.Dispatch<React.SetStateAction<string>>,
    orderOptions: OrderOptions,
    orderBy: SelectState["orderBy"],
    useUrlParams: boolean,
) => {
    useOnMount(defaultValue, orderBy, useUrlParams);
    useOnOrderByFilterStoreChange(
        selected,
        setSelected,
        orderOptions,
        orderBy,
        useUrlParams,
    );
};

// Set up order by display with a hook
const useOrderByDisplay =
    (
        orderOptions: OrderOptions,
        defaultValue: Order,
        orderBy: SelectState["orderBy"],
    ) =>
    () =>
        getKeyByValue<OrderOptions>(orderOptions, orderBy || defaultValue);

export default useCombinedHooks;
