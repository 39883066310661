import randomHexColor from "random-hex-color";

const nodeEnv = process.env.NODE_ENV;

// For ssr as window nor location exist in node
const getLocation = () => {
    if (typeof window !== "undefined") {
        return window.location;
    }
    return null;
};
const location = getLocation();

// eslint-disable-next-line no-restricted-globals
const queryParams = new URLSearchParams(location?.search || "");
const debugName = process.env.REACT_APP_DEBUG; // can be used in production
const debugParam = nodeEnv === "development" ? queryParams.get("debug") : null; // can't be used in production

export default (namespace: string) => {
    const colour = randomHexColor();

    if (
        debugName !== namespace &&
        debugParam !== namespace &&
        debugName !== "*" &&
        debugParam !== "*"
    ) {
        return (...params: any[]) => {};
    }

    return (...params: any[]) => {
        console.log(`%c${namespace}`, `color: ${colour}`, ...params);
    };
};
