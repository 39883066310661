export const getFetchingCategory = (categoryName: string) => {
    switch (categoryName) {
        case "CLUB":
            return "clubsFetching";

        case "LIVE":
            return "gigsFetching";
        case "FEST":
            return "festivalFetching";
        case "COMEDY,THEATRE,ARTS,FILM,DANCE":
            return "comedyFetching";
        case "EXHIB,EXPER,DRIVEIN,KIDS":
            return "experienceFetching";
        case "FOOD,BARPUB":
            return "foodFetching";

        default:
            return "clubsFetching";
    }
};

export const getStoredCategory = (categoryName: string) => {
    switch (categoryName) {
        case "CLUB":
            return "clubsEvents";

        case "LIVE":
            return "gigsEvents";
        case "FEST":
            return "festivalEvents";
        case "COMEDY,THEATRE,ARTS,FILM,DANCE":
            return "comedyEvents";
        case "EXHIB,EXPER,DRIVEIN,KIDS":
            return "experienceEvents";
        case "FOOD,BARPUB":
            return "foodEvents";

        default:
            return "clubsEvents";
    }
};

export const getSsrCategory = (categoryName: string) => {
    switch (categoryName) {
        case "Clubs":
            return "clubsEvents";

        case "Gigs":
            return "gigsEvents";

        case "Festivals":
            return "festivalEvents";

        case "Comedy, Theatre & Arts":
            return "comedyEvents";

        case "Experiences & Attractions":
            return "experienceEvents";

        case "Food & Drink":
            return "foodEvents";
    }

    return categoryName;
};

export const getStoredCategoryHasFetched = (categoryName: string) => {
    switch (categoryName) {
        case "CLUB":
            return "clubsHasFetched";

        case "LIVE":
            return "gigsHasFetched";
        case "FEST":
            return "festivalHasFetched";
        case "COMEDY,THEATRE,ARTS,FILM,DANCE":
            return "comedyHasFetched";
        case "EXHIB,EXPER,DRIVEIN,KIDS":
            return "experienceHasFetched";
        case "FOOD,BARPUB":
            return "foodHasFetched";

        default:
            return "clubsHasFetched";
    }
};

export const getSsrCategoryHasFetched = (categoryName: string) => {
    switch (categoryName) {
        case "Clubs":
            return "clubsHasFetched";

        case "Gigs":
            return "gigsHasFetched";

        case "Festivals":
            return "festivalHasFetched";

        case "Comedy, Theatre & Arts":
            return "comedyHasFetched";

        case "Experiences & Attractions":
            return "experienceHasFetched";

        case "Food & Drink":
            return "foodHasFetched";
    }

    return categoryName;
};
