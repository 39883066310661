// import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import React from "react";
import useStyles from "./styles";
import {Props} from "./types"
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import skeletonTheme from "../theme";

const SkeletonFavourite: React.FC<Props> = ({size}) => {
    const classes = useStyles();
    let fontSize;

    switch (size) {
        case 'small' || 'sm':
            fontSize = '1.4em';
            break;
        case 'medium' || 'md':
            fontSize = '1.6em';
            break;
        case 'large' || 'lg':
            fontSize = '1.8em';
            break;
        default:
            fontSize = '1.4em';
    }

    return (
        <div className={classes.containerStyle}>
            <SkeletonTheme {...skeletonTheme}>
                <Skeleton width={30} height={30} circle={true}/>
            </SkeletonTheme>
        </div>
    )
};

export default SkeletonFavourite;

