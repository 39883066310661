import { Menu, MenuItem, Typography } from "@material-ui/core";
import { FC } from "react";
import { useSelector } from "react-redux";
import { State } from "../../../../store/types";
import { userProfileResult } from "../../../../store/user/selector";
import { UserProfile } from "../../../../store/user/types";
import { Props } from "./types";

const ProfileMenu: FC<Props> = ({ open, onClose, anchorEl }) => {
    const userData = useSelector<State, UserProfile | undefined>((state) =>
        userProfileResult(state),
    );

    if (!open) return null;

    return (
        open && (
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                style={{ top: 50 }}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <a href={`/members/${userData?.username}/`}>
                    <MenuItem>
                        <Typography
                            style={{
                                fontSize: 20,
                                fontWeight: 600,
                                textTransform: "lowercase",
                            }}
                        >
                            Welcome {userData?.forename || userData?.username}
                        </Typography>
                    </MenuItem>
                </a>
                <a href={`/members/${userData?.username}/`}>
                    <MenuItem>
                        <Typography>View Profile</Typography>
                    </MenuItem>
                </a>
                <a href="/members/editprofile/">
                    <MenuItem>
                        <Typography>Edit Profile</Typography>
                    </MenuItem>
                </a>
                <a href="/orders">
                    <MenuItem>
                        <Typography>Order History / Your Tickets</Typography>
                    </MenuItem>
                </a>
                <a href="/members/editprivacy/">
                    <MenuItem>
                        <Typography>Notification Settings</Typography>
                    </MenuItem>
                </a>
                <a href="/register/logout.php">
                    <MenuItem>
                        <Typography>Logout</Typography>
                    </MenuItem>
                </a>
                <a href="/skiddlepeople/editfacebook.php">
                    <MenuItem>
                        <Typography>Edit Facebook settings</Typography>
                    </MenuItem>
                </a>
            </Menu>
        )
    );
};

export default ProfileMenu;
