// eslint-disable-next-line
import { createContext, Dispatch, SetStateAction } from "react";

export const LocationContext = createContext<
    [string, Dispatch<SetStateAction<string>>] | undefined
>(undefined);

export const CityMetaLeftOverText = createContext<
    [string, Dispatch<SetStateAction<string>>] | undefined
>(undefined);

export const SEOUrlContext = createContext<string | undefined>(undefined);
