import React from "react";
import CardMui from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import skeletonTheme from "../theme";
import SkeletonStatus from "../Status";
import classNames from "classnames";
import { Props } from "./types";
import { useOtherMobileSharedStyles } from "@skiddle/web.events.styles";

const FeaturedCard: React.FC<Props> = ({ mobile }) => {
    const classes = useOtherMobileSharedStyles();

    return (
        <SkeletonTheme {...skeletonTheme}>
            <CardMui
                className={classes.root}
                style={mobile ? {} : { marginTop: 5, marginBottom: 5 }}
            >
                <CardContent
                    className={classes.content}
                    style={{ position: "relative" }}
                >
                    <SkeletonStatus />

                    <div>
                        <CardMedia
                            style={{
                                minHeight: "100px",
                                height: 0,
                                background: "#eaeaea",
                            }}
                            className={classes.media}
                            image={"none"}
                        />
                    </div>

                    <Box
                        p={"12px"}
                        className={classes.contentBox}
                        style={{ minHeight: 66 }}
                    >
                        <div style={{ minHeight: "55px", maxHeight: "55px" }}>
                            <Box
                                className={classNames(
                                    classes.contentSections,
                                    classes.contentSectionDate,
                                )}
                                color="inherit"
                            >
                                <Typography
                                    className={classNames(
                                        classes.contentSections,
                                        classes.contentSectionDate,
                                    )}
                                    variant="body2"
                                    color="inherit"
                                    // style={{ float: "left", marginTop: 2 }}
                                >
                                    <span className={classes.detailsListSmall}>
                                        <Skeleton
                                            width={50}
                                            height={10}
                                            count={1}
                                        />
                                    </span>
                                </Typography>
                            </Box>

                            <Typography
                                className={classes.cardTitle}
                                variant="body1"
                                component="h3"
                            >
                                <Skeleton width={100} height={15} count={1} />
                            </Typography>
                        </div>
                    </Box>
                </CardContent>
            </CardMui>
        </SkeletonTheme>
    );
};

export default FeaturedCard;
