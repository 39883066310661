import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    root: {
        // height: 416,
        // boxShadow: "0 1px 7px 0 rgba(19,19,19,.5)",
        boxShadow: 'none',
    },
    media: {
        height: 0,
        // paddingTop: "56.25%", // 16:9
        paddingTop: "70%", // 16:9
    },
    content: {
        color: "#FFFFFF",
        paddingTop: "0px !important",
        padding: 0,
        paddingBottom: "0px !important",
        // padding: 8,
    },
    contentBox: {
        marginBottom: '10px',
        position: "absolute",
        bottom: 0,
        paddingLeft: '12px',
        paddingRight: '12px',
        width: '100%'
    },
    contentSections: {
        display: "table",
        color: "#9a9a9a !important",
        margin: ".66667em !important",
        marginBottom: "0px !important",
        marginTop: "1px !important",
        marginLeft: "0px !important",
    },
    detailsList: {
        fontSize: "0.775rem",
        display: "table-cell",
        verticalAlign: "middle",
        paddingRight: ".23333em",
        color: "#FFFFFF"
    },
    detailsListBlue: {
        fontSize: "0.700rem",
        display: "table-cell",
        verticalAlign: "middle",
        paddingRight: ".23333em",
        color: "#3CB8B0",
        textTransform: 'none',
        maxWidth: '250px',
    },
    smallIcon: {
        fontSize: "0.900rem"
    },
    skiddleFontColor: {
        color: "#3CB8B0",
    },
    infoContainer: {
        height: 120,
    },
    cardTitle: {
        // height: "2.66667em",
        color: "#FFFFFF !important",
        overflow: "hidden",
        fontWeight: 600,
        fontSize: "1.15rem",
        lineHeight: "inherit",
        fontStyle: 'italic',
        textTransform: 'none',
        marginBottom: '5px'
    },
    buttonContainer: {
        borderTop: "1px solid #131313",
        marginTop: 16,
        paddingTop: 10,
    },
    button: {
        color: "#fff",
        background: "#38312e",
        width: "100%",
    },
}));

export default useStyles;
