import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
    containerStyle: {
        position: "absolute",
        right: "10px",
        top: "10px",
        zIndex: 2
    },
    iconStyle: {
        fill: '#00000045',
        stroke: '#fffffff5',
        strokeWidth: '2px',
    },
    iconStyleActive: {
        fill: '#f50057f7',
        stroke: '#fffffff5',
        strokeWidth: '2px',
    },
    iconBig: {
        fontSize: '1.8em',
    },
    iconMedium: {
        fontSize: '1.6em',
    },
    iconSmall: {
        fontSize: '1.4em',
    }
}));

export default useStyles;
