import { State } from "../types";
import { CategoryEvent, CategoryEventsState } from "./types";
import { getStoredCategory, getStoredCategoryHasFetched } from "./utils";
export const categoryEventsIsFetching = (
    state: State,
    fetchingCategory: string,
): boolean => (state.categoryEvents as any)[fetchingCategory];

export const categoryEventsResult = (
    state: State,
    eventsCategory: string,
): CategoryEvent[] | undefined =>
    state.categoryEvents[getStoredCategory(eventsCategory)];

export const categoryEventsAllResults = (
    state: State,
): CategoryEventsState | undefined => state.categoryEvents;

export const categoryEventsHasFetched = (
    state: State,
    eventsCategory: string,
): boolean => state.categoryEvents[getStoredCategoryHasFetched(eventsCategory)];
