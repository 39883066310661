import { Reducer } from "redux";
import { AggregationState, AggregationActions } from "./types";
import {
    EventCodeAggregation,
    GenreAggregation,
} from "@skiddle/web.events.shared-types";
import { copyObject } from "@skiddle/web.events.helpers";

export const initialState: AggregationState = {
    eventcode: {},
    genreids: {},
};

export const sumEventCodes = (
    existingEventCodes: EventCodeAggregation,
    newEventCodes: EventCodeAggregation,
) =>
    Object.entries(newEventCodes).reduce((existing, [newKey, newValue]) => {
        if (!newValue) {
            return existing;
        }

        if (
            existing &&
            existing.hasOwnProperty(newKey) &&
            typeof existing[newKey] === "number"
        ) {
            (existing[newKey] as number) += newValue;
            return existing;
        }

        existing[newKey] = newValue;
        return existing;
    }, copyObject(existingEventCodes));

export const sumGenreIds = (
    existingGenreIds: GenreAggregation,
    newGenreIds: GenreAggregation,
) =>
    Object.keys(newGenreIds).reduce((existing, newKey) => {
        const newNumberKey = Number(newKey);
        const newValue = newGenreIds[newNumberKey];
        if (!newValue) {
            return existing;
        }

        if (
            existing &&
            existing.hasOwnProperty(newNumberKey) &&
            typeof existing[newNumberKey] === "number"
        ) {
            (existing[newNumberKey] as number) += newValue;
            return existing;
        }

        existing[newNumberKey] = newValue;
        return existing;
    }, copyObject(existingGenreIds));

const reducer: Reducer<AggregationState, AggregationActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "UPDATE_AGGREGATIONS":
            const { eventcode = {}, genreids = {} } = action.value;
            return {
                eventcode: sumEventCodes(state.eventcode || {}, eventcode),
                genreids: sumGenreIds(state.genreids || {}, genreids),
            };

        case "CLEAR_AGGREGATIONS":
            return initialState;

        default:
            return state;
    }
};

export default reducer;
