import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";
import CentralContainer from "../components/central-container";
import LandingPage from "../components/LandingPage";
import MetaLinks from "../components/seo/MetaLinks";
import PageTitle from "../components/seo/PageTitle";
import { LandingUrlParams } from "./types";
import Header from "../components/template/Header";
import { useDispatch } from "react-redux";
import { useStateSelector as useEventTypeStateSelector } from "../components/filters/eventTypes/hooks";
import { updateEventTypes } from "../store/filters/actions";
import QuickLinks from "../components/seo/QuickLinks";
import BarDivider from "../components/template/Header/BarDivider";
import CityMetaBottom from "../components/landing/CityMeta/bottom";
import { useSetDefaultFilterValues } from "../utils/hooks";
import { StringParam, useQueryParam } from "use-query-params";
import CanonicalLink from "../components/seo/CanonicalLink";

const Landing: React.FC = () => {
    const dispatch = useDispatch();
    const [sort] = useQueryParam("sort", StringParam);

    const { location, eventType } = useParams<LandingUrlParams>();
    const setDefaultFilterValues = useSetDefaultFilterValues(location, sort);
    const { fetchEventTypes, filterEventTypes, eventTypes } =
        useEventTypeStateSelector();

    // Initialize filter default values
    useEffect(() => {
        setDefaultFilterValues();
        if (eventTypes.length === 0) {
            fetchEventTypes();
        }
    }, []);

    useEffect(() => {
        if (!eventType && filterEventTypes && filterEventTypes.length > 0) {
            dispatch(updateEventTypes([]));
        }
    }, [eventType]);

    return (
        <>
            <CanonicalLink />
            <Header />
            <MetaLinks />
            <PageTitle />

            <BarDivider />

            <CentralContainer>
                <Grid container spacing={0}>
                    <Grid
                        item
                        xl={12}
                        xs={12}
                        style={{ paddingTop: "1em", overflow: "visible" }}
                    >
                        <LandingPage />
                    </Grid>
                </Grid>
            </CentralContainer>

            {/* SEO */}
            <CentralContainer>
                <Grid
                    container
                    spacing={2}
                    justifyContent={"center"}
                    style={{
                        padding: "25px 10px",
                    }}
                >
                    <CityMetaBottom />
                    <QuickLinks landing={true} />
                </Grid>
            </CentralContainer>
        </>
    );
};

export default Landing;
