import React from "react";
import useStyles from "./styles";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { State } from "../../../store/types";
import { SeoDataSelectState } from "../../seo/types";
import { seoResult } from "../../../store/seo/selectors";
import { useLocation } from "react-router-dom";
import { stripHtml } from "@skiddle/web.events.helpers";
import { SEO } from "@skiddle/web.events.store.types";

export const formatText = (
    text: string,
    canonicalLink: string,
    maxLength: number,
): [string, string | undefined] => {
    // if text length large on stripped html then split on <p> tag
    if (stripHtml(text).length > maxLength) {
        // tried regex but won't work, even simple regex e.g. testing.split(/(<p>[^>]+<\/p>)/gi, 4) -> see
        // https://stackoverflow.com/questions/1732348/regex-match-open-tags-except-xhtml-self-contained-tags
        const tmp = text.split("</p>");
        let totalLength = 0;

        const filteredText = tmp
            .filter((stripped: string) => {
                totalLength += stripHtml(stripped).length;
                return totalLength < maxLength;
            })
            .map((filtered: string) => filtered + "</p>")
            .join("");

        const leftOverText = text.replace(filteredText, "");

        return [
            filteredText.replace(/{canonicalLink}/g, canonicalLink),
            leftOverText.replace(/{canonicalLink}/g, canonicalLink),
        ];
    }

    return [text.replace(/{canonicalLink}/g, canonicalLink), undefined];
};

const CityMeta: React.FC = () => {
    const classes = useStyles();
    const { pathname } = useLocation();
    const {
        seo: {
            text = "Search for Events, Clubs, gig guide, Club Nights, Theatre and more. Buy your tickets or get on the guestlist for free",
        },
    } = useSelector<State, SeoDataSelectState>((state) => ({
        seo: seoResult(state) || ({} as SEO),
    }));

    const [formattedText, leftOverText] = formatText(text, pathname, 450);

    console.log({ formattedText });

    return (
        <div className={classes.root}>
            <Box
                className={classes.metaBox}
                p={2}
                dangerouslySetInnerHTML={{ __html: formattedText }}
            ></Box>
        </div>
    );
};

export default CityMeta;
