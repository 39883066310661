import { makeStyles } from "@material-ui/core/styles";
import { white } from "../../../theme";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        clipPath: "inset(-1px -20px -30px -20px)",
        filter: "drop-shadow(0px 1px 6px rgb(19 19 19 / 50%))",
        marginTop: "-4px",
    },
    metaBox: {
        fontSize: "1.05rem",
        clipPath: "polygon(0 0, 100% 0, 100% 92%, 0 100%)",
        background: theme.palette.primary.main,
        color: white,
        fontWeight: 600,
        width: "100%",
        zIndex: 2,
        "& p a, strong, strong > a": {
            color: white,
            textDecoration: "underline",
        },
        "@media (min-width: 600px)": {
            fontSize: "inherit",
        },
    },

    angledBoxMobile: {
        zIndex: 3,
        background: theme.palette.primary.main,
        transform: "rotate(-2deg)",
        position: "relative",
        top: "-10px",
        width: "101%",
        left: "-1%",
        boxShadow: "0 6px 7px -5px rgb(19 19 19 / 50%)", // has to be custom shadow, cannot reuse boxShadow
    },
    angledBoxDesktop: {
        zIndex: 3,
        background: theme.palette.primary.main,
        transform: "rotate(-1deg)",
        position: "relative",
        top: "-10px",
        width: "100%",
        boxShadow: "0 6px 7px -5px rgb(19 19 19 / 50%)", // has to be custom shadow, cannot reuse boxShadow
    },
    h2: {
        fontSize: "1.5rem",
    },
    h2mobile: {
        fontSize: "1.25rem",
    },
    cityMetaH2: {
        fontSize: "1.25rem",
        "@media (min-width: 600px)": {
            fontSize: "1.5rem",
        },
    },
    metaBottomBox: {
        padding: 16,
        fontSize: "inherit",
        background: "none",
        fontWeight: 600,
        width: "100%",
        // boxShadow,
        "& p a, strong, strong > a": {
            color: "#000",
            textDecoration: "underline",
        },
        "@media (min-width: 600px)": {
            fontSize: "1.05rem",
            width: "100%",
            zIndex: 2,
            "& p a, strong, strong > a": {
                color: "#000",
                textDecoration: "underline",
            },
        },
    },
}));

export default useStyles;
