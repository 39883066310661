import { Moment } from "moment";
import { useDispatch } from "react-redux";
import {
    updateMinMaxDates,
    UpdateMinMaxDatesAction,
} from "@skiddle/web.events.store.filters";
import { mysqlDateFormats } from "../";
import { useFromDateQueryParam, useToDateQueryParam } from "./";
import { FocusedInputShape } from "react-dates";
import { getMomentWithTimezone } from "@skiddle/web.events.date-format";

const moment = getMomentWithTimezone();

export const useDatesChange =
    (
        fromDate: Moment | null,
        setFromDate: React.Dispatch<React.SetStateAction<Moment | null>>,
        toDate: Moment | null,
        setToDate: React.Dispatch<React.SetStateAction<Moment | null>>,
        focusInput: FocusedInputShape | null,
        setFocusInput: React.Dispatch<
            React.SetStateAction<FocusedInputShape | null>
        >,
    ) =>
    ({
        startDate,
        endDate,
    }: {
        startDate: Moment | null;
        endDate: Moment | null;
    }) => {
        setFromDate(startDate);
        setToDate(endDate);

        // Replace the on focus event handler as it seems to be faulty firing twice for some reason
        if (fromDate === null && startDate !== null) {
            return setFocusInput("endDate");
        }

        if (toDate === null && endDate !== null) {
            return setFocusInput("startDate");
        }

        if (startDate?.unix() !== fromDate?.unix()) {
            return setFocusInput("endDate");
        }

        if (endDate?.unix() !== toDate?.unix()) {
            return setFocusInput("startDate");
        }
    };

export const useShowAllDates = (
    setFromDate: React.Dispatch<React.SetStateAction<Moment | null>>,
    setToDate: React.Dispatch<React.SetStateAction<Moment | null>>,
    setFocusInput: React.Dispatch<
        React.SetStateAction<FocusedInputShape | null>
    >,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    minDate: Parameters<UpdateMinMaxDatesAction>[0],
    maxDate: Parameters<UpdateMinMaxDatesAction>[1],
    useUrlParams: boolean,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const dispatch = useDispatch();
    const [, setFromDateParam] = useFromDateQueryParam();
    const [, setToDateParam] = useToDateQueryParam();

    return () => {
        setFromDate(moment(minDate));
        setToDate(null);
        setFocusInput("startDate");

        // Set to default dates
        dispatch(updateMinMaxDates(minDate, ""));
        setUserChanged(true);

        if (useUrlParams) {
            setFromDateParam(minDate);
            setToDateParam("");
        }

        setOpen(false);
    };
};

export const useConfirmDates = (
    fromDate: Moment | null,
    toDate: Moment | null,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setFromDate: React.Dispatch<React.SetStateAction<Moment | null>>,
    setToDate: React.Dispatch<React.SetStateAction<Moment | null>>,
    useUrlParams: boolean,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const dispatch = useDispatch();
    const [, setFromDateParam] = useFromDateQueryParam();
    const [, setToDateParam] = useToDateQueryParam();

    return () => {
        if (fromDate && !toDate) {
            const formattedFromDate = fromDate.format(mysqlDateFormats.from);
            if (useUrlParams) {
                setFromDateParam(formattedFromDate);
            }
            dispatch(updateMinMaxDates(formattedFromDate, ""));
            setUserChanged(true);
        }

        if (fromDate && toDate) {
            const formattedFromDate = fromDate.format(mysqlDateFormats.from);
            const formattedToDate = toDate.format(mysqlDateFormats.to);

            if (useUrlParams) {
                setFromDateParam(formattedFromDate);
                setToDateParam(formattedToDate);
            }
            dispatch(updateMinMaxDates(formattedFromDate, formattedToDate));
            setUserChanged(true);
        }
        setOpen(false);
    };
};
