import { makeStyles, Theme } from "@material-ui/core/styles";
import { black } from "@skiddle/web.global.theme";

export const otherDetailsList = {
    display: "table-cell",
    verticalAlign: "middle",
    paddingRight: ".23333em",
    color: black,
    fontWeight: 400,
    marginBottom: 5,
};

export const icon = {
    marginRight: 5,
    verticalAlign: "middle",
};

export const useMobileCardSharedStyles = makeStyles((theme: Theme) => ({
    detailsList: {
        fontSize: "0.8rem",
        display: "table-cell",
        verticalAlign: "middle",
        paddingRight: ".23333em",
        color: theme.palette.primary.contrastText,
    },
    smallIcon: {
        fontSize: "0.900rem",
    },
}));

export const useOtherMobileSharedStyles = makeStyles({
    root: {
        // height: 416,
        boxShadow: "0 1px 7px 0 rgba(19,19,19,.5)",
        // boxShadow: "none",
    },
    mediaDesktop: {
        height: "15vw", // default for desktop
        maxHeight: "175px",
        display: "flex",
    },
    mediaImage: {
        objectFit: "cover",
        objectPosition: "50% 22%",
        width: "100%",
    },
    content: {
        color: black,
        paddingTop: "0px !important",
        padding: 0,
        paddingBottom: "0px !important",
        // padding: 8,
    },
    contentBox: {
        // paddingTop: "12px",
        // paddingBottom: "12px",
        // paddingLeft: "12px",
        // paddingRight: "12px",
        bottom: 0,
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    detailsListSmall: {
        fontSize: "0.95rem",
        textTransform: "capitalize",
        ...otherDetailsList,
    },
    cardTitle: {
        // height: "2.66667em",
        color: `${black} !important`,
        overflow: "hidden",
        fontWeight: 600,
        fontSize: "1rem",
        fontStyle: "italic",
        textTransform: "none",
    },
    icon: {
        fontSize: "1.15rem",
        ...icon,
    },
    iconFeatured: {
        fontSize: "1.05rem",
        ...icon,
    },
    featuredDetailsList: {
        verticalAlign: "middle",
        marginLeft: -3,
    },
    media: {
        height: "45vw", // default for mobile
        display: "flex",
        "@media (min-width: 600px)": {
            height: "unset",
        },
    },
    contentSections: {
        display: "table",
        color: "#9a9a9a !important",
        margin: ".66667em !important",
        marginBottom: "0px !important",
        marginTop: "0px !important",
        marginLeft: "-4px !important",
    },
    contentSectionDate: {
        marginRight: "1px !important",
    },
    detailsListSmallFeatured: {
        fontSize: "0.800rem",
        textTransform: "capitalize",
        ...otherDetailsList,
    },
});
