import { useDispatch } from "react-redux";
import { useGenresQueryParam, useStateSelector } from ".";
import { updateGenres } from "../../../../store/filters/actions";
import { Genre } from "../../../../store/genres/types";

export const parseSelectedGenreIDs = (
    genres: Genre[],
    selectedGenres: string[],
) =>
    genres
        .filter(({ genrename }) =>
            selectedGenres.includes(genrename.toLowerCase()),
        )
        .map(({ genreid }) => Number(genreid));

export const useConfirmGenres = (
    selectedGenres: string[],
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const dispatch = useDispatch();
    const { genres } = useStateSelector();
    const [, setGenresParams] = useGenresQueryParam();

    return () => {
        const parsedGenres = selectedGenres.map((genre) =>
            genre.replace(/ \(([\d]+)\)/, "").toLowerCase(),
        );

        const parsedGenreIDs = parseSelectedGenreIDs(genres, parsedGenres);
        dispatch(updateGenres(parsedGenreIDs));
        setUserChanged(true);
        setGenresParams(parsedGenreIDs);
        setOpen(false);
    };
};

export const useClearGenres = (
    defaultValue: string[],
    setSelectedGenres: React.Dispatch<React.SetStateAction<string[]>>,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const dispatch = useDispatch();
    const [, setGenresParams] = useGenresQueryParam();

    return () => {
        setSelectedGenres(defaultValue);
        setGenresParams(undefined);
        dispatch(updateGenres([]));
        setUserChanged(true);
        setOpen(false);
    };
};
