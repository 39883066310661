import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    searchEvents,
    searchEventsAppend,
} from "../../../../../store/events/search/actions";
import {
    categoryEventsSearchResults,
    categoryEventTotalCount,
    categoryFetchControllers,
    eventsSearchIsFetching,
} from "../../../../../store/events/search/selector";
import {
    allFilters,
    filtersAllFilled,
} from "../../../../../store/filters/selector";
import { State } from "../../../../../store/types";
import { SelectedState } from "../types";
import deepEqual from "deep-equal";
import {
    useOnEventsChange,
    useOnFiltersChange,
    useOnIsFetchingChange,
    useOnMount,
    useSearchCategoryEvents,
} from "./effects";
import { initialCardsToShow } from "..";
import { EventCodes } from "@skiddle/web.events.shared-types";

const selectorEquality = (a: SelectedState, b: SelectedState) => {
    if (a.isFetching !== b.isFetching) {
        return false;
    }

    if (a.fetchController !== b.fetchController) {
        return false;
    }

    if (a.allFiltersFilled !== b.allFiltersFilled) {
        return false;
    }

    if (!deepEqual(a.filtersAll, b.filtersAll)) {
        return false;
    }

    if (!deepEqual(a.events, b.events)) {
        return false;
    }

    if (a.totalCount !== b.totalCount) {
        return false;
    }

    return true;
};

const showMoreCardsLimit = 8;
const useCombinedHooks = (
    initialLoadLimit: number = initialCardsToShow,
    title: string,
    promote: boolean,
    filter: EventCodes[] | undefined,
) => {
    const {
        events,
        isFetching,
        allFiltersFilled,
        totalCount,
        firstClientRender,
    } = useStateSelector(title);

    const [limit] = useState(showMoreCardsLimit);
    const [offset, setOffset] = useState(0);
    const [cardsToShow, setCardsToShow] = useState(initialLoadLimit);
    const [searchWhenFetchStopped, setSearchWhenFetchStopped] = useState(false);
    const [firstRender, setFirstRender] = useState(firstClientRender);
    const [appendEvents, setAppendEvents] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const searchCategoryEvents = useSearchCategoryEvents(
        setAppendEvents,
        promote,
        filter,
        limit,
        initialLoadLimit,
        offset,
        title,
        cardsToShow,
        setCardsToShow,
        setScrollTop,
    );

    const eventCount = events.length || 0;
    const showMore = eventCount < totalCount;
    const preloading = isFetching || !allFiltersFilled;

    useEffects(
        firstRender,
        setFirstRender,
        title,
        initialLoadLimit,
        setCardsToShow,
        searchWhenFetchStopped,
        setSearchWhenFetchStopped,
        searchCategoryEvents,
        setOffset,
        scrollTop,
        setScrollTop,
    );

    return {
        limit,
        offset,
        cardsToShow,
        searchWhenFetchStopped,
        firstRender,
        appendEvents,
        eventCount,
        showMore,
        preloading,
        events,
        searchCategoryEvents,
        scrollTop,
        setScrollTop,
    };
};

export const useStateSelector = (title: string) => {
    const dispatch = useDispatch();

    return useSelector<State, SelectedState>(
        (state) => ({
            isFetching: eventsSearchIsFetching(state, title),
            events: categoryEventsSearchResults(state, title),
            eventsSearch: searchEvents(state, dispatch),
            eventsSearchAppend: searchEventsAppend(state, dispatch),
            allFiltersFilled: filtersAllFilled(state.filters),
            filtersAll: allFilters(state.filters),
            fetchController: categoryFetchControllers(state, title),
            totalCount: categoryEventTotalCount(state, title),
            firstClientRender: state.firstClientRender,
        }),
        selectorEquality,
    );
};

const useEffects = (
    firstRender: boolean,
    setFirstRender: React.Dispatch<React.SetStateAction<boolean>>,
    title: string,
    initialLoadLimit: number,
    setCardsToShow: React.Dispatch<React.SetStateAction<number>>,
    searchWhenFetchStopped: boolean,
    setSearchWhenFetchStopped: React.Dispatch<React.SetStateAction<boolean>>,
    searchCategoryEvents: (append?: boolean) => void,
    setOffset: React.Dispatch<React.SetStateAction<number>>,
    scrollTop: number,
    setScrollTop: React.Dispatch<React.SetStateAction<number>>,
) => {
    useOnMount(firstRender, setFirstRender);
    useOnFiltersChange(
        title,
        initialLoadLimit,
        firstRender,
        setCardsToShow,
        setSearchWhenFetchStopped,
        searchCategoryEvents,
    );
    useOnIsFetchingChange(
        title,
        searchWhenFetchStopped,
        setSearchWhenFetchStopped,
        searchCategoryEvents,
        scrollTop,
        setScrollTop,
    );
    useOnEventsChange(title, setOffset);
};

export default useCombinedHooks;
