import React, { useState, useRef } from "react";
import clsx from "clsx";
import MuiDrawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import useStyles from "./styles";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import HomeIcon from "@material-ui/icons/Home";
import { Collapse, SvgIconProps } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import { Nav } from "@skiddle/web.global.template.store.header";
import { Props, Anchor } from "./types";

const Drawer: React.FC<Props> = ({ navigation }) => {
    const classes = useStyles();
    const listItemScroll: React.MutableRefObject<any> = useRef();
    const [navKey, setNavKey] = useState<null | undefined | number>();
    const [closed, setClosed] = React.useState(true);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const handleClick = (
        key: number,
        listItemScroll: React.MutableRefObject<any>,
    ) => {
        setNavKey(key);
        setClosed(key === navKey && !closed);

        if (listItemScroll.current !== null) {
            setTimeout(() => {
                // listItemScroll.current.scrollIntoView({ behavior: 'smooth' })
                // listItemScroll.current.scrollTo(0, listItemScroll.current.offsetTop)
            }, 2900);
        }
    };

    const toggleDrawer =
        (ancr: Anchor, open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === "keydown" &&
                ((event as React.KeyboardEvent).key === "Tab" ||
                    (event as React.KeyboardEvent).key === "Shift")
            ) {
                return;
            }

            setState({ ...state, [ancr]: open });
        };

    const listItem = (
        title: string,
        link: string,
        key: number,
        icon?: React.ReactElement<SvgIconProps>,
    ) => (
        <ListItem button component="a" key={`listitems_${key}`} href={link}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={title} />
        </ListItem>
    );

    const listDropDown = (nav: Nav, key: number) => (
        <React.Fragment key={key}>
            <ListItem
                ref={listItemScroll}
                button
                onClick={() => handleClick(key, listItemScroll)}
                key={`draweritems_${key}`}
            >
                {/*<ListItemIcon>*/}
                {/*    <InboxIcon />*/}
                {/*</ListItemIcon>*/}
                <ListItemText
                    primary={nav.title}
                    classes={{
                        primary:
                            key === navKey && !closed ? classes.listTitle : "",
                    }}
                />
                {key === navKey && !closed ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={key === navKey && !closed} timeout="auto">
                <List component="div" disablePadding>
                    {nav.categories.map(({ id, category, links }, key) => (
                        <React.Fragment key={id}>
                            <ListItem
                                classes={{
                                    selected: classes.listTitleSelected,
                                }}
                                selected={true}
                                button
                                className={classes.nested}
                                key={`${id}_${key}`}
                            >
                                <ListItemText
                                    classes={{ primary: classes.listTitle }}
                                    primary={category}
                                />
                            </ListItem>
                            {links.map(({ title, link }, key) => (
                                <ListItem
                                    button
                                    href={link}
                                    component={"a"}
                                    className={classes.nestedDeep}
                                    key={title}
                                >
                                    <ListItemText primary={title} />
                                </ListItem>
                            ))}
                        </React.Fragment>
                    ))}
                </List>
            </Collapse>
        </React.Fragment>
    );

    const list = (anchor: Anchor, navigation: Nav[]) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
        >
            <List>
                {listItem(
                    "home",
                    "/",
                    999,
                    <HomeIcon className={classes.icon} />,
                )}
                <Divider className={classes.divider} />
                {navigation.map((nav, key) =>
                    nav.categories.length < 1
                        ? listItem(nav.title, nav.titleLink, key)
                        : listDropDown(nav, key),
                )}
            </List>
        </div>
    );

    const anchor = "left";

    return (
        <div className={classes.drawer}>
            <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer(anchor, true)}
            >
                <MenuIcon />
            </IconButton>

            <ClickAwayListener onClickAway={() => toggleDrawer(anchor, false)}>
                <MuiDrawer
                    classes={{ paper: classes.drawerPaper }}
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                >
                    {list(anchor, navigation)}
                </MuiDrawer>
            </ClickAwayListener>
        </div>
    );
};

export default Drawer;
