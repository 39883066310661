import { useDispatch, useSelector } from "react-redux";
import { DefaultFiltersSateSelector } from "../../types";
import {
    updateEventTypes,
    updateGenres,
    updateHideCancelled,
    updateKeyword,
    updateMaxDate,
    updateMinDate,
    updateOrderBy,
    updateRadius,
    updateShowVirtual,
    updateTicketsAvailable,
    updateUnder18,
} from "../../store/filters/actions";
import { defaultValue as defaultOrderBy } from "../../components/filters/orderBy";
import { defaultValues as defaultLocationValues } from "@skiddle/web.events.filters.location";
import { defaultValue as defaultKeywordValue } from "../../components/filters/keyword";
import { defaultValue as defaultGenreValue } from "../../components/filters/genres";
import { allFilters as getAllFilters } from "../../store/filters/selector";
import { State } from "../../store/types";
import { defaultValue as defaultEventTypesValue } from "../../components/filters/eventTypes";
import { defaultValues as defaultDateValues } from "../../components/filters/dates";
import { defaultValues as defaultOtherValues } from "../../components/filters/other";
import { getLocations } from "../../store/location/actions";

export const useSetDefaultFilterValues = (
    location?: string,
    sort?: string | undefined | null,
) => {
    const dispatch = useDispatch();
    const { allFilters } = useSelector<State, DefaultFiltersSateSelector>(
        (state) => ({
            allFilters: getAllFilters(state.filters),
            locationSearch: getLocations(state.location, dispatch),
        }),
    );

    return () => {
        const {
            orderBy,
            radius,
            keyword,
            genres,
            eventTypes,
            minDate,
            maxDate,
            hideCancelled,
            ticketsAvailable,
            under18,
            showVirtual,
        } = allFilters;
        if (orderBy === undefined) {
            dispatch(updateOrderBy(sort === "date" ? sort : defaultOrderBy));
        }

        if (radius === undefined) {
            dispatch(updateRadius(defaultLocationValues.radius));
        }

        if (keyword === undefined) {
            dispatch(updateKeyword(defaultKeywordValue));
        }

        if (genres === undefined) {
            dispatch(updateGenres(defaultGenreValue as number[]));
        }

        if (eventTypes === undefined) {
            dispatch(updateEventTypes(defaultEventTypesValue));
        }

        if (minDate === undefined) {
            dispatch(updateMinDate(defaultDateValues[0]));
        }

        if (maxDate === undefined) {
            dispatch(updateMaxDate(defaultDateValues[1]));
        }

        if (ticketsAvailable === undefined) {
            dispatch(
                updateTicketsAvailable(defaultOtherValues.ticketsAvailable),
            );
        }

        if (hideCancelled === undefined) {
            dispatch(updateHideCancelled(defaultOtherValues.hideCancelled));
        }

        if (under18 === undefined) {
            dispatch(updateUnder18(defaultOtherValues.under18));
        }

        if (showVirtual === undefined) {
            dispatch(updateShowVirtual("0"));
        }
    };
};
