import React, { lazy, Suspense } from "react";
import FilterDialog from "@skiddle/web.events.filters.dialog";
import { DatesViewDialog as Props } from "../types";
import { CircularProgress, Typography, Button, Box } from "@material-ui/core";
import useStyles from "./styles";
import { buttonPresets } from "./constants";

import "../css/_datepicker.css";

const DateRangePicker = lazy(() => import("./DateRangePicker"));

const DatesViewDialog: React.FC<Props> = ({
    open,
    toggleOpen,
    confirmDates,
    isMobile,
    showAllDates,
    fromDate,
    toDate,
    datesChange,
    focusedInput,
    setFocusedInput,
    dateFormat,
}) => {
    const classes = useStyles();

    const renderCalendarInfo = () => {
        return (
            <Box
                className={classes.presetContainer}
                style={{
                    maxWidth: isMobile ? "300px" : undefined,
                    marginTop: isMobile ? "1rem" : undefined,
                }}
            >
                <Box
                    style={{
                        justifyContent: "space-evenly",
                        alignItems: "flex-start",
                        display: "flex",
                    }}
                >
                    {buttonPresets.map(({ text, start, end }) => {
                        return (
                            <Button
                                classes={{ root: classes.buttonRoot }}
                                style={{
                                    fontSize: isMobile ? "0.875rem" : "1rem",
                                }}
                                key={text}
                                type="button"
                                onClick={() =>
                                    datesChange({
                                        startDate: start,
                                        endDate: end,
                                    })
                                }
                            >
                                {text}
                            </Button>
                        );
                    })}
                </Box>
            </Box>
        );
    };
    return (
        <FilterDialog
            title="Dates"
            open={open}
            toggleOpen={toggleOpen}
            onConfirm={confirmDates}
            height={isMobile ? 720 : 590}
            width={isMobile ? 500 : 650}
            onClear={showAllDates}
            clearLabel="show all dates"
        >
            <Suspense
                fallback={
                    <div
                        style={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "100%",
                        }}
                    >
                        <Typography
                            variant="h4"
                            style={{ textAlign: "center" }}
                        >
                            <CircularProgress />
                            <br />
                            Loading...
                        </Typography>
                    </div>
                }
            >
                <DateRangePicker
                    firstDayOfWeek={1}
                    startDate={fromDate}
                    startDateId="dates-filter-start"
                    endDate={toDate}
                    endDateId="dates-filter-end"
                    onDatesChange={datesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(input) => {
                        if (input && input !== focusedInput) {
                            setFocusedInput(input);
                        }
                    }}
                    readOnly
                    displayFormat={dateFormat}
                    calendarInfoPosition={isMobile ? "before" : "bottom"}
                    orientation={isMobile ? "vertical" : "horizontal"}
                    verticalHeight={isMobile ? 490 : 400}
                    renderCalendarInfo={renderCalendarInfo}
                />
            </Suspense>
        </FilterDialog>
    );
};

export default DatesViewDialog;
