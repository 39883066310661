import {
    EventCodes,
    EventTypeUrlParams,
} from "@skiddle/web.events.shared-types";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useEventCodesQueryParam, useStateSelector } from ".";
import { pathPrefix } from "../../../../App";
import { buildEventLocationUrl } from "@skiddle/web.events.helpers";
import { EventType } from "../../../../store/events/types/types";
import { updateEventTypes } from "../../../../store/filters/actions";
import { LocationUrlParams } from "../../location/types";

export const selectedEventTypesToEventCodes = (
    events: string[],
    eventTypes: EventType[],
) =>
    eventTypes
        .filter(({ eventcodedesc }) => events.includes(eventcodedesc))
        .map(({ eventcode }) => eventcode);

export const selectedEventTypesToEventCodeIDs = (
    events: string[],
    eventTypes: EventType[],
) =>
    eventTypes
        .filter(({ eventcodedesc }) => events.includes(eventcodedesc))
        .map(({ eventcodeid }) => Number(eventcodeid));

export const useConfirmEventTypes = (
    selectedEventTypes: string[],
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const dispatch = useDispatch();
    const { eventTypes, locationResult, retrieveCitySeo } = useStateSelector();
    const [, setEventCodes] = useEventCodesQueryParam();
    const { location } = useParams<EventTypeUrlParams & LocationUrlParams>();
    const history = useHistory();

    return () => {
        dispatch(
            updateEventTypes(
                selectedEventTypesToEventCodes(selectedEventTypes, eventTypes),
            ),
        );
        setUserChanged(true);

        setEventCodes(
            selectedEventTypesToEventCodeIDs(selectedEventTypes, eventTypes),
        );
        setOpen(false);

        const {
            id = "",
            postcodeLookup = "",
            name = "",
            type = "",
            cityCode = "",
        } = locationResult || {};

        if (cityCode && name) {
            const eventCode =
                selectedEventTypes.length === 1
                    ? selectedEventTypesToEventCodes(
                          selectedEventTypes,
                          eventTypes,
                      )[0]
                    : undefined;
            retrieveCitySeo(cityCode, name, eventCode);
        }

        history.push(
            buildEventLocationUrl(
                postcodeLookup || location,
                pathPrefix,
                id,
                name,
                type,
                true,
            ),
        );
    };
};

export const useClearEventTypes = (
    setSelectedEventTypes: React.Dispatch<React.SetStateAction<string[]>>,
    defaultValue: EventCodes[],
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const dispatch = useDispatch();
    const { locationResult } = useStateSelector();
    const [, setEventCodes] = useEventCodesQueryParam();
    const { eventType: eventTypeParam, location } = useParams<
        EventTypeUrlParams & LocationUrlParams
    >();
    const history = useHistory();

    return () => {
        setSelectedEventTypes(defaultValue);
        dispatch(updateEventTypes([]));
        setEventCodes(undefined);
        setUserChanged(true);

        if (eventTypeParam) {
            const {
                id = "",
                postcodeLookup = "",
                name = "",
                type = "",
            } = locationResult || {};

            history.push(
                buildEventLocationUrl(
                    postcodeLookup || location,
                    pathPrefix,
                    id,
                    name,
                    type,
                    true,
                ),
            );
        }
        setOpen(false);
    };
};
