import makeStyles from "@material-ui/core/styles/makeStyles";
import { filterColor, navyBlue } from "../../../../theme";

export const animationTime = 0.4;
export const boxShadow = "rgba(19, 19, 19, 0.5) 0px 5px 6px";
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        padding: 5,
        // paddingLeft: 5,
        // paddingRight: 10,
        fontSize: "1.1rem",
        borderRadius: "0 !important",
        flexWrap: "nowrap",
        overflowX: "auto",
        overflowY: "hidden",
        whiteSpace: "nowrap",

        /* browser decide if x is ok to scroll and show bars on, y hidden */
        overflow: "auto hidden",
        /* prevent scroll chaining on x scroll */
        overscrollBehaviorX: "contain",
        /* scrolling should snap children on x */
        scrollSnapType: "x mandatory",
        "@media (hover: none)": {
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                width: 0,
                height: 0,
            },
        },
        "@media (min-width: 600px)": {
            height: "45px",
            padding: "0px",
            maxWidth: "90%",
            margin: "auto",
        },
    },
    title: {
        color: navyBlue,
        "@media (min-width: 600px)": {
            display: "none",
        },
    },
    buttonRoot: {
        fontSize: "0.875rem",
        textTransform: "none",
        color: navyBlue,
        border: `1px solid ${navyBlue}`,
        padding: "2px 15px",
        margin: theme.spacing(0, 0.5),
        "&:hover": {
            boxShadow: `inset 0px 0px 0px 1px ${navyBlue}`,
            border: `1px solid ${navyBlue}`,
        },
        "@media (min-width: 600px)": {
            fontSize: "1rem",
        },
    },
    button: {
        flex: "none",
        fontWeight: 400,
        fontSize: "0.850em",
        letterSpacing: "0.01857em",
        "&:hover": {
            background: "#fff",
            fontWeight: 600,
            // border: "1px solid #ababab",
        },
    },
}));

export default useStyles;
