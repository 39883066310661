import React from "react";
import { useSelector } from "react-redux";
import { seoResult } from "../../../store/seo/selectors";
import { State } from "../../../store/types";
import { SeoDataSelectState } from "../types";
import { Helmet } from "react-helmet";
import { LocationUrlParams } from "../../filters/location/types";
import { useParams } from "react-router-dom";
import { SEO } from "@skiddle/web.events.store.types";

export const onlineTitleText = "Online and virtual events";

const PageTitle = () => {
    const {
        seo: { pageTitle = "events search" },
    } = useSelector<State, SeoDataSelectState>((state) => ({
        seo: seoResult(state) || ({} as SEO),
    }));
    const { location: urlLocation } = useParams<LocationUrlParams>();

    return (
        <Helmet>
            <title>
                {(urlLocation?.toLowerCase() === "online"
                    ? onlineTitleText
                    : pageTitle
                ).toTitleCase()}
            </title>
        </Helmet>
    );
};

export default PageTitle;
