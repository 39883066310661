import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
    },
    icon: {
        display: "block",
        float: "left"
    },
     typography: {
        fontSize: "0.95rem",
        fontWeight: 600
     }
}));

export default useStyles;
