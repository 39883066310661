import { Location, LocationState } from "./types";

export const locationIsFetching = (state: LocationState): boolean =>
    state.fetching;

export const locationResults = (state: LocationState): Location[] =>
    state.results;

export const locationResult = (state: LocationState): Location | null =>
    state.result;

export const locationFetchController = (
    state: LocationState,
): AbortController | undefined => state.controller;

export const locationCanSubmitFilter = (state: LocationState): boolean =>
    state.canSubmitLocationFilter;
