import React from "react";
import "react-dates/initialize";
import { UpdateMinMaxDatesAction } from "@skiddle/web.events.store.filters";
import DatesView from "./view";
import useCombinedHooks from "./hooks";
import { Props } from "./types";
import { getMomentWithTimezone } from "@skiddle/web.events.date-format";

const moment = getMomentWithTimezone();

const today = moment();
export const dateFormat = "DD MMM YYYY";
const mysqlDateOnlyFormat = "YYYY-MM-DD";
export const mysqlDateFormats = {
    from: `${mysqlDateOnlyFormat}T00:00:00`,
    to: `${mysqlDateOnlyFormat}T23:59:59`,
};

export const defaultValues: Parameters<UpdateMinMaxDatesAction> = [
    today.format(mysqlDateFormats.from),
    "",
];

const DatesFilter: React.FC<Props> = ({
    filterMinDate,
    filterMaxDate,
    useUrlParams = true,
}) => {
    const {
        open,
        toggleOpen,
        confirmDates,
        isMobile,
        showAllDates,
        fromDate,
        toDate,
        datesChange,
        focusedInput: [focusedInput, setFocusedInput],
        datesDisplay,
        isActive,
    } = useCombinedHooks(
        defaultValues,
        filterMinDate,
        filterMaxDate,
        useUrlParams,
    );

    return (
        <DatesView
            open={open}
            toggleOpen={toggleOpen}
            confirmDates={confirmDates}
            isMobile={isMobile}
            showAllDates={showAllDates}
            fromDate={fromDate}
            toDate={toDate}
            datesChange={datesChange}
            focusedInput={focusedInput || "startDate"}
            setFocusedInput={setFocusedInput}
            dateFormat={dateFormat}
            displayDates={datesDisplay}
            isActive={isActive}
        />
    );
};

export default DatesFilter;
