import { EventsSearchParams } from "@skiddle/web.events.shared-types";
import { FiltersState, LastUpdatedFilterState, OtherFilters } from "./types";
import { filterToSearchApiParams } from "./actions";

export const orderByFilter = (state: FiltersState): FiltersState["orderBy"] =>
    state.orderBy;

export const locationFilter = (
    state: FiltersState,
): [FiltersState["latitude"], FiltersState["longitude"]] => [
    state.longitude,
    state.latitude,
];

export const radiusFilter = (state: FiltersState): FiltersState["radius"] =>
    state.radius;

export const keywordFilter = (state: FiltersState): FiltersState["keyword"] =>
    state.keyword;

export const genresFilter = (state: FiltersState): FiltersState["genres"] =>
    state.genres;

export const eventTypesFilter = (
    state: FiltersState,
): FiltersState["eventTypes"] => state.eventTypes;

export const minDateFilter = (state: FiltersState): FiltersState["minDate"] =>
    state.minDate;

export const maxDateFilter = (state: FiltersState): FiltersState["maxDate"] =>
    state.maxDate;

export const ticketsAvailableFilter = (
    state: FiltersState,
): EventsSearchParams["ticketsavailable"] => state.ticketsAvailable;

export const hideCancelledFilter = (
    state: FiltersState,
): EventsSearchParams["hidecancelled"] => state.hideCancelled;

export const under18Filter = (
    state: FiltersState,
): EventsSearchParams["under18"] | null => state.under18;

export const otherFilters = (state: FiltersState): OtherFilters => ({
    hideCancelled: hideCancelledFilter(state),
    ticketsAvailable: ticketsAvailableFilter(state),
    under18: under18Filter(state),
});

export const showVirtualFilter = (
    state: FiltersState,
): EventsSearchParams["showVirtualEvents"] => state.showVirtual;

export const lastUpdatedFilter = <
    S extends Record<K, LastUpdatedFilterState>,
    K extends keyof S,
>(
    state: S,
    lastUpdateFilter: K,
): LastUpdatedFilterState => state[lastUpdateFilter];

export const allFilters = (state: FiltersState): FiltersState => state;

export const filtersAllFilled = (state: FiltersState): boolean =>
    Object.values(state).reduce<boolean>((allFilled, filter) => {
        if (filter === undefined) {
            return false;
        }

        return allFilled;
    }, true);

export const filtersForSearchAPI = (state: FiltersState): EventsSearchParams =>
    filtersToSearchAPI(state);

export const filtersToSearchAPI = (filters: FiltersState): EventsSearchParams =>
    (
        Object.keys(filters) as Array<keyof FiltersState>
    ).reduce<EventsSearchParams>(
        (searchFilters: EventsSearchParams, filter) => {
            // Parse filter state as event search api params and omit if the value is empty
            const searchParam = filterToSearchApiParams[filter];
            if (searchParam === "eventcode") {
                return searchFilters;
            }

            const val = filters[filter];

            if (
                !val ||
                val === "" ||
                (Array.isArray(val) && val.length === 0)
            ) {
                return searchFilters;
            }

            searchFilters[searchParam] = val as unknown as never;

            return searchFilters;
        },
        {},
    );
