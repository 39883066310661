import React, { useLayoutEffect } from "react";
import { Props } from "./types";
import Grid from "@material-ui/core/Grid";
import MobileCard from "../MobileCard";
import DesktopCard from "../DesktopCard";

const Cards: React.FC<Props> = ({
    cards,
    matchMobile,
    scrollTop = 0,
    setScrollTop,
}) => {
    let eventCards = [];

    useLayoutEffect(() => {
        if (scrollTop > 0 && setScrollTop !== undefined) {
            window.scroll({ top: scrollTop });
            setScrollTop(0);
        }
    }, []);

    for (let i = 1; i <= cards; i++) {
        eventCards[i] = (
            <Grid key={i} item xs={12} sm={4} md={3}>
                {matchMobile ? <MobileCard /> : <DesktopCard />}
            </Grid>
        );
    }

    return <>{eventCards}</>;
};

export default Cards;
