import React from "react";
import { Props } from "./types";
import { decodeHtmlEntities } from "@skiddle/web.events.helpers";
import { EventsSearchResult } from "@skiddle/web.events.shared-types";
import { LazyBoundary } from "react-imported-component";
import { Venue } from "@skiddle/web.events.shared-types";

const propsDecodeHtmlEntities = (props: EventsSearchResult) => {
    for (const keyName in props) {
        const key = keyName as keyof EventsSearchResult;

        if (props.hasOwnProperty(key)) {
            const val = props[key];
            if (typeof val === "string") {
                props[key] = decodeHtmlEntities(val) as never;
            }

            if (key === "venue") {
                for (const keyName in props.venue) {
                    const key = keyName as keyof Venue;

                    if (props.venue.hasOwnProperty(key)) {
                        const val = props.venue[key];
                        if (typeof val === "string") {
                            props.venue[key] = decodeHtmlEntities(val) as never;
                        }
                    }
                }
            }
        }
    }

    return props;
};

const Card: React.FC<Props> = ({ component: Component, ...props }) => {
    const decodedProps = propsDecodeHtmlEntities(props);

    return (
        <LazyBoundary fallback={<h2>Loading..</h2>}>
            <Component {...decodedProps} />
        </LazyBoundary>
    );
};

export default Card;
