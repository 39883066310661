import { Aggregations } from "@skiddle/web.events.shared-types";
import { UpdateAggregations, ClearAggregations } from "./types";

export const updateAggregations = (
    aggregations: Aggregations,
): UpdateAggregations => ({
    type: "UPDATE_AGGREGATIONS",
    value: aggregations,
});

export const clearAggregations = (): ClearAggregations => ({
    type: "CLEAR_AGGREGATIONS",
});
