import React from "react";
import DatesViewDialog from "./dialog";
import SearchButton from "@skiddle/web.events.filters.button";
import TodayIcon from "@material-ui/icons/Today";
import { DatesViewProps as Props } from "../types";

const DatesView: React.FC<Props> = ({
    open,
    toggleOpen,
    displayDates,
    isActive,
    ...props
}) => (
    <>
        <DatesViewDialog open={open} toggleOpen={toggleOpen} {...props} />
        <SearchButton
            title={displayDates()}
            onClick={toggleOpen}
            isActive={isActive}
            icon={<TodayIcon />}
        />
    </>
);

export default DatesView;
