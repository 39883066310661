import deepEqual from "deep-equal";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useGenresQueryParam, useStateSelector } from ".";
import { updateGenres } from "../../../../store/filters/actions";
import { Genre } from "../../../../store/genres/types";
import { parseSelectedGenreIDs } from "./event-handlers";

const genreIDToName = (genreIDs: number[], genres: Genre[]) =>
    genres
        .filter(({ genreid }) => genreIDs.includes(Number(genreid)))
        .map(({ genrename }) => genrename);

export const useOnMount = (defaultValue: number[]) => {
    const dispatch = useDispatch();
    const { genres, filterGenres } = useStateSelector();
    const [genresParam] = useGenresQueryParam();
    const { fetchGenres } = useStateSelector();

    useEffect(() => {
        if (genres.length === 0) {
            fetchGenres();
        }

        if (filterGenres === undefined && genresParam === undefined) {
            dispatch(updateGenres(defaultValue));
        }
    }, []);
};

export const useOnGenresLoaded = (
    setSelectedGenres: React.Dispatch<React.SetStateAction<string[]>>,
) => {
    const dispatch = useDispatch();
    const { genres, filterGenres } = useStateSelector();
    const [genresParam] = useGenresQueryParam();
    const genresLoaded = useRef(false);

    useEffect(() => {
        if (genres.length > 0 && !genresLoaded.current) {
            genresLoaded.current = true;
            if (filterGenres === undefined) {
                const genresParamArr: number[] | undefined =
                    typeof genresParam === "number"
                        ? [genresParam]
                        : (genresParam?.filter(
                              (genre) => genre !== null,
                          ) as number[]);

                const genreParamNames = genreIDToName(genresParamArr, genres);
                setSelectedGenres(genreParamNames);
                dispatch(updateGenres(genresParamArr));
            }
        }
    }, [genres]);
};

export const useOnGenresStoreFilterChange = (
    selectedGenres: string[],
    setSelectedGenres: React.Dispatch<React.SetStateAction<string[]>>,
) => {
    const { filterGenres, genres } = useStateSelector();
    const [, setGenresParam] = useGenresQueryParam();

    useEffect(() => {
        const parsedGenres = selectedGenres.map((genre) =>
            genre.replace(/ \(([\d]+)\)/, "").toLowerCase(),
        );
        const genreIDs = parseSelectedGenreIDs(genres, parsedGenres);

        if (filterGenres !== undefined && !deepEqual(filterGenres, genreIDs)) {
            setSelectedGenres(genreIDToName(filterGenres, genres));
            setGenresParam(filterGenres);
        }
    }, [filterGenres]);
};
