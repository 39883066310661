import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Box, Snackbar } from "@material-ui/core";
import useStyles from "./styles";
import { useParams } from "react-router-dom";
import { LocationUrlParams } from "../../filters/location/types";
import LandingLocationFilter from "./location";
import { State } from "../../../store/types";
import { SelectState } from "./type";
import {
    keywordFilter,
    locationFilter,
    maxDateFilter,
    minDateFilter,
    radiusFilter,
    showVirtualFilter,
    orderByFilter,
} from "@skiddle/web.events.store.filters";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import {
    locationResult as locationResultSelector,
    getLocation,
    getLocations,
    locationFetchController,
    locationResults,
    locationCanSubmitFilter,
} from "@skiddle/web.events.store.location";
import { Alert } from "@material-ui/lab";
import { seoResult as seoResultSelector } from "../../../store/seo/selectors";
import { pathPrefix } from "../../../App";
import { seoClear } from "../../../store/seo/actions";
import { useGetSeoData } from "../../seo/hooks";
import LandingDateFilter from "./dates";
import LandingKeywordFilter from "./keyword";
import { defaultEmptyOptionsMap } from "@skiddle/web.events.data";

const cloudfrontBannerUrl =
    "https://d1plawd8huk6hh.cloudfront.net/images/banners";

const paramsRequired = ["to_date", "from_date", "keyword"];

const LandingFilters: React.FC = () => {
    const classes = useStyles();
    const [confirmFilters, setConfirmFilters] = useState<boolean>(false);
    const [queryParams, setQueryParams] = useState("");
    const [filterErrorMessage, setFilterErrorMessage] = useState("");
    const [showFilterError, setShowFilterError] = useState(false);
    const { location: urlLocationParam } = useParams<LocationUrlParams>();
    const dispatch = useDispatch();
    const {
        filterMinDate,
        filterMaxDate,
        locationGet,
        locationSearch,
        locationController,
        location,
        locationResult,
        locations,
        radius,
        seoResult,
        showVirtual,
        filterKeyword,
        canSubmitLocationFilter,
        orderBy,
    } = useSelector<State, SelectState>(
        (state) => ({
            filterMinDate: minDateFilter(state.filters),
            filterMaxDate: maxDateFilter(state.filters),
            locationGet: getLocation(state.location, dispatch),
            locationSearch: getLocations(state.location, dispatch),
            locationController: locationFetchController(state.location),
            location: locationFilter(state.filters),
            locationResult: locationResultSelector(state.location),
            locations: locationResults(state.location),
            radius: radiusFilter(state.filters),
            seoResult: seoResultSelector(state),
            showVirtual: showVirtualFilter(state.filters),
            filterKeyword: keywordFilter(state.filters),
            canSubmitLocationFilter: locationCanSubmitFilter(state.location),
            orderBy: orderByFilter(state.filters),
        }),
        shallowEqual,
    );
    useEffect(() => {
        if (orderBy) setQueryParams(() => `${queryParams}&sort=${orderBy}`);
    }, [orderBy]);

    const closeFilterErrorMessage = () => {
        setShowFilterError(false);
        setFilterErrorMessage("");
    };

    const validateSearchFilters = (value: boolean) => {
        let errorMessage = "";
        if (locations.length > 0 && !locationResult) {
            errorMessage = "Please select a location from the dropdown";
        }
        if (errorMessage === "") {
            setConfirmFilters(true);
        } else {
            setFilterErrorMessage(errorMessage);
            setShowFilterError(true);
        }
    };
    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} sm={6} className={classes.gridContainer}>
                <Box className={classes.container}>
                    <Typography variant="h5" className={classes.heading}>
                        find events{" "}
                        {urlLocationParam &&
                            locationResult &&
                            locationResult.name.toLowerCase() ===
                                urlLocationParam.toLowerCase() &&
                            urlLocationParam !== "all" &&
                            `
                            ${
                                !defaultEmptyOptionsMap.has(urlLocationParam)
                                    ? "in"
                                    : ""
                            } ${urlLocationParam}`}
                    </Typography>
                    <Box className={classes.inputs}>
                        <LandingKeywordFilter
                            keywordState={filterKeyword}
                            confirmFilters={confirmFilters}
                            setConfirmFilters={setConfirmFilters}
                            setQueryParams={setQueryParams}
                        />
                    </Box>
                    <Box className={classes.inputs}>
                        <Box paddingBottom={1} zIndex="2">
                            <LandingLocationFilter
                                getLocation={locationGet}
                                getLocations={locationSearch}
                                locationController={locationController}
                                locationFilter={location}
                                locationResult={locationResult}
                                locations={locations}
                                pathPrefix={pathPrefix}
                                radiusFilter={radius}
                                seoClear={seoClear}
                                seoResult={seoResult}
                                showVirtual={showVirtual}
                                useGetSeoData={useGetSeoData}
                                confirmFilters={confirmFilters}
                                queryParams={queryParams}
                                paramsRequired={paramsRequired}
                            />
                        </Box>
                    </Box>
                    <Box className={classes.inputs}>
                        <LandingDateFilter
                            filterMinDate={filterMinDate}
                            filterMaxDate={filterMaxDate}
                            confirmFilters={confirmFilters}
                            setQueryParams={setQueryParams}
                        />
                    </Box>

                    <Box>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => validateSearchFilters(true)}
                            className={classes.button}
                            disabled={!canSubmitLocationFilter}
                        >
                            Search
                        </Button>
                    </Box>
                </Box>
            </Grid>
            <Grid className={classes.imageContainer} item sm={5}>
                <picture>
                    <source
                        srcSet={`${cloudfrontBannerUrl}/HP_img05.jxl`}
                        type="image/jxl"
                    />
                    <source
                        srcSet={`${cloudfrontBannerUrl}/HP_img05.webp`}
                        type="image/webp"
                    />
                    <img
                        src={`${cloudfrontBannerUrl}/HP_img05.jpg`}
                        alt=""
                        className={classes.image}
                    />
                </picture>
            </Grid>
            <Snackbar
                open={showFilterError}
                autoHideDuration={3000}
                onClose={closeFilterErrorMessage}
                anchorOrigin={{ horizontal: "center", vertical: "top" }}
                onClick={closeFilterErrorMessage}
                style={{ cursor: "pointer" }}
            >
                <Alert severity="warning">
                    <Typography variant="body1">
                        {filterErrorMessage}
                    </Typography>
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default LandingFilters;
