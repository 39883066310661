/// <reference path="./ucwords.d.ts" />
import React from "react";
import { OrderOptions, Props } from "./types";
import ucwords from "ucwords";
import OrderByView from "./view";
import useCombinedHooks from "./hooks";

const orderOptions: OrderOptions = {
    date: "date",
    name: "2",
    popularity: "trending",
    "venue name": "3",
    "venue rating": "5",
    featured: "19",
};

const orderOptionKeys: string[] = Object.keys(orderOptions);
export const defaultValue = orderOptions[orderOptionKeys[2]];

const OrderByFilter: React.FC<Props> = ({
    sortingOptions,
    defaultSorter,
    orderByState,
    useUrlParams = true,
}) => {
    const defaultValue = defaultSorter || orderOptions[orderOptionKeys[2]];
    const {
        open: [open],
        selected: [selected, setSelected],
        toggleOpen,
        selectOption,
        orderByDisplay,
        isActive,
    } = useCombinedHooks(
        orderOptions,
        defaultValue,
        orderByState,
        useUrlParams,
    );

    return (
        <OrderByView
            open={open}
            toggleOpen={toggleOpen}
            orderOptions={sortingOptions ? sortingOptions : orderOptionKeys}
            selectOption={selectOption}
            setSelected={setSelected}
            selected={selected}
            display={ucwords(orderByDisplay())}
            isActive={isActive}
        />
    );
};

export default OrderByFilter;
