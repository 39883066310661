import React from "react";
import { useDispatch } from "react-redux";
import { useFromDateQueryParam, useToDateQueryParam } from ".";
import { useEffect } from "react";
import {
    updateMaxDate,
    updateMinDate,
    updateMinMaxDates,
    UpdateMinMaxDatesAction,
} from "@skiddle/web.events.store.filters";
import { SelectorState } from "../types";
import { Moment } from "moment";
import { FocusedInputShape } from "react-dates";
import { getMomentWithTimezone } from "@skiddle/web.events.date-format";

const moment = getMomentWithTimezone();

export const useOnMount = (
    minDate: Parameters<UpdateMinMaxDatesAction>[0],
    maxDate: Parameters<UpdateMinMaxDatesAction>[1],
    setFocusedInput: React.Dispatch<
        React.SetStateAction<FocusedInputShape | null>
    >,
    filterMinDate: SelectorState["filterMinDate"],
    filterMaxDate: SelectorState["filterMaxDate"],
    useUrlParams: boolean,
) => {
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useFromDateQueryParam();
    const [toDate, setToDate] = useToDateQueryParam();

    // Update filters to default values
    useEffect(() => {
        if (filterMinDate === undefined && filterMaxDate === undefined) {
            const defaultValues: Parameters<UpdateMinMaxDatesAction> = [
                fromDate || minDate,
                toDate || maxDate,
            ];
            dispatch(updateMinMaxDates(...defaultValues));

            if (!fromDate && useUrlParams) {
                setFromDate(defaultValues[0]);
            }

            if (!toDate && useUrlParams) {
                setToDate(defaultValues[1]);
            }

            setFocusedInput("startDate");
        }
    }, []);
};

export const useOnFromDateStoreUpdate = (
    fromDate: Parameters<UpdateMinMaxDatesAction>[0],
    setFromDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
    filterMinDate: SelectorState["filterMinDate"],
    useUrlParams: boolean,
) => {
    const [, setFromDateParam] = useFromDateQueryParam();

    useEffect(() => {
        if (filterMinDate !== undefined && fromDate !== filterMinDate) {
            let newDate: Moment | null = null;
            if (filterMinDate !== null) {
                newDate = moment(filterMinDate);
            }

            setFromDate(newDate);
            if (useUrlParams) {
                setFromDateParam(filterMinDate);
            }
        }
    }, [filterMinDate]);
};

export const useOnToDateStoreUpdate = (
    toDate: Parameters<UpdateMinMaxDatesAction>[1],
    setToDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
    filterMaxDate: SelectorState["filterMaxDate"],
    useUrlParams: boolean,
) => {
    const [, setToDateParam] = useToDateQueryParam();

    useEffect(() => {
        if (filterMaxDate !== undefined && toDate !== filterMaxDate) {
            let newDate: Moment | null = null;
            if (filterMaxDate !== null) {
                newDate = moment(filterMaxDate);
            }
            setToDate(newDate);
            if (useUrlParams) {
                setToDateParam(filterMaxDate);
            }
        }
    }, [filterMaxDate]);
};

export const useOnOpenFilter = (
    open: boolean,
    setFromDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
    setToDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
    setFocusedInput: React.Dispatch<
        React.SetStateAction<FocusedInputShape | null>
    >,
    filterMaxDate: SelectorState["filterMaxDate"],
) => {
    useEffect(() => {
        if (open) {
            setFocusedInput("startDate");

            if (!filterMaxDate) {
                setFromDate(null);
                setToDate(null);
            }
        }
    }, [open]);
};

export const useOnFromDateQueryStringChange = (
    setFromDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
    minDate: Parameters<UpdateMinMaxDatesAction>[0],
    filterMinDate: SelectorState["filterMinDate"],
    useUrlParams: boolean,
) => {
    const dispatch = useDispatch();
    const [fromDateParam] = useFromDateQueryParam();

    useEffect(() => {
        if (!useUrlParams) {
            return;
        }
        if (fromDateParam && fromDateParam !== filterMinDate) {
            dispatch(updateMinDate(fromDateParam));
            setFromDate(moment(fromDateParam));
        }

        if (!fromDateParam) {
            dispatch(updateMinDate(minDate));
            setFromDate(moment(minDate));
        }
    }, [fromDateParam]);
};

export const useOnToDateQueryStringChange = (
    setToDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
    filterMaxDate: SelectorState["filterMaxDate"],
    useUrlParams: boolean,
) => {
    const dispatch = useDispatch();
    const [toDateParam] = useToDateQueryParam();

    useEffect(() => {
        if (!useUrlParams) {
            return;
        }

        if (toDateParam && toDateParam !== filterMaxDate) {
            dispatch(updateMaxDate(toDateParam));
            setToDate(moment(toDateParam));
        }

        if (!toDateParam) {
            dispatch(updateMaxDate(""));
            setToDate(null);
        }
    }, [toDateParam]);
};
