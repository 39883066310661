import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { LocationUrlParams } from "@skiddle/web.events.filters.location";
import { useSelector } from "react-redux";
import { State } from "../../../store/types";
import { EventTypeUrlParams } from "@skiddle/web.events.shared-types";
import { buildEventSearchLink } from "@skiddle/web.events.helpers";
import { parseEventCodesFromUrlParam } from "../../filters/eventTypes/hooks/effects";
import { eventTypesResults } from "../../../store/events/types/selector";
import { EventType } from "../../../store/events/types/types";
import { SEOUrlContext } from "../../../contexts";

const CanonicalLink = () => {
    const {
        location: urlLocation = "",
        poiName = "",
        poiID: urlPoiID = "",
        eventType = "",
    } = useParams<LocationUrlParams & EventTypeUrlParams>();
    const eventTypes = useSelector<State, EventType[]>((state) =>
        eventTypesResults(state),
    );
    const [, eventCodes] = parseEventCodesFromUrlParam(eventType, eventTypes);
    const seoUrl = useContext(SEOUrlContext);

    const path = buildEventSearchLink(
        urlLocation,
        Array.isArray(eventCodes) && eventCodes.length === 1
            ? eventCodes[0]
            : eventType,
        poiName,
        urlPoiID,
        seoUrl,
    );

    return (
        <Helmet>
            <link rel="canonical" href={`https://www.skiddle.com${path}`} />
        </Helmet>
    );
};

export default CanonicalLink;
