import { Dispatch } from "redux";
import moment from "moment";
import { APIResponse, State } from "../types";
import { LocationState } from "../location/types";

import { categoryEventsIsFetching } from "./selector";
import {
    CategoryEvent,
    CategoryEventsActions,
    CategoryEventsFetch,
    CategoryEventsFetchSuccess,
    GetCategoryEvents,
} from "./types";
import { getFetchingCategory } from "./utils";
import { mysqlDateFormats } from "../../components/filters/dates";

import { apiError } from "@skiddle/web.events.store.types";
import { FiltersState } from "@skiddle/web.events.store.filters";
// eslint-disable-next-line @typescript-eslint/no-unused-vars

export const categoryEventsFetch = (
    fetchingCategory: string,
): CategoryEventsFetch => ({
    type: "EVENT_CATEGORIES_FETCH",
    fetchingCategory,
});

export const categoryEventsFetchSuccess = (
    result: CategoryEvent[],
    categoryType: string,
): CategoryEventsFetchSuccess => ({
    type: "EVENT_CATEGORIES_FETCH_SUCCESS",
    categoryType,
    result,
});

export const getCategoryEvents: GetCategoryEvents =
    (state: State, dispatch: Dispatch<CategoryEventsActions>) =>
    async (categoryName: string) => {
        const fetchingCategory = getFetchingCategory(categoryName);
        if (
            categoryEventsIsFetching(state, fetchingCategory) &&
            state.location.result === null
        ) {
            return;
        }
        dispatch(categoryEventsFetch(fetchingCategory));
        try {
            const categoryEventsResult = await fetchCategoryEvents(
                categoryName,
                state.filters,
                state.location,
            );

            if (categoryEventsResult) {
                dispatch(
                    categoryEventsFetchSuccess(
                        categoryEventsResult.results,
                        categoryName,
                    ),
                );
            }
        } catch (err) {
            console.error(err);
            dispatch(apiError(err as string));
        }
    };

export const fetchCategoryEvents = async (
    categoryName: string,
    filters: FiltersState,
    location: LocationState,
) => {
    try {
        const apiURL = process.env.REACT_APP_API_URL;
        const publicKey = process.env.REACT_APP_PUB_KEY;
        const latitude: number =
            location.result?.latitude === undefined
                ? 0
                : location.result.latitude;
        const longitude: number =
            location.result?.longitude === undefined
                ? 0
                : location.result.longitude;
        const minDate = filters.minDate
            ? filters.minDate
            : moment(Date.now()).format(mysqlDateFormats.from);
        // Create query params
        const data = new URLSearchParams(
            Object.entries({
                limit: 8,
                offset: 0,
                eventcode: categoryName,
                order: "trending",
                latitude,
                longitude,
                radius: 5,
                minDate,
                hidecancelled: 1,
                showVirtualEvents: 0,
                artistmeta: 1,
                artistmetalimit: 3,
                aggs: "genreids,eventcode",
                pub_key: publicKey,
                platform: "web",
                collapse: "uniquelistingidentifier",
            }) as string[][],
        );

        const resp = await fetch(`${apiURL}/v1/events/search/?${data}`, {
            method: "GET",
            mode: "cors",
        });

        const { error, errormessage, results }: APIResponse<CategoryEvent[]> =
            await resp.json();

        if (error && errormessage) {
            throw new Error(errormessage);
        }
        return {
            results,
        };
    } catch (err) {
        throw err;
    }
};
