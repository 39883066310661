import React from "react";
import SearchButton from "../Button";
import FilterDialog from "../dialog";
import SelectList from "../select";
import { OtherFilterViewProps as Props } from "./types";
import EventBusyIcon from "@material-ui/icons/EventBusy";

const OtherFilterView: React.FC<Props> = ({
    open,
    confirmOtherFilter,
    toggleOpen,
    onClear,
    options,
    selected,
    setSelected,
    buttonDisplay,
    isActive,
}) => (
    <>
        <FilterDialog
            open={open}
            title="More Filters"
            onConfirm={confirmOtherFilter}
            toggleOpen={toggleOpen}
            onClear={onClear}
            clearLabel="clear filters"
        >
            <SelectList
                options={options}
                multiSelect
                onSelect={(els) => Array.isArray(els) && setSelected(els)}
                selected={selected}
            />
        </FilterDialog>

        <SearchButton
            title={buttonDisplay()}
            onClick={toggleOpen}
            isActive={isActive}
            icon={<EventBusyIcon />}
        />
    </>
);

export default OtherFilterView;
