import React from "react";
import NavBar from "./NavBar/bar";
import Breadcrumbs from "./Breadcrumbs";
import Title from "./Title";
import { useSelector } from "react-redux";
import { State } from "../../../store/types";
import { StateSelector } from "./types";
import { headerNavigation } from "@skiddle/web.global.template.store.header";
import { pathPrefix } from "../../../App";

const Header = () => {
    const { navigation } = useSelector<State, StateSelector>((state) => ({
        navigation: headerNavigation(state.header),
    }));

    return (
        <>
            <NavBar navigation={navigation} />
            <Breadcrumbs pathPrefix={pathPrefix} />
            <Title />
        </>
    );
};

export default Header;
