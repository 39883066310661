import React, { createRef, useEffect } from "react";
import SearchButton from "@skiddle/web.events.filters.button";
import FilterDialog from "@skiddle/web.events.filters.dialog";
import ucwords from "ucwords";
import { KeywordViewProps as Props } from "../types";
import SearchIcon from "@material-ui/icons/Search";
import useStyles from "../style";
import KeywordSearchInput from "./search-input";

const KeywordView: React.FC<Props> = ({
    open,
    toggleOpen,
    confirmKeyword,
    clearKeyword,
    searchChange,
    keyword,
    filterKeyword,
    isActive,
}) => {
    const classes = useStyles();
    const inputRef = createRef<HTMLInputElement>();
    const buttonText = (() => {
        const maxBtnLength = 25;
        if (!filterKeyword || filterKeyword === "") {
            return "Keyword";
        }

        if (filterKeyword.length > maxBtnLength) {
            return `${filterKeyword.slice(0, maxBtnLength)}...`;
        }

        return filterKeyword;
    })();

    useEffect(() => {
        // Interval used as time is needed to assign the input ref after modal opens
        const currentSetInterval = setInterval(() => {
            if (inputRef?.current && open) {
                inputRef.current.focus();
                clearInterval(currentSetInterval);
            }
        }, 200);
    }, [inputRef, open]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === "Enter") {
            toggleOpen();
            confirmKeyword();
        }
    };

    return (
        <>
            <FilterDialog
                title="keyword"
                open={open}
                toggleOpen={toggleOpen}
                onConfirm={confirmKeyword}
                onClear={clearKeyword}
            >
                <KeywordSearchInput
                    ref={inputRef}
                    classes={classes}
                    handleKeyPress={handleKeyPress}
                    searchChange={searchChange}
                    keyword={keyword}
                />
            </FilterDialog>
            <SearchButton
                title={buttonText}
                onClick={toggleOpen}
                isActive={isActive}
                icon={<SearchIcon />}
                minWidth={99}
            />
        </>
    );
};

export default KeywordView;
