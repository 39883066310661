/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSortQueryParam } from ".";
import { getKeyByValue } from "@skiddle/web.events.helpers";
import { Order } from "@skiddle/web.events.shared-types";
import { updateOrderBy } from "@skiddle/web.events.store.filters";
import { OrderOptions, SelectState } from "../types";

// Set filter default value on mount
export const useOnMount = (
    defaultValue: Order,
    orderBy: SelectState["orderBy"],
    useUrlParams: boolean,
) => {
    const dispatch = useDispatch();
    const [sort, setSort] = useSortQueryParam();

    useEffect(() => {
        if (orderBy === undefined) {
            if ((!sort || sort === "0") && useUrlParams) {
                setSort(defaultValue);
            }

            if (sort === "0") {
                dispatch(updateOrderBy(defaultValue));
            } else {
                dispatch(updateOrderBy(sort || defaultValue));
            }
        }
    }, []);
};

export const useOnOrderByFilterStoreChange = (
    selected: string,
    setSelected: React.Dispatch<React.SetStateAction<string>>,
    orderOptions: OrderOptions,
    orderBy: SelectState["orderBy"],
    useUrlParams: boolean,
) => {
    const [, setSort] = useSortQueryParam();

    const parsedOrderValue = getKeyByValue<OrderOptions>(
        orderOptions,
        orderBy as Order,
    ) as string;
    useEffect(() => {
        if (orderBy !== undefined && selected !== parsedOrderValue) {
            setSelected(orderOptions[orderBy]);
            if (useUrlParams) {
                setSort(orderBy);
            }
        }
    }, [orderBy]);
};