import {
    navyBlue,
    showMoreFontSizeDesktop,
    showMoreFontSizeMobile,
} from "@skiddle/web.global.theme";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles({
    showMore: {
        fontSize: showMoreFontSizeMobile,
        backgroundColor: "#fff",
        color: navyBlue,
        borderColor: navyBlue,
        border: "2px solid",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: navyBlue,
            color: "#fff",
        },
        textTransform: "none",
        "@media (min-width: 600px)": {
            fontSize: showMoreFontSizeDesktop,
        },
    },
});

export default useStyles;
