import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
    selectedEventTypesFromEventCodes,
    useEventCodesQueryParam,
    useStateSelector,
} from ".";
import { updateEventTypes } from "../../../../store/filters/actions";
import ucwords from "ucwords";
import { EventType } from "../../../../store/events/types/types";
import { EventCodeParsed } from "../types";
import {
    selectedEventTypesToEventCodeIDs,
    selectedEventTypesToEventCodes,
} from "./event-handlers";
import deepEqual from "deep-equal";
import { useParams } from "react-router-dom";
import { defaultCategories } from "../../../results";
import {
    EventCodes,
    EventTypeUrlParams,
} from "@skiddle/web.events.shared-types";
import { UseGetSeoData } from "@skiddle/web.events.filters.location";

export const eventCodeIDToEventCode = (
    eventCodes: (number | undefined | null)[],
    eventTypes: EventType[],
): EventCodeParsed =>
    eventTypes
        .filter(({ eventcodeid }) => eventCodes.includes(Number(eventcodeid)))
        .reduce<EventCodeParsed>(
            (parsed, { eventcode, eventcodedesc }) => {
                parsed.eventCodes.push(eventcode);
                parsed.names.push(eventcodedesc);

                return parsed;
            },
            { eventCodes: [], names: [] },
        );

export const eventTypesDisplay = (selectedEventTypes: string[]) => {
    const eventTypesLength = selectedEventTypes.length || 0;
    if (eventTypesLength > 0) {
        return `Event Types (${eventTypesLength})`;
    }

    return "Event Types";
};

export const parseEventCodesFromUrlParam = (
    eventTypeUrlParam: string,
    eventTypes: EventType[],
): [undefined | string[], undefined | EventCodes[]] => {
    const eventTypeParamLowercase = eventTypeUrlParam.toLowerCase();
    const category = defaultCategories.find(
        ({ title }) => title.toLowerCase() === eventTypeParamLowercase,
    );

    if (category && category.filter && category.filter.length === 1) {
        const eventType = eventTypes.find(
            ({ eventcode }) =>
                eventcode === String(category?.filter?.[0] || ""),
        );

        if (eventType) {
            return [[eventType.eventcodedesc], [eventType.eventcode]];
        }
    }

    if (category && category.filter && category.filter.length > 1) {
        const catEventTypes = eventTypes.filter(({ eventcodeid }) =>
            category.filter?.includes(eventcodeid as EventCodes),
        );

        return [
            catEventTypes.map(({ eventcodedesc }) => eventcodedesc),
            catEventTypes.map(({ eventcode }) => eventcode),
        ];
    }

    if (!category) {
        const eventType = eventTypes.find(
            ({ eventcode, eventcodeid }) =>
                eventcode.toLowerCase() === eventTypeParamLowercase ||
                eventcodeid === eventTypeParamLowercase,
        );

        if (eventType) {
            return [[eventType.eventcodedesc], [eventType.eventcode]];
        }
    }

    return [undefined, undefined];
};

export const useOnMount = (defaultValue: EventCodes[]) => {
    const dispatch = useDispatch();
    const { eventTypes, fetchEventTypes, filterEventTypes } =
        useStateSelector();
    const [eventCodes] = useEventCodesQueryParam();
    const { eventType: eventTypeParam } = useParams<EventTypeUrlParams>();

    useEffect(() => {
        if (eventTypes.length === 0) {
            fetchEventTypes();
        }

        if (
            filterEventTypes === undefined &&
            eventCodes === undefined &&
            eventTypeParam === undefined
        ) {
            dispatch(updateEventTypes(defaultValue));
        }
    }, []);
};

export const useOnEventTypesLoaded = (
    setSelectedEventTypes: React.Dispatch<React.SetStateAction<string[]>>,
    setButtonTitle: React.Dispatch<React.SetStateAction<string>>,
    useGetSeoData: UseGetSeoData,
) => {
    const dispatch = useDispatch();
    const { eventTypes, filterEventTypes, locationResult } = useStateSelector();
    const [eventCodes] = useEventCodesQueryParam();
    const eventTypesLoaded = useRef(false);
    const prevEventTypeParam = useRef<string | undefined>(undefined);
    const prevEventCodes = useRef<(number | null)[] | null | undefined>(
        undefined,
    );
    const { eventType: eventTypeParam } = useParams<EventTypeUrlParams>();
    const getSeoData = useGetSeoData(locationResult);

    useEffect(() => {
        if (
            eventTypes.length > 0 &&
            (!eventTypesLoaded.current ||
                eventTypeParam !== prevEventTypeParam.current ||
                !deepEqual(eventCodes, prevEventCodes.current))
        ) {
            eventTypesLoaded.current = true;

            if (eventTypeParam !== prevEventTypeParam.current) {
                // Set event type filter from event type url parameter either using category title or eventcode
                prevEventTypeParam.current = eventTypeParam;
                if (eventTypeParam) {
                    const [eventNames, eventCodes] =
                        parseEventCodesFromUrlParam(eventTypeParam, eventTypes);

                    if (eventNames && eventCodes) {
                        if (setSelectedEventTypes) {
                            setSelectedEventTypes(eventNames);
                        }

                        // Only update filter if it's not already set
                        if (!deepEqual(eventCodes, filterEventTypes)) {
                            dispatch(updateEventTypes(eventCodes));
                        }
                        return;
                    }
                } else {
                    if (setSelectedEventTypes) {
                        setSelectedEventTypes([]);
                    }
                    dispatch(updateEventTypes([]));

                    setButtonTitle("Event Types");
                }
                return;
            }

            // If event type param is undefined and filter has a value set it to empty array
            if (
                !eventTypeParam &&
                filterEventTypes &&
                filterEventTypes?.length > 0 &&
                !eventCodes
            ) {
                dispatch(updateEventTypes([]));
            }

            // Set event type filter from event codes in the url parameters
            prevEventCodes.current = eventCodes;
            const eventCodesArr = !Array.isArray(eventCodes)
                ? [eventCodes]
                : eventCodes;

            const { eventCodes: parsedEventCodes, names } =
                eventCodeIDToEventCode(eventCodesArr, eventTypes);

            if (setSelectedEventTypes) {
                setSelectedEventTypes(names);
            }
            if (!deepEqual(filterEventTypes, parsedEventCodes)) {
                dispatch(updateEventTypes(parsedEventCodes));
            }
        }
    }, [eventTypes, eventTypeParam, eventCodes]);
};

export const useOnFilterEventTypesChange = (
    selectedEventTypes: string[],
    setSelected: React.Dispatch<React.SetStateAction<string[]>>,
    setButtonTitle: React.Dispatch<React.SetStateAction<string>>,
) => {
    const { filterEventTypes = [], eventTypes } = useStateSelector();
    const [, setEventCodesParam] = useEventCodesQueryParam();
    const { eventType: eventTypeParam } = useParams<EventTypeUrlParams>();

    useEffect(() => {
        const selectedEventCodes = selectedEventTypesToEventCodes(
            selectedEventTypes,
            eventTypes,
        );

        if (
            !eventTypeParam &&
            !deepEqual(selectedEventCodes, filterEventTypes)
        ) {
            const newSelectedEventTypes = selectedEventTypesFromEventCodes(
                filterEventTypes,
                eventTypes,
            );
            setSelected(newSelectedEventTypes);
            setEventCodesParam(
                selectedEventTypesToEventCodeIDs(
                    newSelectedEventTypes,
                    eventTypes,
                ),
            );

            const display = eventTypesDisplay(newSelectedEventTypes);
            if (display > "") {
                setButtonTitle(ucwords(display));
                return;
            }
        }

        const display = eventTypesDisplay(selectedEventTypes);
        if (display > "") {
            setButtonTitle(ucwords(display));
            return;
        }

        setButtonTitle("Event Types");
    }, [filterEventTypes]);
};
