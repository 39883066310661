import { darken, fade } from "@material-ui/core";
import {
    BackgroundImage,
    ImageSrcSets,
} from "@skiddle/web.events.shared-types";
import cookies from "js-cookie";
import ucwords from "ucwords";

// strips html and returns content
export const stripHtml = (html: string) => {
    return html.replace(/(<([^>]+)>)|&[a-z]+;/gi, "");
};

export type Sizes = "th" | 100 | 120 | 400 | 267 | 1024;

export const getImageSize = (
    url: string,
    size: Sizes,
    useWebP?: boolean,
): string => {
    const imageArr = url.split(".");
    const ext = useWebP ? "webp" : imageArr[imageArr.length - 1];
    delete imageArr[imageArr.length - 1];
    const imgURLWithoutExt = imageArr
        .join(".")
        .replace(/\.$/, "")
        .replace(/_(\d+)$/, "");

    return `${imgURLWithoutExt}_${size}.${ext}`;
};

const imageParams = (
    width: number | false | null = null,
    height: number | false | null = null,
    density: number | "dpr" = "dpr",
    fit = "crop",
    auto = "format",
) => {
    return `?${width ? `w=${width}` : ""}${
        height ? `&h=${height}` : ""
    }&auto=${auto}&fit=${fit}&crop=faces,entropy&dpr=${density}`;
};

export const getImageSizes = (
    url: string,
    smallCard: boolean = false,
    nonParallaxMobile: boolean = false,
): ImageSrcSets[] | [] => {
    const imageHost = "https://skiddle.imgix.net/";
    url = url.replace("https://d31fr2pwly4c4s.cloudfront.net/", imageHost);
    const imageArr = url.split(".");
    const ext = imageArr[imageArr.length - 1];
    const imageDefaultSize = 1024;
    delete imageArr[imageArr.length - 1];
    const imageURLWithoutExt = imageArr
        .join(".")
        .replace(/\.$/, "")
        .replace(/_(\d+)$/, "");
    const newUrl = `${imageURLWithoutExt}_${imageDefaultSize}.${ext}`;
    let images: ImageSrcSets[] | [] = [];

    if (smallCard) {
        images[1] = {
            size: 300,
            density: "1x",
            ext: ext,
            url: `${newUrl}${imageParams(140, 152, 1)}`,
        };

        images[2] = {
            size: 300,
            density: "2x",
            ext: ext,
            url: `${newUrl}${imageParams(140, 152, 2)}`,
        };
    } else if (nonParallaxMobile) {
        images[1] = {
            size: 300,
            density: "1x",
            ext: ext,
            url: `${newUrl}${imageParams(360, 160, 1)}`,
        };

        images[2] = {
            size: 300,
            density: "2x",
            ext: ext,
            url: `${newUrl}${imageParams(360, 160, 2)}`,
        };
    } else {
        images[1] = {
            size: 360,
            density: "1x",
            ext: ext,
            url: `${newUrl}${imageParams(360, 300, 1)}`,
        };

        images[2] = {
            size: 360,
            density: "2x",
            ext: ext,
            url: `${newUrl}${imageParams(360, 300, 2)}`,
        };
    }

    return images;
};

export const srcSet = (images: ImageSrcSets[], _useDensity = true): string => {
    let srcString = "";
    let first = 0;

    images.forEach(({ url, density }) => {
        srcString += first === 0 ? "" : ",";
        srcString += `${url} ${density}`;
        first++;
    });

    return srcString;
};

export const decodeHtmlEntities = (str: string): string =>
    str.replace(/&#(\d+);/g, (_match, dec) => String.fromCharCode(dec));

export const getKeyByValue = <O extends Object>(
    obj: O,
    val: O[keyof O],
): keyof O | undefined =>
    Object.keys(obj).find((key) => obj[key as unknown as keyof O] === val) as
        | keyof O
        | undefined;

export const buildEventLocationUrl = (
    city: string,
    pathPrefix: string,
    poiID: string,
    poiName: string,
    poiType: string,
    eventsRoute: boolean = false,
    eventType?: string,
    useSearchParams: boolean = true,
): string => {
    const nonAlphaNumeric = /[^a-zA-Z0-9\-]/g;
    city = city.replace(" ", "-").replace(nonAlphaNumeric, "");
    if (city !== "") {
        city += "/";
    }

    let poiStr = "";
    if (poiID && poiName && poiType !== "city") {
        poiName = poiName.replace(nonAlphaNumeric, "-");
        const poiTag = `${poiType}-${poiID}`;
        poiStr = `near/${poiName}/${poiTag}/`;
    }

    // eslint-disable-next-line
    const searchParams =
        typeof window !== "undefined" && typeof window.location !== "undefined"
            ? window.location.search
            : "";

    if (eventsRoute) {
        pathPrefix += "/events";
    }

    if (eventType) {
        return `${pathPrefix}/${eventType}/${city}${poiStr}${
            useSearchParams ? searchParams : ""
        }`;
    }

    return `${pathPrefix}/${city}${poiStr}${
        useSearchParams ? searchParams : ""
    }`;
};

/**
 * supply background url and hex and a gradient will be applied with strength
 * returns usable css object
 * @param bgImageUrl
 * @param gradientHex
 */
export const backgroundImageWithGradient = (
    bgImageUrl: string,
    gradientHex: string,
): {} => {
    if (gradientHex === "") {
        return {
            backgroundImage: `linear-gradient(0deg, rgba(0 0 0 / 0.95) 0%, rgba(0 0 0 / 0) 100%),
            url('${bgImageUrl > "" ? bgImageUrl : fallbackEventCardImage}')`,
        };
    }

    let rgb = darken(gradientHex ? gradientHex : "#00000", 0.75);
    const [rgbFrom, rgbTo] = [fade(rgb, 1), fade(rgb, 0)];

    return {
        backgroundImage: `linear-gradient(0deg, ${rgbFrom} 0%, ${rgbTo} 100%),
            url('${bgImageUrl > "" ? bgImageUrl : fallbackEventCardImage}')`,
    };
};

export const backgroundGradient = (gradientHex: string): {} => {
    if (gradientHex === "") {
        return {
            backgroundImage: `linear-gradient(0deg, rgba(0 0 0 / 0.95) 0%, rgba(0 0 0 / 0) 100%)`,
        };
    }

    let rgb = darken(gradientHex ? gradientHex : "#00000", 0.75);
    const [rgbFrom, rgbTo] = [fade(rgb, 1), fade(rgb, 0)];

    return {
        backgroundImage: `linear-gradient(0deg, ${rgbFrom} 0%, ${rgbTo} 100%)`,
    };
};

export const backgroundImage = (bgImageUrl: string): BackgroundImage => {
    return {
        backgroundImage: `url('${
            bgImageUrl > "" ? bgImageUrl : fallbackEventCardImage
        }')`,
    };
};

export const fallbackEventCardImage =
    "https://d1plawd8huk6hh.cloudfront.net/assets/default400.png";

export const copyObject = <T>(object: T): T =>
    JSON.parse(JSON.stringify(object));

export const buildEventSearchLink = (
    city: string = "all",
    ec: string | undefined = "",
    poiName?: string,
    poiID?: string,
    seoUrl?: string,
) => {
    if (city !== "all") {
        city = city.replace(" ", "-");
        city = city.replace(/[^a-zA-Z0-9\-]/, "");
        city = ucwords(city);
    }
    ec = ec.toUpperCase();

    let eventCode = "";

    switch (ec) {
        case "CLUB":
            eventCode = "clubs";
            break;

        case "LIVE":
            eventCode = "gigs";
            break;

        case "COMEDY":
            eventCode = "comedy";
            break;

        case "BARPUB":
            eventCode = "bars";
            break;

        case "DATE":
            eventCode = "dating";
            break;

        case "THEATRE":
            eventCode = "theatre";
            break;

        case "ARTS":
            eventCode = "arts";
            break;

        case "FRESHERS":
            eventCode = "freshers";
            break;

        case "NYE":
            eventCode = "new-years-eve-events";
            break;

        case "XMAS":
            eventCode = "christmas-events";
            break;

        case "VAL":
            eventCode = "valentines-events";
            break;

        case "EASTER":
            eventCode = "easter-events";
            break;

        case "FEST":
            eventCode = "festivals/cities";
            break;

        case "EXHIB":
            eventCode = "exhibitions";
            break;

        case "THINGS-TO-DO":
            eventCode = "things-to-do";
            break;

        default:
            eventCode = "whats-on";
            break;
    }

    let poiStr = "";
    if (poiName && poiID) {
        poiName = ucwords(poiName);
        poiStr = `/near/${poiName}/${poiID}/${seoUrl ? seoUrl : ""}`;
    }

    return `/${eventCode}/${city}${poiStr}/${seoUrl ? seoUrl : ""}`;
};

export const StringUnion = <UnionType extends string>(
    ...values: UnionType[]
) => {
    Object.freeze(values);
    const valueSet: Set<string> = new Set(values);

    const guard = (value: string): value is UnionType => {
        return valueSet.has(value);
    };

    const check = (value: string): UnionType => {
        if (!guard(value)) {
            const actual = JSON.stringify(value);
            const expected = values
                .map((val) => JSON.stringify(val))
                .join(" | ");
            throw new TypeError(
                `Value '${actual}' is not assignable to type '${expected}'`,
            );
        }
        return value;
    };

    const unionNamespace = { guard, check, values };
    return Object.freeze(
        unionNamespace as typeof unionNamespace & { type: UnionType },
    );
};

export const isLoggedIn = () => Number(cookies.get("hasJwt")) === 1;
