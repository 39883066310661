import React from "react";
import { Box, Typography } from "@material-ui/core";
import Divider from "../../divider";
import { useSelector } from "react-redux";
import { State } from "../../../store/types";
import { seoResult } from "../../../store/seo/selectors";
import { SEO } from "@skiddle/web.events.store.types";
import { SeoDataSelectState } from "../../seo/types";
import { formatText } from ".";
import { useLocation } from "react-router-dom";
import "./bottom.css";
import useStyles from "./styles";

const CityMetaBottom: React.FC = () => {
    const {
        seo: {
            text = "Search for Events, Clubs, gig guide, Club Nights, Theatre and more. Buy your tickets or get on the guestlist for free",
            h2,
        },
    } = useSelector<State, SeoDataSelectState>((state) => ({
        seo: seoResult(state) || ({} as SEO),
    }));
    const { pathname } = useLocation();
    const [formattedText, leftOverText] = formatText(text, pathname, 450);
    const classes = useStyles();

    return (
        <>
            <Divider />
            {h2 && (
                <Box style={{ padding: 16, paddingBottom: 0, paddingTop: 0 }}>
                    <Typography variant="h2" className="cityMetaH2">
                        {h2}
                    </Typography>
                </Box>
            )}
            <Box
                className="metaBottomBox"
                dangerouslySetInnerHTML={{
                    __html: `${formattedText}${
                        leftOverText ? leftOverText : ""
                    }`,
                }}
            />
            <Divider />
        </>
    );
};

export default CityMetaBottom;
