import React from "react";
import useCombinedHooks from "./hooks";
import GenresView from "./view";

export const defaultValue: number[] | string[] = [];
export const topGenresCount = 5;

const GenresFilter: React.FC = () => {
    const {
        open,
        toggleOpen,
        confirmGenres,
        clearGenres,
        topGenres,
        selectedGenres,
        setSelectedGenres,
        genres,
        genresDisplay,
        isActive,
    } = useCombinedHooks(defaultValue);

    return (
        <GenresView
            open={open}
            toggleOpen={toggleOpen}
            confirmGenres={confirmGenres}
            clearGenres={clearGenres}
            topGenres={topGenres}
            selectedGenres={selectedGenres}
            setSelectedGenres={setSelectedGenres}
            genres={genres}
            genresDisplay={genresDisplay}
            isActive={isActive}
        />
    );
};

export default GenresFilter;
