import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    options: {
        borderBottom: "1px solid #b1adad",
        padding: 5,
        cursor: "pointer",
        "&:last-of-type": {
            borderBottom: "none",
        },
    },
    optionSelected: {
        color: "white !important",
        background: `${theme.palette.primary.main} !important`,
    },
    chip: {
        margin: 5,
        borderRadius: "4px !important",
    },
    checkMark: {
        float: "right",
        verticalAlign: "text-bottom",
        paddingRight: 5,
    },
    countLabel: {
        marginRight: "5px",
        marginLeft: "-2px",
        minWidth: "15px",
        height: "15px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "inherit",
        textAlign: "center",
        alignItems: "center",
        alignContent: "center",
        fontSize: "0.65rem",
        padding: 3,
        background: "#60cac3",
        letterSpacing: "0.04rem",
    },
}));

export default useStyles;
