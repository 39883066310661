import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCitySEO, getPoiSEO } from "../../../store/seo/actions";
import { State } from "../../../store/types";
import { SelectState } from "../types";
import { SelectState as LocationSelectState } from "../../filters/location/types";
import { eventTypesFilter } from "@skiddle/web.events.store.filters";

export const useSelectorState = (): SelectState => {
    const dispatch = useDispatch();

    return useSelector<State, SelectState>(
        (state) => ({
            getCitySeo: getCitySEO(state, dispatch),
            getPoiSeo: getPoiSEO(state, dispatch),
            singleEventCodeSelected: (() => {
                const eventTypes = eventTypesFilter(state.filters);
                if (!eventTypes || eventTypes.length !== 1) {
                    return;
                }

                return eventTypes[0];
            })(),
        }),
        shallowEqual,
    );
};

export const useGetSeoData = (
    locationResult: LocationSelectState["locationResult"],
) => {
    const { getCitySeo, getPoiSeo, singleEventCodeSelected } =
        useSelectorState();

    return () => {
        if (locationResult) {
            const {
                cityCode,
                name,
                postcodeLookup,
                displayCategory,
                type,
                id,
            } = locationResult;
            if (type === "city") {
                getCitySeo(cityCode, name, singleEventCodeSelected);
            }

            if (type !== "city" && postcodeLookup) {
                getPoiSeo(id, name, postcodeLookup, displayCategory, type);
            }
        }
    };
};
