import "./imported";

// load/execute the rest after letting the browser kick off chunk loading
// for example wrapping it in two promises (1ms timeout or setImmediate)
Promise.resolve().then(() =>
    Promise.resolve().then(() => {
        // load the rest
        require("./ssr");
    }),
);
