import { getMomentWithTimezone } from "@skiddle/web.events.date-format";
const moment = getMomentWithTimezone();
const today = moment();
const tomorrow = moment().add(1, "day");
export const buttonPresets = [
    {
        text: "Today",
        start: today,
        end: today,
    },
    {
        text: "Tomorrow",
        start: tomorrow,
        end: tomorrow,
    },
    {
        text: "Next 7 days",
        start: today,
        end: moment().add(1, "week"),
    },
    {
        text: "This weekend",
        start: moment().endOf("isoWeek").subtract(2, "day"),
        end: moment().endOf("isoWeek"),
    },
    {
        text: "Next weekend",
        start: moment()
            .endOf("isoWeek")
            .add(1, "day")
            .endOf("isoWeek")
            .subtract(2, "day"),
        end: moment().endOf("isoWeek").add(1, "day").endOf("isoWeek"),
    },
];
