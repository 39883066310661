import { isMoment, Moment } from "moment";
import { useState } from "react";
import { FocusedInputShape } from "react-dates";
import { useQueryParam } from "use-query-params";
import { dateFormat, mysqlDateFormats } from "..";
import { useIsMobile, useToggleOpen } from "@skiddle/web.events.hooks";
import { UpdateMinMaxDatesAction } from "@skiddle/web.events.store.filters";
import { SelectorState } from "../types";
import {
    useOnFromDateQueryStringChange,
    useOnFromDateStoreUpdate,
    useOnMount,
    useOnOpenFilter,
    useOnToDateQueryStringChange,
    useOnToDateStoreUpdate,
} from "./effects";
import {
    useConfirmDates,
    useDatesChange,
    useShowAllDates,
} from "./event-handlers";
import { getMomentWithTimezone } from "@skiddle/web.events.date-format";

const moment = getMomentWithTimezone();

export const formatQueryDate = (date: string) =>
    moment(date).format(dateFormat);

const useCombinedHooks = (
    [minDate, maxDate]: Parameters<UpdateMinMaxDatesAction>,
    filterMinDate: SelectorState["filterMinDate"],
    filterMaxDate: SelectorState["filterMaxDate"],
    useUrlParams: boolean,
    startFocusByDefault: boolean = true,
) => {
    const [open, setOpen] = useState(false);
    const [fromDate, setFromDate] = useFromDate(minDate, filterMinDate);
    const [toDate, setToDate] = useToDate(maxDate, filterMaxDate);
    const [userChanged, setUserChanged] = useState<boolean>(false);
    const isActive = userChanged;

    const focusedInputParseParams: [string, string, [string, string]] = [
        fromDate?.format(mysqlDateFormats.from) || "",
        toDate?.format(mysqlDateFormats.to) || "",
        [minDate, maxDate],
    ];

    const focusedInput = useFocusedInput(
        ...focusedInputParseParams,
        startFocusByDefault,
    );
    const isMobile = useIsMobile();
    const datesDisplay = useDatesDisplay(filterMinDate, filterMaxDate);
    const toggleOpen = useToggleOpen(setOpen);
    const datesChange = useDatesChange(
        fromDate,
        setFromDate,
        toDate,
        setToDate,
        ...focusedInput,
    );
    const showAllDates = useShowAllDates(
        setFromDate,
        setToDate,
        focusedInput[1],
        setOpen,
        minDate,
        maxDate,
        useUrlParams,
        setUserChanged,
    );
    const confirmDates = useConfirmDates(
        fromDate,
        toDate,
        setOpen,
        setFromDate,
        setToDate,
        useUrlParams,
        setUserChanged,
    );

    useEffects(
        minDate,
        maxDate,
        setFromDate,
        setToDate,
        focusedInput[1],
        open,
        filterMinDate,
        filterMaxDate,
        useUrlParams,
    );

    return {
        open,
        fromDate,
        toDate,
        focusedInput,
        isMobile,
        datesDisplay,
        toggleOpen,
        datesChange,
        showAllDates,
        confirmDates,
        isActive,
    };
};

export const useFromDateQueryParam = (): [string, (value: string) => void] => {
    const [fromDate, setFromDate] = useQueryParam<string>("from_date");

    const setFormattedFromDate = (value: string) => {
        const formattedDate = formatQueryDate(value);
        setFromDate(formattedDate);
    };

    if (fromDate) {
        const formattedDate = moment(fromDate, dateFormat).format(
            mysqlDateFormats.from,
        );
        return [formattedDate, setFormattedFromDate];
    }
    return [fromDate, setFormattedFromDate];
};
export const useToDateQueryParam = (): [
    string | null,
    (value: string | null) => void,
] => {
    const [toDate, setToDate] = useQueryParam<string | null>("to_date");

    const setFormattedToDate = (value: string | null) => {
        if (!value) {
            setToDate(value);
            return;
        }

        const formattedDate = formatQueryDate(value);
        setToDate(formattedDate);
    };

    if (toDate) {
        const formattedDate = moment(toDate, dateFormat).format(
            mysqlDateFormats.to,
        );
        return [formattedDate, setFormattedToDate];
    }
    return [toDate, setFormattedToDate];
};

const useFromDate = (
    minDate: Parameters<UpdateMinMaxDatesAction>[0],
    filterMinDate: SelectorState["filterMinDate"],
): [Moment | null, React.Dispatch<React.SetStateAction<Moment | null>>] => {
    const [fromDate, setFromDate] = useState<Moment | null>(() => {
        if (isMoment(filterMinDate)) {
            return filterMinDate;
        }

        return moment(filterMinDate || minDate);
    });

    return [fromDate, setFromDate];
};

const useToDate = (
    maxDate: Parameters<UpdateMinMaxDatesAction>[1],
    filterMaxDate: SelectorState["filterMaxDate"],
): [Moment | null, React.Dispatch<React.SetStateAction<Moment | null>>] => {
    const [toDate, setToDate] = useState<Moment | null>(() => {
        if (maxDate !== "" && maxDate !== undefined) {
            return moment(maxDate);
        }

        if (!filterMaxDate) {
            return null;
        }
        return moment(filterMaxDate);
    });

    return [toDate, setToDate];
};

const useEffects = (
    minDate: string,
    maxDate: string,
    setMinDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
    setMaxDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>,
    setFocusedInput: React.Dispatch<
        React.SetStateAction<FocusedInputShape | null>
    >,
    open: boolean,
    filterMinDate: SelectorState["filterMinDate"],
    filterMaxDate: SelectorState["filterMaxDate"],
    useUrlParams: boolean,
) => {
    useOnMount(
        minDate,
        maxDate,
        setFocusedInput,
        filterMinDate,
        filterMaxDate,
        useUrlParams,
    );
    useOnFromDateStoreUpdate(minDate, setMinDate, filterMinDate, useUrlParams);
    useOnToDateStoreUpdate(maxDate, setMaxDate, filterMaxDate, useUrlParams);
    useOnOpenFilter(
        open,
        setMinDate,
        setMaxDate,
        setFocusedInput,
        filterMaxDate,
    );
    useOnFromDateQueryStringChange(
        setMinDate,
        minDate,
        filterMaxDate,
        useUrlParams,
    );
    useOnToDateQueryStringChange(setMaxDate, filterMaxDate, useUrlParams);
};

const useDatesDisplay =
    (
        filterMinDate: SelectorState["filterMinDate"],
        filterMaxDate: SelectorState["filterMaxDate"],
    ) =>
    () => {
        const formattedFromDate = moment(filterMinDate).format(dateFormat);

        if (!formattedFromDate) {
            return "Dates";
        }

        if (filterMaxDate === "" || !filterMaxDate) {
            return `${formattedFromDate} onwards`;
        }

        const formattedToDate = moment(filterMaxDate).format(dateFormat);
        return `${formattedFromDate} - ${formattedToDate}`;
    };

export const focusedInputParse = (
    fromDate: string | null,
    toDate: string | null,
    [minDate, maxDate]: Parameters<UpdateMinMaxDatesAction>,
) => {
    if (fromDate !== minDate && toDate !== maxDate) {
        return "endDate";
    }

    if (fromDate !== minDate) {
        return "endDate";
    }

    return "startDate";
};

const useFocusedInput = (
    fromDate: string | null,
    toDate: string | null,
    defaultVals: Parameters<UpdateMinMaxDatesAction>,
    startFocusByDefault: boolean,
) => {
    return useState<FocusedInputShape | null>(() => {
        if (!startFocusByDefault) {
            return null;
        }

        return focusedInputParse(fromDate, toDate, defaultVals);
    });
};

export default useCombinedHooks;
