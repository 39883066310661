import React from "react";
import { OtherFilters as OtherFiltersType } from "../../../store/filters/types";
import useCombinedHooks from "./hooks";
import { StoreFilterKeysToOptions } from "./types";
import OtherFilterView from "./view";

export const options = [
    "Show only events with tickets available",
    "Hide cancelled events",
    "under 18's allowed",
];

export const storeFilterKeysToOptions: StoreFilterKeysToOptions = {
    ticketsAvailable: options[0],
    hideCancelled: options[1],
    under18: options[2],
};

export const defaultValue = options[1];
export const defaultValues: OtherFiltersType = {
    ticketsAvailable: defaultValue === options[0] ? 1 : 0,
    hideCancelled: defaultValue === options[1] ? 1 : 0,
    under18: defaultValue === options[3] ? "true" : null,
};

const OtherFilters: React.FC = () => {
    const {
        open,
        confirmOtherFilter,
        toggleOpen,
        onClear,
        selected,
        setSelected,
        buttonDisplay,
        isActive,
    } = useCombinedHooks(defaultValue);

    return (
        <OtherFilterView
            open={open}
            confirmOtherFilter={confirmOtherFilter}
            toggleOpen={toggleOpen}
            onClear={onClear}
            options={options}
            selected={selected}
            setSelected={setSelected}
            buttonDisplay={buttonDisplay}
            isActive={isActive}
        />
    );
};

export default OtherFilters;
