import React from "react";
import { hydrate, render } from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store, { initialState as emptyInitialState } from "./store";
import { State } from "./store/types";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "./theme";
import "core-js/stable"; // This replaces babel polyfill as of babel@7.4.0
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import { QueryParamProvider } from "use-query-params";
import { ImportedController, rehydrateMarks } from "react-imported-component";
import ScrollToTop from "./scrollToTop";
import { LocationErrorBoundary } from "@skiddle/web.events.filters.location";
import UAParser from "ua-parser-js";

const initialState: State =
    typeof window !== "undefined"
        ? window.__PRELOADED_STATE__
        : emptyInitialState;

const Main: React.FC = () => (
    <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
            <Provider store={store(initialState)}>
                <ThemeProvider theme={theme}>
                    <LocationErrorBoundary>
                        <>
                            <CssBaseline />
                            <ScrollToTop />
                            <ImportedController>
                                <App />
                            </ImportedController>
                        </>
                    </LocationErrorBoundary>
                </ThemeProvider>
            </Provider>
        </QueryParamProvider>
    </Router>
);

const renderNewOrHydrate = () => {
    const userAgent = new UAParser(navigator.userAgent);
    const deviceType = userAgent.getDevice().type || "desktop";
    if (deviceType === "mobile" && Number(window.outerWidth) > 600) {
        return rehydrateMarks().then(() => {
            render(<Main />, document.getElementById("root"), () => {
                const jssStyles = document.querySelector("#jss-server-side");
                if (jssStyles && jssStyles.parentElement) {
                    jssStyles.parentElement.removeChild(jssStyles);
                }
            });
        });
    }

    rehydrateMarks().then(() => {
        hydrate(<Main />, document.getElementById("root"), () => {
            const jssStyles = document.querySelector("#jss-server-side");
            if (jssStyles && jssStyles.parentElement) {
                jssStyles.parentElement.removeChild(jssStyles);
            }
        });
    });
};

renderNewOrHydrate();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
