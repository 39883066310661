import {
    SEOFetch,
    SEOActions,
    SEOFetchController,
    SEOFetchAbort,
    GetPoiSEO,
    GetCitySEO,
    SEOFetchSuccess,
} from "./types";
import { Dispatch } from "redux";
import { State } from "../types";
import { seoIsFetching } from "./selectors";
import "isomorphic-fetch";
import {
    apiError,
    APIV3Response,
    SEO,
    SEOClear,
} from "@skiddle/web.events.store.types";

export const seoFetch = (): SEOFetch => ({
    type: "SEO_FETCH",
});

export const seoFetchSuccess = (result: SEO): SEOFetchSuccess => ({
    type: "SEO_FETCH_SUCCESS",
    result,
});

export const seoFetchController = (
    controller: AbortController,
): SEOFetchController => ({
    type: "SEO_FETCH_CONTROLLER",
    controller,
});

export const seoFetchAbort = (): SEOFetchAbort => ({
    type: "SEO_FETCH_ABORT",
});

export const seoClear = (): SEOClear => ({
    type: "SEO_CLEAR",
});

export const getCitySEO: GetCitySEO =
    (state: State, dispatch: Dispatch<SEOActions>) =>
    async (cityCode: string, cityName: string, eventCode?: string) => {
        if (seoIsFetching(state)) {
            return;
        }

        dispatch(seoFetch());

        try {
            const controller = new AbortController();
            dispatch(seoFetchController(controller));

            const results = await fetchCitySEO(
                controller,
                cityCode,
                cityName,
                eventCode,
            );

            if (results) {
                const response = await fetch(results.landingImage || "");
                if (response.status === 403) {
                    results.landingImage =
                        "https://d1plawd8huk6hh.cloudfront.net/landing-pages/backgrounds/live.jpg";
                }
                dispatch(seoFetchSuccess(results));
            }
        } catch (err) {
            console.log(err);
            if ((err as Error).name === "AbortError") {
                dispatch(seoFetchAbort());
                return;
            }

            dispatch(apiError(err as string));
        }
    };

export const getPoiSEO: GetPoiSEO =
    (state: State, dispatch: Dispatch<SEOActions>) =>
    async (
        poiID: string,
        name: string,
        postcodeLookup: string,
        displayCategory: string,
        type: string,
    ) => {
        if (seoIsFetching(state)) {
            return;
        }

        dispatch(seoFetch());

        try {
            const controller = new AbortController();
            dispatch(seoFetchController(controller));

            const results = await fetchPoiSEO(
                controller,
                poiID,
                name,
                postcodeLookup,
                displayCategory,
                type,
            );

            if (results) {
                dispatch(seoFetchSuccess(results));
            }
        } catch (err) {
            console.log(err);
            if ((err as Error).name === "AbortError") {
                dispatch(seoFetchAbort());
                return;
            }

            dispatch(apiError(err as string));
        }
    };

export const fetchCitySEO = async (
    controller: AbortController,
    cityCode: string,
    cityName: string,
    eventCode?: string,
) => {
    try {
        const apiURL = process.env.REACT_APP_V3_API_URL;

        const params = Object.entries({
            cityName,
            eventCode,
            byCityName: "1",
        }).filter(([, val]) => !!val) as string[][];
        const searchParams = new URLSearchParams(params);

        const resp = await fetch(
            `${apiURL}/tools/seo/city/${cityCode}?${searchParams}`,
            {
                method: "GET",
                mode: "cors",
                signal: controller.signal,
            },
        );

        const { data, status, title, detail, request_id }: APIV3Response<SEO> =
            await resp.json();
        if (status && title && detail) {
            throw new Error(
                `${status}: ${title}: ${detail}.\n requestID: ${request_id}`,
            );
        }

        return data;
    } catch (err) {
        throw err;
    }
};

export const fetchPoiSEO = async (
    controller: AbortController,
    poiID: string,
    name: string,
    postcodeLookup: string,
    displayCategory: string,
    type: string,
) => {
    poiID = poiID.replace(/poi-/, "");

    try {
        const apiURL = process.env.REACT_APP_V3_API_URL;

        const searchParams = new URLSearchParams(
            Object.entries({
                name,
                postcodeLookup,
                displayCategory,
                type,
            }) as string[][],
        );
        const resp = await fetch(
            `${apiURL}/tools/seo/poi/${poiID}?${searchParams}`,
            {
                method: "GET",
                mode: "cors",
                signal: controller.signal,
            },
        );

        const { data, status, title, detail, request_id }: APIV3Response<SEO> =
            await resp.json();
        if (status && title && detail) {
            throw new Error(
                `${status}: ${title}: ${detail}.\n requestID: ${request_id}`,
            );
        }

        return data;
    } catch (err) {
        throw err;
    }
};

export const fetchCitySEODate = async (
    controller: AbortController,
    cityCode: string,
    cityName: string,
    date: string,
) => {
    try {
        const apiURL = process.env.REACT_APP_V3_API_URL;

        const params = Object.entries({
            cityName,
            byCityName: "1",
        }).filter(([, val]) => !!val) as string[][];
        const searchParams = new URLSearchParams(params);

        const resp = await fetch(
            `${apiURL}/tools/seo/city/${cityCode}/${date}?${searchParams}`,
            {
                method: "GET",
                mode: "cors",
                signal: controller.signal,
            },
        );

        const { data, status, title, detail, request_id }: APIV3Response<SEO> =
            await resp.json();
        if (status && title && detail) {
            throw new Error(
                `${status}: ${title}: ${detail}.\n requestID: ${request_id}`,
            );
        }

        return data;
    } catch (err) {
        throw err;
    }
};
