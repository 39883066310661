// import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import React from "react";
import useStyles from "./styles";
import {Props} from "./types"
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import skeletonTheme from "../../theme";

const Artist: React.FC<Props> = ({size}) => {
    const classes = useStyles();
    let style;

    switch (size) {
        case 'small' || 'sm':
            style = classes.iconSmall;
            break;
        case 'medium' || 'md':
            style = classes.iconMedium;
            break;
        case 'large' || 'lg':
            style = classes.iconLarge;
            break;
        default:
            style = classes.iconMedium;
    }

    return (
        <SkeletonTheme {...skeletonTheme}>
        <div className={classes.containerStyle}>
            <Skeleton height={10} width={10} circle={true}/>

            <Skeleton height={10} width={40} style={{marginLeft: '5px'}}/>
        </div>
        </SkeletonTheme>
    )
};

export default Artist;

