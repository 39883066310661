import { useDispatch } from "react-redux";
import { useSortQueryParam } from ".";
import { updateOrderBy } from "@skiddle/web.events.store.filters";
import { OrderOptions, SelectState } from "../types";

export const useSelectOption = (
    orderOptions: OrderOptions,
    selected: string,
    toggleOpen: () => void,
    orderBy: SelectState["orderBy"],
    useUrlParams: boolean,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const dispatch = useDispatch();
    const [, setSort] = useSortQueryParam();

    return () => {
        const selectedOption = orderOptions[selected];
        if (orderBy !== selectedOption) {
            if (useUrlParams) {
                setSort(selectedOption);
            }
            dispatch(updateOrderBy(selectedOption));
            setUserChanged(true);
        }
        toggleOpen();
    };
};
