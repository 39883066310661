import { Reducer } from "redux";
import {
    EventsSearchState,
    EventsSearchActions,
    CategoryFetchesController,
    CategoryResults,
} from "./types";
import { Category } from "../../../components/results/types";
import { EventsSearchResult } from "@skiddle/web.events.shared-types";

export const initialState: EventsSearchState = {
    fetching: {},
    results: {},
    fetch: {},
    totalCounts: {},
};

const deleteFetchController = (
    category: string,
    fetch: CategoryFetchesController,
): CategoryFetchesController => {
    const newFetch = { ...fetch };
    delete newFetch[category];

    return { ...newFetch };
};

const fetchingBatchedCategories = (categories: string[], fetching: boolean) =>
    categories.reduce(
        (fetchingCats, cat) => ({
            ...fetchingCats,
            [cat]: fetching,
        }),
        {},
    );

// Assign the batched results to the categories by specified filter (event codes)
export const assignResultsToCategory = (
    categories: Category[],
    results: EventsSearchResult[],
) =>
    categories.reduce<CategoryResults>((catResults, cat) => {
        catResults[cat.title] = results.filter(
            ({ EventCode, specialFeatured }) =>
                cat.filter?.includes(EventCode) ||
                (cat.promote && specialFeatured === "1"),
        );

        return catResults;
    }, {});

const reducer: Reducer<EventsSearchState, EventsSearchActions> = (
    state = initialState,
    action,
) => {
    switch (action.type) {
        case "EVENTS_CATEGORY_SEARCH_FETCH":
            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    [action.category]: true,
                },
            };

        case "EVENTS_CATEGORY_SEARCH_APPEND_FETCH":
            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    [action.category]: true,
                },
            };

        case "EVENTS_CATEGORY_SEARCH_APPEND_FETCH_SUCCESS":
            const fetch = deleteFetchController(action.category, state.fetch);

            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    [action.category]: false,
                },
                results: {
                    ...state.results,
                    [action.category]: [
                        ...state.results[action.category],
                        ...action.results,
                    ],
                },
                fetch,
            };

        case "EVENTS_CATEGORY_SEARCH_FETCH_CONTROLLER":
            return {
                ...state,
                fetch: {
                    ...state.fetch,
                    [action.category]: action.controller,
                },
            };

        case "EVENTS_CATEGORY_SEARCH_FETCH_SUCCESS": {
            const fetch = deleteFetchController(action.category, state.fetch);

            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    [action.category]: false,
                },
                results: {
                    ...state.results,
                    [action.category]: action.results,
                },
                fetch,
                totalCounts: {
                    ...state.totalCounts,
                    [action.category]: action.totalCount,
                },
            };
        }

        case "EVENTS_CATEGORY_SEARCH_FETCH_ABORT": {
            const fetch = deleteFetchController(action.category, state.fetch);

            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    [action.category]: false,
                },
                fetch,
            };
        }

        case "EVENTS_BATCH_CATEGORY_SEARCH_FETCH":
            return {
                ...state,
                fetching: {
                    ...state.fetching,
                    ...fetchingBatchedCategories(
                        action.batchedCategories,
                        true,
                    ),
                },
            };

        case "EVENTS_BATCH_CATEGORY_SEARCH_FETCH_SUCCESS":
            const fetchingState = {
                ...state.fetching,
                ...fetchingBatchedCategories(
                    action.batchedCategories.map(({ title }) => title),
                    false,
                ),
            };

            return {
                ...state,
                fetching: fetchingState,
                results: {
                    ...state.results,
                    ...assignResultsToCategory(
                        action.batchedCategories,
                        action.results,
                    ),
                },
                batchFetchComplete: Object.values(fetchingState).every(
                    (fetching) => !fetching,
                ),
            };

        case "EVENTS_SEARCH_CLEAR":
            return initialState;

        default:
            return state;
    }
};

export default reducer;
