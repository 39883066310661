
    /* eslint-disable */
    /* tslint:disable */
    
    // generated by react-imported-component, DO NOT EDIT     
    import {assignImportedComponents} from 'react-imported-component/macro';
    undefined    
    
    // all your imports are defined here
    // all, even the ones you tried to hide in comments (that's the cost of making a very fast parser)
    // to remove any import from here
    // 1) use magic comment `import(/* client-side */ './myFile')` - and it will disappear
    // 2) use file filter to ignore specific locations (refer to the README - https://github.com/theKashey/react-imported-component/#server-side-auto-import)
    // 3) use .imported.js to control this table generation (refer to the README - https://github.com/theKashey/react-imported-component/#-imported-js)
    
    const applicationImports = assignImportedComponents([
      [() => import('./components/filters/dates/view/DateRangePicker'), '', './src/components/filters/dates/view/DateRangePicker', false] /* from ./src/components/filters/dates/view/dialog.tsx */,
      [() => import('./components/results/card/DesktopCard'), '', './src/components/results/card/DesktopCard', false] /* from ./src/components/results/card/Category/view/index.tsx */,
      [() => import('./components/results/card/DesktopHorizontalCard'), '', './src/components/results/card/DesktopHorizontalCard', false] /* from ./src/components/LandingPage/LandingCategory/index.tsx */,
      [() => import('./components/results/card/MobileCard'), '', './src/components/results/card/MobileCard', false] /* from ./src/components/results/card/Category/view/index.tsx */,
      [() => import('./components/results/card/MobileHorizontalCard'), '', './src/components/results/card/MobileHorizontalCard', false] /* from ./src/components/LandingPage/LandingCategory/index.tsx */,
      [() => import('@brainhubeu/react-carousel'), '', '@brainhubeu/react-carousel', false] /* from ./src/components/results/card/Category/view/horizontal.tsx */,
    ]);
    
    export default applicationImports;
    
    // @ts-ignore
    if (module.hot) {
       // these imports would make this module a parent for the imported modules.
       // but this is just a helper - so ignore(and accept!) all updates
       
       // @ts-ignore
       module.hot.accept(() => null);
    }    
    