import { useDispatch } from "react-redux";
import { useKeywordQueryParam } from ".";
import { updateKeyword } from "@skiddle/web.events.store.filters";

export const useSearchChange =
    (setKeyword: React.Dispatch<React.SetStateAction<string>>) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setKeyword(value);
    };

export const useConfirmKeyword = (
    keyword: string,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
    useUrlParams: boolean,
) => {
    const dispatch = useDispatch();
    const [, setKeyword] = useKeywordQueryParam();
    return () => {
        dispatch(updateKeyword(keyword));

        if (useUrlParams) {
            setKeyword(keyword);
        }

        setUserChanged(true);
        setOpen(false);
    };
};

export const useClearKeyword =
    (
        defaultValue: string,
        setKeyword: React.Dispatch<React.SetStateAction<string>>,
        setUserChanged: React.Dispatch<React.SetStateAction<boolean>>,
    ) =>
    () => {
        setKeyword(defaultValue);
    };
