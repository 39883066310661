import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(() => ({
    containerStyle: {
        position: "absolute",
        left: "10px",
        top: "10px",
        zIndex: 2
    }
}));

export default useStyles;
