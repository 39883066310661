import { State } from "../types";
import { Genre } from "./types";

export const genreIsFetching = (state: State): boolean =>
    state.genres.fetching.genres;

export const genreAggregationIsFetching = (state: State): boolean =>
    state.genres.fetching.aggregation;

export const genresResults = (state: State): Genre[] =>
    state.genres.results.map<Genre>(({ genreid, ...props }) => {
        return {
            genreid,
            ...props,
            eventCount: genreEventsCount(state, Number(genreid)),
        };
    });

export const genreEventsCount = (state: State, genreID: number): number =>
    state.aggregations.genreids && state.aggregations.genreids[genreID]
        ? Number(state.aggregations.genreids[genreID]) : 0;
