import makeStyles from "@material-ui/core/styles/makeStyles";
import { StyleProps } from "./types";

const useStyles = makeStyles({
    spacer: {
        flexGrow: ({ fill }: StyleProps) => (fill ? 1 : 0),
    },
});

export default useStyles;
