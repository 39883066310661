import React, { useEffect } from "react";
import SkeletonCategory from "../../../../skeleton-templates/Category";
import SkeletonCards from "../../../../skeleton-templates/Cards";
import Grid from "@material-ui/core/Grid";
import Card from "../../index";
import HorizontalView from "./horizontal";
import { CategoryViewProps as Props } from "../types";
import Typography from "@material-ui/core/Typography";
import { NoSsr } from "@material-ui/core";
import useStyles from "../styles";
import importedComponent from "react-imported-component";
import SeeMoreButton from "../../../../landing/SeeMoreButton";
import { defaultCategories as eventCategories } from "../../../index";
import Divider from "../../../../divider";
import ShowMoreButton from "../../ShowMoreButton";
import { useIsMobile } from "@skiddle/web.events.hooks";

// Card components are code split / loaded here so they load while the events are fetched
const MobileCard = importedComponent(() => import("../../MobileCard"));
const DesktopCard = importedComponent(() => import("../../DesktopCard"));
const MobileHorizontalCard = importedComponent(
    () => import("../../MobileHorizontalCard"),
);
const DesktopHorizontalCard = importedComponent(
    () => import("../../DesktopHorizontalCard"),
);

const CategoryView: React.FC<Props> = ({
    title,
    preloading,
    appendEvents,
    eventCount,
    promote,
    events,
    searchCategoryEvents,
    cardsToShow,
    showMore,
    scrollTop,
    setScrollTop,
    landing,
}) => {
    const classes = useStyles();
    const matchMobile = useIsMobile();

    // Get event category and then if only one filter use the category title for event filters
    const eventCats = eventCategories.find(
        (eventFilter) => eventFilter.title === title,
    );
    let eventFilters: string[] | undefined = eventCats?.filter;
    if (
        eventFilters &&
        eventFilters.length === 1 &&
        eventCats &&
        eventCats.title
    ) {
        eventFilters = [eventCats.title];
    }
    const eventGroup = eventCategories.find(
        (eventFilter) => eventFilter.title === title,
    )?.group;

    useEffect(() => {
        if (matchMobile) {
            if (promote) {
                MobileHorizontalCard.preload();
            }
            MobileCard.preload();
            return;
        }

        if (!matchMobile) {
            if (promote) {
                DesktopHorizontalCard.preload();
            }
            DesktopCard.preload();
        }
    }, [matchMobile, promote]);

    if (preloading && !appendEvents) {
        return (
            <NoSsr>
                <SkeletonCategory matchMobile={matchMobile} promote={promote} />
            </NoSsr>
        );
    }

    // skip category if empty
    if (eventCount < 1) {
        return null;
    }

    // Featured events
    if (promote) {
        return (
            <HorizontalView
                component={
                    matchMobile ? MobileHorizontalCard : DesktopHorizontalCard
                }
                eventCount={eventCount}
                events={events}
                matchMobile={matchMobile}
                title={title}
                cardsToShow={cardsToShow}
                featured={promote}
                landing={landing}
            />
        );
    }

    const titleStyle = matchMobile
        ? {
              style: {
                  fontSize: "2.8rem",
                  paddingTop: "1rem",
                  paddingBottom: "0.5rem",
              },
          }
        : {};

    // Horizontal event cards
    if (landing && matchMobile) {
        return (
            <HorizontalView
                component={MobileHorizontalCard}
                eventCount={eventCount}
                events={events}
                matchMobile={matchMobile}
                title={title}
                cardsToShow={cardsToShow}
                featured={promote}
                landing={landing}
            />
        );
    }

    // Standard events
    return (
        <div>
            {landing && events.length > 0 && !eventGroup && !promote && (
                <Divider />
            )}

            {/* Standard cards */}
            {!matchMobile && (
                <Typography variant="h2" {...titleStyle}>
                    {title}
                </Typography>
            )}

            <Grid
                container
                spacing={2}
                style={matchMobile ? { paddingTop: 5 } : {}}
            >
                {events.map((result, index) => {
                    if (index + 1 > cardsToShow) {
                        return null;
                    }

                    return (
                        <Grid item key={index} xs={12} sm={4} md={3}>
                            <Card
                                component={
                                    matchMobile ? MobileCard : DesktopCard
                                }
                                {...result}
                            />
                        </Grid>
                    );
                })}

                {preloading && appendEvents && (
                    <NoSsr>
                        <SkeletonCards
                            cards={8}
                            matchMobile={matchMobile}
                            scrollTop={scrollTop}
                            setScrollTop={setScrollTop}
                        />
                    </NoSsr>
                )}

                {showMore && !landing && (
                    <ShowMoreButton
                        title={title}
                        onClick={() => {
                            searchCategoryEvents(true, window.scrollY);
                        }}
                    />
                )}

                {!promote && landing && (
                    <SeeMoreButton
                        eventCodes={!eventFilters ? [title] : eventFilters}
                    />
                )}
            </Grid>

            {landing && events.length > 0 && !eventGroup && promote && (
                <Divider />
            )}
        </div>
    );
};

export default CategoryView;
