import React, { forwardRef } from "react";
import { TextField } from "@material-ui/core";
import { KeywordSearchInputProps as Props } from "../types";

const KeywordSearchInput = forwardRef<HTMLInputElement, Props>(
    ({ searchChange, keyword, handleKeyPress, classes }, inputRef) => (
        <TextField
            name="keyword_search"
            onChange={searchChange}
            value={keyword}
            fullWidth
            placeholder="Artist / Venue / Keyword"
            onKeyPress={(event) => handleKeyPress(event)}
            inputRef={inputRef}
            InputProps={{
                disableUnderline: true,
                className: classes.inputRoot,
                classes: { input: classes.input },
            }}
        />
    ),
);

export default KeywordSearchInput;
