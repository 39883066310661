import {
    EventsSearchFetchSuccess,
    EventsSearchActions,
    SearchEvents,
    EventsCategorySearchFetch,
    EventCategorySearchFetchController,
    EventsCategorySearchFetchAbort,
    EventsCategorySearchAppendFetchSuccess,
    EventsCategorySearchAppendFetch,
    ClearEventsSearch,
} from "./types";
import { State, APIResponse } from "../../types";
import { Dispatch } from "redux";
import { eventsSearchIsFetching } from "./selector";
import "isomorphic-fetch";
import { filtersForSearchAPI } from "../../filters/selector";
import { updateAggregations } from "../../aggregations/actions";
import debug from "../../../debug";
import {
    EventsSearchParams,
    EventsSearchResult,
} from "@skiddle/web.events.shared-types";
import { Aggregations } from "@skiddle/web.events.shared-types";
import {
    apiError,
    EventsCategorySearchFetchSuccess,
} from "@skiddle/web.events.store.types";

const filterLog = debug("filters");

export const eventsCategorySearchFetch = (
    category: string,
): EventsCategorySearchFetch => ({
    type: "EVENTS_CATEGORY_SEARCH_FETCH",
    category,
});

export const eventsCategorySearchAppendFetch = (
    category: string,
): EventsCategorySearchAppendFetch => ({
    type: "EVENTS_CATEGORY_SEARCH_APPEND_FETCH",
    category,
});

export const eventsCategorySearchAppendFetchSuccess = (
    category: string,
    results: EventsSearchResult[],
    aggregations: Aggregations,
): EventsCategorySearchAppendFetchSuccess => ({
    type: "EVENTS_CATEGORY_SEARCH_APPEND_FETCH_SUCCESS",
    category,
    aggregations,
    results,
});

export const eventsSearchFetchSuccess = (
    results: EventsSearchResult[],
): EventsSearchFetchSuccess => ({
    type: "EVENTS_SEARCH_FETCH_SUCCESS",
    results,
});

export const eventsCategoryFetchSuccess = (
    category: string,
    results: EventsSearchResult[],
    totalCount: number,
): EventsCategorySearchFetchSuccess => ({
    type: "EVENTS_CATEGORY_SEARCH_FETCH_SUCCESS",
    category,
    results,
    totalCount,
});

export const eventsCategorySearchFetchController = (
    category: string,
    controller: AbortController,
): EventCategorySearchFetchController => ({
    type: "EVENTS_CATEGORY_SEARCH_FETCH_CONTROLLER",
    category,
    controller,
});

export const eventsCategorySearchFetchAbort = (
    category: string,
): EventsCategorySearchFetchAbort => ({
    type: "EVENTS_CATEGORY_SEARCH_FETCH_ABORT",
    category,
});

export const clearEvents = (): ClearEventsSearch => ({
    type: "EVENTS_SEARCH_CLEAR",
});

export const searchEvents: SearchEvents =
    (state: State, dispatch: Dispatch<EventsSearchActions>) =>
    async (category: string, searchParams?: EventsSearchParams) => {
        if (eventsSearchIsFetching(state, category)) {
            return;
        }

        dispatch(eventsCategorySearchFetch(category));

        try {
            const filters = filtersForSearchAPI(state.filters);

            filterLog({ filters });

            const controller = new AbortController();
            dispatch(eventsCategorySearchFetchController(category, controller));

            const { results, aggregations, totalCount } = await fetchEvents(
                controller,
                {
                    ...searchParams,
                    ...filters,
                },
            );

            dispatch(
                eventsCategoryFetchSuccess(
                    category,
                    results,
                    Number(totalCount),
                ),
            );

            dispatch(
                updateAggregations({
                    eventcode: aggregations.eventcode ?? {},
                    genreids: aggregations.genreids ?? {},
                }),
            );
        } catch (err) {
            console.log(err);
            if ((err as Error).name === "AbortError") {
                dispatch(eventsCategorySearchFetchAbort(category));
                return;
            }

            dispatch(apiError(err as string));
        }
    };

export const searchEventsAppend: SearchEvents =
    (state: State, dispatch: Dispatch<EventsSearchActions>) =>
    async (category: string, searchParams?: EventsSearchParams) => {
        if (eventsSearchIsFetching(state, category)) {
            return;
        }

        dispatch(eventsCategorySearchAppendFetch(category));

        try {
            const filters = filtersForSearchAPI(state.filters);

            const controller = new AbortController();
            dispatch(eventsCategorySearchFetchController(category, controller));

            const { results, aggregations } = await fetchEvents(controller, {
                ...searchParams,
                ...filters,
            });

            dispatch(
                updateAggregations({
                    eventcode: aggregations.eventcode ?? {},
                    genreids: aggregations.genreids ?? {},
                }),
            );

            dispatch(
                eventsCategorySearchAppendFetchSuccess(
                    category,
                    results,
                    aggregations,
                ),
            );
        } catch (err) {
            console.log(err);
            if ((err as Error).name === "AbortError") {
                dispatch(eventsCategorySearchFetchAbort(category));
                return;
            }

            dispatch(apiError(err as string));
        }
    };

export const fetchEvents = async (
    controller: AbortController,
    searchParams: EventsSearchParams = {
        limit: 15,
    },
) => {
    // Need to always have description set to 1 and we always need aggrigations of genre and event types
    // searchParams.description = "1"; //not needed anymore, creates bulky responses
    searchParams.artistmeta = 1;
    searchParams.artistmetalimit = 3;
    searchParams.aggs = "genreids,eventcode";

    try {
        const apiURL = process.env.REACT_APP_API_URL;
        const publicKey = process.env.REACT_APP_PUB_KEY;

        // Remove event code if empty array
        if (searchParams?.eventcode?.length === 0) {
            delete searchParams.eventcode;
        }

        // Create query params
        const data = new URLSearchParams(
            Object.entries({
                ...(searchParams as { [key: string]: string }),
                pub_key: publicKey,
                platform: "web",
                collapse: "uniquelistingidentifier",
            }) as string[][],
        );

        const resp = await fetch(`${apiURL}/v1/events/search/?${data}`, {
            method: "GET",
            mode: "cors",
            signal: controller.signal,
        });

        const {
            error,
            errormessage,
            results,
            aggregations = {},
            totalcount,
        }: APIResponse<EventsSearchResult[]> = await resp.json();

        if (error && errormessage) {
            throw new Error(errormessage);
        }

        return {
            results,
            aggregations,
            totalCount: totalcount,
        };
    } catch (err) {
        throw err;
    }
};
