// import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import React from "react";
import useStyles from "./styles";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import skeletonTheme from "../theme";

const SkeletonStatus: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.containerStyle}>
            <SkeletonTheme {...skeletonTheme}>
                <Skeleton width={20} height={20}/>
            </SkeletonTheme>
        </div>
    )
};

export default SkeletonStatus;

