import React, { FC } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { State } from "../../store/types";
import {
    LocationStateSelector as StateSelector,
    LandingPageProps,
} from "./types";
import { useParams } from "react-router-dom";
import { locationResult } from "../../store/location/selectors";
import LandingFilters from "../landing/Filters";
import LandingCategory from "./LandingCategory";
import LazyHydrate from "react-lazy-hydration";
import JumpTo from "../results/card/JumpTo";
import { pathPrefix } from "../../App";
import { LocationUrlParams } from "../filters/location/types";

const LandingPage: FC<LandingPageProps> = () => {
    const { location } = useParams<LocationUrlParams>();
    const { locationInfo } = useSelector<State, StateSelector>(
        (state) => ({
            locationInfo: locationResult(state.location),
        }),
        shallowEqual,
    );
    const jumpToCats = [
        {
            id: "category-Clubs",
            originalTitle: "Clubs",
            title: "Clubs",
        },
        {
            id: "category-Gigs",
            originalTitle: "Gigs",
            title: "Gigs",
        },
        {
            id: "category-Festivals",
            originalTitle: "Festivals",
            title: "Festivals",
        },
        {
            id: "category-Comedy, Theatre & Arts",
            originalTitle: "Comedy, Theatre & Arts",
            title: "Comedy, Theatre & Arts",
        },
        {
            id: "category-Food & Drink",
            originalTitle: "Food & Drink",
            title: "Food & Drink",
        },
        {
            id: "category-Experiences & Attractions",
            originalTitle: "Experiences & Attractions",
            title: "Experiences & Attractions",
        },
        {
            id: "",
            title: "All Events",
            originalTitle: "All Events",
            href: `${pathPrefix}/events/${
                location !== "" && location ? location : "all"
            }/`,
        },
    ];
    return (
        <div>
            <LandingFilters />
            <JumpTo titles={jumpToCats} />
            <LandingCategory
                location={locationInfo}
                categoryEventCode="CLUB"
                title="Clubs"
            />
            <LazyHydrate whenVisible>
                <LandingCategory
                    location={locationInfo}
                    categoryEventCode="LIVE"
                    title="Gigs"
                />
            </LazyHydrate>
            <LazyHydrate whenVisible>
                <LandingCategory
                    location={locationInfo}
                    categoryEventCode="FEST"
                    title="Festivals"
                />
            </LazyHydrate>
            <LazyHydrate whenVisible>
                <LandingCategory
                    location={locationInfo}
                    categoryEventCode="COMEDY,THEATRE,ARTS,FILM,DANCE"
                    title="Comedy, Theatre & Arts"
                />
            </LazyHydrate>
            <LazyHydrate whenVisible>
                <LandingCategory
                    location={locationInfo}
                    categoryEventCode="EXHIB,EXPER,DRIVEIN,KIDS"
                    title="Experiences & Attractions"
                />
            </LazyHydrate>
            <LazyHydrate whenVisible>
                <LandingCategory
                    location={locationInfo}
                    categoryEventCode="FOOD,BARPUB"
                    title="Food & Drink"
                />
            </LazyHydrate>
        </div>
    );
};

export default LandingPage;
