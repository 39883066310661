import { Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { canSubmitLocationFilter } from "@skiddle/web.events.store.location";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
    ErrorBoundaryMapDispatch,
    ErrorBoundaryProps,
    ErrorBoundaryState,
} from "./types";

const geoLocationError = "User denied Geolocation";

class LocationErrorBoundaryComponent extends Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    state = {
        error: null,
    };

    promiseRejectionHandler = (event: PromiseRejectionEvent) => {
        this.setState({
            error: event.reason.message as string,
        });
    };

    componentDidCatch(err: Error) {
        this.setState({
            error: err.message,
        });
    }

    componentDidMount() {
        window.addEventListener(
            "unhandledrejection",
            this.promiseRejectionHandler,
        );
    }

    componentWillUnmount() {
        window.removeEventListener(
            "unhandledrejection",
            this.promiseRejectionHandler,
        );
    }

    handleSnackbarClose = () => {
        this.setState({ error: null });
    };

    render() {
        const { error } = this.state;

        if (error === geoLocationError) {
            this.props.canSubmitLocationFilter(false);
        }

        return (
            <>
                {error === geoLocationError && (
                    <Snackbar
                        open
                        onClose={this.handleSnackbarClose}
                        anchorOrigin={{ horizontal: "center", vertical: "top" }}
                        onClick={this.handleSnackbarClose}
                        style={{ cursor: "pointer" }}
                    >
                        <Alert severity="warning">
                            <Typography variant="body1">
                                Can't show events near you, without access to
                                your location
                            </Typography>
                        </Alert>
                    </Snackbar>
                )}
                {this.props.children}
            </>
        );
    }
}

export const LocationErrorBoundary = connect<
    null,
    ErrorBoundaryMapDispatch,
    { children: {} }
>(null, {
    canSubmitLocationFilter,
})(LocationErrorBoundaryComponent);
