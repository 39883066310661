import {
    UpdateOrderBy,
    UpdateLocation,
    UpdateRadius,
    UpdateKeyword,
    UpdateGenres,
    UpdateEventTypes,
    FiltersToSearchApiParams,
    UpdateMinDate,
    UpdateMaxDate,
    UpdateMinMaxDates,
    UpdateTicketsAvailable,
    UpdateHideCancelled,
    UpdateUnder18,
    UpdateOther,
    FiltersState,
    UpdateMinMaxDatesAction,
    UpdateShowVirtual,
} from "./types";

export const updateOrderBy = (
    order: FiltersState["orderBy"],
): UpdateOrderBy => ({
    type: "UPDATE_FILTERS_ORDER_BY",
    value: order,
});

export const updateLocation = (
    latitude: FiltersState["latitude"],
    longitude: FiltersState["longitude"],
): UpdateLocation => ({
    type: "UPDATE_FILTERS_LOCATION",
    value: [latitude, longitude],
});

export const updateRadius = (radius: FiltersState["radius"]): UpdateRadius => ({
    type: "UPDATE_FILTERS_RADIUS",
    value: radius,
});

export const updateKeyword = (
    keyword: FiltersState["keyword"],
): UpdateKeyword => ({
    type: "UPDATE_FILTERS_KEYWORD",
    value: keyword,
});

export const updateGenres = (genres: FiltersState["genres"]): UpdateGenres => ({
    type: "UPDATE_FILTERS_GENRES",
    value: genres,
});

export const updateEventTypes = (
    eventTypes: FiltersState["eventTypes"],
): UpdateEventTypes => ({
    type: "UPDATE_FILTERS_EVENT_TYPES",
    value: eventTypes,
});

export const updateMinDate = (date: string): UpdateMinDate => ({
    type: "UPDATE_FILTERS_MIN_DATE",
    value: date,
});

export const updateMaxDate = (date: string): UpdateMaxDate => ({
    type: "UPDATE_FILTERS_MAX_DATE",
    value: date,
});

export const updateMinMaxDates: UpdateMinMaxDatesAction = (
    minDate: string,
    maxDate: string,
): UpdateMinMaxDates => ({
    type: "UPDATE_FILTERS_MIN_MAX_DATES",
    value: {
        minDate,
        maxDate,
    },
});

export const updateTicketsAvailable = (
    ticketsAvailable: FiltersState["ticketsAvailable"],
): UpdateTicketsAvailable => ({
    type: "UPDATE_FILTERS_TICKETS_AVAILABLE",
    value: ticketsAvailable,
});

export const updateHideCancelled = (
    hideCancelled: FiltersState["hideCancelled"],
): UpdateHideCancelled => ({
    type: "UPDATE_FILTERS_HIDE_CANCELLED",
    value: hideCancelled,
});

export const updateUnder18 = (
    under18: FiltersState["under18"],
): UpdateUnder18 => ({
    type: "UPDATE_FILTERS_UNDER_18",
    value: under18,
});

export const updateOther = (
    ticketsAvailable: FiltersState["ticketsAvailable"],
    hideCancelled: FiltersState["hideCancelled"],
    under18: FiltersState["under18"] | null,
): UpdateOther => ({
    type: "UPDATE_FILTERS_OTHER",
    value: {
        ticketsAvailable,
        hideCancelled,
        under18,
    },
});

export const updateShowVirtual = (
    showVirtual: FiltersState["showVirtual"],
): UpdateShowVirtual => ({
    type: "UPDATE_SHOW_VIRTUAL",
    value: showVirtual,
});

export const filterToSearchApiParams: FiltersToSearchApiParams = {
    orderBy: "order",
    latitude: "latitude",
    longitude: "longitude",
    minDate: "minDate",
    maxDate: "maxDate",
    eventTypes: "eventcode",
    genres: "g",
    keyword: "keyword",
    radius: "radius",
    hideCancelled: "hidecancelled",
    ticketsAvailable: "ticketsavailable",
    under18: "under18",
    showVirtual: "showVirtualEvents",
};
