import { createTheme, darken, lighten } from "@material-ui/core/styles";

export const maxContentWidth = "1080px";
export const boxShadow = "0 1px 7px 0 rgba(19,19,19,.5)";
export const boxShadowFilter = "rgba(19, 19, 19, 0.5) 0px 5px 6px";
export const black = "#484848";
export const grey = "#9a9a9a";
export const white = "#ececec";
export const showMoreFontSizeDesktop = "1.25rem";
export const showMoreFontSizeMobile = "1.15rem";
export const navyBlue = "#163d8d";
export const navBarBackground = "#38312e";
// export const navBarBackground = "rgba(112, 219, 212, 1)";
export const lighterBlack = "#131313";
export const filterColor = "rgba(60, 184, 176, 1)";

const defaultTheme = createTheme();
const palette = (primaryColour?: string, textColour?: string) => {
    const primary = {
        light: "rgba(112, 219, 212, 1)",
        main: "rgba(60, 184, 176, 1)",
        dark: "rgba(29, 145, 137, 1)",
        contrastText: "#fff",
    };
    const text = {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
    };

    if (primaryColour && textColour) {
        primary.main = primaryColour;
        primary.contrastText = textColour;
        primary.dark = darken(primaryColour, 0.4);
        primary.light = lighten(primaryColour, 0.4);

        text.primary = `${textColour}87`;
        text.secondary = `${textColour}54`;
        text.disabled = `${textColour}38`;
        text.hint = `${textColour}38`;
    }

    return {
        common: { black: "#000", white: "#fff" },
        background: { paper: "#fff", default: "#fafafa" },
        primary,
        secondary: {
            light: "#ff4081",
            main: "#f50057",
            dark: "#c51162",
            contrastText: "#fff",
        },
        error: {
            light: "#e57373",
            main: "#c1000e",
            dark: "#d32f2f",
            contrastText: "#fff",
        },
        text,
    };
};

const theme = createTheme({
    palette: palette(),
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: navBarBackground,
            },
        },
        MuiButton: {
            contained: {
                boxShadow: boxShadow,
            },
            fullWidth: {
                marginTop: "11px",
                fontSize: "1.0rem",
                padding: "4px 16px",
            },
            outlined: {
                border: "2px solid",
                "&:hover": {
                    border: "2px solid",
                },
            },
            outlinedPrimary: {
                border: "2px solid",
                "&:hover": {
                    border: "2px solid",
                },
            },
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: 650,
            },
        },
        MuiDialogTitle: {
            root: {
                "& h2": {
                    color: palette().primary.main,
                    textTransform: "none",
                    fontWeight: "bold",
                },
            },
        },
        MuiDialogContent: {
            root: {
                padding: "8px 15px",
            },
        },
        MuiDialogActions: {
            spacing: {
                justifyContent: "space-between",
            },
        },
    },
    typography: {
        fontFamily: `"BuenosAires",sans-serif;`,
        h2: {
            fontWeight: 600,
            fontSize: "2.5rem",
            color: "#484848",
            marginTop: "1.4em",
            marginBottom: "0.5em",
            textTransform: "none",
            [defaultTheme.breakpoints.up("xs")]: {
                fontSize: "2.2rem",
            },
        },
    },
});

export const setThemeColours = (primary?: string, contrastTxt?: string) => {
    const overridenPalette = palette(primary, contrastTxt);

    theme.palette = { ...theme.palette, ...overridenPalette };
    if (theme.overrides && theme.overrides.MuiAppBar) {
        theme.overrides.MuiAppBar.colorPrimary = {
            backgroundColor: overridenPalette.primary.main,
        };
    }
};

export default theme;
