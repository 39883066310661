import { Props } from "./types";
import React from "react";
import Button from "@material-ui/core/Button";
import useStyles from "./styles";
import classNames from "classnames";

const SearchButton: React.FC<Props> = ({
    title,
    isActive,
    onClick,
    icon,
    minWidth,
}) => {
    const classes = useStyles();
    const materialIcon = icon ?? null;

    return (
        <Button
            onClick={onClick}
            variant="outlined"
            className={classNames(classes.button, {
                [classes.active]: isActive,
            })}
            classes={{ root: classes.root }}
            style={{ minWidth }}
            disableElevation={false}
            startIcon={<span className={classes.icon}>{materialIcon}</span>}
        >
            {title}
        </Button>
    );
};

export default SearchButton;
