/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useKeywordQueryParam } from ".";
import { updateKeyword } from "@skiddle/web.events.store.filters";
import { SelectorState } from "../types";

export const useOnMount = (
    defaultValue: string,
    filterKeyword: SelectorState["filterKeyword"],
    useUrlParams: boolean,
) => {
    const dispatch = useDispatch();
    const [keyword, setKeyword] = useKeywordQueryParam();

    useEffect(() => {
        if (filterKeyword === undefined) {
            dispatch(updateKeyword(keyword || defaultValue));
            if (!keyword && useUrlParams) {
                setKeyword(defaultValue);
            }
        }
    }, []);
};

export const useOnKeywordFilterStoreChange = (
    keyword: string,
    setKeyword: React.Dispatch<React.SetStateAction<string>>,
    filterKeyword: SelectorState["filterKeyword"],
    useUrlParams: boolean,
) => {
    const [, setKeywordParam] = useKeywordQueryParam();

    useEffect(() => {
        if (filterKeyword !== undefined && keyword !== filterKeyword) {
            setKeyword(filterKeyword);
            if (useUrlParams) {
                setKeywordParam(filterKeyword);
            }
        }
    }, [filterKeyword]);
};