import { SEO } from "@skiddle/web.events.store.types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { seoResult } from "../../../store/seo/selectors";
import { State } from "../../../store/types";
import { LocationUrlParams } from "../../filters/location/types";
import { onlineTitleText } from "../PageTitle";
import { SeoDataSelectState } from "../types";

const parseSearchTitle = (urlLocation: string, h1: string) => {
    switch (urlLocation?.toLowerCase()) {
        case "online":
            return onlineTitleText;
        case "all":
            return h1 || "events search";
        default:
            return h1;
    }
};

const SearchTitle = () => {
    const { location: urlLocation } = useParams<LocationUrlParams>();
    const {
        seo: { h1 = "events search" },
    } = useSelector<State, SeoDataSelectState>((state) => ({
        seo: seoResult(state) || ({} as SEO),
    }));
    const [title, setTitle] = useState(parseSearchTitle(urlLocation, h1));

    useEffect(() => {
        setTitle(parseSearchTitle(urlLocation, h1));
    }, [urlLocation, h1]);

    return title;
};

export default SearchTitle;
