import { makeStyles } from "@material-ui/core/styles";
import { boxShadowFilter } from "@skiddle/web.global.theme";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        height: 6,
        boxShadow: boxShadowFilter,
        width: "100%",
    },
}));

export default useStyles;
