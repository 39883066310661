import React, { useEffect } from "react";
import { defaultEmptyOptionsMap } from "@skiddle/web.events.data";
import { buildEventLocationUrl } from "@skiddle/web.events.helpers";
import { EventTypeUrlParams } from "@skiddle/web.events.shared-types";
import { useParams } from "react-router-dom";
import { defaultValues } from "@skiddle/web.events.filters.location";
import useCombinedHooks from "../../../filters/location/hooks";
import LocationSearchInput from "../../../filters/location/view/search-input";
import { Props } from "./types";

const LandingLocationFilter: React.FC<Props> = ({
    getLocation,
    getLocations,
    locationFilter,
    locationController,
    locationResult,
    locations,
    radiusFilter,
    showVirtual,
    seoResult,
    useGetSeoData,
    pathPrefix,
    seoClear,
    useUrlParams = true,
    confirmFilters,
    queryParams,
    paramsRequired,
}) => {
    const {
        locationSelect,
        parsedLocations,
        searchInputChange,
        searchLocation,
        poiID,
    } = useCombinedHooks(
        defaultValues,
        getLocation,
        getLocations,
        locationFilter,
        locationController,
        locationResult,
        locations,
        radiusFilter,
        showVirtual,
        seoResult,
        useGetSeoData,
        pathPrefix,
        seoClear,
        useUrlParams,
    );
    const { eventType } = useParams<EventTypeUrlParams>();
    useEffect(() => {
        const requiredParamsSet = paramsRequired.every((param) =>
            queryParams.includes(param),
        );

        if (confirmFilters && requiredParamsSet) {
            const lowerCaseSearchLocation = searchLocation.toLowerCase();
            const eventTypeParam = !eventType ? true : false;
            const defaultOptionValue = ["all", "online", "near me"].includes(
                lowerCaseSearchLocation,
            )
                ? lowerCaseSearchLocation
                : defaultEmptyOptionsMap.get(searchLocation) ||
                  (searchLocation === "" ? "all" : "");

            if (defaultOptionValue !== "") {
                const url = buildEventLocationUrl(
                    defaultOptionValue,
                    pathPrefix,
                    "",
                    "",
                    "",
                    eventTypeParam,
                    eventType,
                    false,
                );
                window.location.href = `${url}?${queryParams.replace(
                    /^&/,
                    "",
                )}`;
                return;
            }

            if (locationResult) {
                const { postcodeLookup, name, type } = locationResult;
                if (searchLocation && postcodeLookup) {
                    const url = buildEventLocationUrl(
                        postcodeLookup,
                        pathPrefix,
                        poiID,
                        name,
                        type,
                        eventTypeParam,
                        eventType,
                        false,
                    );
                    window.location.href = `${url}?${queryParams.replace(
                        /^&/,
                        "",
                    )}`;
                    return;
                }
            }
        }
    }, [confirmFilters, queryParams]);

    return (
        <LocationSearchInput
            locationSelect={locationSelect}
            parsedLocations={parsedLocations}
            searchInputChange={searchInputChange}
            searchLocation={searchLocation}
            fontSize="1.2rem"
        />
    );
};

export default LandingLocationFilter;
