import { FiltersState, FilterActions, LastUpdatedFilterState } from "./types";
import { Reducer } from "redux";
import deepEqual from "deep-equal";

export const initialState: FiltersState = {
    orderBy: undefined,
    latitude: undefined,
    longitude: undefined,
    radius: undefined,
    minDate: undefined,
    maxDate: undefined,
    eventTypes: undefined,
    genres: undefined,
    keyword: undefined,
    hideCancelled: undefined,
    ticketsAvailable: undefined,
    under18: undefined,
    showVirtual: undefined,
};

const reducer: Reducer<FiltersState, FilterActions> = (
    state = initialState,
    action,
) => {
    // If any new cases are added to this reducer, they also need adding to compareQueryParamsClientSide reducer in (store/reducer.ts)
    switch (action.type) {
        case "UPDATE_FILTERS_ORDER_BY":
            return {
                ...state,
                orderBy: action.value,
            };

        case "UPDATE_FILTERS_LOCATION":
            const [latitude, longitude] = action.value;
            if (latitude === state.latitude && longitude === state.longitude) {
                return state;
            }

            return {
                ...state,
                latitude,
                longitude,
            };

        case "UPDATE_FILTERS_RADIUS":
            return {
                ...state,
                radius: action.value,
            };

        case "UPDATE_FILTERS_KEYWORD":
            return {
                ...state,
                keyword: action.value,
            };

        case "UPDATE_FILTERS_GENRES":
            return {
                ...state,
                genres: action.value,
            };

        case "UPDATE_FILTERS_EVENT_TYPES":
            if (deepEqual(action.value, state.eventTypes)) {
                return state;
            }

            return {
                ...state,
                eventTypes: action.value,
            };

        case "UPDATE_FILTERS_MIN_DATE":
            if (state.minDate === action.value) {
                return state;
            }

            return {
                ...state,
                minDate: action.value,
            };

        case "UPDATE_FILTERS_MAX_DATE":
            if (state.maxDate === action.value) {
                return state;
            }

            return {
                ...state,
                maxDate: action.value,
            };

        case "UPDATE_FILTERS_MIN_MAX_DATES":
            const { minDate, maxDate } = action.value;
            if (state.minDate === minDate && state.maxDate === maxDate) {
                return state;
            }

            return {
                ...state,
                minDate,
                maxDate,
            };

        case "UPDATE_FILTERS_TICKETS_AVAILABLE":
            return {
                ...state,
                ticketsAvailable: action.value,
            };

        case "UPDATE_FILTERS_HIDE_CANCELLED":
            return {
                ...state,
                hideCancelled: action.value,
            };

        case "UPDATE_FILTERS_UNDER_18":
            return {
                ...state,
                under18: action.value,
            };

        case "UPDATE_FILTERS_OTHER":
            const { ticketsAvailable, hideCancelled, under18 } = action.value;

            return {
                ...state,
                ticketsAvailable,
                hideCancelled,
                under18,
            };

        case "UPDATE_SHOW_VIRTUAL":
            return {
                ...state,
                showVirtual: action.value,
            };

        default:
            return state;
    }
};

export const lastUpdatedReducer: Reducer<
    LastUpdatedFilterState,
    FilterActions
> = (state = "", action) => {
    switch (action.type) {
        case "EVENTS_CATEGORY_SEARCH_FETCH_SUCCESS":
            const { totalCount, results } = action;

            if (totalCount > 0 && results.length > 0) {
                return "";
            }

            return state;

        case "UPDATE_FILTERS_ORDER_BY":
            return "orderBy";

        case "UPDATE_FILTERS_LOCATION":
            return "location";

        case "UPDATE_FILTERS_KEYWORD":
            return "keyword";

        case "UPDATE_FILTERS_GENRES":
            return "genre";

        case "UPDATE_FILTERS_EVENT_TYPES":
            return "eventType";

        case "UPDATE_FILTERS_MIN_DATE":
        case "UPDATE_FILTERS_MAX_DATE":
        case "UPDATE_FILTERS_MIN_MAX_DATES":
            return "date";

        case "UPDATE_FILTERS_TICKETS_AVAILABLE":
            return "ticketsAvailable";

        case "UPDATE_FILTERS_HIDE_CANCELLED":
            return "hideCancelled";

        case "UPDATE_FILTERS_UNDER_18":
            return "under18";

        case "UPDATE_SHOW_VIRTUAL":
            return "showVirtual";

        default:
            return state;
    }
};

export default reducer;
