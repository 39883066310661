import React, { useEffect, useState } from "react";
import { KeywordSearchInput } from "@skiddle/web.events.filters.keyword";
import { Props } from "./types";
import useStyles from "./styles";
import { StringParam, useQueryParam } from "use-query-params";

const LandingKeywordFilter: React.FC<Props> = ({
    confirmFilters,
    setConfirmFilters,
    setQueryParams,
}) => {
    const classes = useStyles();
    const [keywordQuery] = useQueryParam("keyword", StringParam);
    const [keyword, setKeyword] = useState(keywordQuery || "");

    useEffect(() => {
        if (confirmFilters) {
            setQueryParams(
                (queryParams) => `${queryParams}&keyword=${keyword}`,
            );
        }
    }, [confirmFilters]);

    const handleKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === "Enter") {
            setConfirmFilters(true);
        }
    };
    const searchInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setKeyword(e.target.value);

    return (
        <KeywordSearchInput
            classes={classes}
            handleKeyPress={handleKeyPress}
            keyword={keyword}
            searchChange={searchInputChange}
        />
    );
};

export default LandingKeywordFilter;
