import { Dispatch } from "redux";
import { State } from "../types";
import { userProfileIsFetching } from "./selector";
import {
    GetUserProfile,
    UserActions,
    UserProfile,
    UserProfileFetch,
    UserProfileFetchSuccess,
} from "./types";
import "isomorphic-fetch";
import { apiError, APIV3Response } from "@skiddle/web.events.store.types";

const apiURL = process.env.REACT_APP_V3_API_URL;

export const userProfileFetch = (): UserProfileFetch => ({
    type: "USER_PROFILE_FETCH",
});

export const userProfileFetchSuccess = (
    result: UserProfile,
): UserProfileFetchSuccess => ({
    type: "USER_PROFILE_FETCH_SUCCESS",
    result,
});

export const getUserProfile: GetUserProfile =
    (state: State, dispatch: Dispatch<UserActions>) => async () => {
        if (userProfileIsFetching(state)) {
            return;
        }

        dispatch(userProfileFetch());

        try {
            const resp = await fetch(`${apiURL}/user/`, {
                method: "GET",
                mode: "cors",
                credentials: "include",
            });

            const { data }: APIV3Response<UserProfile> = await resp.json();

            if (data) {
                dispatch(userProfileFetchSuccess(data as UserProfile));
                return;
            }

            throw new Error("Failed to get user profile data");
        } catch (err) {
            console.error(err);
            dispatch(apiError(err as string));

            throw err;
        }
    };
