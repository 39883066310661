import { State } from "../../types";
import { FetchingCategories } from "./types";
import { FiltersState } from "../../filters/types";
import { EventsSearchResult } from "@skiddle/web.events.shared-types";
import { Aggregations } from "@skiddle/web.events.shared-types";

export const eventsSearchIsFetching = (
    state: State,
    category: string,
): boolean => state.events.search.fetching[category] || false;

export const eventsSearchIsFetchingAll = (state: State): FetchingCategories =>
    state.events.search.fetching;

export const anyEventsSearchIsFetching = (state: State): boolean =>
    Object.values(state.events.search.fetching).some((fetching) => fetching);

export const eventsSearchResults = (state: State): EventsSearchResult[] =>
    Object.values(state.events.search.results).flatMap((results) => results);

export const categoryEventsSearchResults = (
    state: State,
    category: string,
): EventsSearchResult[] => state.events.search.results[category] || [];

export const categoryFetchControllers = (
    state: State,
    category: string,
): AbortController | undefined => state.events.search.fetch[category];

export const eventSearchCount = (state: State, category: string): number =>
    state.events.search.results[category].length || 0;

export const eventSearchAggregations = (state: State): Aggregations =>
    state.aggregations;

export const eventFilters = (state: State): FiltersState => state.filters;

export const categoryEventTotalCount = (
    state: State,
    category: string,
): number => state.events.search.totalCounts[category];
