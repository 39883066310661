import React from "react";
import useStyles from "./styles";
import clsx from "clsx";
import ucwords from "ucwords";
import DoneIcon from "@material-ui/icons/Done";
import { Props } from "./types";
import { Chip } from "@material-ui/core";

const SelectList: React.FC<Props> = ({
    options,
    onSelect,
    multiSelect = false,
    selected,
    count,
    hideEmptyCount = false
}) => {
    const classes = useStyles();

    const handelSingleSelect = (name: string) => onSelect(name);
    const handleMultiSelect = (name: string) => {
        if (selected.includes(name)) {
            const selectedArr = selected as string[];
            selectedArr.splice(selectedArr.indexOf(name), 1);

            return onSelect([...selectedArr]);
        }

        onSelect([...(selected as string[]), name]);
    };

    return (
        <>
            {options
                .sort((a, b) => a.localeCompare(b))
                .map((optName, i) => {
                    const isSelected = (() => {
                        if (multiSelect) {
                            return selected.includes(optName);
                        }

                        return optName === selected;
                    })();

                    let label = `${ucwords(optName)}`;
                    let countLabel = null;
                    let disabled = false;

                    if (count && isSelected) {
                        const matchedCount =
                            count.find(
                                (x) => ucwords(x.name) === ucwords(optName),
                            )?.count ?? 0;

                        if (hideEmptyCount) {
                            if (matchedCount !== 0) {
                                countLabel = (
                                    <span className={classes.countLabel}>
                                    {matchedCount}
                                </span>
                                );
                            }
                        } else {
                            countLabel = (
                            <span className={classes.countLabel}>
                                {matchedCount}
                            </span>
                            );
                        }


                    }

                    return (
                        <Chip
                            className={clsx({
                                [classes.chip]: true,
                                [classes.optionSelected]: isSelected,
                            })}
                            key={label}
                            size="small"
                            label={label}
                            deleteIcon={
                                isSelected ? (
                                    <>
                                        {count ? countLabel : ""}
                                        <DoneIcon
                                            className={classes.checkMark}
                                        />
                                    </>
                                ) : (
                                    <></>
                                )
                            }
                            onDelete={() => {}}
                            clickable
                            onClick={() => {
                                if (multiSelect) {
                                    handleMultiSelect(optName);
                                    return;
                                }

                                handelSingleSelect(optName);
                            }}
                            disabled={!isSelected && disabled}
                        />
                    );
                })}
        </>
    );
};

export default SelectList;
