import React from "react";
import { Box, Divider as MuiDivider } from "@material-ui/core";
import useStyles from "./styles";
import { navyBlue } from "@skiddle/web.global.theme";
import "./divider.css";

const Divider: React.FC = () => {
    return (
        <Box className="divider-box">
            <MuiDivider
                style={{
                    backgroundColor: navyBlue,
                    height: "2px",
                }}
                orientation="horizontal"
            />
        </Box>
    );
};

export default Divider;
