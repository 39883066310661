import React from "react";
import { Props } from "./types";
import useStyles from "./styles";

const Spacer: React.FC<Props> = ({ fill = true }) => {
    const classes = useStyles({ fill });

    return <div className={classes.spacer}></div>;
};

export default Spacer;
