import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    showMoreFontSizeDesktop,
    showMoreFontSizeMobile,
} from "../../../theme";
const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.text.primary,
        fontSize: "1rem",
    },
    linkHighlight: {
        color: theme.palette.secondary.dark,
        fontSize: "1rem",
    },
    quickLinkH2: {
        textTransform: "none",
        fontSize: showMoreFontSizeMobile,
        "@media (min-width: 600px)": {
            fontSize: showMoreFontSizeDesktop,
        },
    },
}));

export default useStyles;
