import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import CardMui from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import skeletonTheme from "../theme";
import classNames from "classnames";
import SkeletonStatus from "../Status";
import { useOtherMobileSharedStyles } from "@skiddle/web.events.styles";

const SkeletonMobileCard = () => {
    const classes = useOtherMobileSharedStyles();

    return (
        <SkeletonTheme {...skeletonTheme}>
            <CardMui className={classes.root}>
                <CardContent
                    className={classes.content}
                    style={{ position: "relative" }}
                >
                    <SkeletonStatus />

                    <div>
                        <CardMedia
                            style={{ height: 0, background: "#eaeaea" }}
                            className={classes.media}
                            image={"none"}
                        />
                    </div>

                    <Box
                        p={"12px"}
                        className={classes.contentBox}
                        style={{ minHeight: 66 }}
                    >
                        <div style={{ minHeight: "55px", maxHeight: "55px" }}>
                            <Box
                                className={classNames(
                                    classes.contentSections,
                                    classes.contentSectionDate,
                                )}
                                color="inherit"
                            >
                                <Typography
                                    className={classNames(
                                        classes.contentSections,
                                        classes.contentSectionDate,
                                    )}
                                    variant="body2"
                                    color="inherit"
                                    // style={{ float: "left", marginTop: 2 }}
                                >
                                    <span className={classes.detailsListSmall}>
                                        <Skeleton
                                            width={50}
                                            height={10}
                                            count={1}
                                        />
                                    </span>
                                </Typography>
                            </Box>

                            <Typography
                                className={classes.cardTitle}
                                variant="body1"
                                component="h3"
                            >
                                <Skeleton width={150} height={15} count={1} />
                            </Typography>
                        </div>

                        <Box display={"flex"} alignItems={"center"}>
                            <Skeleton width={40} height={40} count={1} />
                        </Box>
                    </Box>
                </CardContent>
            </CardMui>
        </SkeletonTheme>
    );
};

export default SkeletonMobileCard;
