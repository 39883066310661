import React, { useEffect } from "react";
import { dateFormat, defaultValues } from "../../../filters/dates";
import useCombinedHooks from "../../../filters/dates/hooks";
import DateRangePicker from "../../../filters/dates/view/DateRangePicker";
import { Props } from "./types";
import { ClickAwayListener, NoSsr, Button, Box } from "@material-ui/core";
import "@skiddle/web.events.filters.dates/css/_datepicker.css";
import { getMomentWithTimezone } from "@skiddle/web.events.date-format";
import useStyles from "../../../filters/dates/view/styles";
import { buttonPresets } from "../../../filters/dates/view/constants";

const moment = getMomentWithTimezone();

const LandingDateFilter: React.FC<Props> = ({
    filterMinDate,
    filterMaxDate,
    useUrlParams = true,
    confirmFilters,
    setQueryParams,
}) => {
    const {
        fromDate,
        toDate,
        datesChange,
        focusedInput: [focusedInput, setFocusedInput],
        isMobile,
    } = useCombinedHooks(
        defaultValues,
        filterMinDate,
        filterMaxDate,
        false,
        false,
    );

    useEffect(() => {
        if (confirmFilters) {
            let queryDates = "";

            const fromDateFormatted =
                fromDate?.format(dateFormat) ||
                moment(defaultValues[0]).format(dateFormat);
            queryDates += `&from_date=${fromDateFormatted}`;

            const toDateFormatted = toDate?.format(dateFormat) || "";
            queryDates += `&to_date=${toDateFormatted}`;

            setQueryParams((queryParams) => `${queryParams}${queryDates}`);
        }
    }, [confirmFilters]);
    const classes = useStyles();

    const renderCalendarInfo = () => {
        return (
            <Box
                className={classes.presetContainer}
                style={{
                    maxWidth: isMobile ? "300px" : undefined,
                    marginTop: isMobile ? "1rem" : undefined,
                }}
            >
                <Box
                    style={{
                        justifyContent: "space-evenly",
                        alignItems: "flex-start",
                        display: "flex",
                    }}
                >
                    {buttonPresets.map(({ text, start, end }) => {
                        return (
                            <Button
                                classes={{ root: classes.buttonRoot }}
                                style={{
                                    fontSize: isMobile ? "0.875rem" : "1rem",
                                }}
                                key={text}
                                type="button"
                                onClick={() =>
                                    datesChange({
                                        startDate: start,
                                        endDate: end,
                                    })
                                }
                            >
                                {text}
                            </Button>
                        );
                    })}
                </Box>
            </Box>
        );
    };

    return (
        <ClickAwayListener onClickAway={() => setFocusedInput(null)}>
            <span id="date-range-picker-container">
                <NoSsr
                    fallback={
                        <DateRangePicker
                            firstDayOfWeek={1}
                            startDate={fromDate}
                            startDateId="dates-filter-start"
                            endDate={toDate}
                            endDateId="dates-filter-end"
                            onDatesChange={datesChange}
                            focusedInput={null}
                            onFocusChange={(input) => {
                                if (input && input !== focusedInput) {
                                    setFocusedInput(input);
                                }
                            }}
                            readOnly
                            displayFormat={dateFormat}
                            orientation={"horizontal"}
                            dayPickerNavigationInlineStyles={{ zIndex: 100 }}
                            renderCalendarInfo={renderCalendarInfo}
                        />
                    }
                >
                    <DateRangePicker
                        firstDayOfWeek={1}
                        startDate={fromDate}
                        startDateId="dates-filter-start"
                        endDate={toDate}
                        endDateId="dates-filter-end"
                        onDatesChange={datesChange}
                        focusedInput={focusedInput}
                        onFocusChange={(input) => {
                            if (input && input !== focusedInput) {
                                setFocusedInput(input);
                            }
                        }}
                        readOnly
                        displayFormat={dateFormat}
                        calendarInfoPosition={isMobile ? "before" : "bottom"}
                        orientation={isMobile ? "vertical" : "horizontal"}
                        verticalHeight={isMobile ? 490 : undefined}
                        dayPickerNavigationInlineStyles={{ zIndex: 100 }}
                        renderCalendarInfo={renderCalendarInfo}
                    />
                </NoSsr>
            </span>
        </ClickAwayListener>
    );
};

export default LandingDateFilter;
