import makeStyles from "@material-ui/core/styles/makeStyles";
import { white } from "../../../theme";

const useStyles = makeStyles((theme) => ({
    seeMoreMobileContainer: {
        width: "100%",
        "@media (min-width: 600px)": {
            display: "none",
        },
    },
    seeMoreContainer: {
        width: "100%",
        "@media (max-width: 600px)": {
            display: "none",
        },
    },
    seeMore: {
        textTransform: "none",
        fontWeight: 600,
        fontSize: "1.10rem",
        float: "right",
        padding: "3px 12px",
        position: "relative",
        bottom: "-10px",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
            color: white,
        },
    },
}));

export default useStyles;
