import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { Box, IconButton } from "@material-ui/core";
import { Props } from "@skiddle/web.global.template.nav-bar.nav-list/types";
import CloseIcon from "@material-ui/icons/Close";
import { white } from "@skiddle/web.global.theme";
import UAParser from "ua-parser-js";

const NavList: React.FC<Props> = ({ navigation }) => {
    const classes = useStyles();
    const [hovering, setHovering] = useState(false);
    const [navKey, setNavKey] = useState<null | undefined | number>();
    const [deviceType, setDeviceType] = useState("desktop");
    const isTabletOrMobile = ["tablet", "mobile"].includes(deviceType);

    useEffect(() => {
        const uaParsed = new UAParser(navigator.userAgent);
        setDeviceType(uaParsed.getDevice().type || "desktop");
    }, []);

    const handleMouseEvent = (state: boolean, key?: number | undefined) => {
        setNavKey(key);
        setHovering(state);
    };
    return (
        <>
            <Typography
                variant="h6"
                component={"div"}
                className={classes.navItem}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3}
                    onMouseLeave={() => handleMouseEvent(false)}
                >
                    {navigation.map((nav, key) => {
                        return (
                            <Grid item key={`navitem_${key}`}>
                                <Link
                                    onMouseEnter={() =>
                                        handleMouseEvent(true, key)
                                    }
                                    onClick={(e) => {
                                        if (nav.categories.length === 0) {
                                            return;
                                        }

                                        if (isTabletOrMobile) {
                                            e.preventDefault();
                                        }
                                        handleMouseEvent(true, key);
                                    }}
                                    className={classes.navLink}
                                    href={nav.titleLink}
                                    underline={"none"}
                                >
                                    {nav.title}
                                </Link>
                                {hovering && nav.categories.length !== 0 && (
                                    <div
                                        className={classes.navBridge}
                                        style={{
                                            display:
                                                navKey === key
                                                    ? "block"
                                                    : "none",
                                        }}
                                    >
                                        <>
                                            {isTabletOrMobile && (
                                                <IconButton
                                                    // className={classes.closeBtn}
                                                    onClick={(e) =>
                                                        handleMouseEvent(
                                                            false,
                                                            key,
                                                        )
                                                    }
                                                    style={{
                                                        position: "absolute",
                                                        top: "10px",
                                                        right: "2px",
                                                        color: white,
                                                        zIndex: 250,
                                                    }}
                                                    id="icon-btn-close"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            )}
                                            <div className={classes.navMenu}>
                                                {/* Menu Title */}
                                                {nav.links &&
                                                    nav.links.map(
                                                        ({ id, title, link }) =>
                                                            id ===
                                                                "all_eventtype" && (
                                                                <React.Fragment
                                                                    key={id}
                                                                >
                                                                    <Link
                                                                        className={
                                                                            classes.menuTitle
                                                                        }
                                                                        underline={
                                                                            "none"
                                                                        }
                                                                        href={
                                                                            link
                                                                        }
                                                                    >
                                                                        {title}
                                                                    </Link>
                                                                    <Box
                                                                        m={1}
                                                                    />
                                                                </React.Fragment>
                                                            ),
                                                    )}

                                                <Grid
                                                    spacing={4}
                                                    alignContent="flex-start"
                                                    container
                                                    direction="row"
                                                >
                                                    {/* Categories */}
                                                    {nav.categories.map(
                                                        ({
                                                            category,
                                                            links,
                                                            id,
                                                        }) => (
                                                            <Grid
                                                                item
                                                                key={category}
                                                                alignContent="flex-start"
                                                            >
                                                                <Typography
                                                                    className={
                                                                        classes.categoryTitle
                                                                    }
                                                                    variant="h6"
                                                                    color={
                                                                        "primary"
                                                                    }
                                                                >
                                                                    {category}
                                                                </Typography>
                                                                <div>
                                                                    <Grid
                                                                        style={{
                                                                            maxWidth:
                                                                                id ===
                                                                                    "top_clubs" ||
                                                                                id ===
                                                                                    "top_events"
                                                                                    ? "10rem"
                                                                                    : links.length >=
                                                                                      20
                                                                                    ? "40rem"
                                                                                    : links.length >=
                                                                                      12
                                                                                    ? "20rem"
                                                                                    : "10rem",
                                                                            ...(category ===
                                                                            "festival tickets"
                                                                                ? {
                                                                                      maxHeight: 270,
                                                                                  }
                                                                                : {}),
                                                                        }}
                                                                        container
                                                                        spacing={
                                                                            1
                                                                        }
                                                                        direction={
                                                                            category ===
                                                                            "festival tickets"
                                                                                ? "column"
                                                                                : "row"
                                                                        }
                                                                    >
                                                                        {links?.map(
                                                                            (
                                                                                {
                                                                                    title,
                                                                                    link,
                                                                                },
                                                                                key,
                                                                            ) => (
                                                                                <Grid
                                                                                    key={`grid-${title}-${link}-${key}`}
                                                                                    item
                                                                                    sm={
                                                                                        id ===
                                                                                            "top_clubs" ||
                                                                                        id ===
                                                                                            "top_events"
                                                                                            ? 12
                                                                                            : links.length >=
                                                                                              20
                                                                                            ? 3
                                                                                            : links.length >=
                                                                                              12
                                                                                            ? 6
                                                                                            : 12
                                                                                    }
                                                                                    style={{
                                                                                        fontWeight: 500,
                                                                                        padding:
                                                                                            "0 4px",
                                                                                        flexGrow:
                                                                                            links.length >=
                                                                                            20
                                                                                                ? 2
                                                                                                : 1,
                                                                                        ...(category ===
                                                                                        "festival tickets"
                                                                                            ? {
                                                                                                  width: 600,
                                                                                              }
                                                                                            : {}),
                                                                                    }}
                                                                                >
                                                                                    <Link
                                                                                        key={`${title}-${link}-${key}`}
                                                                                        className={
                                                                                            classes.categoryLink
                                                                                        }
                                                                                        href={
                                                                                            link
                                                                                        }
                                                                                        underline={
                                                                                            "none"
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            title
                                                                                        }
                                                                                    </Link>
                                                                                </Grid>
                                                                            ),
                                                                        )}
                                                                    </Grid>
                                                                </div>
                                                            </Grid>
                                                        ),
                                                    )}
                                                </Grid>

                                                {/* Genres */}
                                                <div
                                                    className={
                                                        classes.genresContainer
                                                    }
                                                >
                                                    {nav.genres &&
                                                        Object.keys(nav.genres)
                                                            .length && (
                                                            <>
                                                                <Typography
                                                                    className={
                                                                        classes.categoryTitle
                                                                    }
                                                                    variant="h6"
                                                                >
                                                                    {
                                                                        nav
                                                                            .genres
                                                                            ?.title
                                                                    }
                                                                </Typography>

                                                                <Grid container>
                                                                    {nav.genres.links?.map(
                                                                        ({
                                                                            title,
                                                                            image,
                                                                            link,
                                                                        }) => (
                                                                            <Grid
                                                                                item
                                                                                sm={
                                                                                    3
                                                                                }
                                                                                key={
                                                                                    title
                                                                                }
                                                                            >
                                                                                <Link
                                                                                    style={{
                                                                                        display:
                                                                                            "block",
                                                                                    }}
                                                                                    className={
                                                                                        classes.genresCard
                                                                                    }
                                                                                    href={
                                                                                        link
                                                                                    }
                                                                                    underline={
                                                                                        "none"
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        className={
                                                                                            classes.genresImage
                                                                                        }
                                                                                        src={
                                                                                            image
                                                                                                ? image
                                                                                                : ""
                                                                                        }
                                                                                        alt={
                                                                                            title
                                                                                        }
                                                                                    />
                                                                                    <div
                                                                                        className={
                                                                                            classes.genresLink
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            title
                                                                                        }
                                                                                    </div>
                                                                                </Link>
                                                                            </Grid>
                                                                        ),
                                                                    )}
                                                                </Grid>
                                                            </>
                                                        )}
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                )}
                            </Grid>
                        );
                    })}
                </Grid>
            </Typography>
        </>
    );
};

export default NavList;
