import makeStyles from "@material-ui/core/styles/makeStyles";
import { darken, Theme } from "@material-ui/core/styles";
import { filterColor } from "@skiddle/web.global.theme";

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        flex: "none",
        textTransform: "none",
        fontWeight: 400,
        fontSize: "0.850em",
        letterSpacing: "0.01857em",
        margin: theme.spacing(0.5),
        background: "#fff",
        border: "1px solid #ababab",
        "&:hover": {
            background: "#fff",
            border: "1px solid #ababab",
        },
        color: "#000000de",
    },
    icon: {
        display: "flex",
        "& svg": {
            fontSize: "1rem",
        },
    },
    root: {
        padding: "4px 10px",
        justifyContent: "flex-start",
        "&:hover": {
            borderWidth: 1,
            borderColor: filterColor,
        },
    },
    active: {
        background:
            theme.palette.primary.dark === "rgba(29, 145, 137, 1)"
                ? "#3a928e"
                : theme.palette.primary.dark,
        borderColor: "#387774",
        color: "white",
        borderWidth: 1,
        "&:hover": {
            background:
                theme.palette.primary.dark === "rgba(29, 145, 137, 1)"
                    ? "#32827e"
                    : darken(theme.palette.primary.dark, 0.2),
            borderColor: "#366d6a",
        },
    },
}));

export default useStyles;
