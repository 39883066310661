import React from "react";
import useStyles from "./styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Drawer from "../Drawer";
import { Container } from "@material-ui/core";
import SearchBar from "../SearchBar";
import { maxContentWidth } from "@skiddle/web.global.theme";
import NavList from "../NavList";
import { Props } from "./types";
import { useLocation } from "react-router-dom";
import { isLoggedIn } from "@skiddle/web.events.helpers";
import ProfileMenu from "../../ProfileMenu";
import { ProfileIcon } from "../../ProfileIcon";

const NavBar: React.FC<Props> = ({ navigation }) => {
    const classes = useStyles();
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] =
        React.useState<null | HTMLElement>(null);
    const isProfileMenuOpen = Boolean(profileMenuAnchorEl);
    const { pathname, search } = useLocation();
    const loggedIn = isLoggedIn();

    const handleClickProfileMenu = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
        if (!isLoggedIn()) return;

        setProfileMenuAnchorEl(e.currentTarget);
    };

    const currentPath = () => {
        let path = `${pathname}${search}`;
        if (typeof window !== "undefined" && window.location) {
            path = `${window.location.pathname}${window.location.search}`;
        }

        return encodeURIComponent(path);
    };

    const menuId = "primary-search-account-menu";

    return (
        <>
            <div className={classes.grow}>
                <AppBar position="static" className={classes.appBar}>
                    <Container
                        style={{ maxWidth: maxContentWidth, padding: 0 }}
                    >
                        <Toolbar
                            className={classes.toolbar}
                            disableGutters={true}
                        >
                            <Drawer navigation={navigation} />
                            <a
                                id="skiddle-logo"
                                className={classes.title}
                                href="/"
                            >
                                Skiddle.com
                            </a>

                            <div className={classes.navlist}>
                                <NavList navigation={navigation} />

                                <div className={classes.grow} />
                            </div>
                            <div style={{ minWidth: "4rem", display: "flex" }}>
                                <SearchBar />
                                <div
                                    style={{ display: "flex", marginRight: 10 }}
                                >
                                    <IconButton
                                        edge="end"
                                        aria-label="account of current user"
                                        aria-controls={menuId}
                                        aria-haspopup="true"
                                        onClick={handleClickProfileMenu}
                                        color="inherit"
                                        href={
                                            !loggedIn
                                                ? `/login/?url=${currentPath()}`
                                                : ""
                                        }
                                    >
                                        {loggedIn ? (
                                            <ProfileIcon />
                                        ) : (
                                            <AccountCircle />
                                        )}
                                    </IconButton>
                                </div>
                            </div>
                        </Toolbar>
                    </Container>
                </AppBar>
            </div>
            <ProfileMenu
                open={isProfileMenuOpen}
                anchorEl={profileMenuAnchorEl}
                onClose={() => {
                    setProfileMenuAnchorEl(null);
                }}
            />
        </>
    );
};

export default NavBar;
