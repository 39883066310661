import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import Box from "@material-ui/core/Box";
import { useIsMobile } from "@skiddle/web.events.hooks";
import Sticky from "react-sticky-el";
import { filterColor } from "@skiddle/web.global.theme";
import { Props } from "./types";

const maxWidth = 1087;

const Bar: React.FC<Props> = ({ justify, children }) => {
    const isMobile = useIsMobile();
    const classes = useStyles({ isMobile });
    const [stickyStyle, setStickStyle] = useState<{
        zIndex: number;
        left?: string;
        maxWidth?: number | string;
    }>(() => {
        const genericStyles = {
            zIndex: 100,
        };
        if (isMobile) {
            return { ...genericStyles, margin: "0px", marginTop: "5px" };
        }
        return { ...genericStyles, marginTop: 7 };
    });

    useEffect(() => {
        if (isMobile && stickyStyle.hasOwnProperty("maxWidth")) {
            setStickStyle((style) => {
                delete style.maxWidth;
                return {
                    ...style,
                };
            });
        }

        if (!isMobile && !stickyStyle.hasOwnProperty("maxWidth")) {
            setStickStyle((style) => {
                return { ...style, maxWidth };
            });
        }
    }, [isMobile]);

    if (typeof window !== "undefined" && window.document) {
        const innerWrap = document.querySelector<HTMLElement>(
            ".js-menus #inner-wrap",
        );

        if (isMobile && !!innerWrap) {
            const styles = `
                transform-style: initial;
                -webkit-perspective: initial;
                -webkit-transform-style: initial;
            `;
            innerWrap.setAttribute("style", styles);
        }
    }

    return (
        <Sticky
            boundaryElement="#filters-boundary"
            stickyStyle={stickyStyle}
            style={{
                backgroundColor: filterColor,
            }}
        >
            <Box className={classes.root}>
                <Box
                    className={classes.filters}
                    style={{ justifyContent: justify }}
                >
                    {children}
                    {/* leaves space at the on of filter scroll on mobile without breaking style */}
                    {isMobile && (
                        <Box width={"4px"} p={"4px"}>
                            &nbsp;
                        </Box>
                    )}
                </Box>
            </Box>
        </Sticky>
    );
};

export default Bar;
