import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop: React.FC = () => {
    const mounted = useRef(false);
    const { pathname } = useLocation();

    // On route change, scroll to the top of the page
    useEffect(() => {
        if (mounted.current) {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    // On mount
    useEffect(() => {
        mounted.current = true;
    }, []);

    return null;
};

export default ScrollToTop;
