import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStyles from "./styles";
import { useStateSelector } from "../../filters/eventTypes/hooks";
import { pathPrefix } from "../../../App";
import Button from "@material-ui/core/Button";
import { Props, ViewProps } from "./types";
import { EventType } from "../../../store/events/types/types";
import { LandingUrlParams } from "../../../Landing/types";
import { Box, Grid, Link } from "@material-ui/core";
import "./seeMore.css";

export const eventCodeToID = (events: string[], eventTypes: EventType[]) =>
    eventTypes
        .filter(({ eventcode }) => events.includes(eventcode))
        .map(({ eventcodeid }) => Number(eventcodeid));

const SeeMoreDesktop: React.FC<ViewProps> = ({ classes, link, category }) => (
    <Grid item xs={12} sm={12} md={12} style={{ paddingTop: 0, marginTop: 10 }}>
        <Box
            component="div"
            paddingTop={1}
            paddingBottom={1}
            width="100%"
            style={{ width: "100%", padding: "8px 0px" }}
        >
            <Link href={link}>
                <Button
                    variant="contained"
                    className="seeMore"
                    id="seeMoreDesktop"
                    disableElevation={false}
                    style={{ width: "100%", fontSize: "1.25rem" }}
                >
                    See more{category ? ` ${category.toLowerCase()}` : ""}
                </Button>
            </Link>
        </Box>
    </Grid>
);

const SeeMoreMobile: React.FC<ViewProps> = ({ classes, link, category }) => (
    <Link href={link}>
        <Button
            variant="contained"
            id="seeMoreMobile"
            className="seeMore"
            disableElevation={false}
        >
            See more{category ? ` ${category.toLowerCase()}` : ""}
        </Button>
    </Link>
);

const SeeMoreButton: React.FC<Props> = ({ eventCodes, category }) => {
    // builds link for {see more} button
    const { location = "/all" } = useParams<LandingUrlParams>();
    const classes = useStyles();
    const { eventTypes, order } = useStateSelector();
    const currentLocation = location?.substring(location.lastIndexOf("/") + 1);
    let link = "";
    if (eventTypes.length > 0) {
        if (eventCodes && eventCodes?.length > 1) {
            // multiple event types (grouped category)
            const eventNumbers = eventCodeToID(eventCodes, eventTypes);
            link = `${pathPrefix}/events/${currentLocation}/?eventcodes%5B%5D=${eventNumbers.join(
                "&eventcodes%5B%5D=",
            )}${order === "date" ? "&sort=date" : ""}`;
        } else {
            // single event type
            link = `/${eventCodes[0].toLowerCase()}/${currentLocation}${
                order === "date" ? "?sort=date" : ""
            }/`;
            if (eventCodes[0].toLowerCase() === "festivals") {
                link = `/festivals/cities/${currentLocation}/`;
            }
        }
    }

    return (
        <>
            <div className="seeMoreMobileContainer">
                <SeeMoreMobile
                    link={link}
                    classes={classes}
                    category={category}
                />
            </div>
            <div className="seeMoreContainer">
                <SeeMoreDesktop
                    link={link}
                    classes={classes}
                    category={category}
                />
            </div>
        </>
    );
};

export default SeeMoreButton;
